<template>
  <div
    :class="
      `${
        getSplitView == 1 && !isDrafts && !isSharedRoot && !isSpam
          ? 'cr-wrapper cr-split-1'
          : 'cr-wrapper'
      }`
    "
  >
    <div class="cr-container">
      <v-data-table
        show-select
        draggable="true"
        :headers="headers"
        :hide-default-header="!isShareRoot"
        hide-default-footer
        class="cr-mail-list"
        item-key="dataId"
        v-model="selectedMails"
        :mobile-breakpoint="0"
        :headers-length="100"
        :show-expand="!isDrafts && !isShareRoot && !isShare"
        :single-expand="isSent"
        :group-by="getGroupBy"
        :loading="getLoading"
        :no-data-text="noDataText"
        :options.sync="options"
        :expanded.sync="expanded"
        :items="getMails"
        :items-per-page="getPageSize"
        :server-items-length="getTotalElements"
        @page-count="pageCount = $event"
        v-drag-and-drop="{
          /**
           * drag&drop directive
           *
           * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀
           * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element
           * dragstart    - dragstart / Argument(event, setDragInfo))
           * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
           * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
           * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
           */
          DRAG_COMP: 'list',
          genHelper,
          dragstart,
          genDropzone,
          markDropzone,
          mouseup
        }"
      >
        <template
          v-slot:[`header.data-table-select`]="{
            props: { value, indeterminate },
            on: { input }
          }"
        >
          <v-simple-checkbox
            :ripple="false"
            :value="value"
            :indeterminate="indeterminate"
            @input="input"
          />
        </template>

        <template
          v-slot:body="{
            items,
            expand,
            isExpanded,
            select,
            isSelected,
            originalItemsLength
          }"
        >
          <tbody v-if="originalItemsLength">
            <!-- :closedDivision="closedDivision"
            @expandDivision="expandDivision" -->
            <TableRow
              v-for="(item, idx) in items"
              :key="item.mailId"
              :item="item"
              :hoverItem="hoverItem"
              :closedGroup="closedGroup"
              :getSplitView="getSplitView"
              :resizeWidth="minimumWidth"
              :isSent="isSent"
              :isDrafts="isDrafts"
              :isShareRoot="isShareRoot"
              :isShare="isShare"
              :isSpam="isSpam"
              :isMinimum="isMinimum"
              :isCreatedGroup="
                item.group &&
                  (idx == 0 || items[idx - 1].group !== item.group) &&
                  getGroupView == 1
              "
              :isExpanded="isExpanded(item)"
              @mailRowClick="item => mailRowClick(item)"
              @expand="expand"
              @expandGroup="expandGroup"
              @selectFunc="item => selectFunc(item, select, isSelected)"
              @select="select"
              @isSelected="isSelected"
              @mouseover="item => (hoverItem = item)"
              @mouseleave="hoverItem = null"
            />
          </tbody>
          <tbody v-else>
            <tr class="v-data-table__empty-wrapper">
              <td v-if="!getLoading" colspan="11" v-text="$t('common.3')"></td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
          <!-- 
            페이징
            page : 현재페이지
            pageSize : 한페이지당 아이템 수
            blockSize : 페이지 한 블럭당 갯수
            totalElements : 아이템 총 갯수
            pageClick : 페이지 이동시 라우팅 이벤트
           -->
          <Pagination
            v-if="pageCount > 0"
            type="list"
            :page.sync="page"
            :pageSize="getPageSize"
            :blockSize="5"
            :totalElements="getTotalElements"
            @pageClick="page => pageClick(page)"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* 메인 컨텐츠 영역 */
.cr-wrapper {
  /* 데이타 테이블 */
  .cr-container {
    overflow: hidden;

    > .v-data-table ::v-deep {
      position: absolute;
      width: 100%;
      top: 0px;
      bottom: 0px;
      /* content body */
      > .v-data-table__wrapper {
        height: calc(100% - 54px);
        overflow-y: auto;
        overflow-x: hidden;
        .table-row {
          cursor: pointer;
        }

        .v-data-table-header .cr-header-permission {
          padding-left: 26px;
        }
      }
      /* row style */
      .v-data-table__wrapper > table {
        > tbody {
          > tr > td {
            padding: 0 0 0 16px;
            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
          }
          > tr > td {
            .v-input--checkbox.v-input--is-disabled,
            .v-btn--disabled i {
              color: rgba(0, 0, 0, 0.18) !important;
            }
          }

          > tr.cr-minimum-row.unread > td {
            padding: 0 0 0 13px;
          }
          /* 그룹보기 tr */
          > tr.v-row-group__header {
            > td {
              padding: 0 0 0 10px;
              cursor: pointer;
            }
          }
        }
      }
      /* 발신 메일 */
      .cr-sent-mail {
        border-radius: 2px !important;
        padding: 2px 5px 2px 5px;
        font-size: 11px;
        line-height: 1;
        height: auto;
        opacity: 0.8;
      }
      /* 태그 */
      .cr-tag-chip {
        border-radius: 2px !important;
        padding: 2px 5px 2px 5px;
        font-size: 11px;
        line-height: 1;
        height: auto;
        opacity: 0.8;
      }

      /* 폴더이름 */
      .v-chip.cr-name-folder {
        border-radius: 2px !important;
        max-width: 100px;
        > .v-chip__content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline;
          padding-top: 1px;
        }
      }

      /* 보낸메일 표시 */
      .cr-sent-mail {
        border-radius: 2px !important;
        max-width: 100px;
      }
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { listDefaultAction } from "@/mail/store/base/baseRoute";
import TableRow from "./list/tr/TableRow";
import Pagination from "@/commons/views/Pagination";

export default {
  components: {
    TableRow,
    Pagination
  },
  props: {
    resizeWidth: {
      default: 0,
      type: Number
    }
  },
  mounted() {
    this.page = this.getPage;
  },
  watch: {
    // pageSize 변경시 options에 넣어줘야 pageCount도 update됨
    getPageSize(pageSize) {
      this.options = { itemsPerPage: pageSize };
    },
    getGroupView(getGroupView) {
      if (getGroupView == 0) {
        this.closedGroup = [];
      }
    },
    getPage(page) {
      this.page = page;
    }
  },
  computed: {
    ...mapGetters("messenger", ["isRoomOpend"]),
    ...mapGetters("dragDrop", ["dragComp", "dragMode"]),
    ...mapGetters("mail", [
      "getMails",
      "getTotalElements",
      "getLoading",
      "getSelectedMails",
      "getExpanded",
      "getPage"
    ]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailConfig", [
      "getSplitView",
      "getPopupRead",
      "getGroupView",
      "getPageSize"
    ]),
    ...mapGetters("folder", [
      "currentFolder",
      "isSent",
      "isSpam",
      "isDrafts",
      "isShareRoot",
      "isSharedRoot",
      "isShare",
      "getSentId",
      "getTrashId"
    ]),
    getGroupBy() {
      return this.getGroupView == 0 ? [] : "group";
    },
    selectedMails: {
      get() {
        return this.getSelectedMails;
      },
      set(selectedMails) {
        this.SET_SELECTED_MAILS(selectedMails);
      }
    },
    expanded: {
      get() {
        return this.getExpanded;
      },
      set(expanded) {
        this.SET_EXPANDED(expanded);
      }
    },
    noDataText() {
      return this.getRouteListInfo.actionObj?.search?.type
        ? i18n.t("common.4")
        : i18n.t("common.3");
    },
    isMinimum() {
      return (
        (!!this.resizeWidth && this.resizeWidth <= 520) ||
        this.$vuetify.breakpoint.xs
      );
    },
    minimumWidth() {
      return this.isMinimum ? this.resizeWidth || document.body.clientWidth : 0;
    },
    headers() {
      if (this.isShareRoot) {
        if (!this.isSharedRoot) {
          return [
            {
              text: i18n.t("mail.47"),
              value: "nameFolder",
              sortable: false,
              filterable: false,
              groupable: false,
              divider: false
            },
            {
              text: i18n.t("mail.관리"),
              value: "permission",
              align: "center",
              class: "cr-header-permission",
              width: this.$vuetify.breakpoint.xs ? 120 : 140,
              sortable: false,
              filterable: false,
              groupable: false,
              divider: false
            }
          ];
        }

        return [
          {
            text: i18n.t("mail.47"),
            value: "nameFolder",
            sortable: false,
            filterable: false,
            groupable: false,
            divider: false
          },
          {
            text: i18n.t("mail.소유자"),
            value: "owner",
            width: this.$vuetify.breakpoint.xs ? 100 : 200,
            sortable: false,
            filterable: false,
            groupable: false,
            divider: false
          },
          {
            text: i18n.t("mail.권한"),
            value: "permission",
            width: this.$vuetify.breakpoint.xs ? 80 : 150,
            sortable: false,
            filterable: false,
            groupable: false,
            divider: false
          }
        ];
      }

      return [];
    }
  },
  methods: {
    ...mapMutations("messenger", ["SET_MINIMIZE"]),
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapMutations("mail", ["SET_SELECTED_MAILS", "SET_EXPANDED"]),
    ...mapActions("mailReceipt", ["cancelAllSend"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("mail", [
      "showBlockDetailDialog",
      "getReferencesMailList",
      "updateFolderId",
      "updateTags",
      "checkMailsBeforeMove"
    ]),
    ...mapActions("mailRoute", [
      "routeMailWrite",
      "routeMailList",
      "routeMailView"
    ]),
    ...mapActions("messenger", ["pleaseRoomOpenMessage"]),
    expandGroup(group) {
      const index = this.closedGroup.findIndex(g => g == group);

      if (index >= 0) {
        this.closedGroup.splice(index, 1);
      } else {
        this.closedGroup.push(group);
      }
    },
    // expandDivision(division) {
    //   const index = this.closedDivision.findIndex(d => d == division);

    //   if (index >= 0) {
    //     this.closedDivision.splice(index, 1);
    //   } else {
    //     this.closedDivision.push(division);
    //   }
    // },
    selectFunc(item, select, isSelect) {
      // 주고 받은 메일이 아닌 경우 주고 받은 메일도 추가해줘야함
      if (!item.isExchange) {
        select(item, !isSelect(item));

        this.getMails.forEach(mail => {
          if (mail.exchangedMail == 1) {
            const [exchangeMail] = mail.exchange.filter(
              ({ mailId }) => mailId == item.mailId
            );

            if (exchangeMail) {
              select(exchangeMail, !isSelect(exchangeMail));
            }
          }
        });
      } else {
        // 주고 받은 메일인 경우 원래 메일과 주고받은 메일 추가해줘야함
        this.getMails.forEach(mail => {
          if (mail.mailId == item.mailId) {
            select(mail, !isSelect(mail));
          }

          if (mail.exchangedMail == 1) {
            const [exchangeMail] = mail.exchange.filter(
              ({ mailId }) => mailId == item.mailId
            );

            if (exchangeMail) {
              select(exchangeMail, !isSelect(exchangeMail));
            }
          }
        });
      }
    },
    mailRowClick(item) {
      if (this.isDrafts) {
        this.routeMailWrite({ i: item.mailId, t: 4 });
      } else if (this.isSpam) {
        this.showBlockDetailDialog(item.dataId);
      } else {
        this.routeMailView({
          basedId: item.isExchange ? item.parentMailId : item.mailId,
          selectedDataId: item.dataId
        });
      }
    },
    pageClick(page) {
      // scroll 초기화
      if (this.$el.querySelector(".v-data-table__wrapper")) {
        this.$el.querySelector(".v-data-table__wrapper").scrollTop = 0;
      }

      const { actionObj } = this.getRouteListInfo;

      const params = {
        name: "mail_list",
        page
      };
      if (JSON.stringify(actionObj) != listDefaultAction) {
        params.name = "mail_list_action";
        params.actionObj = actionObj;
      }

      this.routeMailList(params);
    },

    // drag & drop
    genHelper(e) {
      const target = e.target.closest(".cr-draggable-list");
      if (!target) return null;

      const ghost = document.createElement("div");
      ghost.setAttribute("data-id", target.getAttribute("data-id"));
      ghost.setAttribute("data-dataId", target.getAttribute("data-dataId"));
      ghost.classList.add("dnd-ghost");
      ghost.innerHTML =
        '<div class="dnd-node">' +
        ' <div class="dnd-icon">' +
        '   <i aria-hidden="true" class="v-icon mdi mdi-email theme--light"></i>' +
        " </div>" +
        ' <div class="dnd-text"><span class="num"></span><span class="txt">개 메일이동</span></div>' +
        "</div>";

      return ghost;
    },
    dragstart() {
      const crHelper = document.getElementById("crHelper");
      const draggedMailId = crHelper.getAttribute("data-id");
      const draggedDataId = crHelper.getAttribute("data-dataId");

      // 선택되어있는 메일중 현재 drag한 메일이 있는지 확인
      const [draggedMail] = this.selectedMails.filter(
        m => m.dataId == draggedDataId
      );
      if (!draggedMail) {
        // 해당되는 dataId랑 같은 mailId 를 갖는 모든 메일을 담는다.
        const mails = [];
        this.getMails.forEach(m => {
          if (m.mailId == draggedMailId) {
            mails.push(m);
          }

          m?.exchange?.forEach(m => {
            if (m.mailId == draggedMailId) {
              mails.push(m);
            }
          });
        });

        if (mails.length == 0) return;
        this.SET_SELECTED_MAILS(mails);
      }

      var el = crHelper.querySelector(".num");
      if (el) {
        const mails = {};
        this.selectedMails.forEach(m => {
          mails[m.mailId] = m;
        });

        el.innerText = Object.keys(mails).length;
      }
    },
    genDropzone() {
      if (this.dragComp == "list") {
        const { length: sentMailCnt } = this.selectedMails.filter(
          m =>
            m.mailType == "SENT" ||
            m.mailType == "RESERVE" ||
            m.mailType == "RESERVE_CANCEL"
        );
        // document.getElementsByClassName은 배열이 아니라 HtmlCollection을 리턴함
        // 아래 라인은 이를 배열로 인식하게 함.
        HTMLCollection.prototype.forEach = Array.prototype.forEach;
        document.getElementsByClassName("cr-dropzone-list").forEach(el => {
          const { length } = el.getElementsByClassName("cr-drop-list");
          if (length > 0) return;

          const folderId = el.getAttribute("data-id");
          if (sentMailCnt == 0 && folderId == this.getSentId) return;

          const dropEl = document.createElement("div");
          Object.assign(dropEl.style, {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px"
          });
          dropEl.id = folderId;
          dropEl.classList.add("cr-drop-list");
          el.appendChild(dropEl);

          dropEl.addEventListener("mouseleave", async function(e) {
            e.target.style.border = null;
            e.target.style.background = null;
          });
        });
      }
    },
    markDropzone(e) {
      if (e.target.classList.contains("cr-drop-list")) {
        const borderStyle = "3px solid #9E9E9E";
        const backgroundStyle = "rgba(1, 1, 1, 0.1)";

        e.target.style.borderTop = borderStyle;
        e.target.style.borderBottom = borderStyle;
        e.target.style.background = backgroundStyle;
      }

      if (
        e.target.classList.contains("v-treeview-node__toggle") &&
        !e.target.classList.contains("v-treeview-node__toggle--open")
      ) {
        e.target.click();
      }

      if (this.getSelectedMails.length !== 1) return;
      const isMessenger = e.target.closest(".cr-messenger");
      const ghostTxt = document.querySelector(".dnd-ghost span.txt");
      ghostTxt.innerText = isMessenger ? "개 메일공유" : "개 메일이동";
      if (e.target.closest(".fab-messenger")) {
        this.SET_MINIMIZE(false);
      }
    },
    mouseup(e) {
      // 메신저
      const isMessenger = e.target.closest(".cr-messenger");
      if (isMessenger && this.getSelectedMails.length === 1) {
        if (this.isRoomOpend) {
          // 여기서 메신저 메시지 호출
          this.SET_DIALOG({
            show: true,
            type: "linkShare",
            params: { mail: this.getSelectedMails[0] }
          });
        } else {
          this.pleaseRoomOpenMessage();
        }
      }

      // 드래그한 row에 내려놓으면 클릭되는거 방지
      const p =
        e.target.closest(".cr-minimum-row") || e.target.closest(".cr-base-row");
      if (p && this.dragMode) {
        p.addEventListener(
          "click",
          function(e) {
            e.stopPropagation();
            e.preventDefault();
          },
          { capture: true, once: true }
        );
      }

      const targetId = e.target.id;
      if (e.target.classList.contains("cr-drop-list")) {
        const { getSelectedMails: mails } = this;

        // 선택된 메일함이 태그일때
        if (typeof targetId === "string" && targetId.startsWith("t_")) {
          const tag = targetId.replace("t_", "");
          this.updateTags({ mails, type: "add", tag });

          // 일반 메일함들
        } else if (parseInt(targetId)) {
          this.checkMailsBeforeMove({
            targetFolderId: parseInt(targetId),
            selectedMails: this.selectedMails
          });
        }
      }

      // 드랍가능영역 제거
      const dropList = document.getElementsByClassName("cr-drop-list");
      while (dropList.length > 0) {
        dropList[0].parentNode.removeChild(dropList[0]);
      }
    }
  },
  data() {
    return {
      options: {},
      closedGroup: [],
      // closedDivision: [],
      pageCount: 0,
      hoverItem: null,
      page: 1
    };
  }
};
</script>
