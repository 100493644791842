<template>
  <v-row class="pb-2">
    <v-col cols="12" sm="4" md="4" class="pa-0">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="querySubjectModel"
        :label="$t('mail.제목')"
        class="pa-1"
        autocomplete="off"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="pa-0">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="queryContentModel"
        :label="$t('mail.본문')"
        class="pa-1"
        autocomplete="off"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="pa-0">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="queryAttachModel"
        :label="$t('mail.첨부파일')"
        class="pa-1"
        autocomplete="off"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    querySubject: {
      default: "",
      type: String
    },
    queryContent: {
      default: "",
      type: String
    },
    queryAttach: {
      default: "",
      type: String
    }
  },
  computed: {
    querySubjectModel: {
      get() {
        return this.querySubject;
      },
      set(querySubject) {
        this.$emit("update:querySubject", querySubject);
      }
    },
    queryContentModel: {
      get() {
        return this.queryContent;
      },
      set(queryContent) {
        this.$emit("update:queryContent", queryContent);
      }
    },
    queryAttachModel: {
      get() {
        return this.queryAttach;
      },
      set(queryAttach) {
        this.$emit("update:queryAttach", queryAttach);
      }
    }
  }
};
</script>
