<template>
  <v-row class="ma-0">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
      {{ $t("mail.557") }}
    </v-col>
    <v-col cols="12" sm="4" class="pl-0">
      <v-select
        ref="fetchTypeRef"
        dense
        outlined
        hide-details
        item-text="text"
        item-value="value"
        style="max-width: 400px;"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        :items="fetchServerTypes"
        v-model="fetchServerType"
        @click:append.stop="$refs.fetchTypeRef.activateMenu()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: Object,
    default: () => ({})
  },
  data({ $store: { getters }, item: { fetchServerType } }) {
    const getFetchServerType = getters["mailConfigExternal/getFetchServerType"];
    const { value } = getFetchServerType(fetchServerType);

    return { fetchServerType: value };
  },
  computed: {
    ...mapGetters("mailConfigExternal", ["fetchServerTypes"])
  },
  watch: {
    fetchServerType(fetchServerType) {
      this.$emit("update:item", { ...this.item, fetchServerType });
    }
  }
};
</script>
