<template>
  <div class="black--text d-flex mt-2 pt-2 cr-border">
    <div class="subtitle-2 d-flex mb-1">
      <div class="cr-label">{{ $t("mail.첨부파일") }}</div>
      <div v-if="!attachments.length" class="primary--text font-weight-bold">
        {{ $t("mail.554") }}
      </div>
      <div v-else class="d-flex flex-wrap">
        <div v-if="tempFileCnt" class="mr-4">
          <v-icon size="14">mdi-paperclip</v-icon>
          <span>{{ $t("mail.296") }}</span>
          <span class="mx-1 primary--text font-weight-bold">
            {{ $t("mail.298", { value: tempFileCnt }) }}
          </span>
          <span class="grey--text text--darken-1">
            {{ `(${tempFileSize})` }}
          </span>
        </div>

        <div v-if="largeTempFileCnt">
          <v-icon size="14">mdi-paperclip</v-icon>
          <span>{{ $t("mail.297") }}</span>
          <span class="mx-1 primary--text font-weight-bold">
            {{ $t("mail.298", { value: largeTempFileCnt }) }}
          </span>
          <span class="grey--text text--darken-1">
            {{ `(${largeTempFileSize})` }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-border {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>

<script>
import { mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  computed: {
    ...mapGetters("mailFile", {
      attachments: "items",
      totalTempSize: "totalTempSize",
      totalLargeTempSize: "totalLargeTempSize"
    }),
    tempFileCnt() {
      return this.attachments.filter(
        f => f.uploadType === "TEMP_FILE" || f.uploadType === "DRAFT_FILE"
      ).length;
    },
    tempFileSize() {
      return byteCalculation(this.totalTempSize);
    },
    largeTempFileCnt() {
      return this.attachments.filter(
        f =>
          f.uploadType === "LARGE_TEMP_FILE" ||
          f.uploadType === "DRAFT_LARGE_FILE"
      ).length;
    },
    largeTempFileSize() {
      return byteCalculation(this.totalLargeTempSize);
    }
  }
};
</script>
