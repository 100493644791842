<template>
  <div>
    {{ type }}

    <v-btn
      v-if="showBtn"
      text
      small
      outlined
      :style="style"
      :disabled="disabled"
      @click.stop="changeUploadType(item.key)"
    >
      {{ $t("mail.113") }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import uType from "@/mail/constant/uploadType";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    /**
        TEMP_FILE, LARGE_TEMP_FILE, DRAFT_FILE, DRAFT_LARGE_FILE
    */
    type() {
      const { uploadType: type } = this.item;
      if (type === uType.TEMP_FILE || type === uType.DRAFT_FILE) {
        return this.$t("mail.111");
      }

      return this.$t("mail.112");
    },
    showBtn() {
      // 파일 용량 20MB
      return this.item.size < 20971520 && !this.disabled;
    },
    style() {
      const { uploadType: type } = this.item;
      if (type === uType.TEMP_FILE) return "margin-left: 18px;";
      return "margin-left: 4px;";
    },
    disabled() {
      return this.item.status === 2;
    }
  },
  methods: {
    ...mapActions("mailFile", ["changeUploadType"])
  }
};
</script>
