<template>
  <v-btn icon>
    <v-icon>mdi-paperclip</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    item: {
      default: () => {},
      type: Object
    }
  }
};
</script>
