<template>
  <v-date-picker
    no-title
    reactive
    scrollable
    show-adjacent-months
    elevation="3"
    class="cr-reserve-date-picker"
    :min="minDate"
    :max="maxDate"
    :day-format="dayFormat"
    v-model="date"
  >
    <div class="d-flex" @click.stop="">
      <v-select
        dense
        attach
        outlined
        hide-details
        class="pr-2"
        :items="hoursList"
        item-text="title"
        item-value="value"
        :menu-props="{ 'offset-y': true }"
        v-model="hours"
      />
      <v-select
        dense
        attach
        outlined
        hide-details
        class="pr-2"
        :items="minList"
        item-text="title"
        item-value="value"
        :menu-props="{ 'offset-y': true }"
        v-model="min"
      />
      <v-btn color="accent" outlined height="40" width="80" @click="click">
        {{ $t("common.확인") }}
      </v-btn>
    </div>
  </v-date-picker>
</template>

<style lang="scss" scoped>
.cr-reserve-date-picker::v-deep {
  position: absolute;
  z-index: 4;
  .v-select {
    width: 100px;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    selectedDate: {
      type: String,
      default: ""
    }
  },
  data() {
    const today = moment().format("YYYY-MM-DD");
    return {
      date: today,
      hours: moment().hour() + 2,
      min: "00",
      minDate: today,
      maxDate: moment()
        .add(5, "years")
        .format("YYYY-MM-DD"),
      hoursList: [...new Array(24)].map((_, idx) => ({
        title: this.$t("mail.89", { value: idx }),
        value: idx
      })),
      minList: [
        { title: this.$t("mail.92"), value: "00" },
        { title: this.$t("mail.93"), value: "15" },
        { title: this.$t("mail.94"), value: "30" },
        { title: this.$t("mail.95"), value: "45" }
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    dayFormat(date) {
      return parseInt(date.split("-")[2]);
    },
    click() {
      const reserveDate = `${this.date} ${this.hours}:${this.min}`;
      // if (moment(reserveDate).unix() <= moment().unix()) {
      //   this.openSnackbar({ message: this.$t("mail.100"), type: "ERROR" });
      //   return;
      // }

      this.$emit("update:selectedDate", reserveDate);
      this.$emit("update:showDatePicker", false);
    }
  }
};
</script>
