<template>
  <div
    class="text-truncate text-subtitle-2 text-md-subtitle-1"
    @dragenter="e => $emit('dragenter', e)"
    @dragleave="e => $emit('dragleave', e)"
    @dragover.prevent
  >
    <v-icon class="mr-1" size="17">
      mdi-plus-box-multiple-outline
    </v-icon>
    <a href="#" @click.prevent="$emit('openFileInput')">
      {{ $vuetify.breakpoint.xs ? $t("mail.121") : $t("mail.122") }}
    </a>

    <!-- 드라이브 -->
    <div v-if="getUsedModule.drive" style="display: contents;">
      <span class="mx-1">{{ "|" }}</span>
      <a href="#" @click.prevent="$emit('openDriveDialog')">
        {{ $t("mail.123") }}
      </a>
    </div>

    {{ $vuetify.breakpoint.xs ? "" : $t("mail.132") }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["getUsedModule"])
  }
};
</script>
