<template>
  <v-card elevation="0" class="mx-auto" rounded="0" @click.stop="openLink">
    <v-img
      class="cr_img white--text align-end"
      height="250px"
      :src="sharedLink.img"
    >
      <div class="desc">
        <v-card-title v-text="title" class="d-block cr-ellipsis" />
        <v-card-subtitle v-text="sharedLink.desc" class="cr-ellipsis" />
      </div>
    </v-img>
  </v-card>
</template>

<style lang="scss" scoped>
.cr_img {
  background-color: #4c4c4c;
  .desc {
    background: rgba($color: #000000, $alpha: 0.4);
  }
  .cr-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<script>
import i18n from "@/_locales";

export default {
  props: {
    sharedLink: {
      type: Object,
      default: () => {},
      description: "게시물 링크 공유 데이타"
    }
  },
  methods: {
    openLink() {
      window.open(this.sharedLink.link, "_blank");
    }
  },
  computed: {
    title() {
      return this.sharedLink.title ? this.sharedLink.title : this.domain;
    },
    domain() {
      try {
        const url = new URL(this.sharedLink.link);
        return url;
      } catch (e) {
        return i18n.t("board.155");
      }
    }
  }
};
</script>
