<template>
  <v-menu offset-y v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon elevation="0" v-bind="attrs" v-on="on">
        <v-icon color="grey lighten-1">
          mdi-emoticon-happy-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card class="card-container" :ripple="false" @click.stop="() => {}">
      <v-card-text>
        <div class="category" v-for="category in categories" :key="category">
          <div class="category_container">
            {{ $t(`common.emoji_${category}`) }}
          </div>
          <div class="emoji_container">
            <a
              href="#"
              class="emoji-icon mr-1 mb-1 text-decoration-none"
              v-for="(emoji, index) in emojis(category)"
              :key="`emoji_${index}`"
              @click="() => handler(emoji)"
            >
              {{ emoji }}
            </a>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import data from "./data.json";

export default {
  data() {
    return {
      show: false
    };
  },
  computed: {
    categories() {
      return Object.keys(data);
    },
    emojis: () => category => {
      return Object.values(data[category]);
    }
  },
  methods: {
    handler(emoji) {
      this.show = false;
      this.$emit("appendEmoji", emoji);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  max-width: 315px;
  max-height: 360px;
  .v-card__text {
    background-color: white;
  }
  .v-card--link:before {
    background-color: transparent;
  }
}

.category_container {
  margin-top: 8px;
  margin-bottom: 4px;
}

.emoji-icon {
  font-size: 40px;
  display: inline-block;
  width: 52px;
  height: 52px;
  line-height: normal;
  transition: 0.3s;
  &:hover {
    transform: scale(1.5);
  }
}
</style>
