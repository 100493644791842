<template>
  <v-container>
    <!-- 이름 -->
    <v-row class="mb-2">
      <v-col cols="1" class="pt-5">
        <v-icon v-text="'mdi-account-outline'" />
      </v-col>
      <v-col class="py-0">
        <v-text-field
          ref="firstName"
          :label="$t('contact.이름*')"
          autofocus
          autocomplete="off"
          @keydown.enter="$emit('update:confirm', true)"
          v-model="cardData.firstName"
          :error-messages="firstNameErrorMessage"
        />
      </v-col>
      <v-col class="py-0">
        <v-text-field
          ref="lastName"
          :label="$t('contact.성')"
          v-model="cardData.lastName"
        />
      </v-col>
    </v-row>

    <!-- 이메일 -->
    <v-row
      class="mb-2"
      v-for="(email, index) in emailList"
      :key="`email-${index}`"
    >
      <v-col cols="1" class="pt-5">
        <v-icon v-text="index == 0 ? 'mdi-email-outline' : 'mdi-empty'" />
      </v-col>
      <v-col cols="10" class="py-0">
        <v-text-field
          ref="email"
          :label="$t('contact.이메일*')"
          v-model="email.value"
          @keydown.enter="$emit('update:confirm', true)"
          :error-messages="email.errorMessage"
        ></v-text-field>
      </v-col>
      <v-col v-if="index == 0" cols="1" align-self="center" class="pl-1 pt-4">
        <v-btn icon @click="() => addInput('emailList')">
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
      </v-col>
      <v-col v-else cols="1" align-self="center" class="pl-1 pt-4">
        <v-btn icon @click="() => removeInput('emailList', index)">
          <v-icon v-text="'mdi-minus'" />
        </v-btn>
      </v-col>
    </v-row>

    <!-- 전화번호 -->
    <v-row v-for="(tel, index) in telList" :key="`tel-${index}`">
      <v-col cols="4" class="py-0">
        <v-select
          prepend-icon="mdi-phone"
          v-model="tel.telType"
          :items="telTypeList"
          item-text="name"
          item-value="value"
        />
      </v-col>
      <v-col cols="7" class="py-0">
        <v-text-field
          ref="tel"
          :label="$t('contact.전화번호')"
          v-model="tel.value"
        ></v-text-field>
      </v-col>
      <v-col v-if="index == 0" cols="1" align-self="center" class="pl-1 pt-4">
        <v-btn icon @click="() => addInput('telList')">
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
      </v-col>
      <v-col v-else cols="1" align-self="center" class="pl-1 pt-4">
        <v-btn icon @click="() => removeInput('telList', index)">
          <v-icon v-text="'mdi-minus'" />
        </v-btn>
      </v-col>
    </v-row>

    <!-- 그룹 -->
    <v-row>
      <v-col cols="12" class="py-0">
        <v-select
          prepend-icon="mdi-folder-outline"
          ref="contactGroupList"
          v-model="cardData.contactGroupIdList"
          :items="contactGroupList"
          item-text="groupName"
          item-value="id"
          chips
          :label="$t('contact.그룹')"
          multiple
        />
        <!-- @click="getContactGroupList" -->
      </v-col>
    </v-row>

    <!-- 상세정보 -->
    <div v-if="expand">
      <v-row>
        <v-col cols="1" class="pt-5">
          <v-icon v-text="'mdi-domain'" />
        </v-col>
        <!-- 회사 -->
        <v-col class="py-0">
          <v-text-field
            :label="$t('contact.회사')"
            v-model="cardData.company"
          ></v-text-field>
        </v-col>
        <!-- 부서 -->
        <v-col class="py-0">
          <v-text-field
            :label="$t('contact.부서')"
            v-model="cardData.organ"
          ></v-text-field>
        </v-col>
        <!-- 직책 -->
        <v-col class="py-0">
          <v-text-field
            :label="$t('contact.직책')"
            v-model="cardData.position"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 주소 -->
      <div v-for="(addr, index) in addrList" :key="`addr-${index}`">
        <v-row>
          <v-col cols="1" class="pt-5">
            <v-icon v-text="'mdi-map-marker-outline'" />
          </v-col>
          <!-- 우편번호 -->
          <v-col class="py-0">
            <v-select
              v-model="addr.addrType"
              :items="addrTypeList"
              item-text="name"
              item-value="value"
            />
          </v-col>
          <!-- 우편번호 -->
          <v-col class="py-0">
            <v-text-field
              :label="$t('contact.우편번호')"
              v-model="addr.post"
            ></v-text-field>
          </v-col>
          <!-- 시/도 -->
          <v-col class="py-0">
            <v-text-field
              :label="$t('contact.시도')"
              v-model="addr.locality"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="index == 0"
            cols="1"
            align-self="center"
            class="pl-1 pt-4"
          >
            <v-btn icon @click="() => addInput('addrList')">
              <v-icon v-text="'mdi-plus'" />
            </v-btn>
          </v-col>
          <v-col v-else cols="1" align-self="center" class="pl-1 pt-4">
            <v-btn icon @click="() => removeInput('addrList', index)">
              <v-icon v-text="'mdi-minus'" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" class="pt-5">
            <v-icon v-text="'mdi-empty'" />
          </v-col>
          <!-- 우편번호 -->
          <v-col class="py-0">
            <v-text-field
              :label="$t('contact.주소')"
              v-model="addr.streetAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <!-- 홈페이지 -->
      <v-row v-for="(url, index) in urlList" :key="`url-${index}`">
        <v-col cols="11" class="py-0">
          <v-text-field
            prepend-icon="mdi-web"
            ref="url"
            :label="$t('contact.홈페이지')"
            v-model="url.value"
          ></v-text-field>
        </v-col>
        <v-col v-if="index == 0" cols="1" align-self="center" class="pl-1 pt-4">
          <v-btn icon @click="() => addInput('urlList')">
            <v-icon v-text="'mdi-plus'" />
          </v-btn>
        </v-col>
        <v-col v-else cols="1" align-self="center" class="pl-1 pt-4">
          <v-btn icon @click="() => removeInput('urlList', index)">
            <v-icon v-text="'mdi-minus'" />
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- 메모 -->
    <v-row>
      <v-col cols="12" class="py-0">
        <v-textarea
          prepend-icon="mdi-comment-text-outline"
          rows="1"
          auto-grow
          ref="memo"
          :label="$t('contact.메모')"
          v-model="cardData.memo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    },
    expand: {
      default: false,
      type: Boolean,
      description: "더보기"
    }
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.addCardFunc();
      }
    }
  },
  async mounted() {
    this.contactGroupList = await this.getPrivateContactGroupList();
    this.$refs["contactGroupList"].$refs.menu.updateDimensions();

    if (this.getCardListInfo.contactGroupId > 0) {
      this.cardData.contactGroupIdList = [
        parseInt(this.getCardListInfo.contactGroupId)
      ];
    }

    // 메일에서 주소록 추가일 경우
    if (this.$route.path.split("/")[1] == "mail") {
      const { email, name } = this.params;
      this.cardData.firstName = name;
      this.emailList = [{ value: email, errorMessage: "" }];
    }
  },
  data: () => ({
    addrTypeList: [
      { name: i18n.t("contact.회사"), value: "work" },
      { name: i18n.t("contact.집"), value: "home" }
    ],
    telTypeList: [
      { name: i18n.t("contact.핸드폰"), value: "cell" },
      { name: i18n.t("contact.회사"), value: "work" },
      { name: i18n.t("contact.집"), value: "home" },
      { name: i18n.t("contact.팩스"), value: "fax" },
      { name: i18n.t("contact.기타"), value: "voice" }
    ],
    contactGroupList: [],
    emailList: [{ value: "", errorMessage: "" }],
    telList: [{ telType: "cell", value: "" }],
    addrList: [
      {
        addrType: "work",
        post: "",
        locality: "",
        streetAddress: ""
      }
    ],
    urlList: [{ value: "" }],
    firstNameErrorMessage: "",
    cardData: {
      contactGroupIdList: null,
      lastName: "",
      firstName: "",
      emailList: [],
      telList: [],
      telTypeList: [],
      urlList: [],
      postList: [],
      localityList: [],
      streetAddressList: [],
      addrTypeList: [],
      company: "",
      organ: "",
      position: "",
      memo: ""
    }
  }),
  computed: {
    ...mapGetters("mailDialog", ["params", "dialogType"]),
    ...mapGetters("contactRoute", ["getCardListInfo"])
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("contactDialog", ["close"]),
    ...mapActions("card", ["addCard"]),
    ...mapActions("group", ["getPrivateContactGroupList"]),
    addInput(input) {
      if (input == "telList") {
        this[input].push({
          telType: "cell",
          value: ""
        });
      } else if (input == "addrList") {
        this[input].push({
          addrType: "work",
          post: "",
          locality: "",
          streetAddress: ""
        });
      } else {
        this[input].push({ value: "" });
      }
    },
    removeInput(input, index) {
      this[input].splice(index, 1);
    },
    async addCardFunc() {
      const { addCard, cardData, emailList, telList, urlList, addrList } = this;
      let validateCount = 0;

      // 이름 밸리데이션 체크
      if (!cardData.firstName || cardData.firstName.length <= 0) {
        this.firstNameErrorMessage = i18n.t("contact.31");
        this.$refs.firstName.focus();
        validateCount++;
      } else {
        this.firstNameErrorMessage = "";
      }

      // 이메일 밸리데이션 체크
      for (let i = 0; i < emailList.length; i++) {
        const email = emailList[i];
        if (!email.value || email.value.length <= 0) {
          email.errorMessage = i18n.t("contact.33");
          validateCount++;
        } else {
          if (!/.+@.+/.test(email.value)) {
            email.errorMessage = i18n.t("contact.32");
            validateCount++;
          } else {
            email.errorMessage = "";
          }
        }
      }

      // 밸리데이션 체크 결과에 따른 confirm 초기화 처리
      if (validateCount > 0) {
        this.$emit("update:confirm", false);
        return;
      }

      // 이메일 리스트 추가
      emailList.map(email => {
        email.value && cardData.emailList.push(email.value);
      });

      // 전화 리스트 추가
      telList.map(tel => {
        if (tel.value) {
          cardData.telList.push(tel.value);
          cardData.telTypeList.push(tel.telType);
        }
      });

      // 주소 리스트 추가
      addrList.map(addr => {
        if (addr.post || addr.locality || addr.streetAddress) {
          cardData.addrTypeList.push(addr.addrType);
          cardData.postList.push(addr.post);
          cardData.localityList.push(addr.locality);
          cardData.streetAddressList.push(addr.streetAddress);
        }
      });

      // 홈페이지 리스트 추가
      urlList.map(url => {
        url.value && cardData.urlList.push(url.value);
      });

      await addCard(cardData);
      // 다이얼로그 닫기
      this.dialogType ? this.CLOSE_DIALOG() : this.close();
    }
  }
};
</script>
