<template>
  <div
    v-drag-and-drop="{
      /**
       * drag&drop directive
       *
       * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀
       * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element
       * dragstart    - dragstart / Argument(event, setDragInfo))
       * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
       * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
       * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
       */
      DRAG_COMP: 'autocomplete',
      genHelper,
      dragstart,
      markDropzone,
      mouseup
    }"
  >
    <From ref="from" v-show="!showOnlySubject && !isWriteToMe" />
    <To ref="to" v-show="!showOnlySubject && !isWriteToMe" />
    <Cc ref="cc" v-show="!showOnlySubject && !isWriteToMe" />
    <Bcc ref="bcc" v-show="!showOnlySubject && showBcc && !isWriteToMe" />
    <Subject ref="subject" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import From from "./from";
import To from "./To";
import Cc from "./Cc";
import Bcc from "./Bcc";
import Subject from "./Subject";

export default {
  components: { From, To, Cc, Bcc, Subject },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"]),
    ...mapGetters("mailCompose", [
      "showOnlySubject",
      "showBcc",
      "isWriteToMe",
      "to",
      "cc",
      "bcc"
    ])
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_RECIPIENT"]),
    /**
     * DnD
     */
    genHelper(e, setDragInfo) {
      const target = e.target.closest(".cr-draggable-autocomplete");
      if (!target) return null;

      const { vnodekey: vnodeKey } =
        target.closest(".cr-autocomplete-wrapper")?.dataset || {};
      const vnode = this.$refs[vnodeKey]?.$refs?.autocompleteRef;
      if (!vnode) return null;

      const { id: draggedChipId, idx: draggedChipIdx } = target.dataset;
      setDragInfo({ vnodeKey, vnode, draggedChipId, draggedChipIdx });

      const cloneTarget = target.cloneNode(true);
      cloneTarget.style.setProperty("background-color", "#fff", "important");
      cloneTarget
        .querySelector(".cr-autocomplete-chip")
        ?.classList?.remove("cr-chip-selected", "cr-chip-border");

      return cloneTarget;
    },
    dragstart(e, setDragInfo, dragInfo) {
      if (!dragInfo || !dragInfo.vnode) return;

      const { vnode, draggedChipId, draggedChipIdx } = dragInfo;
      const [chipIdx] = vnode.chipSelect.filter(
        i => i == draggedChipIdx && vnode.selections[i].key == draggedChipId
      );

      const selectionKeys = [];
      vnode.chipSelect.forEach(i => {
        selectionKeys.push(vnode.selections[i].key);
      });
      const recipients =
        chipIdx == undefined
          ? vnode.selections.filter(v => v.key == draggedChipId)
          : vnode.selections.filter(v => selectionKeys.indexOf(v.key) > -1);
      const filteredSelections =
        chipIdx == undefined
          ? vnode.selections.filter(v => v.key != draggedChipId)
          : vnode.selections.filter(v => selectionKeys.indexOf(v.key) == -1);

      setDragInfo({
        ...dragInfo,
        recipients,
        selections: [...vnode.selections]
      });
      vnode.$emit("update:selection", filteredSelections);
      vnode.chipSelect = [];
    },
    markDropzone(e) {
      const autocompleteInputEl = e.target.closest(
        ".cr-dropzone-autocomplete-input"
      );
      // 자동완성 인풋 포커스
      if (autocompleteInputEl) {
        const vInput = autocompleteInputEl.querySelector(".v-input");
        vInput.classList.add("v-input--is-focused");
        vInput.addEventListener(
          "mouseleave",
          function() {
            vInput.classList.remove("v-input--is-focused");
          },
          { once: true }
        );
      }

      document
        .getElementsByClassName("cr-draggable-autocomplete")
        .forEach(el => {
          el.classList.remove("cr-drop-ahead");
          el.classList.remove("cr-drop-behind");
        });
      // chip 확인
      const draggableEl = e.target.closest(".cr-draggable-autocomplete");
      if (draggableEl) {
        const autocompleteChipEl = draggableEl.querySelector(
          ".cr-autocomplete-chip"
        );
        const { width } = autocompleteChipEl.getBoundingClientRect();
        if (
          width / 2 > e.offsetX &&
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "I"
        ) {
          draggableEl.classList.remove("cr-drop-behind");
          draggableEl.classList.add("cr-drop-ahead");
        } else {
          draggableEl.classList.remove("cr-drop-ahead");
          draggableEl.classList.add("cr-drop-behind");
        }
      }
    },
    mouseup(e, dragInfo) {
      if (!this.dragMode) return;

      document
        .getElementsByClassName("cr-draggable-autocomplete")
        .forEach(el => {
          el.classList.remove("cr-drop-ahead");
          el.classList.remove("cr-drop-behind");
        });

      const autocompleteInputEl = e.target.closest(
        ".cr-dropzone-autocomplete-input"
      );

      // 원복
      if (!autocompleteInputEl && dragInfo && dragInfo?.vnodeKey) {
        this.SET_RECIPIENT({ [dragInfo.vnodeKey]: dragInfo?.selections || [] });
        return;
      }

      const { vnodekey: vnodeKey } =
        e.target.closest(".cr-autocomplete-wrapper")?.dataset || {};
      if (!vnodeKey || !dragInfo?.recipients) return null;

      const chips = [];
      [...this[vnodeKey]].forEach(c => chips.push({ ...c }));
      let index = chips.length + 1;

      const draggableEl = e.target.closest(".cr-draggable-autocomplete");
      if (draggableEl) {
        const draggedChipIdx = parseInt(draggableEl.getAttribute("data-idx"));
        const { width } =
          draggableEl
            .querySelector(".cr-autocomplete-chip")
            ?.getBoundingClientRect() || {};

        if (
          width / 2 > e.offsetX &&
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "I"
        ) {
          // 왼쪽
          index = draggedChipIdx;
        } else {
          // 오른쪽
          index = draggedChipIdx + 1;
        }
      }
      dragInfo.recipients.forEach((r, idx) => {
        chips.splice(index + idx, 0, r);
      });
      chips.forEach((c, idx) => (c.key = idx));

      this.SET_RECIPIENT({ [vnodeKey]: chips });
    }
  }
};
</script>
