<template>
  <v-row class="ma-0">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
      {{ $t("mail.332") }}
    </v-col>
    <v-col cols="12" sm="4" class="pl-0">
      <v-text-field
        ref="fetchServerRef"
        dense
        outlined
        hide-details
        autofocus
        placeholder="pop or imap.your_domain.com"
        :readonly="serverName !== 'custom'"
        v-model="fetchServer"
      />
    </v-col>
    <v-col cols="12" sm="4" class="pl-0">
      <v-text-field
        ref="fetchPortRef"
        dense
        outlined
        hide-details
        type="number"
        :label="$t('mail.333')"
        v-model="fetchPort"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ item: { serverName, fetchServer, fetchPort } }) {
    return {
      serverName,
      fetchServer,
      fetchPort: fetchPort || 995
    };
  },
  watch: {
    item(
      { fetchServerType: fetchServerType, serverName: serverName },
      { fetchServerType: prevFetchServerType, serverName: prevServerName }
    ) {
      if (
        serverName === prevServerName &&
        fetchServerType === prevFetchServerType
      )
        return;

      this.serverName = serverName;
      this.fetchPort = fetchServerType === "POP3" ? 995 : 993;

      switch (serverName) {
        case "hiworks":
          if (fetchServerType === "POP3") {
            this.fetchServer = "pop3s.hiworks.com";
          } else {
            this.fetchServer = "";
          }
          break;
        case "gmail":
          this.fetchServer =
            (fetchServerType === "POP3" ? "pop" : "imap") + ".gmail.com";
          break;
        case "naver":
          this.fetchServer =
            (fetchServerType === "POP3" ? "pop" : "imap") + ".naver.com";
          break;
        case "daum":
          this.fetchServer =
            (fetchServerType === "POP3" ? "pop" : "imap") + ".daum.net";
          break;
        case "nate":
          this.fetchServer =
            (fetchServerType === "POP3" ? "pop3" : "imap") + ".nate.com";
          break;
        default:
          this.fetchServer = "";
          break;
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    validation() {
      if (isBlank(this.fetchServer)) {
        this.$refs.fetchServerRef.focus();
        this.openSnackbar({ message: this.$t("mail.347"), type: "VALIDATION" });
        return false;
      }

      if (isBlank(this.fetchPort)) {
        this.$refs.fetchPortRef.focus();
        this.openSnackbar({ message: this.$t("mail.348"), type: "VALIDATION" });
        return false;
      }

      return true;
    }
  }
};
</script>
