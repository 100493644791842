<template>
  <div class="receipt-detail-dialog">
    <table>
      <ReceiptDetail :item="items" :isMinimum="false" />
    </table>
  </div>
</template>

<script>
import ReceiptDetail from "@/mail/views/components/main/list/ReceiptDetail";
import { mapGetters } from "vuex";

export default {
  components: { ReceiptDetail },
  props: {},
  data() {
    return {
      items: {}
    };
  },
  mounted() {
    this.items = this.params.item;
  },
  computed: {
    ...mapGetters("mailDialog", ["params"])
  }
};
</script>

<style lang="scss" scoped>
.receipt-detail-dialog {
  margin: 0px -24px;
  table {
    width: 100%;
  }
}
</style>
