<template>
  <v-alert tile text border="left" type="error">
    <template v-slot:prepend>
      <v-icon class="mr-2" color="red">
        mdi-alert-circle-outline
      </v-icon>
    </template>

    <div class="d-flex align-center justify-space-between">
      <span class="grey--text text--darken-2">
        <span>{{ $t("mail.152") }}</span>
        <span class="font-weight-bold">
          {{ item.mailType == "REPORT_MAIL" ? $t("mail.153") : $t("mail.154") }}
        </span>
      </span>
      <v-btn
        v-if="showUnblockBtn"
        text
        tile
        small
        color="pink"
        :ripple="false"
        @click="confirmUnblockMail"
      >
        {{ $t("mail.155") }}
      </v-btn>
    </div>
  </v-alert>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("folder", ["isShare", "currentShareFolder"]),
    showUnblockBtn() {
      const { mailType } = this.item;
      const { permission = 3 } = this.isShare && this.currentShareFolder;
      return mailType == "REPORT_MAIL" && permission === 3;
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("mail", ["unblockMail"]),
    confirmUnblockMail() {
      this.confirm({
        message: this.$t("mail.158"),
        callback: () => {
          this.unblockMail(this.item.mailId);
        },
        showCloseBtn: true
      });
    }
  }
};
</script>
