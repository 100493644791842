<template>
  <div class="d-flex align-center mt-1 cr-s-d-mbx">
    <div class="cr-detail-label text-subtitle-2">
      {{ $t("mail.메일함") }}
    </div>
    <div class="cr-detail-mailbox">
      <MailboxSelect :folderId="folderId" @change="changeFolder" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-detail-mailbox {
  width: 100%;
  height: 40px;
}
</style>

<script>
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";

export default {
  components: { MailboxSelect },
  props: {
    folderId: {
      default: -1,
      type: Number
    }
  },
  methods: {
    changeFolder(folder) {
      this.$emit("update:folderId", folder.id);
    },
    getParams() {
      return { valid: false, params: {} };
    }
  }
};
</script>
