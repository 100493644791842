<template>
  <div class="ml-2 pa-2 pb-0 cr-rule-wrapper">
    <v-row no-gutters class="mt-3 pb-2" style="max-width: 1029px;">
      <v-col class="d-flex">
        <v-btn
          class="mr-3"
          color="primary"
          outlined
          :disabled="selectedRules.length == 0"
          v-text="$t('common.삭제')"
          @click="
            removeRules(
              selectedRules.map(({ id }) => id),
              true
            )
          "
        />
        <v-btn
          tile
          icon
          outlined
          :ripple="false"
          :disabled="selectedRules.length != 1"
          style="border-right: 0"
          @click="moveRule('up')"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          tile
          icon
          outlined
          class="mr-2"
          :ripple="false"
          :disabled="selectedRules.length != 1"
          @click="moveRule('down')"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          tile
          icon
          outlined
          :ripple="false"
          :disabled="selectedRules.length != 1"
          style="border-right: 0"
          @click="moveRule('first')"
        >
          <v-icon>mdi-chevron-double-up</v-icon>
        </v-btn>
        <v-btn
          tile
          icon
          outlined
          :ripple="false"
          :disabled="selectedRules.length != 1"
          @click="moveRule('last')"
        >
          <v-icon>mdi-chevron-double-down</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          text
          outlined
          :disabled="totalElements >= 100"
          v-text="$t('mail.231')"
          @click="
            SET_DIALOG({ headline: $t('mail.231'), show: true, type: 'rule' })
          "
        />
      </v-col>
    </v-row>
    <v-data-table
      ref="tableRef"
      show-select
      hide-default-footer
      :loading="loading"
      :headers="headers"
      :items="list"
      :items-per-page="pageSize"
      :item-class="() => 'item-row'"
      v-model="selectedRules"
      @click:row="(r, { select, isSelected }) => select(!isSelected)"
    >
      <!--  -->
      <template v-slot:[`item.conditionOptions`]="{ item }">
        <div class="py-2 primary--text">
          <div v-if="item.adrFrom.length > 0" class="cr-condition-ellipsis">
            <span class="mr-2 black--text">
              <span v-text="$t('mail.보낸사람')"></span>:
            </span>

            {{ item.adrFrom.join(` ${$t("mail.또는")} `) }}
          </div>
          <div v-if="item.adrTo.length > 0" class="cr-condition-ellipsis">
            <span class="mr-2 black--text">
              <span v-text="$t('mail.받는사람')"></span>:
            </span>
            {{ item.adrTo.join(` ${$t("mail.또는")} `) }}
          </div>
          <div v-if="item.adrCc.length > 0" class="cr-condition-ellipsis">
            <span class="mr-2 black--text">
              <span v-text="$t('mail.참조')"></span>:
            </span>
            {{ item.adrCc.join(` ${$t("mail.또는")} `) }}
          </div>
          <div v-if="item.adrToCc.length > 0" class="cr-condition-ellipsis">
            <span class="mr-2 black--text">
              <span v-text="$t('mail.232')"></span>:
            </span>
            {{ item.adrToCc.join(` ${$t("mail.또는")} `) }}
          </div>
          <div v-if="item.subject.length > 0" class="cr-condition-ellipsis">
            <span class="mr-2 black--text">
              <span v-text="$t('mail.233')"></span>: </span
            >{{ item.subject }}
          </div>
          <div v-if="item.content.length > 0" class="cr-condition-ellipsis">
            <span class="mr-2 black--text">
              <span v-text="$t('mail.234')"></span>: </span
            >{{ item.content }}
          </div>
          <div class="cr-condition-ellipsis black--text">
            {{ item.hasAttach ? "첨부파일이 있는 메일" : "" }}
          </div>
          <div class="cr-condition-ellipsis black--text">
            {{ item.hasCalendarMail ? "캘린더 약속 메일" : "" }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.processOptions`]="{ item }">
        <div class="py-2">
          <div class="cr-process-ellipsis">
            <span class="mr-2"> <span v-text="$t('mail.235')"></span>: </span>
            {{ item.nameFolder }}
          </div>
          <div class="cr-process-ellipsis">
            {{ item.markRead ? $t("mail.236") : "" }}
          </div>
          <div class="cr-process-ellipsis">
            {{ item.markImportant ? $t("mail.237") : "" }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          x-small
          icon
          @click.stop="
            SET_DIALOG({
              headline: $t('mail.238'),
              show: true,
              type: 'rule',
              params: { rule: item }
            })
          "
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn x-small icon @click.stop="removeRules([item.id])">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.v-btn {
  border-radius: 0px;
}
.v-btn.v-btn--outlined {
  border-color: rgba(0, 0, 0, 0.12);
}

.cr-rule-wrapper {
  position: absolute;
  max-width: 1029px;
  width: 100%;
  top: 0px;
  bottom: 0px;

  .v-data-table ::v-deep {
    position: absolute;
    top: 62px;
    bottom: 8px;
    left: 8px;
    right: 8px;
    border: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    /* row style */
    .v-data-table__wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      > table {
        > thead > tr > th,
        > tbody > tr > td {
          padding: 0 0 0 16px;
          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);

          .cr-condition-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            max-width: 435px;
          }

          .cr-process-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            max-width: 380px;
          }
        }
      }
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  mounted() {
    this.getRuleList();
  },
  data() {
    return {
      selectedRules: [],
      headers: [
        {
          text: i18n.t("mail.239"),
          align: "start",
          sortable: false,
          value: "conditionOptions"
        },
        {
          text: i18n.t("mail.240"),
          sortable: false,
          value: "processOptions",
          width: 400
        },
        {
          text: i18n.t("mail.관리"),
          align: "center",
          sortable: false,
          value: "actions",
          width: 120
        }
      ]
    };
  },
  computed: {
    ...mapGetters("mailConfigRule", {
      list: "getRuleList",
      totalElements: "getRuleTotalElements",
      pageSize: "getRulePageSize",
      loading: "getRuleLoading"
    })
  },
  watch: {
    list(nextList, prevList) {
      if (nextList.length - prevList.length == 1) {
        setTimeout(() => {
          const el = this.$refs.tableRef.$el.querySelector(
            ".v-data-table__wrapper"
          );
          el.scrollTop = el.scrollHeight;
        }, 0);
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mailConfigRule", [
      "getRuleList",
      "updateSortOrder",
      "deleteRules"
    ]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    removeRules(ruleIds, resetSelect) {
      this.confirm({
        message: i18n.t("mail.241"),
        callback: () => {
          this.deleteRules(ruleIds);
          if (resetSelect) this.selectedRules = [];
        },
        showCloseBtn: true
      });
    },
    moveRule(type) {
      const { length } = this.selectedRules;
      if (length != 1) return;
      const [rule] = this.selectedRules;
      let index = this.list.findIndex(({ id }) => id == rule.id);
      let targetSortOrder = 0;
      let isLast = false;

      switch (type) {
        case "first":
          if (index == 0) return;
          targetSortOrder = this.list[0].sortOrder;
          break;
        case "last":
          isLast = true;
          break;
        case "up": {
          if (index == 0) return;
          const { sortOrder } = this.list[index - 1];
          targetSortOrder = sortOrder;
          break;
        }
        case "down": {
          if (index + 1 >= this.list.length) return;
          const { sortOrder } = this.list[index + 1];
          targetSortOrder = sortOrder;
          break;
        }

        default:
          return;
      }

      this.updateSortOrder({ id: rule.id, targetSortOrder, isLast });
    }
  }
};
</script>
