<template>
  <div>
    <!-- 받는사람 -->
    <div class="d-flex align-baseline mt-2 user-select-text">
      <div class="cr-adr-label">
        <span>{{ `${$t("mail.받는사람")}:` }}</span>
      </div>
      <div class="d-flex flex-wrap">
        <AdrEmail
          v-for="(to, idx) in adrTo"
          :key="idx"
          :address="to.address"
          :personal="to.personal"
          :readStatus="1"
          ruleSelection="to"
          type="view"
          propStyle="max-width: 250px;"
          cls="mr-4"
        />
      </div>
    </div>

    <!-- 참조 -->
    <div
      v-if="adrCC.length > 0"
      class="d-flex align-baseline mt-2 user-select-text"
    >
      <div class="cr-adr-label">
        <span>{{ `${$t("mail.참조")}:` }}</span>
      </div>
      <div class="d-flex flex-wrap">
        <AdrEmail
          v-for="(cc, idx) in adrCC"
          :key="idx"
          :address="cc.address"
          :personal="cc.personal"
          :readStatus="1"
          ruleSelection="cc"
          type="view"
          propStyle="max-width: 250px;"
          cls="mr-4"
        />
      </div>
    </div>

    <!-- 숨은 참조 -->
    <div
      v-if="adrBcc.length > 0"
      class="d-flex align-baseline mt-2 user-select-text"
    >
      <div class="cr-adr-label">
        <span>{{ `${$t("mail.숨은참조")}:` }}</span>
      </div>
      <div class="d-flex flex-wrap">
        <AdrEmail
          v-for="(bcc, idx) in adrBcc"
          :key="idx"
          :address="bcc.address"
          :personal="bcc.personal"
          :readStatus="1"
          type="view"
          propStyle="max-width: 250px;"
          cls="mr-4"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// 받는사람, 참조
.cr-adr-label {
  color: #333;
  float: left;
  width: 80px;
  min-width: 80px;
  margin-right: 8px;
}
</style>

<script>
import AdrEmail from "@/mail/views/components/main/commons/adrEmail";

export default {
  components: { AdrEmail },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isPanelOpen: {
      type: Boolean,
      default: false
    },
    showRecipients: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    adrTo() {
      return this.item.to || [];
    },
    adrCC() {
      return this.item.cc || [];
    },
    adrBcc() {
      return this.item.bcc || [];
    }
  }
};
</script>
