<template>
  <v-btn icon x-small color="grey" @click.stop="openPopup">
    <v-icon :size="isView ? 16 : 12">mdi-vector-arrange-above</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailView", "routeMailWrite"]),
    openPopup() {
      const { folderType } = this.item;

      if (folderType === "DRAFTS") this.openPopupWrite();
      else this.openPopupView();
    },
    openPopupView() {
      const { isExchange, parentMailId, mailId, dataId } = this.item;
      const basedId = isExchange ? parentMailId : mailId;
      this.routeMailView({ basedId, selectedDataId: dataId, openPopup: true });
    },
    openPopupWrite() {
      const { mailId } = this.item;
      this.routeMailWrite({ t: 4, i: mailId, openPopup: true });
    }
  }
};
</script>
