var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showCountryIcon)?_c('CountryIcon',{attrs:{"countryCode":_vm.countryCode}}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('update:showMenu', true)}}},'div',attrs,false),on),[_c('span',{class:{
              'font-weight-bold': !_vm.readStatus,
              'text-decoration-underline': hover
            }},[_vm._v(" "+_vm._s(_vm.displayEmail)+" ")]),_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.fullEmail))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }