<template>
  <v-list-item>
    <v-list-item-content>
      <div
        class="d-flex align-center justify-space-between"
        style="min-width: 0px;"
      >
        <div
          :style="`min-width: 0px; ${item.size > 0 ? '' : 'opacity: 0.6'}`"
          class="d-flex align-center"
        >
          <v-icon class="cr-view-print-hide" :color="getIcon.color">
            {{ getIcon.icon }}
          </v-icon>
          <!-- 파일 이름 / 다운로드 -->
          <div v-if="item.size > 0" style="min-width: 0px;">
            <a
              class="d-flex align-center ml-1"
              :download="item.name"
              @click.prevent="attachDownload"
            >
              <FileName :item="item" />
            </a>
            <div class="size grey--text mt-1 ml-1">
              {{ displaySize }}
            </div>
          </div>

          <!-- 첨부파일 삭제 시 표기 -->
          <div v-else class="d-flex align-center" style="min-width: 0px;">
            <span
              class="text-truncate"
              style="cursor: default; text-decoration: line-through; min-width: 0px;"
            >
              {{ item.name }}
            </span>
            <span style="flex-shrink: 0;" class="mr-2"> .{{ item.ext }} </span>
          </div>
        </div>

        <!-- eml보기/다운로드/삭제버튼 -->
        <div
          v-if="item.size > 0"
          class="cr-view-print-hide d-flex align-center ml-1"
        >
          <v-tooltip top :disabled="$vuetify.breakpoint.xs">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="showEmlViewBtn"
                icon
                x-small
                v-on="on"
                v-bind="attrs"
                class="cr-attach-list-btn"
                @click="routeView"
              >
                <v-icon size="18">mdi-content-copy</v-icon>
              </v-btn>
            </template>

            <div class="caption">{{ $t("common.메일로_보기") }}</div>
          </v-tooltip>

          <v-tooltip top :disabled="$vuetify.breakpoint.xs">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                v-bind="attrs"
                v-on="on"
                class="cr-attach-list-btn download"
                @click="attachDownload"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>

            <div class="caption">{{ $t("common.저장") }}</div>
          </v-tooltip>

          <v-tooltip v-if="!isSentType" top :disabled="$vuetify.breakpoint.xs">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!isEmlView"
                icon
                x-small
                v-on="on"
                v-bind="attrs"
                class="cr-attach-list-btn close"
                @click="removeAttach"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>

            <div class="caption">{{ $t("common.삭제") }}</div>
          </v-tooltip>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<style lang="scss" scoped>
.cr-attach-list-btn {
  padding: 0px;
  height: 24px;
  min-width: 24px;

  &.close {
    padding-left: 8px;
    padding-bottom: 1px;

    .v-icon.v-icon {
      font-size: 18px;
    }
  }

  &.download {
    .v-icon.v-icon {
      font-size: 20px;
      margin-top: 2px;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { removeAttach } from "@/mail/api/mail.api";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import FileName from "@/mail/views/components/main/commons/FileName";

export default {
  components: { FileName },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    mail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("folder", ["isShare"]),
    ...mapGetters("mailRoute", ["getRouteShareInfo", "getRouteViewInfo"]),
    displaySize() {
      const { size = 0 } = this.item;
      return byteCalculation(size);
    },
    getIcon() {
      const { name } = this.item;
      const index = name.lastIndexOf(".");
      const ext = name.substring(index + 1, name.length).toLowerCase();

      return {
        icon: fileIcons[ext] || fileIcons["file"],
        color: fileIconColors[ext] || fileIconColors["file"]
      };
    },
    isSharedMail() {
      if (this.$route.name !== "popup_mail_view") return this.isShare;

      const { actionObj } = this.getRouteViewInfo;
      const { sfi, owi, sp } = actionObj?.list || {};
      return !!(parseInt(sfi) && parseInt(owi) && parseInt(sp));
    },
    isEmlView() {
      const { attachPosition } = this.mail;
      return attachPosition > -1;
    },
    showEmlViewBtn() {
      const { ext } = this.item;
      return ext === "eml" && !this.isEmlView;
    },
    isSentType() {
      const { mailType } = this.mail;
      return ["SENT", "RESERVE", "RESERVE_CANCEL"].includes(mailType);
    }
  },
  methods: {
    ...mapMutations("mailView", ["REMOVE_ATTACH_SIZE"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("auth", ["checkToken"]),
    ...mapActions("mailRoute", ["routeMailView"]),
    routeView() {
      const { mailId: basedId, position: attachPosition } = this.item;
      this.routeMailView({
        basedId,
        attachPosition,
        openPopup: true,
        selectedDataId: basedId
      });
    },
    async attachDownload() {
      // 토큰확인
      await this.checkToken();

      const { mailId, position } = this.item;
      const { attachPosition } = this.mail;
      const { folderId, ownerId } = this.getRouteShareInfo;
      const token = localStorage.getItem("access_token");
      const baseUrl = `${process.env.VUE_APP_API_SERVER_URL}/api`;

      // 일반 메일
      let url = `${baseUrl}/mail/download/attach/${mailId}/${position}?access_token=${token}`;
      if (this.isEmlView) {
        //eml 보기 화면
        url = `${baseUrl}/mail/download/eml/${mailId}/${attachPosition}/${position}?access_token=${token}`;
      }
      // 공유 받은 메일
      if (this.isSharedMail) {
        url = `${baseUrl}/share/mails/download/attach/${mailId}/${position}?folderId=${folderId}&ownerId=${ownerId}&access_token=${token}`;
        if (this.isEmlView) {
          //eml 보기 화면
          url = `${baseUrl}/share/mails/download/eml/${mailId}/${attachPosition}/${position}?folderId=${folderId}&ownerId=${ownerId}&access_token=${token}`;
        }
      }

      /*
       * 파일 다운로드 수정
       * - 편지쓰기 탭을 열어둔 상태에서 첨부 다운로드시 경고창이 나오는 문제 수정
       */
      let target = "_blank";
      if (this.$route.name === "popup_mail_view") {
        target = "_self";
      }
      window.open(url, target);
    },
    removeAttach() {
      this.confirm({
        message: this.$t("mail.161"),
        callback: async () => {
          const { isSharedMail: isShare } = this;
          const { mailId, position } = this.item;
          const { folderId, ownerId } = this.getRouteShareInfo;
          const params = { mailId, position, folderId, ownerId, isShare };
          const { status } = await removeAttach(params);

          if (status !== 200) return;
          this.REMOVE_ATTACH_SIZE({ mailId, position });
        }
      });
    }
  }
};
</script>
