import http from "@/commons/api/http";

const prefix = "/api/share/board";

/**
 * 게시글 작성 폼 데이터
 */
const getPostForm = ({ mailId, includeAttach }) =>
  http.get(`${prefix}/form/${mailId}`, { params: { includeAttach } });

/**
 * 메일 바로 공유 (수정화면 skip)
 */
const addPost = params => http.post(`${prefix}`, params);

export { getPostForm, addPost };
