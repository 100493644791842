<template>
  <div class="mb-4">
    <div>{{ $t("mail.85") }}</div>
    <div>
      <v-select
        dense
        outlined
        hide-details
        return-object
        :items="timezone"
        item-text="title"
        item-value="value"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        v-model="_gmt"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { timezone } from "@/commons/utils/moment";

export default {
  data() {
    return { timezone };
  },
  computed: {
    ...mapGetters("mailCompose", ["gmt"]),
    _gmt: {
      get() {
        return this.gmt;
      },
      set() {}
    }
  }
};
</script>
