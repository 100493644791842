<template>
  <v-card
    class="cr-reserve-wrapper"
    :class="{ 'cr-xs': $vuetify.breakpoint.xs }"
    outlined
    elevation="1"
    v-click-outside="() => SET_SHOW_RESERVE_MODAL(false)"
  >
    <v-card-title>{{ $t("mail.84") }}</v-card-title>
    <v-card-text>
      <Gmt style="display:none;" />
      <Time ref="time" />

      <div class="cr-reserve-guide mt-4">
        <div v-html="$t('mail.87')"></div>
        <div v-html="$t('mail.88')"></div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text outlined @click="SET_SHOW_RESERVE_MODAL(false)">
        {{ $t("common.취소") }}
      </v-btn>
      <v-btn outlined color="accent" @click="confirm">
        {{ $t("common.확인") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.cr-reserve-wrapper::v-deep {
  position: absolute;
  top: -1px;
  left: 80px;
  width: 460px;
  z-index: 3;
  &.cr-xs {
    left: 30px;
  }

  .cr-reserve-guide {
    margin-top: 2px;
    padding: 15px 14px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #fbfbfb;
    font-size: 14px;
    line-height: 1.6;

    .cr-text-emphasis {
      font-weight: 400;
      color: #157efb;
    }
  }
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";
import moment from "moment";
import Gmt from "./Gmt.vue";
import Time from "./Time.vue";

export default {
  components: { Gmt, Time },
  methods: {
    ...mapMutations("mailCompose", ["SET_RESERVE", "SET_SHOW_RESERVE_MODAL"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    confirm() {
      const { time } = this.$refs;
      if (!time) return;

      let reserveDate = "";
      const { timeValue, selectedDate } = time;
      switch (timeValue) {
        case 1:
          // 오늘 오후 6시
          if (moment().hours() >= 18) {
            this.openSnackbar({ message: this.$t("mail.100"), type: "ERROR" });
            return;
          }

          reserveDate = `${moment().format("YYYY-MM-DD")} 18:00`;
          break;
        case 2:
          // 내일 오전 9시
          reserveDate = `${moment()
            .add(1, "days")
            .format("YYYY-MM-DD")} 9:00`;
          break;
        case 3:
          // 내일 오후 6시
          reserveDate = `${moment()
            .add(1, "days")
            .format("YYYY-MM-DD")} 18:00`;
          break;

        default:
          // 직접 설정
          reserveDate = selectedDate;
          break;
      }

      this.SET_RESERVE({ isReserve: true, reserveDate });
      this.SET_SHOW_RESERVE_MODAL(false);
    }
  }
};
</script>
