<template>
  <v-row class="ma-0 mb-2">
    <v-col class="px-2 py-0 mb-1" cols="12">
      <div
        class="cr-info text-caption grey--text text--darken-2"
        v-text="$t('mail.300')"
      ></div>
    </v-col>
    <v-col class="px-2 py-0 mb-1" cols="12">
      <div
        class="cr-info text-caption grey--text text--darken-2"
        v-text="$t('mail.301')"
      ></div>
    </v-col>
    <v-col class="px-2 py-0 mb-1" cols="12">
      <div
        class="cr-info text-caption grey--text text--darken-2"
        v-text="$t('mail.302')"
      ></div>
    </v-col>
    <v-col class="px-2 py-0 mb-1" cols="12">
      <div
        class="cr-info text-caption red--text text--darken-2"
        v-text="$t('mail.303')"
      ></div>
    </v-col>

    <Period
      ref="period"
      :backupList.sync="backupList"
      @requestBackupList="requestBackupList"
    />

    <div
      v-if="backupList.length > 0"
      class="d-flex align-center justify-space-between px-1"
      style="width: 100%"
    >
      <div
        class="grey--text text--darken-3 font-weight-black"
        v-text="$t('mail.304')"
      ></div>
      <div class="grey--text text--darken-2">
        <span v-text="$t('mail.전체')"></span>
        <span class="blue--text text--darken-2">
          {{ byteCalculation(totalMsgSize) }}
        </span>
      </div>
    </div>

    <v-progress-linear
      v-show="loading"
      color="primary accent-4"
      indeterminate
      height="6"
    ></v-progress-linear>
    <v-list outlined style="width: 100%;">
      <v-list-item v-for="(item, idx) in backupList" :key="idx">
        <v-list-item-content>
          <div
            class="d-flex align-center justify-space-between"
            style="min-width: 0px;"
          >
            <div class="d-flex align-center" style="min-width: 0px;">
              <div style="min-width: 0px;">
                <a
                  :ref="`aRef_${idx}`"
                  class="d-flex align-center ml-1"
                  style="min-width: 0px;"
                  :href="downloadLink(item)"
                  :download="item.name"
                >
                  <FileName :item="item" />
                </a>
                <div class="grey--text text-caption ml-1">
                  ({{ byteCalculation(item.size) }})
                </div>
              </div>
            </div>

            <!-- 다운로드 삭제버튼 -->
            <div style="min-width: 100px;">
              <v-btn
                text
                outlined
                :ripple="false"
                @click="$refs[`aRef_${idx}`][0].click()"
              >
                {{ $t("common.다운로드") }}
              </v-btn>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="backupList.length == 0">
        <v-list-item-content>
          <v-list-item-title
            class="grey--text text--darken-3 font-weight-black"
          >
            {{ loading ? $t("common.2") : $t("common.3") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-info {
  position: relative;
}
.cr-info::before {
  position: absolute;
  top: 9px;
  left: -5px;
  width: 2px;
  height: 2px;
  background-color: #767676;
  border-radius: 50%;
  content: "";
}

.v-list ::v-deep {
  a {
    text-decoration: none;
    color: #333;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { getBackupList } from "@/mail/api/file.api";
import Period from "./backup/Period";
import FileName from "@/mail/views/components/main/commons/FileName";

export default {
  components: { Period, FileName },
  mounted() {
    this.requestBackupList();
  },
  data() {
    return {
      loading: true,
      backupList: [],
      totalMsgSize: 0
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"])
  },
  methods: {
    byteCalculation(size) {
      return byteCalculation(size);
    },
    downloadLink(item) {
      const { folder = {} } = this.params;
      const fileName = item.name + "." + item.ext;

      return `${
        process.env.VUE_APP_API_SERVER_URL
      }/api/mail/download/backup/folder/${
        folder.id
      }?access_token=${localStorage.getItem(
        "access_token"
      )}&start=${item.start ?? ""}&end=${item.end ?? ""}&startId=${
        item.startId
      }&endId=${item.endId}&fileName=${fileName}`;
    },
    async requestBackupList() {
      const { folder = {} } = this.params;
      if (parseInt(folder.id)) {
        this.loading = true;
        const params = {};
        const { startDate, endDate } = this.$refs.period;
        if (new Date(startDate).getTime() && new Date(endDate).getTime()) {
          params["start"] = new Date(`${startDate} 00:00:00`).getTime();
          params["end"] = new Date(`${endDate} 23:59:59`).getTime();
        }

        const { data, status } = await getBackupList(folder.id, params);
        if (status == 200) {
          this.loading = false;
          this.backupList = data.backupList;
          this.totalMsgSize = data.totalMsgSize;
        }

        if (status == 204) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
