<template>
  <div class="mt-3 d-flex justify-center">
    <v-btn text @click.stop="goInbox"> {{ $t("mail.75") }}</v-btn>
    <v-btn text @click.stop="goSent">{{ $t("mail.76") }}</v-btn>
    <v-btn text @click.stop="goWrite">{{ $t("mail.메일쓰기") }}</v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("folder", ["getInboxId", "getSentId"])
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailList", "routeMailWrite"]),
    goInbox() {
      this.routeMailList({ folderId: this.getInboxId, page: 1 });
    },
    goSent() {
      this.routeMailList({ folderId: this.getSentId, page: 1 });
    },
    goWrite() {
      this.routeMailWrite({ t: 0 });
    }
  }
};
</script>
