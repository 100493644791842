<template>
  <div class="cr-send-result">
    <Message :recipients="recipients" />
    <Btns />
    <Recipients :recipients="recipients" />
    <ContactsContainer :contactList="contactList" />
  </div>
</template>

<style lang="scss" scoped>
.cr-send-result {
  height: 100%;
  overflow-y: auto;
}
</style>

<script>
import { mapGetters } from "vuex";
import Message from "./Message.vue";
import Btns from "./Btns.vue";
import Recipients from "./Recipients.vue";
import ContactsContainer from "./ContactsContainer.vue";

export default {
  components: { Message, Btns, Recipients, ContactsContainer },
  computed: {
    ...mapGetters("mailCompose", ["sendResult"]),
    recipients() {
      const { status, data } = this.sendResult;
      if (status !== 200) return [];
      if (data.responseCode === 204) return [];
      return data.recipientsResult;
    },
    contactList() {
      const { status, data } = this.sendResult;
      if (status !== 200) return [];
      if (data.responseCode === 204) return [];
      return data.contactList;
    }
  }
};
</script>
