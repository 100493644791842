<template>
  <TagAutocomplete
    v-if="attachPosition == -1"
    link
    :myTags="getTags"
    :viewTags="viewTags"
    @add:tag="addTag"
    @delete:tag="deleteTag"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TagAutocomplete from "@/commons/views/tag/autocomplete";

export default {
  components: { TagAutocomplete },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("folder", ["getTags"]),
    ...mapGetters("mailRoute", ["getRouteViewInfo"]),
    viewTags() {
      const { tags = "" } = this.item;
      if (!tags) return [];
      return tags.split(",");
    },
    attachPosition() {
      return this.getRouteViewInfo.actionObj?.view?.attachPosition || -1;
    }
  },
  methods: {
    ...mapActions("mail", ["updateTags"]),
    addTag(tag) {
      this.updateTags({ mails: [this.item], type: "add", tag });
    },
    deleteTag(tag) {
      this.updateTags({ mails: [this.item], type: "delete", tag });
    }
  }
};
</script>
