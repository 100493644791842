<template>
  <div class="cr-result-message">
    <h3>{{ title }}</h3>
    <h5 v-if="description" :class="{ 'red--text': !isReserve }">
      {{ description }}
    </h5>
  </div>
</template>

<style lang="scss" scoped>
.cr-result-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    recipients: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("mailCompose", ["isReserve", "sendResult"]),
    successRcptCnt() {
      return this.recipients.filter(r => r.status == 0).length;
    },
    invalidAllRcpt() {
      return (
        this.recipients.length ===
        this.recipients.filter(r => [501, 550].includes(r.replyCode)).length
      );
    },

    title() {
      const { status, data } = this.sendResult;

      if (status !== 200) return this.$t("mail.82");
      switch (data.responseCode) {
        case 204:
          if (this.isReserve) return this.$t("mail.375");
          return this.$t("mail.374");
        case 404:
          return this.$t("mail.82");
        case 405:
          if (!this.successRcptCnt) return this.$t("mail.82");
          return this.$t("mail.373");
        default:
          return this.$t("mail.358");
      }
    },
    description() {
      const { status, data } = this.sendResult;

      // 수신자 없음
      if (status === 500 && data.code === "RECIPIENT_NOT_FOUND") {
        return this.$t("mail.574");
      }

      // 사용자 용량 부족
      if (status === 507) return this.$t("mail.377");
      if (status !== 200) return data?.description || this.$t("common.7");

      switch (data.responseCode) {
        case 401:
          // 외부메일로 발송시 인증오류
          return this.$t("mail.79");
        case 404:
          // 수신자 메일주소 오류
          if (this.invalidAllRcpt) return this.$t("mail.372");
          return this.$t("mail.196");
        case 405:
          // 통신중 에러 발생
          return "";
        case 552:
          // eml 사이즈 초과
          return this.$t("mail.197");
        case 204:
          // 내게쓰기, 예약메일 저장시
          if (this.isReserve) return this.$t("mail.376");
          return "";
        default:
          return "";
      }
    }
  }
};
</script>
