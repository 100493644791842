<template>
  <div class="cr-external-wrap" @keydown.enter.stop="addExternalMailFunc">
    <!-- 메일서버 선택 -->
    <ServerName :item.sync="item" />
    <!-- 가져오기 방식 -->
    <FetchType :item.sync="item" />
    <!-- 서버정보 -->
    <FetchServer :item.sync="item" />
    <!-- 메일주소 -->
    <Email :item.sync="item" />
    <!-- 비밀번호 -->
    <Password :item.sync="item" />
    <!-- 메일함 선택 -->
    <Mailbox :item.sync="item" />
    <!-- 태그 -->
    <Tag :item.sync="item" />
    <!-- 외부메일 자동으로 가져오기 -->
    <AutoFetch :item.sync="item" />
    <v-divider />
    <!-- SMTP -->
    <Smtp :item.sync="item" />
    <v-divider />
    <PolicyAgree :item.sync="item" />
  </div>
</template>

<style lang="scss" scoped>
.cr-external-wrap::v-deep {
  max-width: 1029px;
  padding: 8px 8px 0px 8px;

  .cr-config-title {
    font-size: 1rem !important;
    font-weight: bold;
    display: flex !important;
    align-items: center !important;
    color: #616161 !important;
    caret-color: #616161 !important;
  }
}
</style>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import RSAKey from "@/commons/utils/rsa/rsa.js";
import ServerName from "./external/ServerName.vue";
import FetchServer from "./external/FetchServer.vue";
import Email from "./external/Email.vue";
import Password from "./external/Password.vue";
import Mailbox from "./external/Mailbox.vue";
import Tag from "./external/Tag.vue";
import AutoFetch from "./external/AutoFetch.vue";
import Smtp from "./external/Smtp.vue";
import PolicyAgree from "./external/PolicyAgree.vue";
import FetchType from "./external/FetchType.vue";

export default {
  components: {
    ServerName,
    FetchServer,
    Email,
    Password,
    Mailbox,
    Tag,
    AutoFetch,
    Smtp,
    PolicyAgree,
    FetchType
  },
  props: { confirm: { default: false, type: Boolean } },
  async mounted() {
    const { data = {}, status = 500 } = await this.authKey();
    if (status !== 200) {
      this.openSnackbar({ message: this.$t("drive.7"), type: "ERROR" });
      this.CLOSE_DIALOG();
      return;
    }

    this.rsa.setPublic(data.modulus, data.exponent);
  },
  data({ $store: { getters } }) {
    const rsa = new RSAKey();
    const { external = {} } = getters["mailDialog/params"];
    const item = { serverName: "custom", fetchServerType: "POP3", ...external };
    // reference제거
    Object.keys(item).forEach(key => {
      if (!item[key] || typeof item[key] !== "object") return;
      if (Array.isArray(item[key])) return (item[key] = [...item[key]]);
      item[key] = { ...item[key] };
    });

    return { rsa, item };
  },
  watch: {
    confirm(next) {
      if (!next) return;
      this.addExternalMailFunc();
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"])
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("auth", ["authKey"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mailConfigExternal", [
      "addExternalMail",
      "updateExternalMail",
      "getExternalList"
    ]),
    ...mapActions("confirm", {
      confirmFunc: "confirm",
      disagree: "disagree",
      agree: "agree"
    }),
    async addExternalMailFunc() {
      let p = {};
      const { length } = this.$children;
      for (let i = 0; i < length; i += 1) {
        const node = this.$children[i];
        if (node.validation && !node.validation()) {
          this.$emit("update:confirm", false);
          return;
        }

        Object.keys(node.$data).forEach(key => {
          p[key] = node.$data[key];
        });
      }

      let func = this.addExternalMail;
      if (this.item.id > 0) {
        p["id"] = this.item.id;
        func = this.updateExternalMail;
      }
      p = {
        ...p,
        email: this.rsa.encrypt(p.email),
        serverName: p.serverName === "custom" || p.fetchServer.split(".")[1],
        fetchPassword: this.rsa.encrypt(p.fetchPassword),
        smtpPassword: this.rsa.encrypt(p.smtpPassword),
        useTag: p.useTag ? 1 : 0,
        useSmtp: p.useSmtp ? 1 : 0,
        useAutoFetch: p.useAutoFetch ? 1 : 0,
        policyAgree: p.policyAgree ? 1 : 0
      };

      const statusCode = await func(p);
      await this.getExternalList();

      if (statusCode) return this.CLOSE_DIALOG();

      this.$emit("update:confirm", false);
    }
  }
};
</script>
