<template>
  <div class="d-flex" :class="{ 'flex-column py-1': smAndDown }">
    <FileName :item="fileName" />
    <div v-if="smAndDown" class="d-flex align-center">
      <Size :item="item" class="mr-1" />
      <Type :item="item" class="d-flex align-center" />
    </div>
  </div>
</template>

<script>
import FileName from "@/mail/views/components/main/commons/FileName";
import Size from "./Size.vue";
import Type from "./Type.vue";

export default {
  components: { FileName, Size, Type },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    fileName() {
      const { name: fileName } = this.item;
      const ext = fileName.split(".").at(-1);
      const [name] = fileName.split(`.${ext}`);

      return { name, ext };
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>
