<template>
  <span v-show="src" class="mr-1 ml-0 px-0 v-chip cr-view-print-hide">
    <img :src="src" />
  </span>
</template>

<style lang="scss" scoped>
.v-chip {
  min-width: 24px;
}
</style>

<script>
export default {
  props: {
    countryCode: {
      default: "",
      type: String
    }
  },
  computed: {
    src() {
      try {
        return require(`@/commons/assets/country/${this.countryCode?.toLowerCase()}.png`);
      } catch (e) {
        return "";
      }
    }
  }
};
</script>
