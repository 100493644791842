const languageMap = {
  sbp: ["tz"], // [탄자니아]
  ksh: ["de"], // [독일]
  nyn: ["ug"], // [우간다]
  ps: ["af"], // [아프가니스탄]
  pt: ["mo", "mz", "lu", "gq", "ch", "tl", "gw", "cv", "br", "st", "ao", "pt"], // [마카오(중국 특별행정구), 모잠비크, 룩셈부르크, 적도 기니, 스위스, 동티모르, 기니비사우, 카보베르데, 브라질, 상투메 프린시페, 앙골라, 포르투갈]
  luo: ["ke"], // [케냐]
  fil: ["ph"], // [필리핀]
  mgh: ["mz"], // [모잠비크]
  luy: ["ke"], // [케냐]
  ccp: ["bd", "in"], // [방글라데시, 인도]
  mgo: ["cm"], // [카메룬]
  bas: ["cm"], // [카메룬]
  teo: ["ug", "ke"], // [우간다, 케냐]
  qu: ["ec", "pe", "bo"], // [에콰도르, 페루, 볼리비아]
  af: ["za", "na"], // [남아프리카, 나미비아]
  brx: ["in"], // [인도]
  ak: ["gh"], // [가나]
  am: ["et"], // [에티오피아]
  kde: ["tz"], // [탄자니아]
  ar: [
    "jo",
    "sa",
    "td",
    "eg",
    "eh",
    "sd",
    "km",
    "mr",
    "il",
    "sy",
    "ps",
    "ye",
    "om",
    "dz",
    "ss",
    "ma",
    "er",
    "so",
    "lb",
    "ae",
    "bh",
    "tn",
    "iq",
    "qa",
    "001",
    "kw",
    "ly",
    "dj"
  ], // [요르단, 사우디아라비아, 차드, 이집트, 서사하라, 수단, 코모로, 모리타니, 이스라엘, 시리아, 팔레스타인 지구, 예멘, 오만, 알제리, 남수단, 모로코, 에리트리아, 소말리아, 레바논, 아랍에미리트, 바레인, 튀니지, 이라크, 카타르, 세계, 쿠웨이트, 리비아, 지부티]
  as: ["in"], // [인도]
  az: ["az", "az"], // [아제르바이잔, 아제르바이잔]
  rm: ["ch"], // [스위스]
  rn: ["bi"], // [부룬디]
  ro: ["ro", "md"], // [루마니아, 몰도바]
  ru: ["kz", "md", "ru", "by", "kg", "ua"], // [카자흐스탄, 몰도바, 러시아, 벨라루스, 키르기스스탄, 우크라이나]
  be: ["by"], // [벨라루스]
  bg: ["bg"], // [불가리아]
  rw: ["rw"], // [르완다]
  kea: ["cv"], // [카보베르데]
  bm: ["ml"], // [말리]
  bn: ["bd", "in"], // [방글라데시, 인도]
  bo: ["cn", "in"], // [중국, 인도]
  twq: ["ne"], // [니제르]
  br: ["fr"], // [프랑스]
  bs: ["ba", "ba"], // [보스니아 헤르체고비나, 보스니아 헤르체고비나]
  xog: ["ug"], // [우간다]
  sd: ["pk"], // [파키스탄]
  se: ["no", "fi", "se"], // [노르웨이, 핀란드, 스웨덴]
  sg: ["cf"], // [중앙 아프리카 공화국]
  si: ["lk"], // [스리랑카]
  seh: ["mz"], // [모잠비크]
  sk: ["sk"], // [슬로바키아]
  sl: ["si"], // [슬로베니아]
  sn: ["zw"], // [짐바브웨]
  so: ["ke", "dj", "so", "et"], // [케냐, 지부티, 소말리아, 에티오피아]
  sq: ["al", "mk", "xk"], // [알바니아, 마케도니아, 코소보]
  ca: ["fr", "es", "it", "ad", "es"], // [프랑스, 스페인, 이탈리아, 안도라, 스페인]
  sr: ["me", "me", "cs", "me", "ba", "rs", "ba", "xk", "ba", "xk", "rs", "rs"], // [몬테네그로, 몬테네그로, 세르비아 몬테네그로(유고슬라비아), 몬테네그로, 보스니아 헤르체고비나, 세르비아, 보스니아 헤르체고비나, 코소보, 보스니아 헤르체고비나, 코소보, 세르비아, 세르비아]
  ses: ["ml"], // [말리]
  mzn: ["ir"], // [이란]
  ce: ["ru"], // [러시아]
  sv: ["se", "fi", "ax"], // [스웨덴, 핀란드, 올란드 제도]
  sw: ["ug", "cd", "ke", "tz"], // [우간다, 콩고-킨샤사, 케냐, 탄자니아]
  ta: ["sg", "my", "lk", "in"], // [싱가포르, 말레이시아, 스리랑카, 인도]
  asa: ["tz"], // [탄자니아]
  yav: ["cm"], // [카메룬]
  cs: ["cz"], // [체코]
  te: ["in"], // [인도]
  cu: ["ru"], // [러시아]
  tg: ["tj"], // [타지키스탄]
  th: ["th", "th"], // [태국, 태국]
  ti: ["et", "er"], // [에티오피아, 에리트리아]
  cy: ["gb"], // [영국]
  tk: ["tm"], // [투르크메니스탄]
  dyo: ["sn"], // [세네갈]
  to: ["to"], // [통가]
  da: ["dk", "gl"], // [덴마크, 그린란드]
  tr: ["tr", "cy"], // [터키, 키프로스]
  tt: ["ru"], // [러시아]
  de: ["it", "ch", "be", "lu", "de", "li", "at"], // [이탈리아, 스위스, 벨기에, 룩셈부르크, 독일, 리히텐슈타인, 오스트리아]
  cgg: ["ug"], // [우간다]
  ast: ["es"], // [스페인]
  nmg: ["cm"], // [카메룬]
  bem: ["zm"], // [잠비아]
  ug: ["cn"], // [중국]
  dz: ["bt"], // [부탄]
  uk: ["ua"], // [우크라이나]
  bez: ["tz"], // [탄자니아]
  ur: ["in", "pk"], // [인도, 파키스탄]
  dje: ["ne"], // [니제르]
  haw: ["us"], // [미국]
  ee: ["tg", "gh"], // [토고, 가나]
  prg: ["001"], // [세계]
  uz: ["uz", "uz", "af"], // [우즈베키스탄, 우즈베키스탄, 아프가니스탄]
  tzm: ["ma"], // [모로코]
  el: ["cy", "gr"], // [키프로스, 그리스]
  en: [
    "nu",
    "ms",
    "gg",
    "jm",
    "zm",
    "mt",
    "lr",
    "gh",
    "il",
    "pw",
    "vc",
    "us",
    "150",
    "kn",
    "mo",
    "bz",
    "nr",
    "mp",
    "gd",
    "bw",
    "au",
    "cy",
    "rw",
    "ie",
    "ki",
    "sz",
    "gb",
    "as",
    "je",
    "cx",
    "at",
    "sx",
    "tz",
    "pr",
    "bs",
    "ke",
    "nl",
    "ss",
    "mg",
    "za",
    "tv",
    "pn",
    "mh",
    "gy",
    "ng",
    "pk",
    "lc",
    "tt",
    "bm",
    "vu",
    "us",
    "nf",
    "gu",
    "ai",
    "cm",
    "to",
    "pg",
    "er",
    "ph",
    "dm",
    "ck",
    "bi",
    "ag",
    "ws",
    "na",
    "sl",
    "sh",
    "ky",
    "dk",
    "zw",
    "um",
    "tk",
    "si",
    "fm",
    "be",
    "sg",
    "ch",
    "sd",
    "my",
    "fk",
    "gm",
    "dg",
    "se",
    "001",
    "sb",
    "mw",
    "io",
    "de",
    "cc",
    "fi",
    "sc",
    "vi",
    "ug",
    "nz",
    "fj",
    "bb",
    "mu",
    "im",
    "ls",
    "hk",
    "gi",
    "ca",
    "vg",
    "tc",
    "in"
  ], // [니우에, 몬트세라트, 건지, 자메이카, 잠비아, 몰타, 라이베리아, 가나, 이스라엘, 팔라우, 세인트빈센트그레나딘, 미국, 유럽, 세인트키츠 네비스, 마카오(중국 특별행정구), 벨리즈, 나우루, 북마리아나제도, 그레나다, 보츠와나, 오스트레일리아, 키프로스, 르완다, 아일랜드, 키리바시, 스와질란드, 영국, 아메리칸 사모아, 저지, 크리스마스섬, 오스트리아, 신트마르턴, 탄자니아, 푸에르토리코, 바하마, 케냐, 네덜란드, 남수단, 마다가스카르, 남아프리카, 투발루, 핏케언 섬, 마셜 제도, 가이아나, 나이지리아, 파키스탄, 세인트루시아, 트리니다드 토바고, 버뮤다, 바누아투, 미국, 노퍽섬, 괌, 앵귈라, 카메룬, 통가, 파푸아뉴기니, 에리트리아, 필리핀, 도미니카, 쿡 제도, 부룬디, 앤티가 바부다, 사모아, 나미비아, 시에라리온, 세인트헬레나, 케이맨 제도, 덴마크, 짐바브웨, 미국령 해외 제도, 토켈라우, 슬로베니아, 미크로네시아, 벨기에, 싱가포르, 스위스, 수단, 말레이시아, 포클랜드 제도, 감비아, 디에고 가르시아, 스웨덴, 세계, 솔로몬 제도, 말라위, 영국령 인도양 식민지, 독일, 코코스 제도, 핀란드, 세이셸, 미국령 버진아일랜드, 우간다, 뉴질랜드, 피지, 바베이도스, 모리셔스, 맨 섬, 레소토, 홍콩(중국 특별행정구), 지브롤터, 캐나다, 영국령 버진아일랜드, 터크스 케이커스 제도, 인도]
  nnh: ["cm"], // [카메룬]
  eo: ["001"], // [세계]
  chr: ["us"], // [미국]
  es: [
    "bo",
    "ph",
    "es",
    "co",
    "us",
    "gt",
    "cl",
    "do",
    "pe",
    "pa",
    "gq",
    "mx",
    "hn",
    "ve",
    "py",
    "ec",
    "ea",
    "bz",
    "pr",
    "ar",
    "sv",
    "419",
    "ic",
    "br",
    "cu",
    "uy",
    "cr",
    "ni"
  ], // [볼리비아, 필리핀, 스페인, 콜롬비아, 미국, 과테말라, 칠레, 도미니카 공화국, 페루, 파나마, 적도 기니, 멕시코, 온두라스, 베네수엘라, 파라과이, 에콰도르, 세우타 및 멜리야, 벨리즈, 푸에르토리코, 아르헨티나, 엘살바도르, 라틴 아메리카, 카나리아 제도, 브라질, 쿠바, 우루과이, 코스타리카, 니카라과]
  et: ["ee"], // [에스토니아]
  eu: ["es"], // [스페인]
  vi: ["vn"], // [베트남]
  khq: ["ml"], // [말리]
  shi: ["ma", "ma"], // [모로코, 모로코]
  hsb: ["de"], // [독일]
  vo: ["001"], // [세계]
  fa: ["af", "ir"], // [아프가니스탄, 이란]
  ff: ["sn", "cm", "gn", "mr"], // [세네갈, 카메룬, 기니, 모리타니]
  fi: ["fi"], // [핀란드]
  rwk: ["tz"], // [탄자니아]
  yue: ["cn", "hk"], // [중국, 홍콩(중국 특별행정구)]
  fo: ["dk", "fo"], // [덴마크, 페로 제도]
  fr: [
    "dz",
    "ml",
    "pm",
    "mg",
    "mf",
    "vu",
    "mc",
    "ne",
    "nc",
    "cm",
    "sn",
    "ma",
    "bl",
    "bi",
    "tn",
    "pf",
    "fr",
    "bj",
    "gq",
    "ci",
    "ht",
    "cg",
    "be",
    "bf",
    "yt",
    "re",
    "gp",
    "dj",
    "ch",
    "cf",
    "tg",
    "gn",
    "td",
    "lu",
    "cd",
    "sc",
    "ca",
    "mu",
    "wf",
    "km",
    "mq",
    "gf",
    "mr",
    "ga",
    "sy",
    "rw"
  ], // [알제리, 말리, 생피에르 미클롱, 마다가스카르, 생마르탱, 바누아투, 모나코, 니제르, 뉴칼레도니아, 카메룬, 세네갈, 모로코, 생바르텔레미, 부룬디, 튀니지, 프랑스령 폴리네시아, 프랑스, 베냉, 적도 기니, 코트디부아르, 아이티, 콩고-브라자빌, 벨기에, 부르키나파소, 마요트, 리유니온, 과들루프, 지부티, 스위스, 중앙 아프리카 공화국, 토고, 기니, 차드, 룩셈부르크, 콩고-킨샤사, 세이셸, 캐나다, 모리셔스, 왈리스-푸투나 제도, 코모로, 마르티니크, 프랑스령 기아나, 모리타니, 가봉, 시리아, 르완다]
  fy: ["nl"], // [네덜란드]
  jgo: ["cm"], // [카메룬]
  lkt: ["us"], // [미국]
  wo: ["sn"], // [세네갈]
  zgh: ["ma"], // [모로코]
  ga: ["ie"], // [아일랜드]
  wae: ["ch"], // [스위스]
  gd: ["gb"], // [영국]
  gl: ["es"], // [스페인]
  gu: ["in"], // [인도]
  gv: ["im"], // [맨 섬]
  ha: ["ng", "ne", "gh"], // [나이지리아, 니제르, 가나]
  ckb: ["iq", "ir"], // [이라크, 이란]
  hi: ["in"], // [인도]
  agq: ["cm"], // [카메룬]
  gsw: ["ch", "fr", "li"], // [스위스, 프랑스, 리히텐슈타인]
  hr: ["hr", "ba"], // [크로아티아, 보스니아 헤르체고비나]
  kkj: ["cm"], // [카메룬]
  hu: ["hu"], // [헝가리]
  hy: ["am"], // [아르메니아]
  yo: ["ng", "bj"], // [나이지리아, 베냉]
  ig: ["ng"], // [나이지리아]
  vai: ["lr", "lr"], // [라이베리아, 라이베리아]
  naq: ["na"], // [나미비아]
  ii: ["cn"], // [중국]
  in: ["id"], // [인도네시아]
  id: ["id"], // [인도네시아]
  is: ["is"], // [아이슬란드]
  it: ["va", "sm", "ch", "it"], // [바티칸 시국, 산마리노, 스위스, 이탈리아]
  kln: ["ke"], // [케냐]
  zh: ["sg", "sg", "hk", "tw", "hk", "cn", "mo", "mo", "cn", "tw", "hk"], // [싱가포르, 싱가포르, 홍콩(중국 특별행정구), 대만, 홍콩(중국 특별행정구), 중국, 마카오(중국 특별행정구), 마카오(중국 특별행정구), 중국, 대만, 홍콩(중국 특별행정구)]
  iw: ["il"], // [이스라엘]
  ja: ["jp", "jp"], // [일본, 일본]
  zu: ["za"], // [남아프리카]
  ji: ["001"], // [세계]
  guz: ["ke"], // [케냐]
  smn: ["fi"], // [핀란드]
  ka: ["ge"], // [조지아]
  ki: ["ke"], // [케냐]
  mas: ["ke", "tz"], // [케냐, 탄자니아]
  kk: ["kz"], // [카자흐스탄]
  kl: ["gl"], // [그린란드]
  km: ["kh"], // [캄보디아]
  kn: ["in"], // [인도]
  ko: ["kp", "kr"], // [북한, 대한민국]
  ks: ["in"], // [인도]
  kw: ["gb"], // [영국]
  ky: ["kg"], // [키르기스스탄]
  lb: ["lu"], // [룩셈부르크]
  ebu: ["ke"], // [케냐]
  lg: ["ug"], // [우간다]
  nds: ["de", "nl"], // [독일, 네덜란드]
  jmc: ["tz"], // [탄자니아]
  ln: ["cd", "cf", "cg", "ao"], // [콩고-킨샤사, 중앙 아프리카 공화국, 콩고-브라자빌, 앙골라]
  fur: ["it"], // [이탈리아]
  lo: ["la"], // [라오스]
  kok: ["in"], // [인도]
  lt: ["lt"], // [리투아니아]
  lu: ["cd"], // [콩고-킨샤사]
  lv: ["lv"], // [라트비아]
  nus: ["ss"], // [남수단]
  vun: ["tz"], // [탄자니아]
  lag: ["tz"], // [탄자니아]
  dav: ["ke"], // [케냐]
  mg: ["mg"], // [마다가스카르]
  lrc: ["ir", "iq"], // [이란, 이라크]
  mk: ["mk"], // [마케도니아]
  ml: ["in"], // [인도]
  mn: ["mn"], // [몽골]
  mr: ["in"], // [인도]
  ms: ["my", "sg", "bn"], // [말레이시아, 싱가포르, 브루나이]
  mt: ["mt"], // [몰타]
  my: ["mm"], // [미얀마]
  dsb: ["de"], // [독일]
  nb: ["sj", "no"], // [스발바르제도-얀마웬섬, 노르웨이]
  nd: ["zw"], // [짐바브웨]
  ne: ["in", "np"], // [인도, 네팔]
  mua: ["cm"], // [카메룬]
  nl: ["sr", "bq", "nl", "cw", "sx", "aw", "be"], // [수리남, 네덜란드령 카리브, 네덜란드, 퀴라소, 신트마르턴, 아루바, 벨기에]
  nn: ["no"], // [노르웨이]
  no: ["no", "no"], // [노르웨이, 노르웨이]
  rof: ["tz"], // [탄자니아]
  kab: ["dz"], // [알제리]
  kam: ["ke"], // [케냐]
  sah: ["ru"], // [러시아]
  mer: ["ke"], // [케냐]
  om: ["et", "ke"], // [에티오피아, 케냐]
  saq: ["ke"], // [케냐]
  or: ["in"], // [인도]
  os: ["ge", "ru"], // [조지아, 러시아]
  mfe: ["mu"], // [모리셔스]
  pa: ["in", "pk"], // [인도, 파키스탄]
  dua: ["cm"], // [카메룬]
  ksb: ["tz"], // [탄자니아]
  pl: ["pl"], // [폴란드]
  ewo: ["cm"], // [카메룬]
  ksf: ["cm"] // [카메룬]
};

const countryCodeListForLanguage = languageCode =>
  languageMap[languageCode] || [];

const containsCountryCode = (languageCode, countryCode) => {
  const countryCodeList = countryCodeListForLanguage(
    languageCode.toLowerCase()
  );
  return countryCodeList.includes(countryCode.toLowerCase());
};

export { countryCodeListForLanguage, containsCountryCode };
