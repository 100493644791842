<template>
  <div class="d-flex align-center">
    <div class="cr-detail-label text-subtitle-2">
      {{ $t("mail.내용") }}
    </div>

    <v-select
      ref="contentRef"
      dense
      outlined
      hide-details
      v-model="contentSelectItem"
      :items="contentSelectItems"
      :menu-props="{ 'offset-y': true, closeOnClick: true }"
      @click:append.stop="$refs.contentRef.activateMenu()"
    />
    <v-text-field
      dense
      outlined
      hide-details
      autocomplete="off"
      v-model="text"
      @keydown.enter="$parent.searchDetail"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  width: 304px;
  height: 40px;

  &.v-select {
    max-width: 155px;
    margin-right: 5px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  mounted() {
    // url 타고들어왔을때
    const item = this.contentSelectItems.find(i => this[i.value]);
    this.contentSelectItem = item?.value || "querySubjectContentAttach";
    this.text = this[this.contentSelectItem];
  },
  data({ $store: { getters } }) {
    const { actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};
    let querySubjectContentAttach = search?.querySubjectContentAttach || "";
    if (search?.type === "all.content") {
      querySubjectContentAttach = search?.query;
    }

    let querySubject = search?.querySubject || "";
    if (search?.type === "all.subject" || search?.type === "all.from.subject") {
      querySubject = search?.query || querySubject;
    }

    return {
      text: "",
      contentSelectItem: "querySubjectContentAttach",
      querySubjectContentAttach,
      querySubjectContent: search?.querySubjectContent || "",
      querySubject: querySubject,
      queryContent: search?.queryContent || "",
      queryAttach: search?.queryAttach || ""
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    contentSelectItems() {
      return [
        { text: this.$t("mail.전체"), value: "querySubjectContentAttach" },
        { text: this.$t("mail.36"), value: "querySubjectContent" },
        { text: this.$t("mail.제목"), value: "querySubject" },
        { text: this.$t("mail.본문"), value: "queryContent" },
        { text: this.$t("mail.첨부파일"), value: "queryAttach" }
      ];
    }
  },
  watch: {
    text(text) {
      this[this.contentSelectItem] = text;
    },
    contentSelectItem(contentSelectItem) {
      this.contentSelectItems.forEach(i => (this[i.value] = ""));
      this[contentSelectItem] = this.text;
    },
    getRouteListInfo({ actionObj }) {
      const { search } = actionObj || {};

      this.text = search?.querySubjectContentAttach || "";

      this["querySubjectContentAttach"] =
        search?.querySubjectContentAttach || "";
      this["querySubjectContent"] = search?.querySubjectContent || "";

      // 기본 검색 데이터 바인딩
      this["querySubject"] =
        search?.type === "all.subject" && search?.query
          ? search?.query
          : search?.querySubject || "";

      // 상세 검색 데이터 바인딩
      this["queryContent"] =
        search?.type === "all.content" && search?.query
          ? search?.query
          : search?.queryContent;

      this["queryAttach"] = search?.queryAttach || "";

      const item = this.contentSelectItems.find(i => this[i.value]);
      if (!item) return;

      this.contentSelectItem = item?.value || "querySubjectContentAttach";
      this.text = this[this.contentSelectItem];
    }
  },
  methods: {
    getParams() {
      const { contentSelectItem } = this;
      const params = {
        querySubjectContentAttach: "",
        querySubjectContent: "",
        querySubject: "",
        queryContent: "",
        queryAttach: ""
      };

      return {
        valid: !isBlank(this[contentSelectItem]),
        params: { ...params, [contentSelectItem]: this[contentSelectItem] }
      };
    }
  }
};
</script>
