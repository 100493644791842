<template>
  <v-row class="ma-0 cr-border">
    <v-col
      class="pb-0 pb-sm-3 align-center cr-config-title grey--text text--darken-2  font-weight-bold"
      cols="12"
      sm="4"
    >
      {{ $t("mail.540") }}
    </v-col>
    <v-col
      class="pl-0 pb-0 pb-sm-3 align-center cr-config-title grey--text text--darken-2 "
    >
      <v-textarea class="no-resize" v-model="contents" outlined hide-details>
      </v-textarea>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.v-input.v-textarea::v-deep {
  .v-input__control .v-input__slot {
    fieldset {
      right: -1px;
    }
  }
}
.no-resize ::v-deep textarea {
  resize: none;
}
</style>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    contents: {
      get() {
        return this.config?.contents || "";
      },
      set(contents) {
        this.$emit("update:config", { ...this.config, contents });
      }
    }
  }
};
</script>
