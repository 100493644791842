<template>
  <CommonComp>
    <div :class="mainCls" v-resize="resize">
      <SearchDetail />
      <Tab />
      <Toolbar />
      <div class="cr-mail-main-content">
        <!-- write -> split -> view -> list 순서 중요 -->
        <MailWrite v-show="isShow('write')" />
        <MailConfig v-show="isShow('config')" :key="getConfigKey" />
        <Split
          v-if="isRender('split')"
          v-show="isShow('split')"
          :key="`split_${getSplitView}`"
        />
        <MailView v-if="isRender('view')" v-show="isShow('view')" />
        <MailList
          v-if="isRender('list')"
          v-show="isShow('list')"
          :key="getRouteListInfo.folderId"
        />
      </div>

      <ConfigDrawer />
      <MailTutorial />
    </div>
  </CommonComp>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CommonComp from "./CommonComp.vue";
import MailTutorial from "@/commons/views/tutorial/MailTutorial";
import SearchDetail from "./components/search/SearchDetail";
import Tab from "./components/main/tab";
import Toolbar from "./components/main/toolbar";
import Split from "./components/main/Split";
import MailList from "./components/main/MailList";
import MailView from "./components/main/MailView";
import MailWrite from "./components/main/MailWrite";
import MailConfig from "./components/main/MailConfig";
import ConfigDrawer from "./components/main/ConfigDrawer";

export default {
  components: {
    CommonComp,
    MailTutorial,
    SearchDetail,
    Tab,
    Toolbar,
    MailWrite,
    Split,
    MailList,
    MailView,
    MailConfig,
    ConfigDrawer
  },
  created() {
    // 환경설정 닫기
    this.SET_SHOW_CONFIG_DRAWER(false);
  },
  data() {
    return { innerWidth: 0 };
  },
  computed: {
    ...mapGetters("sidePanel", ["sidePanelInfo"]),
    ...mapGetters("mailCompose", ["showSendResult"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailConfig", ["getSplitView", "getUseTab"]),
    ...mapGetters("mailTab", ["tabs", "existWriteTab"]),
    ...mapGetters("mailLayout", [
      "naviInfo",
      "showConfigDrawer",
      "configDrawerWidth"
    ]),
    ...mapGetters("folder", [
      "currentFolder",
      "isDrafts",
      "isShareRoot",
      "isShare",
      "isSpam"
    ]),
    mainCls() {
      return {
        "cr-mail-main": true,
        "cr-lg-up": this.$vuetify.breakpoint.lgAndUp,
        "show-config-drawer": this.showConfigDrawer
      };
    },
    getConfigKey() {
      // tab 사용시 탭열려있는지 확인필요
      const splitName = this.$route.name.split("_");
      if (!this.getUseTab) return "config-key-" + splitName[1];

      const [tab] = this.tabs.filter(({ tabId }) => tabId == "config");
      if (!tab) return "config-key";
      return "config-key-" + splitName[0];
    },
    isRender: vue => view => {
      if (!vue.currentFolder) return false;

      // 모바일
      if (vue.$vuetify.breakpoint.xs) {
        if (view == "split") return false;
        if (view == "list" || view == "view") return true;
      }

      // 분할화면
      if (
        view == "split" &&
        vue.getSplitView > 0 &&
        !vue.isDrafts &&
        !vue.isShareRoot &&
        !vue.isSpam
      ) {
        return true;
      }

      return (
        vue.isDrafts ||
        vue.isSpam ||
        (vue.getSplitView == 0 && view != "split") ||
        (vue.isShareRoot && view == "list")
      );
    },
    isShow: vue => view => {
      if (!vue.currentFolder) return false;
      const routeName = vue.$route.name.split("_")[1];

      // 모바일
      if (vue.$vuetify.breakpoint.xs && ["list", "view"].includes(view)) {
        return (!routeName && view == "list") || routeName == view;
      }

      // 좌우분할에 메일쓰기 화면이아닐때
      if (
        vue.getSplitView > 0 &&
        routeName != "write" &&
        routeName != "config"
      ) {
        // 임시보관 메일함일때
        if (vue.isSpam || vue.isDrafts || vue.isShareRoot) {
          return view == "list";
        }

        return view == "split";
      }
      if (view == "list" && !routeName) return true;
      return routeName && routeName.includes(view);
    },
    mainWidth() {
      let mainWidth = this.innerWidth;
      const lgAndUp = this.$vuetify.breakpoint.lgAndUp;
      const { showNavi, naviWidth } = this.naviInfo;
      // 왼쪽 Drawer 열려있고 화면이 lg보다 클때
      if (showNavi && lgAndUp) mainWidth -= naviWidth;
      // sidePanel 열려있고 화면이 lg보다 클때
      const { showSidePanel, sidePanelWidth } = this.sidePanelInfo;
      if (showSidePanel && lgAndUp) mainWidth -= sidePanelWidth;
      // 환경설정 Drawer 열려있고 화면이 lg보다 클때
      if (this.showConfigDrawer && lgAndUp) mainWidth -= this.configDrawerWidth;

      return mainWidth;
    }
  },
  watch: {
    mainWidth(width) {
      this.SET_MAIN_WIDTH(width);
    }
  },
  methods: {
    ...mapMutations("splash", ["SET_SHOW_SPLASH"]),
    ...mapMutations("mailView", ["RESET_VIEW_STATE"]),
    ...mapMutations("mailRoute", ["RESET_ROUTE_VIEW"]),
    ...mapMutations("mail", ["SET_SELECTED_MAILS", "SET_EXPANDED"]),
    ...mapMutations("mailTab", ["SET_TAB", "RESET_TAB", "DELETE_TAB"]),
    ...mapMutations("mailLayout", ["SET_SHOW_CONFIG_DRAWER", "SET_MAIN_WIDTH"]),

    resize() {
      this.innerWidth = window.innerWidth;
    }
  },

  /**
   * 처음 들어올때 setParams -> Enter,
   * 라우트 이동시 Leave -> setParams -> Enter
   * 같은 라우트 이동시 setParams -> Update
   *
   * 같은 라우트 이동시 파라미터까지 동일하면 라우트 중복에러 발생
   * 위와 같은 경우 초기화는 route.js 에서 처리
   *
   */
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 메일 스플래시 종료
      vm.SET_SHOW_SPLASH(false);

      // 탭 사용중이 아닐때 이전 라우트 탭은 제거
      if (!vm.getUseTab && from.name) {
        vm.DELETE_TAB(from.name.split("_")[1]);
      }

      // 라우팅에 따라 탭 추가
      let tab = { tabId: "list", name: "" };
      if (to.name == "mail_view" && vm.getSplitView == 0) {
        tab = { tabId: "view", name: vm.$t("mail.메일보기") };
      } else if (to.name == "mail_write") {
        tab = { tabId: "write", name: vm.$t("mail.메일쓰기") };
      } else if (to.name.includes("mail_config")) {
        tab = { tabId: "config", name: vm.$t("mail.메일설정") };
      }
      vm.SET_TAB(tab);
    });
  },
  beforeRouteUpdate(to, from, next) {
    // 메일리스트 라우팅 업데이트시 selectedMails / expanded 초기화
    if (
      (to.name == "mail" || to.name.includes("mail_list")) &&
      (from.name == "mail" || from.name.includes("mail_list"))
    ) {
      const { folderId: toFolderId, page: toPage } = to.params;
      const {
        folderId: fromFolderId = "all",
        page: fromPage = 1
      } = from.params;
      if (toFolderId != fromFolderId || toPage != fromPage) {
        this.SET_EXPANDED([]);
        this.SET_SELECTED_MAILS([]);
      }
    }

    next();
  },
  beforeRouteLeave(to, from, next) {
    let answer = true;

    // 탭 사용 안할때
    if (!this.getUseTab) {
      const tabId = from.name.split("_")[1];
      // 메일쓰기에서 이동할 경우 컨펌
      if (tabId === "write" && !this.showSendResult) {
        answer = window.confirm(this.$t("mail.1"));
      }
    }

    // 탭사용하고 다른 모듈 이동시 메일쓰기 탭 켜져있으면 컨펌
    if (
      this.getUseTab &&
      !to.name.includes("mail") &&
      this.existWriteTab &&
      !this.showSendResult
    ) {
      answer = window.confirm(this.$t("mail.1"));
    }

    // 다른 모듈 이동시 목록 탭을 제외하고 모든 탭 제거, 메일선택 제거
    if (answer && !to.name.includes("mail")) {
      this.RESET_TAB();
      this.SET_EXPANDED([]);
      this.SET_SELECTED_MAILS([]);
    }

    next(answer);
  }
};
</script>
