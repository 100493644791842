<template>
  <div class="cr-empty-view">
    <v-icon size="130" color="rgba(152, 128, 255, 0.2)">
      mdi-email-fast-outline
    </v-icon>
  </div>
</template>

<style lang="scss" scoped>
.cr-empty-view {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  top: 0px;
  left: 0px;
  right: 0px;
  height: calc(100% - 103px);
  z-index: 2;

  .v-icon {
    animation: flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  @keyframes flashing {
    0% {
      color: rgba(152, 128, 255, 0.2);
    }

    25% {
      color: rgba(152, 128, 255, 0.4);
    }

    50% {
      color: rgba(152, 128, 255, 0.6);
    }

    75% {
      color: rgba(152, 128, 255, 0.8);
    }

    100% {
      color: #9880ff;
    }
  }
}
</style>

<script>
export default {};
</script>
