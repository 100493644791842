<template>
  <div class="cr-spamRestore-wrapper">
    <!-- 스팸사유 -->
    <v-row class="no-gutters">
      <v-col cols="12" class="mb-4">
        <div class="d-flex align-center mb-2">
          <v-icon class="mr-1" color="red" size="22">
            mdi-alert-octagon-outline
          </v-icon>
          <div class="red--text font-weight-bold" v-text="$t('mail.435')"></div>
        </div>
        <!-- vendor별 스팸정보가 다르기 때문에 각각 만들고 vendor로 분기 처리 -->
        <div class="cr-detail-box pa-3">
          <!-- SPAM BREAKER -->
          <div class="cr-sb-wrapper" v-if="vendor == 'SB'">
            <!-- 필터링 대상 -->
            <div class="d-flex mb-1">
              <div class="cr-title" v-text="$t('mail.436') + ':'" />
              <span
                class="font-weight-bold"
                v-text="$t(`mail.spam_srcId_${sbDetail.srcId}`)"
              />
            </div>
            <!-- 대응 행동 -->
            <div class="d-flex mb-1">
              <div class="cr-title" v-text="$t('mail.437') + ':'" />
              <span
                class="font-weight-bold"
                v-text="$t(`mail.spam_actionType_${sbDetail.filterActionType}`)"
              />
            </div>
            <!-- 필터링 값 -->
            <div class="d-flex mb-1">
              <div class="cr-title" v-text="$t('mail.438') + ':'" />
              <span class="font-weight-bold" v-text="sbDetail.pattern" />
            </div>
            <div v-if="sbDetail.matchType > 0" class="d-flex mb-1">
              <div class="cr-title" v-text="$t('mail.439') + ':'" />
              <span
                class="font-weight-bold"
                v-text="$t(`mail.spam_matchType_${sbDetail.matchType}`)"
              />
            </div>
            <!-- 필터링 단계 -->
            <div class="d-flex">
              <div class="cr-title" v-text="$t('mail.440') + ':'" />
              <span
                class="font-weight-bold"
                v-text="$t(`mail.spam_category_${sbDetail.category}`)"
              />
            </div>
          </div>
          <div v-else>
            {{ $t("mail.441") }}
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-list-item class="px-2" dense>
          <v-list-item-content>
            <v-list-item-title class="d-flex algin-center">
              <span class="mr-1" v-text="$t('mail.442')" />
              <span class="red--text" v-text="$t('mail.443')" />
              <v-icon
                color="red"
                size="9"
                class="align-self-start"
                v-text="'mdi-asterisk'"
              />
            </v-list-item-title>
            <v-list-item-subtitle v-text="$t('mail.444')" />
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox v-model="restoreCheck" color="primary" />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="isShowAllowSenderCheck" class="px-2" dense>
          <v-list-item-content>
            <v-list-item-title v-text="$t('mail.445')" />
            <v-list-item-subtitle v-text="$t('mail.446')" />
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox v-model="allowSenderCheck" color="primary" />
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular
        class="mb-5 mx-auto d-flex justify-center"
        indeterminate
        size="64"
      ></v-progress-circular>
      <div
        class="font-weight-bold"
        style="text-align:center;"
        v-html="$t('mail.288')"
      ></div>
    </v-overlay>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  async created() {
    this.$emit("update:confirmDisabled", true);
    this.vendor = this.params.vendor;
    switch (this.vendor) {
      case "SB": {
        const {
          seq,
          srcId,
          actionType,
          mailType,
          virusName,
          pattern,
          matchType,
          category
        } = this.params.detail;

        const patternSplit = pattern.split(":");

        this.isShowAllowSenderCheck = patternSplit.length > 1 ? false : true;

        const filterActionType =
          actionType == 0
            ? 2
            : actionType == 2 && mailType == 1
            ? 4
            : actionType;

        this.id = seq;
        this.sbDetail = {
          // 필터링 대상
          srcId,
          // 대응 행동
          filterActionType,
          // 필터링 값
          pattern: mailType == 2 ? virusName : pattern,
          // 필터링 조건
          matchType,
          // 필터링 단계
          category
        };
        break;
      }
      default:
        break;
    }
  },
  props: {
    confirm: {
      default: false,
      type: Boolean
    },
    confirmDisabled: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    id: 0,
    vendor: "SB",
    isShowAllowSenderCheck: true,
    sbDetail: {
      srcId: 0, // 필터링 대상
      filterActionType: 0, // 대응 행동
      pattern: 0, // 필터링 값
      matchType: 0, // 필터링 조건
      category: 0 // 필터링 단계
    },

    restoreCheck: false,
    allowSenderCheck: false,
    overlay: false
  }),
  watch: {
    async confirm(value) {
      if (value) {
        if (!this.restoreCheck) {
          this.openSnackbar({
            message: i18n.t("mail.447")
          });
          return this.$emit("update:confirm", false);
        } else {
          this.overlay = true;

          const { fromEmail, subject } = this.params.detail;
          await this.recoveryMail({
            id: this.id,
            fromEmail: fromEmail,
            subject: subject,
            isUnblockChecked: !this.isShowAllowSenderCheck
              ? false
              : this.allowSenderCheck
          });
          this.overlay = false;
          this.CLOSE_DIALOG();
        }
      }
    },
    restoreCheck(value) {
      this.$emit("update:confirmDisabled", !value);
    }
  },
  computed: {
    ...mapGetters("mailDialog", ["params"])
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mail", ["recoveryMail"])
  }
};
</script>

<style lang="scss" scoped>
.cr-spamRestore-wrapper ::v-deep {
  .cr-detail-box {
    border: 1px solid rgba(0, 0, 0, 0.12);
    .cr-sb-wrapper {
      .cr-title {
        width: 100px;
      }
    }
  }
}
</style>
