var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("" + (_vm.getSplitView == 1 && !_vm.isDrafts && !_vm.isSharedRoot && !_vm.isSpam
        ? 'cr-wrapper cr-split-1'
        : 'cr-wrapper'))},[_c('div',{staticClass:"cr-container"},[_c('v-data-table',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
        /**
         * drag&drop directive
         *
         * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀
         * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element
         * dragstart    - dragstart / Argument(event, setDragInfo))
         * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
         * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
         * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
         */
        DRAG_COMP: 'list',
        genHelper: _vm.genHelper,
        dragstart: _vm.dragstart,
        genDropzone: _vm.genDropzone,
        markDropzone: _vm.markDropzone,
        mouseup: _vm.mouseup
      }),expression:"{\n        /**\n         * drag&drop directive\n         *\n         * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀\n         * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element\n         * dragstart    - dragstart / Argument(event, setDragInfo))\n         * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)\n         * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)\n         * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))\n         */\n        DRAG_COMP: 'list',\n        genHelper,\n        dragstart,\n        genDropzone,\n        markDropzone,\n        mouseup\n      }"}],staticClass:"cr-mail-list",attrs:{"show-select":"","draggable":"true","headers":_vm.headers,"hide-default-header":!_vm.isShareRoot,"hide-default-footer":"","item-key":"dataId","mobile-breakpoint":0,"headers-length":100,"show-expand":!_vm.isDrafts && !_vm.isShareRoot && !_vm.isShare,"single-expand":_vm.isSent,"group-by":_vm.getGroupBy,"loading":_vm.getLoading,"no-data-text":_vm.noDataText,"options":_vm.options,"expanded":_vm.expanded,"items":_vm.getMails,"items-per-page":_vm.getPageSize,"server-items-length":_vm.getTotalElements},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
        var ref_props = ref.props;
        var value = ref_props.value;
        var indeterminate = ref_props.indeterminate;
        var input = ref.on.input;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":value,"indeterminate":indeterminate},on:{"input":input}})]}},{key:"body",fn:function(ref){
        var items = ref.items;
        var expand = ref.expand;
        var isExpanded = ref.isExpanded;
        var select = ref.select;
        var isSelected = ref.isSelected;
        var originalItemsLength = ref.originalItemsLength;
return [(originalItemsLength)?_c('tbody',_vm._l((items),function(item,idx){return _c('TableRow',{key:item.mailId,attrs:{"item":item,"hoverItem":_vm.hoverItem,"closedGroup":_vm.closedGroup,"getSplitView":_vm.getSplitView,"resizeWidth":_vm.minimumWidth,"isSent":_vm.isSent,"isDrafts":_vm.isDrafts,"isShareRoot":_vm.isShareRoot,"isShare":_vm.isShare,"isSpam":_vm.isSpam,"isMinimum":_vm.isMinimum,"isCreatedGroup":item.group &&
                (idx == 0 || items[idx - 1].group !== item.group) &&
                _vm.getGroupView == 1,"isExpanded":isExpanded(item)},on:{"mailRowClick":function (item) { return _vm.mailRowClick(item); },"expand":expand,"expandGroup":_vm.expandGroup,"selectFunc":function (item) { return _vm.selectFunc(item, select, isSelected); },"select":select,"isSelected":isSelected,"mouseover":function (item) { return (_vm.hoverItem = item); },"mouseleave":function($event){_vm.hoverItem = null}}})}),1):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[(!_vm.getLoading)?_c('td',{attrs:{"colspan":"11"},domProps:{"textContent":_vm._s(_vm.$t('common.3'))}}):_vm._e()])])]}},{key:"footer",fn:function(){return [(_vm.pageCount > 0)?_c('Pagination',{attrs:{"type":"list","page":_vm.page,"pageSize":_vm.getPageSize,"blockSize":5,"totalElements":_vm.getTotalElements},on:{"update:page":function($event){_vm.page=$event},"pageClick":function (page) { return _vm.pageClick(page); }}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedMails),callback:function ($$v) {_vm.selectedMails=$$v},expression:"selectedMails"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }