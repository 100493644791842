<template>
  <div
    v-if="itemSize > 0"
    class="contacts-container d-flex justify-center"
    style="margin-bottom:100px;"
  >
    <div style="width:700px;">
      <v-divider class="mt-16 mb-10" />

      <!-- 저장 폼 -->
      <div v-if="!isSave">
        <div
          class="caption text-center"
          v-html="$t('contact.75', { value: itemSize })"
        ></div>

        <div class="contact-form mt-1 pa-5">
          <v-row v-for="(item, index) in items" :key="item.address">
            <v-col cols="12" sm="5" class="pr-0" v-if="index < displayItem">
              <v-text-field
                filled
                outlined
                dense
                hide-details
                clearable
                :label="$t('contact.이름')"
                v-model="item.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="7" v-if="index < displayItem">
              <v-text-field
                append-outer-icon="mdi-close-circle-outline"
                @click:append-outer="removeItem(item)"
                filled
                outlined
                dense
                hide-details
                readonly
                v-model="item.address"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- 모두 보기 버튼 -->
          <div
            class="d-flex justify-center mt-3 mb-4"
            v-if="displayItem < itemSize"
          >
            <a href="#" @click="showAllItems" class="more-btn">
              <span>{{ $t("contact.81") }}</span>
              <v-icon>mdi-menu-down</v-icon>
            </a>
          </div>

          <div class="d-flex justify-center mt-6">
            <v-btn depressed @click="reset" :disabled="usingReset" class="mr-3">
              {{ $t("contact.77") }}
            </v-btn>
            <v-btn depressed color="primary" @click="saveConfirm">
              {{ $t("contact.78") }}
            </v-btn>
          </div>
        </div>
      </div>

      <!-- 저장 폼 -->
      <div v-if="isSave">
        <div
          class="caption text-center"
          v-html="$t('contact.76', { value: itemSize })"
        ></div>
        <div class="d-flex justify-center mt-6">
          <v-btn depressed @click="routeModule('contact')">
            {{ $t("contact.79") }}
          </v-btn>
        </div>
      </div>

      <!-- end div -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contacts-container ::v-deep {
  .caption b {
    color: var(--v-primary-base);
  }
  .more-btn {
    color: #444;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.contact-form {
  background-color: #fbfbff;
  border: 1px solid #e1e1e1;
}
.contact-form ::v-deep {
  .v-input__slot {
    background-color: white !important;
  }
  .v-input--is-readonly .v-input__slot {
    background-color: #f1f1f1 !important;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import i18n from "@/_locales";
import { isSameObj } from "@/commons/utils/validation";
import { addAllCard } from "@/contact/api/card.api";

export default {
  props: {
    contactList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      items: [],
      displayItem: 1,
      isSave: false
    };
  },
  mounted() {
    this.items = [];
    this.contactList.forEach(e => {
      this.items.push({
        name: e.name,
        address: e.address
      });
    });
  },
  computed: {
    itemSize() {
      return this.items.length;
    },
    usingReset() {
      return isSameObj(this.contactList, this.items);
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    showAllItems() {
      this.displayItem = this.items.length;
    },
    removeItem(item) {
      this.items = this.items.filter(e => e.address !== item.address);
    },
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    saveConfirm() {
      this.openConfirmDialog(
        "",
        i18n.t("contact.80", { value: this.itemSize }),
        this.save
      );
    },
    // 주소록 저장
    async save() {
      const params = this.items.map(e => {
        return {
          firstName: e.name,
          emailList: [e.address]
        };
      });

      const response = await addAllCard(params);
      const { status } = response;

      const messages = {
        ERROR: i18n.t("contact.53"),
        SUCCESS: i18n.t("contact.54")
      };
      const type = status === 201 ? "SUCCESS" : "ERROR";

      this.openSnackbar({
        type: type,
        message: messages[type]
      });

      if (status === 201) {
        this.isSave = true;
      }
    },
    reset() {
      this.items = [];
      this.contactList.forEach(e => {
        this.items.push({
          name: e.name,
          address: e.address
        });
      });
    },
    routeModule(name) {
      this.$router.push({ name });
    }
  }
};
</script>
