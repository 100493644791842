<template>
  <v-row class="ma-0">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
      {{ $t("mail.317") }}
    </v-col>
    <v-col cols="12" sm="8" class="pl-0">
      <v-text-field
        ref="emailRef"
        outlined
        dense
        hide-details
        v-model="email"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { isBlank /*, isEmail*/ } from "@/commons/utils/validation";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ item: { email } }) {
    return { email };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    validation() {
      if (isBlank(this.email)) {
        this.$refs.emailRef.focus();
        this.openSnackbar({ message: this.$t("mail.349"), type: "VALIDATION" });
        return false;
      }

      /* 이메일 형식 검사 제거 - 외부 메일 업체중 아이디만 가지고 검증하는 곳이 있음
      if (!isEmail(this.email)) {
        this.$refs.emailRef.focus();
        this.openSnackbar({ message: this.$t("mail.350"), type: "VALIDATION" });
        return false;
      }
      */

      return true;
    }
  }
};
</script>
