<template>
  <div style="position: relative; height: 300px; background: #fff;">
    <SearchInput
      ref="searchInput"
      :inputValue.sync="inputValue"
      :openMenu="true"
    />
    <List
      key="move"
      :openMenu="true"
      :selectedFolderId="selectedFolderId"
      :inputValue="inputValue"
      :showAllFolder="false"
      :useAddFolder="true"
      @select="select"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import SearchInput from "@/mail/views/components/main/commons/mailboxSelect/SearchInput.vue";
import List from "@/mail/views/components/main/commons/mailboxSelect/list";

export default {
  components: { SearchInput, List },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.searchInput?.$refs?.textField?.focus();
    }, 0);
  },
  data(vue) {
    const getters = vue.$store.getters;
    const { mails = [] } = getters["mailDialog/params"];
    const [mail] = mails;

    return {
      inputValue: "",
      selectedFolderId: mail?.folderId
    };
  },
  watch: {
    confirm(next) {
      if (!next) return;
      this.moveMail();
    }
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    ...mapGetters("mail", ["getSelectedMails"]),
    ...mapGetters("folder", ["getSentId", "getTrashId"]),
    selectedMails: {
      get() {
        return this.getSelectedMails;
      },
      set(selectedMails) {
        this.SET_SELECTED_MAILS(selectedMails);
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("mail", ["SET_SELECTED_MAILS"]),
    ...mapActions("mail", ["checkMailsBeforeMove"]),
    ...mapActions("confirm", { openConfirm: "confirm" }),
    async moveMail() {
      const { selectedFolderId: targetFolderId, params } = this;
      const { mails: selectedMails = [] } = params;
      if (targetFolderId === undefined) {
        return this.$emit("update:confirm", false);
      }

      this.checkMailsBeforeMove({
        targetFolderId,
        selectedMails,
        callback: isClose => {
          this.$emit("update:confirm", false);
          if (isClose) this.CLOSE_DIALOG();
        }
      });
    },
    select(folder) {
      this.selectedFolderId = folder.id;
    }
  }
};
</script>
