<template>
  <v-dialog
    persistent
    max-width="700px"
    :value="show"
    @input="show = false"
    overlay-color="transparent"
  >
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ $t("mail.110") }}
      </v-card-title>
      <v-card-text>
        <div class="py-2">
          <span style="color: #1976d2;">{{ `${completedCount}개` }}</span>
          <span style="color: #333;">{{ ` / ${totalCount}개` }}</span>
          <span style="fontSize: 13px;">
            {{
              `${byteCalculation(totalLoaded)} / ${byteCalculation(totalSize)}`
            }}
          </span>
          <span style="float: right;">
            {{ remainingTime }}
          </span>
        </div>

        <v-progress-linear rounded :value="totalProgress" height="30">
          <strong>{{ Math.ceil(totalProgress) }}%</strong>
        </v-progress-linear>

        <v-data-table
          dense
          fixed-header
          hide-default-footer
          item-key="key"
          class="cr-uploader-data-table"
          :headers="headers"
          :items="items"
          :items-per-page="20"
          :server-items-length="20"
        >
          <!-- 전송량 -->
          <template v-slot:[`item.size`]="{ item }">
            {{
              `${byteCalculation(item.loaded)} / ${byteCalculation(item.size)}`
            }}
          </template>
          <!-- 전송 상태 -->
          <template v-slot:[`item.progress`]="{ item }">
            <v-progress-linear
              v-if="item.status == 1"
              rounded
              height="12"
              :value="item.progress"
            />
            <div v-else>
              {{
                item.status == 0 ? $t("common.대기중") : $t("common.전송완료")
              }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="red accent"
          :disabled="totalProgress == 100"
          @click="show = false"
        >
          {{ $t("common.취소") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-data-table.cr-uploader-data-table::v-deep {
  padding-top: 20px;
  .v-data-table__wrapper {
    height: 190px;
    border: thin solid rgba(0, 0, 0, 0.12);
    table {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      td {
        border: none;
      }

      td.v-data-table__mobile-row .v-data-table__mobile-row__cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  data() {
    return {
      headers: [
        {
          text: this.$t("common.파일명"),
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("common.전송량"),
          sortable: false,
          width: 220,
          value: "size"
        },
        {
          text: this.$t("common.전송 상태"),
          sortable: false,
          width: 90,
          value: "progress"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("mailFile", [
      "showUploader",
      "items",
      "totalProgress",
      "totalCount",
      "completedCount",
      "totalSize",
      "totalLoaded",
      "remainingTime",
      "isUploadComplete"
    ]),
    show: {
      get() {
        return this.showUploader;
      },
      set() {
        return this.closeUploader();
      }
    }
  },
  methods: {
    ...mapActions("mailFile", ["closeUploader"]),
    byteCalculation(size) {
      return byteCalculation(size);
    }
  }
};
</script>
