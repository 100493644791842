<template>
  <v-expansion-panels
    v-model="_panel"
    :class="{ readonly }"
    tile
    hover
    multiple
    readonly
  >
    <ExpansionViewPanel
      v-for="(item, idx) in getViewMails"
      :ref="`${item.dataId}_ref`"
      :key="`${item.dataId}`"
      :item="item"
      :readonly="readonly"
      :resizeWidth="resizeWidth"
      :isPanelOpen="_panel.findIndex(i => i == idx) != -1"
      @close="_panel = _panel.filter(i => i !== idx)"
      @update:clickedPanel="$emit('update:clickedPanel', true)"
    />
  </v-expansion-panels>
</template>

<style lang="scss" scoped>
.v-expansion-panels::v-deep {
  z-index: 0;

  .v-expansion-panel::before {
    box-shadow: none;
  }

  &.readonly > .v-expansion-panel > button {
    cursor: default;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import ExpansionViewPanel from "./panel";

export default {
  components: { ExpansionViewPanel },
  props: {
    panel: {
      type: Array,
      default: () => []
    },
    clickedPanel: {
      type: Boolean,
      default: false
    },
    resizeWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters("mailView", ["getViewMails"]),
    readonly() {
      return this.getViewMails.length == 1;
    },
    _panel: {
      get() {
        return this.panel;
      },
      set(panel) {
        this.$emit("update:panel", panel);
      }
    }
  }
};
</script>
