<template>
  <v-select
    ref="ref"
    dense
    outlined
    hide-details
    return-object
    v-model="model"
    :items="items"
    item-text="content"
    item-value="content"
    class="cr-from-select mr-1"
    :class="{ 'cr-mobile': $vuetify.breakpoint.xs }"
    :menu-props="{ 'offset-y': true, closeOnClick: true }"
    @click:append.stop="$refs.ref.activateMenu()"
  />
</template>

<style lang="scss" scoped>
/* 보낸사람 셀렉트박스 크기 */
.cr-from-select.v-input {
  display: inline-block;
  width: calc(100% - 177px);
  max-width: 250px;

  &.cr-mobile {
    max-width: 100%;
    width: 100%;
  }
}
</style>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    model: {
      get() {
        return this.selectedItem;
      },
      set(model) {
        this.$emit("update:selectedItem", model);
      }
    }
  }
};
</script>
