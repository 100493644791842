<template>
  <v-menu
    bottom
    offset-y
    :value="show"
    :open-on-click="false"
    :close-on-click="closeOnClick"
    @input="
      show = false;
      closeOnClick = false;
    "
  >
    <template v-slot:activator="{ attrs }">
      <v-btn
        icon
        :disabled="isSpam"
        v-bind="attrs"
        v-long-press="showMenu"
        @click="importantChange"
      >
        <v-icon v-if="mail.importantStatus == 0">mdi-star-outline</v-icon>
        <v-icon v-else :color="getImportantColor">
          mdi-star
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-container fluid>
        <v-row>
          <v-btn icon @click="() => importantClick(0)">
            <v-icon>
              mdi-star-outline
            </v-icon>
          </v-btn>
          <v-btn
            icon
            v-for="(color, index) in colors"
            :key="index"
            @click="() => importantClick(index + 1)"
          >
            <v-icon :color="color">
              mdi-star
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
let pressTimer = null;

export default {
  props: {
    mail: {
      default: () => {},
      type: Object
    }
  },
  data: () => ({
    show: false,
    closeOnClick: false,
    updateStart: false,
    colors: [
      "yellow darken-1",
      "blue darken-1",
      "red darken-1",
      "green darken-1",
      "purple darken-1"
    ]
  }),
  computed: {
    ...mapGetters("folder", ["isSpam"]),
    getImportantColor: function() {
      const { importantStatus } = this.mail;

      return this.colors[importantStatus - 1];
    }
  },
  methods: {
    ...mapActions("mail", ["updateImportantStatus"]),
    // 롱프레시 후 메뉴 중요 표시 클릭
    importantClick(important) {
      this.updateImportantStatus({
        mails: [{ ...this.mail }],
        importantStatus: important,
        toServer: true
      });
    },
    // 일반 클릭
    importantChange(e) {
      e.stopPropagation();
      if (this.show) return;

      const mails = [{ ...this.mail }];
      const { importantStatus } = this.mail;
      let important = importantStatus > 0 ? 0 : 1;
      if (this.updateStart) {
        if (pressTimer !== null) {
          clearTimeout(pressTimer);
          pressTimer = null;
        }
        important =
          importantStatus >= this.colors.length ? 0 : importantStatus + 1;
      }

      this.updateImportantStatus({
        mails,
        importantStatus: important
      });
      if (!this.updateStart) this.updateStart = true;
      pressTimer = setTimeout(() => {
        this.updateStart = false;
        this.updateImportantStatus({
          mails,
          importantStatus: important,
          toServer: true
        });
      }, 800);
    },
    showMenu(e) {
      if (e.type != "mousedown" && e.type != "touchstart") {
        this.closeOnClick = true;
      } else {
        this.show = true;
        this.closeOnClick = false;
      }
    }
  }
};
</script>
