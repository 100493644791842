<template>
  <div class="cr-mail-view-attach-wrapper">
    <Header :show.sync="show" :items="items" />
    <List v-show="show" :items="items" :mail="mail" />
  </div>
</template>

<style lang="scss" scoped>
.cr-mail-view-attach-wrapper {
  max-width: 600px;
  margin: 20px 0px 30px 0px;
}
</style>

<script>
import Header from "./Header.vue";
import List from "./List.vue";

export default {
  components: { Header, List },
  props: {
    mail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { show: true };
  },
  computed: {
    items() {
      return this.mail.attachList || [];
    }
  }
};
</script>
