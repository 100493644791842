<template>
  <v-row class="ma-0 pa-0 mt-3">
    <v-col cols="12" sm="6" md="3" class="pa-1">
      <v-select
        dense
        outlined
        ref="periodRef"
        :label="$t('mail.기간')"
        v-model="periodSelectItem"
        :items="periodSelectItems"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        @click:append.stop="$refs.periodRef.activateMenu()"
      />
    </v-col>
    <v-col cols="12" sm="6" md="3" class="pa-1">
      <v-menu
        offset-y
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            readonly
            autocomplete="off"
            v-model="startDate"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="startDate" no-title scrollable color="primary">
          <v-spacer></v-spacer>
          <v-btn text outlined @click="startMenu = false">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn outlined color="accent" @click="startDateClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="pa-1">
      <v-menu
        offset-y
        ref="endMenu"
        v-model="endMenu"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        min-width="auto"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            readonly
            autocomplete="off"
            v-model="endDate"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="endDate" no-title scrollable color="primary">
          <v-spacer></v-spacer>
          <v-btn text outlined @click="endMenu = false">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn outlined color="accent" @click="endDateClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="3" class="pa-1">
      <v-btn
        text
        tile
        outlined
        :disabled="disabledloadBtn"
        color="primary"
        class="cr-load"
        @click="$emit('requestBackupList')"
      >
        {{ $t("mail.259") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.v-btn.cr-load {
  height: 40px;
}
</style>

<script>
import i18n from "@/_locales";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  props: {
    backupList: {
      default: () => [],
      type: Array
    }
  },
  data() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);

    return {
      startDate: getDateWithoutTime(date.getTime(), "YYYY-MM-DD"),
      endDate: getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD"),
      startMenu: false,
      endMenu: false,
      periodSelectItem: 5,
      periodSelectItems: [
        { text: i18n.t("mail.전체"), value: 0 },
        { text: i18n.t("mail.39"), value: 1 },
        { text: i18n.t("mail.40"), value: 2 },
        { text: i18n.t("mail.41"), value: 3 },
        { text: i18n.t("mail.42"), value: 4 },
        { text: i18n.t("mail.43"), value: 5 },
        { text: i18n.t("mail.32"), value: 6 }
      ]
    };
  },
  computed: {
    disabledloadBtn() {
      if (this.periodSelectItem < 6) return false;
      return !this.startDate || !this.endDate;
    }
  },
  watch: {
    periodSelectItem(value) {
      const date = new Date();
      const m = date.getMonth();

      switch (value) {
        // 1 주일
        case 1:
          date.setDate(date.getDate() - 7);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 1 개월
        case 2:
          date.setMonth(m - 1);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 3 개월
        case 3:
          date.setMonth(m - 3);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 6 개월
        case 4:
          date.setMonth(m - 6);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 1년
        case 5:
          date.setFullYear(date.getFullYear() - 1);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        case 6:
          // if (prevValue != 0) {
          //   this.startDate = "";
          //   this.endDate = "";
          // }
          break;
        default:
          this.startDate = "";
          this.endDate = "";
          break;
      }

      this.$emit("update:backupList", []);
    }
  },
  methods: {
    startDateClick() {
      this.periodSelectItem = 6;
      const { startDate, endDate } = this;

      if (endDate && startDate > endDate) {
        this.startDate = endDate;
        this.$refs.startMenu.save(endDate);

        this.endDate = startDate;
        this.$refs.endMenu.save(startDate);
      } else {
        this.$refs.startMenu.save(startDate);
      }
    },
    endDateClick() {
      this.periodSelectItem = 6;
      const { startDate, endDate } = this;

      if (startDate && startDate > endDate) {
        this.startDate = endDate;
        this.$refs.startMenu.save(endDate);

        this.endDate = startDate;
        this.$refs.endMenu.save(startDate);
      } else {
        this.$refs.endMenu.save(endDate);
      }
    }
  }
};
</script>
