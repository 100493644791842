<template>
  <v-row class="pb-2">
    <v-col cols="12" sm="4" md="4" class="pa-1">
      <v-select
        dense
        outlined
        hide-details
        ref="sizeRef"
        :label="$t('mail.116')"
        :items="sizeSelectItems"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        v-model="sizeSelectItem"
        @click:append.stop="$refs.sizeRef.activateMenu()"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2" md="2" class="pa-1">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="startSize"
        type="number"
        :label="$t('mail.357')"
        autocomplete="off"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2" md="2" class="pa-1">
      <v-select
        dense
        outlined
        hide-details
        ref="startSizeRef"
        v-model="startSizeUnit"
        :items="sizeUnitSelectIems"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        @click:append.stop="$refs.startSizeRef.activateMenu()"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2" md="2" class="pa-1">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="endSize"
        type="number"
        :label="$t('mail.357')"
        autocomplete="off"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2" md="2" class="pa-1">
      <v-select
        dense
        outlined
        hide-details
        ref="endSizeRef"
        v-model="endSizeUnit"
        :items="sizeUnitSelectIems"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        @click:append.stop="$refs.endSizeRef.activateMenu()"
      />
    </v-col>
  </v-row>
</template>

<script>
import i18n from "@/_locales";
export default {
  props: {
    sizeRange: {
      default: undefined,
      type: Array
    }
  },
  mounted() {
    const [start, end] = this.sizeRange;
    if (start && start / 1000000 >= 1) {
      this.startSize = start / 1000000;
      this.startSizeUnit = 1000000;
    } else if (start && start / 1000 >= 1) {
      this.startSize = start / 1000;
    }

    if (end && end / 1000000 >= 1) {
      this.endSize = end / 1000000;
      this.endSizeUnit = 1000000;
    } else if (end && end / 1000 >= 1) {
      this.endSize = end / 1000;
    }
  },
  data() {
    return {
      sizeSelectItem: "all",
      sizeSelectItems: [
        { text: i18n.t("mail.전체"), value: "all" },
        { text: i18n.t("mail.32"), value: "directInput" }
      ],
      sizeUnitSelectIems: [
        { text: "KB", value: 1000 },
        { text: "MB", value: 1000000 }
      ],
      startSize: undefined,
      startSizeUnit: 1000,
      endSize: undefined,
      endSizeUnit: 1000
    };
  },
  watch: {
    startSize() {
      this.sizeSelectItem = "directInput";
      this.checkSize();
    },
    startSizeUnit() {
      this.checkSize();
    },
    endSize() {
      this.sizeSelectItem = "directInput";
      this.checkSize();
    },
    endSizeUnit() {
      this.checkSize();
    },
    sizeSelectItem(sizeSelectItem) {
      if (sizeSelectItem == "all") {
        this.startSize = undefined;
        this.startSizeUnit = "KB";
        this.endSize = undefined;
        this.endSizeUnit = "KB";
      }
    }
  },
  methods: {
    checkSize() {
      setTimeout(() => {
        this.startSize = this.startSize < 0 ? 0 : this.startSize;
        this.endSize = this.endSize < 0 ? 0 : this.endSize;

        const { startSize, startSizeUnit, endSize, endSizeUnit } = this;
        if (startSize * startSizeUnit > endSize * endSizeUnit) {
          this.startSize = endSize;
          this.startSizeUnit = endSizeUnit;

          this.endSize = startSize;
          this.endSizeUnit = startSizeUnit;
        }
      }, 0);
    }
  }
};
</script>
