<template>
  <div>{{ nameFolder }}</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  computed: {
    ...mapGetters("folder", ["getFolder"]),
    nameFolder() {
      const { folderId } = this.item;
      const folder = this.getFolder(folderId);
      return folder?.nameFolder || "";
    }
  }
};
</script>
