var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
    /**
     * drag&drop directive
     *
     * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀
     * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element
     * dragstart    - dragstart / Argument(event, setDragInfo))
     * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
     * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
     * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
     */
    DRAG_COMP: 'autocomplete',
    genHelper: _vm.genHelper,
    dragstart: _vm.dragstart,
    markDropzone: _vm.markDropzone,
    mouseup: _vm.mouseup
  }),expression:"{\n    /**\n     * drag&drop directive\n     *\n     * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀\n     * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element\n     * dragstart    - dragstart / Argument(event, setDragInfo))\n     * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)\n     * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)\n     * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))\n     */\n    DRAG_COMP: 'autocomplete',\n    genHelper,\n    dragstart,\n    markDropzone,\n    mouseup\n  }"}]},[_c('From',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showOnlySubject && !_vm.isWriteToMe),expression:"!showOnlySubject && !isWriteToMe"}],ref:"from"}),_c('To',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showOnlySubject && !_vm.isWriteToMe),expression:"!showOnlySubject && !isWriteToMe"}],ref:"to"}),_c('Cc',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showOnlySubject && !_vm.isWriteToMe),expression:"!showOnlySubject && !isWriteToMe"}],ref:"cc"}),_c('Bcc',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showOnlySubject && _vm.showBcc && !_vm.isWriteToMe),expression:"!showOnlySubject && showBcc && !isWriteToMe"}],ref:"bcc"}),_c('Subject',{ref:"subject"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }