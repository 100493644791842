<template>
  <v-row class="ma-0">
    <v-col
      class="pb-0 pb-sm-3 align-center cr-config-title grey--text text--darken-2  font-weight-bold"
      cols="12"
      sm="4"
    >
      {{ $t("mail.541") }}
    </v-col>

    <v-col class="pl-0">
      <v-card class="vCard" outlined>
        <v-img
          class="previewImage"
          src="https://www.sirteam.net/img/h1_bi.png"
          height="40px"
          contain
        />
        <v-spacer></v-spacer>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1  mt-16">
              {{ $t("mail.544") }}
            </v-list-item-title>
            <v-list-item-subtitle class="mt-8 mb-2 ">
              {{ $t("mail.545", { value: userName }) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-2 " style="font-weight:bold">
              {{
                $t("mail.546", {
                  value1: startTimeMillis,
                  value2: endTimeMillis
                })
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-2 ">
              {{ $t("mail.547") }}
            </v-list-item-subtitle>
            <p class="mt-10 " style="font-size:12px; color: grey">
              {{ $t("mail.548", { value: userName }) }}
            </p>

            <div class="cr-contents">{{ contents }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-contents {
  min-height: 80px;
  width: 600px;
  border: thin solid rgba(0, 0, 0, 0.12);
  margin-top: 4px;
  padding: 10px 12px;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1.6;
}

.previewImage {
  position: absolute;
  top: 15px;
  left: 12px;
}

.vCard {
  position: relative;
}
</style>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";
export default {
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("mailConfig", ["getDefaultSenderName"]),
    userName() {
      const { username } = this.getUserInfo;
      return `${this.getDefaultSenderName} (${username})`;
    },
    contents() {
      return this.config?.contents || "";
    },
    startTimeMillis() {
      const { startTimeMillis } = this.config;
      if (parseInt(startTimeMillis)) {
        return moment(startTimeMillis).format("YYYY-MM-DD");
      }
      return getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
    },
    endTimeMillis() {
      const { endTimeMillis } = this.config;
      if (parseInt(endTimeMillis)) {
        return moment(endTimeMillis).format("YYYY-MM-DD");
      }
      return getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
    }
  }
};
</script>
