<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-color-picker
          width="400"
          hide-canvas
          hide-mode-switch
          hide-sliders
          hide-inputs
          show-swatches
          v-model="color"
        >
        </v-color-picker>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.v-color-picker ::v-deep {
  margin-left: -14px;
  margin-right: -5px;

  .v-color-picker__color {
    width: 54px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data({ $store }) {
    const { targetFolder } = $store.getters["mailDialog/params"] ?? {};

    return {
      color: targetFolder.color || "#00000000"
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"])
  },
  watch: {
    confirm(next) {
      if (next) {
        const color = this.color === "#00000000" ? "" : this.color;
        this.updateFolderColor({
          color,
          folderId: this.params.targetFolder.id
        });
        this.CLOSE_DIALOG();
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("folder", ["updateFolderColor"])
  }
};
</script>
