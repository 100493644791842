<template>
  <v-list outlined>
    <ListItem
      dense
      v-for="(item, idx) in attachs"
      :key="idx"
      :item="item"
      :mail="mail"
    />
  </v-list>
</template>

<style lang="scss" scoped>
.v-list ::v-deep {
  .v-list-item__content {
    * {
      padding: 0;
      margin: 0;
    }
  }

  a {
    text-decoration: none;
    color: #333;
  }

  .size {
    font-size: 13px;
  }
}
</style>

<script>
import ListItem from "./ListItem.vue";

export default {
  components: { ListItem },
  props: {
    mail: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    attachs() {
      //{ mailId, name, position, size }
      return this.items.map(i => {
        const ext = i.name
          .split("?")[0]
          .split("#")[0]
          .split(".")
          .pop();
        const name = i.name.slice(0, i.name.length - 1 - ext.length);
        return { ...i, name, ext };
      });
    }
  }
};
</script>
