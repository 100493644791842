<template>
  <div class="pa-2 pb-0 cr-sign-wrapper">
    <v-row class="ma-0 mb-2">
      <v-col class="d-flex mt-2 pb-0 align-center" cols="12">
        <div class="cr-config-title" v-text="$t('mail.223')"></div>
      </v-col>
    </v-row>
    <v-row class="ma-0 mb-10">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('mail.224')"
      ></v-col>

      <v-col cols="12" sm="8">
        <v-select
          class="new-select"
          dense
          outlined
          hide-details
          item-text="title"
          item-value="id"
          :items="getSelectSignList"
          v-model="newDefault"
          @change="changeNewDefault"
        ></v-select>
      </v-col>

      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('mail.225')"
      ></v-col>

      <v-col cols="12" sm="8">
        <v-select
          class="repFor-select"
          dense
          outlined
          hide-details
          item-text="title"
          item-value="id"
          :items="getSelectSignList"
          v-model="repForDefault"
          @change="changeRepForDefault"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col class="d-flex align-center py-0" cols="12">
        <div class="cr-config-title" v-text="$t('mail.226')"></div>
        <v-divider class="mx-2 d-none d-md-flex" vertical inset></v-divider>
        <div
          class="text-caption d-none d-md-flex"
          v-text="$t('mail.227')"
        ></div>

        <v-spacer />
        <v-btn
          text
          outlined
          :disabled="getSignList.length >= 5"
          v-text="$t('mail.228')"
          @click="
            SET_DIALOG({
              headline: $t('mail.228'),
              show: true,
              type: 'signAdd'
            })
          "
        />
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col v-if="getSignList.length > 0" cols="12">
        <v-list dense class="py-0">
          <v-list-item
            class="px-2"
            v-for="(sign, index) in getSignList"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-black text-subtitle-1"
                v-text="sign.title"
              >
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <v-btn
                  small
                  plain
                  text
                  :ripple="false"
                  @click="
                    SET_DIALOG({
                      headline: $t('mail.229'),
                      show: true,
                      type: 'previewSign',
                      params: { sign },
                      btnTitles: { confirm: $t('common.수정') }
                    })
                  "
                  v-text="$t('mail.미리보기')"
                ></v-btn>
                <v-btn
                  small
                  plain
                  text
                  :ripple="false"
                  @click="
                    SET_DIALOG({
                      headline: $t('mail.230'),
                      show: true,
                      type: 'signEdit',
                      params: { sign }
                    })
                  "
                  v-text="$t('common.수정')"
                >
                </v-btn>
                <v-btn
                  small
                  plain
                  text
                  :ripple="false"
                  @click="deleteBtnClick(sign)"
                  v-text="$t('common.삭제')"
                ></v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col v-else cols="12">
        <div
          class="pa-4 d-flex justify-center"
          style="border-top: 1px solid #a9a9a9; color: rgba(0, 0, 0, 0.38);"
          v-text="$t('common.3')"
        ></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      newDefault: 0,
      repForDefault: 0
    };
  },
  async created() {
    await this.getInitList();
    this.newDefault = this.getNewDeafault ? this.getNewDeafault.id : 0;
    this.repForDefault = this.getRepForDeafault ? this.getRepForDeafault.id : 0;
  },
  watch: {},
  computed: {
    ...mapGetters("mailConfigSign", [
      "getSignList",
      "getSelectSignList",
      "getNewDeafault",
      "getRepForDeafault"
    ])
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mailConfigSign", [
      "getInitList",
      "updateDefaultSign",
      "deleteSign"
    ]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    // 새 메일 서명 기본값 변경
    async changeNewDefault(id) {
      await this.updateDefaultSign({ id, mailType: "new" });
    },

    // 답장, 전달 메일 서명 기본값 변경
    async changeRepForDefault(id) {
      await this.updateDefaultSign({ id, mailType: "reply" });
    },

    // 삭제 버튼
    deleteBtnClick({ id }) {
      this.confirm({
        message: `선택하신 서명을 삭제 하시겠습니까?`,
        callback: async () => {
          await this.deleteSign(id);

          // 기본값 설정에서 설정된 값이 삭제 된 경우 없음으로 변경
          // 1. 새 메일 서명
          if (id == this.newDefault) this.newDefault = 0;
          // 2. 답장 전달 메일 서명
          if (id == this.repForDefault) this.repForDefault = 0;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 0px;
}
.v-btn.v-btn--outlined {
  border-color: rgba(0, 0, 0, 0.12);
}

.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
  // text-align: left;
  // line-height: 2.5;
}

.cr-sign-wrapper {
  max-width: 1029px;
  .new-select,
  .repFor-select {
    max-width: 400px;
  }
  .v-list ::v-deep {
    border-top: 1px solid #a9a9a9;
    .v-list-item {
      //   border-bottom: thin solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
