<template>
  <div class="cr-search-detail-tag-wrapper">
    <div class="cr-detail-label text-subtitle-2">
      {{ $t("mail.folder_TAG_ROOT") }}
    </div>

    <TagAutocomplete
      outlined
      fixedHeight
      :creatable="false"
      :placeholder="$t('mail.483')"
      :listHeight="240"
      :myTags="getTags"
      :viewTags="viewTags"
      @add:tag="addTag"
      @delete:tag="deleteTag"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-search-detail-tag-wrapper::v-deep {
  display: flex;
  align-items: center;

  .cr-tag-ac {
    width: 100%;
    .v-input.v-text-field {
      width: 100%;
      height: 40px;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import TagAutocomplete from "@/commons/views/tag/autocomplete";

export default {
  components: { TagAutocomplete },
  data({ $store: { getters } }) {
    const { actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};
    const viewTags = search?.tags ? search.tags.split(",") : [];

    return { viewTags };
  },
  computed: {
    ...mapGetters("folder", ["getTags"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"])
  },
  watch: {
    getRouteListInfo({ actionObj }) {
      const { search } = actionObj || {};
      if (!search?.tags) return (this.viewTags = []);
      this.viewTags = search.tags.split(",");
    }
  },
  methods: {
    addTag(tag) {
      this.viewTags.push(tag);
    },
    deleteTag(tag) {
      this.viewTags = this.viewTags.filter(t => t !== tag);
    },
    getParams() {
      return {
        valid: this.viewTags.length > 0,
        params: { tags: this.viewTags.join(",") }
      };
    }
  }
};
</script>
