<template>
  <div>
    <v-expansion-panels multiple accordion flat tile v-model="panels">
      <v-expansion-panel :class="firstItemClosed ? 'cr-closed' : ''">
        <v-expansion-panel-header>
          <v-icon color="black">mdi-numeric-1-box</v-icon>
          <span>{{ $t("mail.316") }}</span>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="py-3 px-6">
          <v-row class="ma-0">
            <v-col class="pa-0 d-flex align-center" cols="2">
              {{ $t("mail.317") }}
            </v-col>
            <v-col class="pa-0" cols="4">
              <v-select
                dense
                outlined
                hide-details
                ref="addressRef"
                item-text="text"
                item-value="value"
                :items="addressItems"
                :menu-props="{ 'offset-y': true, closeOnClick: true }"
                v-model="selectedAddress"
                @click:append.stop="$refs.addressRef.activateMenu()"
              />
            </v-col>
            <v-col class="pa-0 pl-1" cols="6">
              <Autocomplete
                :selection.sync="acSelection"
                :listHeight="150"
                :useChips="false"
                :typeList="['MEMBER']"
              />
            </v-col>
            <v-col cols="2"></v-col>
            <v-col class="pa-0 pt-1 pb-2 grey--text" cols="10">
              {{ $t("mail.318") }}
            </v-col>
          </v-row>

          <v-row v-if="adrFrom.length > 0" class="ma-0">
            <v-col class="pa-0 pt-3 d-flex align-start" cols="2">
              {{ $t("mail.보낸사람") }}
            </v-col>
            <v-col class="pa-0 pb-2 grey--text" cols="10">
              <v-list outlined dense class="pa-0">
                <v-list-item
                  v-for="(adr, idx) in adrFrom"
                  :key="`${adr}_${idx}`"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${idx > 0 ? `(${$t("mail.또는")}) ` : ""} ${adr}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon x-small @click="adrFrom.splice(idx, 1)">
                      <v-icon size="16">
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row v-if="adrTo.length > 0" class="ma-0">
            <v-col class="pa-0 pt-3 d-flex align-start" cols="2">
              {{ $t("mail.받는사람") }}
            </v-col>
            <v-col class="pa-0 pb-2 grey--text" cols="10">
              <v-list outlined dense class="pa-0">
                <v-list-item v-for="(adr, idx) in adrTo" :key="`${adr}_${idx}`">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${idx > 0 ? `(${$t("mail.또는")}) ` : ""} ${adr}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon size="16" @click="adrTo.splice(idx, 1)">
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row v-if="adrCc.length > 0" class="ma-0">
            <v-col class="pa-0 pt-2 d-flex align-start" cols="2">
              {{ $t("mail.참조") }}
            </v-col>
            <v-col class="pa-0 pb-2 grey--text" cols="10">
              <v-list outlined dense class="pa-0">
                <v-list-item v-for="(adr, idx) in adrCc" :key="`${adr}_${idx}`">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${idx > 0 ? `(${$t("mail.또는")}) ` : ""} ${adr}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon size="16" @click="adrCc.splice(idx, 1)">
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row v-if="adrToCc.length > 0" class="ma-0">
            <v-col class="pa-0 pt-3 d-flex align-start" cols="2">
              {{ $t("mail.232") }}
            </v-col>
            <v-col class="pa-0 pb-2 grey--text" cols="10">
              <v-list outlined dense class="pa-0">
                <v-list-item
                  v-for="(adr, idx) in adrToCc"
                  :key="`${adr}_${idx}`"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${idx > 0 ? `(${$t("mail.또는")}) ` : ""} ${adr}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon size="16" @click="adrToCc.splice(idx, 1)">
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col class="pa-0 d-flex align-center" cols="2">
              {{ $t("mail.233") }}
            </v-col>
            <v-col class="pa-0">
              <v-text-field dense hide-details outlined v-model="subject" />
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col class="pa-0 d-flex align-center" cols="2">
              {{ $t("mail.234") }}
            </v-col>
            <v-col class="px-0">
              <v-text-field dense hide-details outlined v-model="content" />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0 d-flex align-center" cols="2">
              {{ $t("mail.319") }}
            </v-col>
            <v-col class="px-0 py-0" cols="10">
              <v-radio-group dense hide-details v-model="selectedOption">
                <v-radio
                  :label="$t('mail.220')"
                  class="ma-0 pa-0 my-2"
                  value="none"
                />
                <v-radio
                  :label="$t('mail.320')"
                  class="ma-0 pa-0 my-2"
                  value="attachMail"
                />
                <v-radio
                  :label="$t('mail.321')"
                  class="ma-0 pa-0 my-2"
                  value="calendarMail"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col class="pa-0 pl-1 grey--text" cols="10">
              {{ $t("mail.322") }}
            </v-col>
            <v-col cols="12" class="mt-5 d-flex align-center justify-center">
              <v-btn text outlined color="primary" @click="panels = [1]">
                {{ $t("common.다음") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-icon color="black">mdi-numeric-2-box</v-icon>
          <span v-text="$t('mail.323')"></span>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="py-3 px-6">
          <v-row class="ma-0">
            <v-col class="pa-0 d-flex align-center" cols="2">
              {{ $t("mail.324") }}
            </v-col>
            <v-col class="pa-0 pl-1 d-flex align-start" cols="10">
              <MailboxSelect
                ref="mailboxSelect"
                maxWidth="500px"
                :folderId="folderId"
                :useAddFolder="true"
                :showAllFolder="false"
                :selectedTrash.sync="selectedTrash"
              />
            </v-col>
            <v-col class="pa-0" cols="2" />
            <v-col class="pa-0 mb-2" cols="10">
              <v-checkbox
                dense
                hide-details
                :label="$t('mail.325')"
                class="ma-0 px-0"
                v-model="selectedTrash"
              />
            </v-col>
            <v-col class="px-0 d-flex align-center" cols="2">
              {{ $t("mail.451") }}
            </v-col>
            <v-col class="px-0 mr-5" cols="2">
              <v-checkbox
                dense
                hide-details
                :label="$t('mail.326')"
                class="ma-0 pa-0"
                v-model="markRead"
              />
            </v-col>
            <v-col class="px-0" cols="2">
              <v-checkbox
                dense
                hide-details
                :label="$t('mail.237')"
                class="ma-0 pa-0"
                v-model="markImportant"
              />
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col class="px-0 d-flex align-center" cols="2">
              태그 설정
            </v-col>
            <v-col class="px-0" cols="10">
              <TagAutocomplete
                outlined
                :placeholder="$t('mail.483')"
                :listHeight="240"
                :myTags="getTags"
                :viewTags="viewTags"
                @add:tag="addTag"
                @delete:tag="deleteTag"
              />
            </v-col>
          </v-row>

          <!-- TODO 기존메일 적용 -->
          <!-- <v-row class="ma-0" v-show="false">
            <v-col class="px-0 d-flex align-center" cols="2">
              {{ $t("mail.327") }}
            </v-col>
            <v-col class="px-0" cols="1">
              <v-switch
                dense
                inset
                hide-details
                disabled
                class="ma-0 pa-0 pl-1"
                v-model="applyPreviousMail"
              />
            </v-col>
            <v-col class="px-0 pl-2 grey--text d-flex align-center">
              {{ $t("mail.328") }}
            </v-col>
          </v-row> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style lang="scss" scoped>
.v-expansion-panels ::v-deep {
  .v-expansion-panel {
    > button {
      min-height: 60px;
      padding: 8px 10px;
      border: thin solid rgba(0, 0, 0, 0.12);
      border-left: none;
      border-right: none;
      i {
        flex: unset;
        margin-right: 10px;
        margin-top: -2px;
      }
    }

    &.cr-closed > button {
      border-bottom: none;
    }

    > .v-expansion-panel-content {
      background: #fafafb;

      > .v-expansion-panel-content__wrap {
        padding: 0px;

        .v-input,
        .v-label,
        .v-list-item__title {
          color: rgba(0, 0, 0, 0.87);
          font-size: 0.875rem !important;
        }

        .v-input.v-text-field {
          background-color: #fff;
        }
      }
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";
import TagAutocomplete from "@/commons/views/tag/autocomplete";

export default {
  components: { Autocomplete, MailboxSelect, TagAutocomplete },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data({ $store: { getters } }) {
    const { rule = {} } = getters["mailDialog/params"];
    const item = { ...rule };
    // reference 제거
    Object.keys(item).forEach(key => {
      if (!item[key] || typeof item[key] !== "object") return;
      if (Array.isArray(item[key])) return (item[key] = [...item[key]]);
      item[key] = { ...item[key] };
    });

    return {
      panels: [0],
      addressItems: [
        { text: i18n.t("mail.보낸사람"), value: "adrFrom" },
        { text: i18n.t("mail.받는사람"), value: "adrTo" },
        { text: i18n.t("mail.참조"), value: "adrCc" },
        { text: i18n.t("mail.232"), value: "adrToCc" }
      ],
      selectedAddress: "adrFrom",
      selectedFolderRadio: 0,
      selectedTrash: false,
      // 자동완성 선택 row
      acSelection: {},

      adrFrom: item?.adrFrom || [],
      adrTo: item?.adrTo || [],
      adrCc: item?.adrCc || [],
      adrToCc: item?.adrToCc || [],
      subject: item?.subject || "",
      content: item?.content || "",
      hasCalendarMail: item?.hasCalendarMail || 0,
      hasAttach: item?.hasCalendarMail ? 0 : item?.hasAttach || 0,
      markImportant: item?.markImportant || 0,
      markRead: item?.markRead || 0,
      viewTags: item?.tags || [],
      applyPreviousMail: item?.applyPreviousMail || 0
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    ...mapGetters("folder", ["getTags", "getInboxId"]),
    firstItemClosed() {
      return this.panels.indexOf(0) == -1;
    },
    folderId() {
      return this.params?.rule?.folderId || this.getInboxId;
    },
    selectedOption: {
      get() {
        if (this.hasCalendarMail) return "calendarMail";
        if (this.hasAttach) return "attachMail";
        return "none";
      },
      set(option) {
        this.hasAttach = option === "attachMail" ? 1 : 0;
        this.hasCalendarMail = option === "calendarMail" ? 1 : 0;
      }
    }
  },
  watch: {
    confirm(n) {
      if (!n) return;
      this.add();
    },
    acSelection(n) {
      this[this.selectedAddress].push(n.email || n.value);
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("mailConfigRule", ["addRule", "updateRule"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    addTag(tag) {
      this.viewTags.push(tag);
    },
    deleteTag(tag) {
      this.viewTags = this.viewTags.filter(t => t !== tag);
    },
    async add() {
      const {
        adrFrom,
        adrTo,
        adrCc,
        adrToCc,
        subject,
        content,
        hasAttach,
        hasCalendarMail,
        markRead,
        markImportant,
        viewTags,
        applyPreviousMail
      } = this;
      const address = [];
      adrFrom.forEach(adr => address.push({ From: adr }));
      adrTo.forEach(adr => address.push({ To: adr }));
      adrCc.forEach(adr => address.push({ Cc: adr }));
      adrToCc.forEach(adr => address.push({ ToCc: adr }));

      this.subject = subject.trim();
      this.content = content.trim();

      // 분류 기준 체크
      if (
        address.length == 0 &&
        !this.subject &&
        !this.content &&
        !hasAttach &&
        !hasCalendarMail
      ) {
        this.openSnackbar({
          message: i18n.t("mail.329"),
          type: "VALIDATION"
        });
        this.panels = [0];
        this.$emit("update:confirm", false);
        return;
      }

      let idObj = {};
      let func = this.addRule;
      if (this.params?.rule?.id > 0) {
        idObj = { id: this.params?.rule?.id };
        func = this.updateRule;
      }

      const result = await func({
        ...idObj,
        conditionOptions: {
          address,
          hasAttach: hasAttach ? 1 : 0,
          hasCalendarMail: hasCalendarMail ? 1 : 0,
          subject: this.subject,
          content: this.content
        },
        processOptions: {
          markRead: markRead ? 1 : 0,
          markImportant: markImportant ? 1 : 0,
          applyPreviousMail: applyPreviousMail ? 1 : 0,
          tags: viewTags,
          folderId: this.$refs?.mailboxSelect?.selectedFolderId || this.folderId
        }
      });

      if (result) {
        this.CLOSE_DIALOG();
      } else {
        this.$emit("update:confirm", false);
      }
    }
  }
};
</script>
