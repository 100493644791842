<template>
  <Toolbar
    ref="listToolRef"
    :indeterminate="indeterminate"
    :items="items"
    :rightItems="rightItems"
    :checkbox.sync="checkbox"
  />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { quickMenuEnum } from "@/mail/constant/folderType";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  components: { Toolbar },
  mounted() {
    setTimeout(() => {
      const widthArr = {};
      // toolbar 공백 초기값 11 + 체크박스 32 + dots 44
      let sumWidth = 87;
      // 오른쪽 메뉴와의 공백을 40정도로 준다.
      let rightWidth = 30;

      if (this.$refs?.listToolRef?.$refs) {
        Object.keys(this.$refs.listToolRef.$refs).forEach(key => {
          const [itemRef] = this.$refs.listToolRef.$refs[key] || [];
          if (itemRef) {
            if (key.startsWith("right_")) {
              rightWidth += itemRef.$el.offsetWidth;
            } else {
              if (key != "dots") sumWidth += itemRef.$el.offsetWidth;
              widthArr[key] = sumWidth;
            }
          }
        });
      }

      this.widthArr = widthArr;
      this.rightWidth = rightWidth;
    }, 0);
  },
  data() {
    return {
      checkbox: false,
      widthArr: [],
      rightWidth: 0
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsedModule", "getCustomizeUIInfo"]),
    ...mapGetters("mailLayout", ["mainWidth"]),
    ...mapGetters("mail", ["getMails", "getSelectedMails"]),
    ...mapGetters("mailConfig", ["getSplitView"]),
    ...mapGetters("folder", [
      "getTrashId",
      "currentFolder",
      "isShare",
      "isSearch",
      "isSent",
      "isTome",
      "isDeleted",
      "currentShareFolder"
    ]),
    ...mapGetters("mailRoute", [
      "getRouteListInfo",
      "getUnreadChecked",
      "getReadChecked",
      "getIncludesAttachChecked",
      "getImportantStatusesChecked",
      "getSort",
      "getDir"
    ]),
    selectedMails: {
      get() {
        return this.getSelectedMails;
      },
      set(selectedMails) {
        return this.SET_SELECTED_MAILS(selectedMails);
      }
    },
    filterDisabled() {
      return (
        !!this.getRouteListInfo.actionObj?.search?.type ||
        this.isSearch ||
        this.getRouteListInfo.folderId == quickMenuEnum.EXCHANGED ||
        this.getRouteListInfo.folderId == quickMenuEnum.INTERNAL ||
        this.getRouteListInfo.folderId == quickMenuEnum.CONTACT
      );
    },
    allReadMailsDisabled() {
      return (
        this.getMails.length === 0 ||
        !!this.getRouteListInfo.actionObj?.search?.type ||
        this.currentFolder?.folderType == "IMPORTANT" ||
        this.currentFolder?.folderType == "UNREAD" ||
        this.currentFolder?.folderType == "ATTACH" ||
        this.currentFolder?.folderType == "READ" ||
        this.currentFolder?.folderType == "EXCHANGED" ||
        this.currentFolder?.folderType == "INTERNAL" ||
        this.currentFolder?.folderType == "CONTACT"
      );
    },
    // 정렬 아이콘 표시 (vue == this)
    getSortAppendIcon: vue => sort => {
      if (vue.getSort != sort) return "";

      return vue.getDir == "desc" ? "mdi-arrow-down" : "mdi-arrow-up";
    },
    getMailsTotalCnt() {
      let cnt = 0;
      this.getMails.forEach(mail => {
        cnt += 1;
        mail?.exchange?.forEach(() => {
          cnt += 1;
        });
      });

      return cnt;
    },
    // 체크박스 미확정 여부
    indeterminate() {
      const { length: selectedLength } = this.selectedMails;
      return selectedLength > 0 && selectedLength < this.getMailsTotalCnt;
    },
    // 툴바 버튼 리스트
    items() {
      const { length: selectedLength } = this.selectedMails;
      // 선택된 row가 있을때 활성화
      const disabled = selectedLength < 1;
      // 선택된 메일중 안읽은 메일이 있으면 읽음
      const { length: unreadCount } = this.selectedMails.filter(
        ({ readStatus }) => readStatus == 0
      );
      const { permission = 3 } = this.isShare && this.currentShareFolder;

      return [
        {
          ref: "read",
          title:
            unreadCount || !selectedLength
              ? this.$t("mail.읽음")
              : this.$t("mail.안읽음"),
          disabled,
          showItem: permission == 3,
          click: () => this.updateReadStatus({ mails: this.selectedMails })
        },
        {
          ref: "delete",
          title: this.$t("common.삭제"),
          disabled,
          click: this.deleteMailConfirm,
          showItem: !this.isDeleted && !this.isShare
        },
        {
          ref: "deleteCompletely",
          title: this.$t("mail.완전삭제"),
          disabled,
          click: this.deleteMailPermanentlyConfirm,
          showItem: this.isDeleted
        },
        {
          ref: "reply",
          title: this.$t("mail.답장"),
          disabled: selectedLength != 1,
          showItem: permission == 3,
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: this.$t("mail.164")
              });
            this.routeWrite(1);
          }
        },
        {
          ref: "replyAll",
          title: this.$t("mail.전체답장"),
          disabled: selectedLength != 1,
          showItem: permission == 3,
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: this.$t("mail.164")
              });

            this.routeWrite(3);
          }
        },
        {
          ref: "forward",
          title: this.$t("mail.전달"),
          showItem: permission == 3,
          disabled,
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: this.$t("mail.164")
              });
            this.routeWrite(2);
          }
        },
        {
          ref: "move",
          title: this.$t("mail.이동"),
          disabled,
          showItem: !this.isShare,
          click: () =>
            this.SET_DIALOG({
              headline: this.$t("mail.165"),
              show: true,
              type: "moveMail",
              params: {
                mails: [...this.selectedMails]
              }
            })
        },
        {
          ref: "share",
          disabled: selectedLength != 1,
          showItem:
            permission == 3 &&
            (this.getUsedModule.board ||
              this.getCustomizeUIInfo.tigrisEnabled == 1),
          title: this.$t("common.공유"),
          type: "menu",
          vModel: "share",
          children: [
            {
              ref: "postShare",
              title: this.$t("mail.511"),
              showItem: this.getUsedModule.board,
              click: () => {
                this.SET_DIALOG({
                  headline: this.$t("mail.511"), // 게시판으로 공유
                  show: true,
                  type: "postShare",
                  params: {
                    mailId: this.selectedMails[0].mailId,
                    includeAttach: true
                  }
                });
              }
            },
            {
              title: this.$t("mail.516"),
              showItem: this.getCustomizeUIInfo.tigrisEnabled == 1,
              click: () => {
                this.SET_DIALOG({
                  headline: this.$t("mail.516"), // 티그리스 피드 등록
                  show: true,
                  type: "postShareForTigris",
                  params: {
                    mailId: this.selectedMails[0].mailId,
                    includeAttach: true
                  }
                });
              }
            }
          ]
        },
        {
          ref: "allRead",
          title: this.$t("mail.19"),
          disabled: this.allReadMailsDisabled,
          showItem: permission == 3,
          click: () => this.updateAllRead(this.getRouteListInfo.folderId)
        },
        {
          ref: "report",
          title: this.$t("mail.악성메일신고"),
          disabled: selectedLength != 1,
          showItem: false,
          /* TODO 메일 회수 사용시 활성화
          showItem:
            !this.isShare && permission == 3 && !this.isSent && !this.isTome,
          */
          click: () => this.openReportMailDialog()
        },
        {
          ref: "block",
          title: this.$t("mail.수신거부"),
          disabled: disabled || selectedLength > 1,
          showItem:
            this.currentFolder.folderType !== "SENT" && !this.isSharedMail,
          click: () => {
            const [{ adrFrom }] = this.selectedMails;
            const { address, personal } = adrFrom;
            const email = personal ? `${personal} &lt${address}&gt` : address;

            this.confirm({
              headline: this.$t("mail.428"),
              message: `<div>${this.$t("mail.429", { email: email })}</div>`,
              callback: () => {
                this.unsubscribe(address);
              }
            });
          }
        },
        {
          ref: "dots",
          title: "dots",
          tooltip: this.$t("mail.더보기"),
          type: "menu",
          menuIcon: "mdi-dots-horizontal",
          vModel: "dots",
          showItem: permission == 3,
          children: [
            {
              ref: "read",
              title:
                unreadCount || !selectedLength
                  ? this.$t("mail.읽음")
                  : this.$t("mail.안읽음"),
              disabled,
              showItem: permission == 3,
              click: () => this.updateReadStatus({ mails: this.selectedMails })
            },
            {
              ref: "delete",
              title: this.$t("common.삭제"),
              disabled,
              click: this.deleteMailConfirm,
              showItem: !this.isDeleted && !this.isShare
            },
            {
              ref: "deleteCompletely",
              title: this.$t("mail.완전삭제"),
              disabled,
              click: this.deleteMailPermanentlyConfirm,
              showItem: this.isDeleted
            },
            {
              ref: "reply",
              title: this.$t("mail.답장"),
              disabled: selectedLength != 1,
              showItem: permission == 3,
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: this.$t("mail.164")
                  });
                this.routeWrite(1);
              }
            },
            {
              ref: "replyAll",
              title: this.$t("mail.전체답장"),
              disabled: selectedLength != 1,
              showItem: permission == 3,
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: this.$t("mail.164")
                  });

                this.routeWrite(3);
              }
            },
            {
              ref: "forward",
              title: this.$t("mail.전달"),
              showItem: permission == 3,
              disabled,
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: this.$t("mail.164")
                  });
                this.routeWrite(2);
              }
            },
            {
              ref: "move",
              title: this.$t("mail.이동"),
              disabled,
              showItem: !this.isShare,
              click: () =>
                this.SET_DIALOG({
                  headline: this.$t("mail.165"),
                  show: true,
                  type: "moveMail",
                  params: {
                    mails: [...this.selectedMails]
                  }
                })
            },
            {
              ref: "share",
              disabled: selectedLength != 1,
              showItem:
                permission == 3 &&
                (this.getUsedModule.board ||
                  this.getCustomizeUIInfo.tigrisEnabled == 1),
              title: this.$t("common.공유"),
              type: "menu",
              vModel: "share",
              children: [
                {
                  ref: "postShare",
                  title: this.$t("mail.511"),
                  showItem: this.getUsedModule.board,
                  click: () => {
                    this.SET_DIALOG({
                      headline: this.$t("mail.511"), // 게시판으로 공유
                      show: true,
                      type: "postShare",
                      params: {
                        mailId: this.selectedMails[0].mailId,
                        includeAttach: true
                      }
                    });
                  }
                },
                {
                  title: this.$t("mail.516"),
                  showItem: this.getCustomizeUIInfo.tigrisEnabled == 1,
                  click: () => {
                    this.SET_DIALOG({
                      headline: this.$t("mail.516"), // 티그리스 피드 등록
                      show: true,
                      type: "postShareForTigris",
                      params: {
                        mailId: this.selectedMails[0].mailId,
                        includeAttach: true
                      }
                    });
                  }
                }
              ]
            },
            {
              ref: "allRead",
              title: this.$t("mail.19"),
              showItem: permission == 3,
              disabled: this.allReadMailsDisabled,
              click: () => this.updateAllRead(this.getRouteListInfo.folderId)
            },
            {
              ref: "report",
              title: this.$t("mail.악성메일신고"),
              disabled: selectedLength != 1,
              showItem: false,
              /* TODO 메일 회수 사용시 활성화
              showItem:
                !this.isShare &&
                permission == 3 &&
                !this.isSent &&
                !this.isTome,
              */
              click: () => this.openReportMailDialog()
            },
            {
              ref: "block",
              title: this.$t("mail.수신거부"),
              disabled: disabled || selectedLength > 1,
              showItem:
                this.currentFolder.folderType !== "SENT" && !this.isSharedMail,
              click: () => {
                const [{ adrFrom }] = this.selectedMails;
                const { address, personal } = adrFrom;
                const email = personal
                  ? `${personal} &lt${address}&gt`
                  : address;

                this.confirm({
                  headline: this.$t("mail.428"),
                  message: `<div>${this.$t("mail.429", {
                    email: email
                  })}</div>`,
                  callback: () => {
                    this.unsubscribe(address);
                  }
                });
              }
            }
          ].filter(
            item => this.widthArr[item.ref] >= this.mainWidth - this.rightWidth
          )
        }
      ].filter(item => {
        if (this.mainWidth == 0 || this.widthArr.length == 0) return true;
        if (item.title == "dots") {
          return this.widthArr[item.ref] >= this.mainWidth - this.rightWidth;
        }
        return this.widthArr[item.ref] < this.mainWidth - this.rightWidth;
      });
    },
    // 툴바 버튼 리스트 (오른쪽)
    rightItems: function() {
      return [
        {
          class: "cr-tutorial-filter",
          ref: "right_filter",
          title: this.$t("mail.필터"),
          type: "menu",
          vModel: "filter",
          closeOnContentClick: true,
          checkbox: true,
          disabled: this.filterDisabled,
          children: [
            {
              title: this.$t("mail.175"),
              checked: this.getUnreadChecked,
              check: checked => this.filterClick(checked, "readStatuses", 0)
            },
            {
              title: this.$t("mail.176"),
              checked: this.getReadChecked,
              check: checked => this.filterClick(checked, "readStatuses", 1)
            },
            {
              title: this.$t("mail.177"),
              checked: this.getIncludesAttachChecked,
              check: checked => this.filterClick(checked, "includesAttach", 1)
            },
            {
              title: this.$t("mail.178"),
              type: "menu",
              vModel: "important",
              checkbox: true,
              left: true,
              checked: this.getImportantStatusesChecked("all"),
              check: checked =>
                this.filterClick(checked, "importantStatuses", "all"),
              children: [
                {
                  title: this.$t("mail.179"),
                  checked: this.getImportantStatusesChecked(1),
                  check: checked =>
                    this.filterClick(checked, "importantStatuses", 1)
                },
                {
                  title: this.$t("mail.180"),
                  checked: this.getImportantStatusesChecked(2),
                  check: checked =>
                    this.filterClick(checked, "importantStatuses", 2)
                },
                {
                  title: this.$t("mail.181"),
                  checked: this.getImportantStatusesChecked(3),
                  check: checked =>
                    this.filterClick(checked, "importantStatuses", 3)
                },
                {
                  title: this.$t("mail.182"),
                  checked: this.getImportantStatusesChecked(4),
                  check: checked =>
                    this.filterClick(checked, "importantStatuses", 4)
                },
                {
                  title: this.$t("mail.183"),
                  checked: this.getImportantStatusesChecked(5),
                  check: checked =>
                    this.filterClick(checked, "importantStatuses", 5)
                }
              ]
            }
          ]
        },
        {
          ref: "right_sort",
          title: this.$t("mail.정렬"),
          type: "menu",
          vModel: "sort",
          vValue: this.getSort,
          selectable: true,
          children: [
            {
              title: this.$t("mail.보낸사람"),
              value: "adrFrom",
              appendIcon: this.getSortAppendIcon("adrFrom"),
              click: () => this.changeSort("adrFrom")
            },
            {
              title: this.$t("mail.제목"),
              value: "subject",
              appendIcon: this.getSortAppendIcon("subject"),
              click: () => this.changeSort("subject")
            },
            {
              title: this.$t("mail.날짜"),
              value: "receivedTimeMillis",
              appendIcon: this.getSortAppendIcon("receivedTimeMillis"),
              click: () => this.changeSort("receivedTimeMillis")
            },
            {
              title: this.$t("mail.크기"),
              value: "emlSize",
              appendIcon: this.getSortAppendIcon("emlSize"),
              click: () => this.changeSort("emlSize")
            }
          ]
        },
        {
          class: "cr-tutorial-splitview",
          ref: "right_split",
          title: "splitView",
          showItem: !this.$vuetify.breakpoint.xs,
          tooltip: this.getSplitView
            ? this.$t("mail.184")
            : this.$t("mail.185"),
          btnIcon: this.getSplitView ? "mdi-dock-left" : "mdi-view-sequential",
          click: () => this.updateSplitView()
        }
      ];
    }
  },
  watch: {
    checkbox(checkbox) {
      if (checkbox || (!checkbox && this.indeterminate)) {
        if (this.getMailsTotalCnt != this.selectedMails.length) {
          const mails = [];
          this.getMails.forEach(mail => {
            mails.push(mail);
            mail?.exchange?.forEach(mail => {
              mails.push(mail);
            });
          });

          this.selectedMails = mails;
        }
      } else {
        this.selectedMails = [];
      }
    },
    // 선택된 메일과 store의 메일 개수가 같으면 체크
    selectedMails(selectedMails) {
      const { length: selectedLength } = selectedMails;
      if (selectedLength == 0 && this.checkbox) {
        this.checkbox = false;
      } else if (
        selectedLength != 0 &&
        this.getMailsTotalCnt == selectedLength
      ) {
        setTimeout(() => {
          this.checkbox = true;
        }, 0);
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapMutations("mail", ["SET_SELECTED_MAILS"]),
    ...mapActions("mailConfig", ["updateSplitView"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mail", [
      "updateReadStatus",
      "updateAllRead",
      "deleteMails",
      "unsubscribe",
      "checkMailsBeforeMove"
    ]),
    ...mapActions("mailRoute", ["routeMailWrite", "routeMailList"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    // 메일쓰기 이동
    routeWrite(type) {
      const [{ mailId }] = this.selectedMails;
      const mailIds = this.selectedMails.map(({ mailId }) => mailId);
      this.routeMailWrite({ t: type, i: mailId, is: mailIds });
    },

    // 필터 클릭
    filterClick(checked, filterField, filterValue) {
      const { actionObj } = this.getRouteListInfo;
      const prevValue = actionObj[filterField];
      let value;
      switch (filterField) {
        // 읽음 / 안읽음
        case "readStatuses": {
          value = [filterValue];
          // 기존값 존재시
          if (prevValue != undefined) {
            value = checked
              ? [...new Set([...prevValue, filterValue])]
              : prevValue.filter(v => v != filterValue);
          }
          break;
        }
        // 첨부 메일
        case "includesAttach":
          value = checked ? filterValue : -1;
          break;
        // 중요 메일
        case "importantStatuses": {
          const isAll = filterValue == "all";
          value = isAll ? [1, 2, 3, 4, 5] : [filterValue];
          // 기존값 존재시
          if (prevValue != undefined) {
            if (checked && !isAll) {
              value = [...prevValue, filterValue];
            } else if (!checked) {
              value = isAll ? [] : prevValue.filter(v => v != filterValue);
            }
          }
          break;
        }
      }
      actionObj[filterField] = value;

      this.routeMailList({
        name: "mail_list_action",
        page: 1,
        actionObj
      });
    },
    // 정렬 클릭
    changeSort(sort) {
      const { actionObj } = this.getRouteListInfo;
      const actionSort = actionObj["sort"];
      const actiondir = actionObj["dir"];

      if (actionSort == sort) {
        actionObj["dir"] = actiondir == "desc" ? "asc" : "desc";
      } else {
        actionObj["sort"] = sort;
        actionObj["dir"] = "desc";
      }

      this.routeMailList({
        name: "mail_list_action",
        page: 1,
        actionObj
      });
    },
    // 메일 삭제
    deleteMailConfirm() {
      this.checkMailsBeforeMove({
        targetFolderId: this.getTrashId,
        selectedMails: this.selectedMails
      });
    },
    // 메일 완전 삭제
    deleteMailPermanentlyConfirm() {
      this.confirm({
        message: this.$t("mail.187"),
        callback: () => this.deleteMails(this.getSelectedMails)
      });
    },
    openReportMailDialog() {
      if (this.isBlockedMail())
        return this.openSnackbar({
          message: this.$t("mail.170")
        });

      this.SET_DIALOG({
        headline: this.$t("mail.171"),
        show: true,
        type: "spamReport",
        params: {
          mails: [...this.selectedMails]
        },
        btnTitles: { confirm: this.$t("mail.보내기") }
      });
    },
    // 차단 메일 확인
    isBlockedMail() {
      const isMaliciousMail = this.selectedMails.filter(
        mail =>
          mail.mailType == "REPORT_MAIL" || mail.mailType == "SYSTEM_BLOCK"
      );
      return isMaliciousMail.length > 0;
    }
  }
};
</script>
