<template>
  <v-tab @click="$emit('tabClick', tab.tabId)">
    <span class="text-truncate">{{ tabName }}</span>
    <v-btn v-if="tab.tabId !== 'list' && getUseTab" icon @click.stop="closeTab">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-tab>
</template>

<style lang="scss" scoped>
.v-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  max-width: 200px;
  padding-right: 0px;

  .v-btn {
    padding: 0px;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    tab: { type: Object, default: () => ({}) }
  },
  computed: {
    ...mapGetters("mailTab", ["tabs"]),
    ...mapGetters("mailConfig", ["getUseTab"]),
    ...mapGetters("mailCompose", ["showSendResult"]),
    ...mapGetters("mailRoute", ["getRouteListInfo", "getRouteWriteInfo"]),
    ...mapGetters("folder", [
      "currentShareFolder",
      "currentFolder",
      "isTag",
      "isShare"
    ]),

    tabName() {
      const { tabId, name } = this.tab;
      switch (tabId) {
        case "list": {
          if (!this.currentFolder) return "";

          const { nameFolder } = this.currentFolder;
          const { owner, nameFolder: sFolder } = this.currentShareFolder ?? {};
          if (this.isTag) return nameFolder;
          if (this.isShare) return `${owner}'s ${sFolder}`;

          const { actionObj } = this.getRouteListInfo;
          if (actionObj?.search?.type) return this.$t("mail.44");
          return nameFolder;
        }
        case "write": {
          const { actionObj } = this.getRouteWriteInfo;
          if (actionObj?.t == 6) return this.$t("mail.내게쓰기");
          return this.$t("mail.메일쓰기");
        }
        default:
          return name;
      }
    }
  },
  methods: {
    ...mapMutations("mailTab", ["DELETE_TAB", "SET_MOVE_TO_TAB_ID"]),
    closeTab() {
      const { tabId } = this.tab;
      const index = this.tabs.findIndex(t => t.tabId === tabId);
      if (index == -1 || index == 0) return;

      // 메일쓰기 탭일경우 confirm 확인
      if (
        tabId === "write" &&
        !this.showSendResult &&
        !window.confirm(this.$t("mail.45"))
      ) {
        return;
      }

      // 현재 열려있는 탭을 닫을때만 이동할 탭 지정
      if (this.$route.name.split("_")[1] === tabId) {
        this.SET_MOVE_TO_TAB_ID(this.tabs[index - 1]?.tabId);
      }
      this.DELETE_TAB(tabId);
    }
  }
};
</script>
