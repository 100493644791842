<template>
  <v-btn
    icon
    :ripple="false"
    :disabled="disabled"
    @click.stop="$emit('click:action')"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="text-truncate">
          <v-icon>{{ icon }}</v-icon>
        </span>
      </template>

      <div>{{ tooltip }}</div>
    </v-tooltip>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
