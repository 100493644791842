<template>
  <div class="d-flex align-center mt-1">
    <div class="cr-detail-label text-subtitle-2">
      {{ $t("mail.기간") }}
    </div>

    <v-select
      ref="periodRef"
      dense
      outlined
      hide-details
      v-model="periodSelectItem"
      :items="periodSelectItems"
      :menu-props="{ 'offset-y': true, closeOnClick: true }"
      @click:append.stop="$refs.periodRef.activateMenu()"
    />

    <v-menu
      ref="startMenu"
      offset-y
      min-width="auto"
      transition="scale-transition"
      v-model="startMenu"
      :close-on-content-click="false"
      :return-value.sync="startDate"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          dense
          readonly
          outlined
          hide-details
          autocomplete="off"
          v-model="startDate"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker v-model="startDate" no-title scrollable color="primary">
        <v-spacer />
        <v-btn text outlined @click="startMenu = false">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn outlined color="accent" @click="startDateClick">
          {{ $t("common.확인") }}
        </v-btn>
      </v-date-picker>
    </v-menu>

    <span class="mx-1">-</span>

    <v-menu
      ref="endMenu"
      offset-y
      min-width="auto"
      transition="scale-transition"
      v-model="endMenu"
      :close-on-content-click="false"
      :return-value.sync="endDate"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          dense
          readonly
          outlined
          hide-details
          autocomplete="off"
          v-model="endDate"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker v-model="endDate" no-title scrollable color="primary">
        <v-spacer />
        <v-btn text outlined @click="endMenu = false">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn outlined color="accent" @click="endDateClick">
          {{ $t("common.확인") }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  // width: 146px;
  height: 40px;

  &.v-select {
    max-width: 155px;
    margin-right: 5px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  data({ $store: { getters } }) {
    const { actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};
    const [start, end] = search?.timeRange || [];

    return {
      startDate: start || "",
      endDate: end || "",
      startMenu: false,
      endMenu: false,
      timeRange: search?.timeRange || [],
      periodSelectItem: start || end ? 6 : 0
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    periodSelectItems() {
      return [
        { text: this.$t("mail.전체"), value: 0 },
        { text: this.$t("mail.39"), value: 1 },
        { text: this.$t("mail.40"), value: 2 },
        { text: this.$t("mail.41"), value: 3 },
        { text: this.$t("mail.42"), value: 4 },
        { text: this.$t("mail.43"), value: 5 },
        { text: this.$t("mail.32"), value: 6 }
      ];
    }
  },
  watch: {
    getRouteListInfo({ actionObj }) {
      const { search } = actionObj || {};
      if (!search?.timeRange) return (this.timeRange = []);
      this.timeRange = search.timeRange;
    },
    timeRange(timeRange) {
      const [start, end] = timeRange;
      this.startDate = start || "";
      this.endDate = end || "";

      if ((start || end) && this.periodSelectItem == 0) {
        this.periodSelectItem = 6;
      } else if (!start && !end) {
        this.periodSelectItem = 0;
      }
    },
    startDate(startDate) {
      this.timeRange = [startDate, this.endDate];
    },
    endDate(endDate) {
      this.timeRange = [this.startDate, endDate];
    },
    periodSelectItem(value, prevValue) {
      const date = new Date();
      const m = date.getMonth();

      switch (value) {
        // 1 주일
        case 1:
          date.setDate(date.getDate() - 7);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 1 개월
        case 2:
          date.setMonth(m - 1);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 3 개월
        case 3:
          date.setMonth(m - 3);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 6 개월
        case 4:
          date.setMonth(m - 6);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 1년
        case 5:
          date.setFullYear(date.getFullYear() - 1);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        case 6:
          if (prevValue != 0) {
            this.startDate = "";
            this.endDate = "";
          }
          break;
        default:
          this.startDate = "";
          this.endDate = "";
          break;
      }
    }
  },
  methods: {
    startDateClick() {
      this.periodSelectItem = 6;
      const { startDate, endDate } = this;

      if (endDate && startDate > endDate) {
        this.startDate = endDate;
        this.$refs.startMenu.save(endDate);

        this.endDate = startDate;
        this.$refs.endMenu.save(startDate);
      } else {
        this.$refs.startMenu.save(startDate);
      }
    },
    endDateClick() {
      this.periodSelectItem = 6;
      const { startDate, endDate } = this;

      if (startDate && startDate > endDate) {
        this.startDate = endDate;
        this.$refs.startMenu.save(endDate);

        this.endDate = startDate;
        this.$refs.endMenu.save(startDate);
      } else {
        this.$refs.endMenu.save(endDate);
      }
    },
    getParams() {
      return {
        valid: false,
        params: { timeRange: this.timeRange }
      };
    }
  }
};
</script>
