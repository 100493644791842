<template>
  <v-row class="ma-0">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
      {{ $t("mail.331") }}
    </v-col>
    <v-col cols="12" sm="4" class="pl-0">
      <v-select
        ref="pageSizeRef"
        dense
        outlined
        hide-details
        item-text="text"
        item-value="value"
        style="max-width: 400px;"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        :items="serverNames"
        v-model="serverName"
        @click:append.stop="$refs.pageSizeRef.activateMenu()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ $store: { getters }, item: { serverName } }) {
    const getServerName = getters["mailConfigExternal/getServerName"];
    const { value } = getServerName(serverName);

    return { serverName: value };
  },
  computed: {
    ...mapGetters("mailConfigExternal", ["serverNames"])
  },
  watch: {
    serverName(serverName) {
      this.$emit("update:item", { ...this.item, serverName });
    }
  }
};
</script>
