<template>
  <v-row class="ma-0">
    <v-col class="pb-0 mt-2" cols="12">
      <div style="position: relative;">
        <v-icon
          color="red"
          style="position: absolute; font-size: 20px; top: 0px; left: -22px;"
        >
          mdi-exclamation
        </v-icon>

        <p class="mb-0" v-text="$t('mail.340')"></p>
        <p v-text="$t('mail.341')"></p>
        <div class="d-flex justify-end">
          <v-checkbox
            hide-details
            class="ma-0 mr-1"
            :label="$t('mail.342')"
            v-model="policyAgree"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ item: { policyAgree } }) {
    return { policyAgree: !!policyAgree };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    validation() {
      if (!this.policyAgree) {
        const { $parent = {} } = this.$parent;
        const { $el } = $parent;
        if ($el) $el.scrollTop = $el.scrollHeight;
        this.openSnackbar({ message: this.$t("mail.355"), type: "VALIDATION" });
        return false;
      }
      return true;
    }
  }
};
</script>
