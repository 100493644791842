<template>
  <div style="display: contents;">
    <ReserveModal v-if="showReserveModal" />
    <SecureModal v-if="showSecureModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReserveModal from "./reserve";
import SecureModal from "./secure";

export default {
  components: { ReserveModal, SecureModal },
  computed: {
    ...mapGetters("mailCompose", ["showReserveModal", "showSecureModal"])
  }
};
</script>
