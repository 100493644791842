<template>
  <v-checkbox
    multiple
    hide-details
    color="gray"
    v-model="selectedMails"
    :value="item"
    :ripple="false"
    :disabled="disabled"
    @click.stop=""
  />
</template>

<style lang="scss" scoped>
.v-input.v-input--checkbox ::v-deep {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  vertical-align: bottom;
  .v-input--selection-controls__input {
    margin-right: 0px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  computed: {
    ...mapGetters("mail", ["getSelectedMails"]),
    ...mapGetters("folder", ["isSpam"]),
    selectedMails: {
      get() {
        return this.getSelectedMails;
      },
      set() {
        this.$emit("selectItem");
      }
    },
    disabled() {
      const { isSpam, item } = this;
      return isSpam && item.isUserFilter;
    }
  }
};
</script>
