<template>
  <v-row class="ma-0">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="8" sm="4">
      {{ $t("mail.337") }}
    </v-col>
    <v-col
      sm="2"
      cols="4"
      class="pl-0"
      :class="$vuetify.breakpoint.xs ? 'd-flex justify-end pr-0' : ''"
    >
      <v-switch hide-details inset class="mt-0" v-model="useAutoFetch" />
    </v-col>

    <!-- <v-col
        cols="7"
        sm="4"
        class="labelCls"
        :class="$vuetify.breakpoint.smAndUp ? 'pl-0' : ''"
        v-text="'자동 분류 설정 적용'"
        v-model="useAutoFetch"
      ></v-col>
      <v-col
        cols="5"
        sm="2"
        class="cr-swtich-wrapper pr-0 d-flex justify-end"
        :class="$vuetify.breakpoint.smAndUp ? 'pl-0' : ''"
      >
        <v-switch hide-details inset class="mt-0" v-model="useRule" />
      </v-col> -->
  </v-row>
</template>

<script>
export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ item: { useAutoFetch } }) {
    return { useAutoFetch: !!useAutoFetch };
  }
};
</script>
