<template>
  <div style="display: contents;">
    <div
      style="width: 100%;"
      class="d-flex align-center justify-space-between"
      @dragenter="e => $emit('dragenter', e)"
      @dragleave="e => $emit('dragleave', e)"
      @dragover.prevent
    >
      <div>
        <!-- 파일 / 내 PC -->
        <v-btn text tile @click="$emit('openFileInput')">
          {{ $vuetify.breakpoint.xs ? $t("mail.121") : $t("mail.122") }}
        </v-btn>
        <!-- 드라이브 -->
        <v-btn v-if="useDrive" text tile @click="$emit('openDriveDialog')">
          {{ $t("mail.123") }}
        </v-btn>
        <v-btn text tile :disabled="!selectedItems.length" @click="deleteFile">
          {{ $t("common.삭제") }}
        </v-btn>
        <v-btn icon tile :disabled="disabledMove" @click="fileMove('up')">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn icon tile :disabled="disabledMove" @click="fileMove('down')">
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
      </div>

      <!-- 첨부파일 정보 -->
      <AttachInfo v-if="$vuetify.breakpoint.mdAndUp" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AttachInfo from "./AttachInfo.vue";

export default {
  components: { AttachInfo },
  computed: {
    ...mapGetters("auth", ["getUsedModule"]),
    ...mapGetters("mailFile", ["disabledMove", "selectedItems"]),
    useDrive() {
      return this.getUsedModule.drive;
    }
  },
  methods: {
    ...mapActions("mailFile", ["deleteFile", "fileMove"])
  }
};
</script>
