import { render, staticRenderFns } from "./BaseTr.vue?vue&type=template&id=3d183e43&scoped=true&"
import script from "./BaseTr.vue?vue&type=script&lang=js&"
export * from "./BaseTr.vue?vue&type=script&lang=js&"
import style0 from "./BaseTr.vue?vue&type=style&index=0&id=3d183e43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d183e43",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VChip,VHover,VIcon,VTimeline,VTimelineItem})
