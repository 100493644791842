<template>
  <div class="cr-envelope-container">
    <div class="d-flex align-center">
      <v-subheader>
        {{ $t("mail.받는사람") }}
      </v-subheader>

      <v-checkbox
        hide-details
        class="cr-to-checkbox mt-0 pt-0"
        :label="$t('mail.104')"
        v-model="_eachSend"
      >
        <template v-slot:append>
          <v-tooltip v-if="!$vuetify.breakpoint.xs" right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
            </template>

            <div class="text-caption" v-html="$t('mail.105')" />
          </v-tooltip>
        </template>
      </v-checkbox>
    </div>

    <div class="cr-input-wrapper" @click="SET_SHOW_RCPT_SELECTOR(1)">
      <Autocomplete
        ref="autocompleteRef"
        key="to"
        class="cr-dropzone-autocomplete-input"
        :selection.sync="selection_to"
        :crDraggable="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-to-checkbox.v-input::v-deep {
  align-items: center;
  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input i {
        font-size: 16px;
      }
      label {
        left: -7px !important;
        font-size: 0.72rem;
      }
    }
  }
  .v-input__append-outer {
    margin-left: -5px;
    .v-icon {
      font-size: 16px;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  computed: {
    ...mapGetters("mailCompose", ["to", "eachSend"]),
    selection_to: {
      get() {
        return this.to.map(t => ({ ...t }));
      },
      set(to) {
        return this.SET_RECIPIENT({ to });
      }
    },
    _eachSend: {
      get() {
        return !!this.eachSend;
      },
      set(eachSend) {
        return this.SET_EACHSEND({ eachSend });
      }
    }
  },
  methods: {
    ...mapMutations("mailCompose", [
      "SET_RECIPIENT",
      "SET_EACHSEND",
      "SET_SHOW_RCPT_SELECTOR"
    ])
  }
};
</script>
