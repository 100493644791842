<template>
  <div class="cr-auto-save">
    <span class="mr-2">{{ $t("mail.자동저장") }}</span>
    <span>
      <span class="primary--text font-weight-bold"> {{ sec }} </span>
      초
      <span v-if="savedTime" class="grey--text darken-3">
        ( {{ savedTime }} )
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.cr-auto-save {
  display: flex;
  align-items: center;
  font-size: 14px;
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  destroyed() {
    if (this.secInterval) {
      clearInterval(this.secInterval);
    }
  },
  data() {
    return {
      savedTime: "",
      sec: 0,
      secInterval: null
    };
  },
  computed: {
    ...mapGetters("mailFile", { files: "items" }),
    ...mapGetters("mailRoute", ["getRouteWriteInfo"]),
    ...mapGetters("mailTab", ["existWriteTab"]),
    ...mapGetters("mailConfig", ["getAutoSave", "getUseTab"]),
    ...mapGetters("mailCompose", ["ASObserver"]),
    autoSave() {
      return parseInt(this.getAutoSave);
    }
  },
  watch: {
    sec(sec) {
      if (sec < this.autoSave) return;

      clearInterval(this.secInterval);
      this.secInterval = null;
      this.sec = 0;

      // 브라우저 성능 이슈로 본문은 여기서 따로 넣어줌
      this.checkAttach({ funcStr: "saveDraftMail" });
      this.savedTime = moment().format("MM.DD hh:mm");
    },
    files() {
      if (this.secInterval !== null) return;
      this.start();
    },
    ASObserver() {
      if (this.secInterval !== null) return;
      this.start();
    },
    // 메일쓰기 화면 닫을때 자동저장 클리어
    $route() {
      if (this.getUseTab && this.existWriteTab) return;

      clearInterval(this.secInterval);
      this.secInterval = null;
      this.sec = 0;
    },
    // 임시저장 버튼 눌렀을때 자동저장 클리어
    getRouteWriteInfo(n, p) {
      const prevRouteName = this.$route.name;
      setTimeout(() => {
        const routeName = this.$route.name;
        if (!routeName.includes("mail_write")) return;
        if (prevRouteName !== routeName) return;
        if (JSON.stringify(n.actionObj) !== JSON.stringify(p.actionObj)) {
          if (n.actionObj.t !== 4) return;
        }

        clearInterval(this.secInterval);
        this.secInterval = null;
        this.sec = 0;
      }, 0);
    }
  },
  methods: {
    ...mapActions("mailCompose", ["checkAttach"]),
    start() {
      this.secInterval = setInterval(() => {
        this.sec += 1;
      }, 1000);
    }
  }
};
</script>
