<template>
  <v-list-item class="pr-0">
    <v-list-item-content class="text-black">
      <v-list-item-title class="d-flex text-subtitle-2 pt-1">
        <FileName :item="fileName" />
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ fileSize }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <div class="d-flex align-center">
        <v-btn
          small
          rounded
          outlined
          :disabled="disabled"
          color="primary"
          class="cr-change"
          @click="$emit('chagneType')"
        >
          {{ uploadType }}
          <v-icon v-if="!disabled">mdi-swap-horizontal</v-icon>
        </v-btn>
        <v-btn icon small class="ml-1" @click="$emit('remove')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<style lang="scss" scoped>
.cr-change.v-btn.v-btn--disabled {
  color: var(--v-primary-base) !important;
}
</style>

<script>
import { byteCalculation } from "@/commons/utils/byteCalculation";
import FileName from "@/mail/views/components/main/commons/FileName";

export default {
  components: { FileName },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    selectedFiles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    disabled() {
      return this.item.fileSize > 20971520;
    },
    fileName() {
      const { fileName, extension } = this.item;
      const ext = fileName.split(".").at(-1);
      const [name] = fileName.split(`.${ext}`);
      return { name, ext: extension || ext };
    },
    fileSize() {
      const { fileSize } = this.item;
      return fileSize > 0 ? `${byteCalculation(fileSize)}` : "—";
    },
    uploadType() {
      const { uploadType } = this.item;
      return uploadType === "TEMP_FILE"
        ? this.$t("mail.111")
        : this.$t("mail.112");
    }
  }
};
</script>
