<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <v-tooltip top nudge-top="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              tile
              block
              depressed
              height="40"
              @click="changeType"
            >
              <span class="primary--text font-weight-bold">{{ title }}</span>
              <v-icon color="primary" v-if="typesSize > 1">
                mdi-swap-horizontal
              </v-icon>
            </v-btn>
          </template>

          {{ tooltip }}
        </v-tooltip>
      </div>
      <div class="d-flex" style="width:36px" v-if="typesSize > 2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small tile depressed height="40" v-bind="attrs" v-on="on">
              <v-icon small color="grey">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in typesArray"
              :key="index"
              @click="selectType(item.text)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-btn.v-btn--block.v-btn--disabled {
  background-color: #f5f5f5 !important;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    selectorType: {
      type: String,
      default: "contact"
    }
  },
  data() {
    return {
      types: this.initializeTypes()
    };
  },
  computed: {
    ...mapGetters("auth", ["restrictedFunctions"]),
    title() {
      return this.types[this.selectorType].title;
    },
    tooltip() {
      return this.types[this.selectorType].tooltip;
    },
    typesSize() {
      return Object.keys(this.types).length;
    },
    typesArray() {
      return Object.values(this.types);
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    initializeTypes() {
      const types = {};

      const { isOrgChartRestricted } = this.$store.getters[
        "auth/restrictedFunctions"
      ];
      const { customer } = this.$store.getters["auth/getUsedModule"];

      if (!isOrgChartRestricted) {
        types.organ = {
          text: "organ",
          title: this.$t("common.조직도"),
          tooltip: this.$t("mail.551")
        };
      }

      types.contact = {
        text: "contact",
        title: this.$t("common.주소록"),
        tooltip: customer ? this.$t("mail.587") : this.$t("mail.552")
      };

      if (customer) {
        types.customer = {
          text: "customer",
          title: this.$t("common.공용주소록"),
          tooltip: this.$t("mail.552")
        };
      }

      const itemSize = Object.keys(types).length;
      if (itemSize === 1) {
        for (const key in types) {
          types[key].tooltip = types[key].title;
        }
      }

      return types;
    },
    selectType(value) {
      const params = { type: value };
      this.reOpenDialog(params);
    },
    changeType() {
      if (this.isOrgChartRestricted) return;

      const keys = Object.keys(this.types);
      const currentIndex = keys.indexOf(this.selectorType);
      const nextIndex = (currentIndex + 1) % keys.length;
      const params = { type: this.types[keys[nextIndex]].text };
      this.reOpenDialog(params);
    },
    reOpenDialog(params) {
      this.SET_DIALOG({
        show: true,
        headline: "",
        type: "recipient",
        params
      });
    }
  }
};
</script>
