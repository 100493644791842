<template>
  <div class="pa-3 cr-preview">
    <div class="black--text">
      <!-- 제목 -->
      <div class="text-h6 font-weight-bold mb-2">
        {{ _subject }}
      </div>

      <!-- 보낸사람 -->
      <PreviewItem :title="$t('mail.보낸사람')" :value="from" />

      <!-- 받는사람 -->
      <PreviewItem :title="$t('mail.받는사람')" :values="toValue" />

      <!-- 참조 -->
      <PreviewItem :title="$t('mail.참조')" :values="ccValue" />

      <!-- 숨은참조 -->
      <PreviewItem :title="$t('mail.숨은참조')" :values="bccValue" />
    </div>

    <!-- 첨부파일 -->
    <PreviewAttach />

    <!-- 본문 -->
    <div>
      <v-divider class="my-1" />
    </div>
    <div class="content black--text">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-preview::v-deep {
  height: 680px;
  overflow: auto;
  user-select: text;
  border: thin solid rgba(0, 0, 0, 0.12);
  .cr-label {
    width: 80px;
    min-width: 80px;
  }

  .content {
    min-height: 445px;
    word-break: break-all;
    line-height: 1.7;
    user-select: text;
    -ms-user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;

    p {
      padding: 0px;
      margin: 0px;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import PreviewItem from "./preview/PreviewItem.vue";
import PreviewAttach from "./preview/PreviewAttach.vue";

export default {
  components: { PreviewItem, PreviewAttach },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("mailCompose", [
      "subject",
      "subjectFirst",
      "senderName",
      "senderEmail",
      "to",
      "cc",
      "bcc",
      "content"
    ]),
    _subject() {
      const subject = this.subject || `(${this.$t("mail.109")})`;
      if (this.subjectFirst && this.subjectFirst !== this.$t("mail.107")) {
        return `[${this.subjectFirst}] ${subject}`;
      }
      return subject;
    },
    from() {
      return `"${this.senderName}" <${this.senderEmail}>`;
    },
    toValue() {
      return this.to.map(t => t.value);
    },
    ccValue() {
      return this.cc.map(t => t.value);
    },
    bccValue() {
      return this.bcc.map(t => t.value);
    }
  },
  watch: {
    async confirm(next) {
      if (!next) return;

      const subject = this.subject || `(${this.$t("mail.109")})`;
      this.SET_SUBJECT({ subject });
      await this.sendParamsValidation();
      this.CLOSE_DIALOG();
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("mailCompose", ["SET_SUBJECT"]),
    ...mapActions("mailCompose", ["sendParamsValidation"])
  }
};
</script>
