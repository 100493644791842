import { render, staticRenderFns } from "./SpamRestore.vue?vue&type=template&id=0050a58e&scoped=true&"
import script from "./SpamRestore.vue?vue&type=script&lang=js&"
export * from "./SpamRestore.vue?vue&type=script&lang=js&"
import style0 from "./SpamRestore.vue?vue&type=style&index=0&id=0050a58e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0050a58e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VCol,VIcon,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VOverlay,VProgressCircular,VRow})
