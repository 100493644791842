<template>
  <div>
    <v-btn x-small icon @click.stop="openAddExternal">
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-btn x-small icon @click.stop="deleteExternalMail([item.id])">
      <v-icon>
        mdi-delete
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mailConfigExternal", ["deleteExternalMail"]),
    openAddExternal() {
      this.SET_DIALOG({
        show: true,
        headline: this.$t("mail.263"),
        type: "externalMail",
        params: { external: this.item }
      });
    }
  }
};
</script>
