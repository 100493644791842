<template>
  <v-tooltip bottom nudge-bottom="-8">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-on="on"
        v-bind="attrs"
        close
        label
        :color="color"
        class="mr-1 mb-1"
        close-icon="mdi-close"
        @click:close="close"
      >
        {{ name }}
      </v-chip>
    </template>

    {{ chip.value }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    chip: {
      type: Object,
      default: () => ({})
    },
    vnodeKey: {
      type: String,
      default: ""
    },
    selectedPanel: {
      type: String,
      default: ""
    }
  },
  computed: {
    name() {
      const { value } = this.chip;
      const [name, address] = value.split("<");
      if (
        address &&
        (address.startsWith("C#") ||
          address.startsWith("O#") ||
          address.startsWith("O+"))
      ) {
        return "@" + (name?.trim().replace(/"/g, "") || "");
      }

      if (name) {
        return name.trim().replace(/"/g, "") || "";
      } else {
        return value;
      }
    },
    color() {
      const { value } = this.chip;
      const address = value?.split("<")?.at(-1);

      if (address.startsWith("C#")) return "cyan lighten-4";
      if (address.startsWith("O#")) return "blue lighten-4";
      if (address.startsWith("O+")) return "blue lighten-4";
      return "grey lighten-3";
    }
  },
  methods: {
    close() {
      this.$emit("selectPanel", this.vnodeKey);
      this.$emit("removeRcpt", this.chip);
    }
  }
};
</script>
