<template>
  <div class="d-flex justify-center pt-6">
    <v-data-table
      v-if="recipients.length"
      fixed-header
      hide-default-footer
      item-key="key"
      :item-class="() => 'item-row'"
      :items-per-page="100"
      :server-items-length="100"
      :headers="headers"
      :items="recipients"
    >
      <template v-slot:[`item.resultCode`]="{ item }">
        <span
          :class="{ 'red--text': item.status, 'primary--text': !item.status }"
        >
          {{ getResultMsg(item) }}
        </span>
      </template>
      <template v-slot:[`item.sendTime`]="{ item }">
        {{ getDateWithTime(item.sendTime) }}
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.v-data-table::v-deep {
  width: 700px;
  border: 1px solid rgba(0, 0, 0, 0.22);
  border-radius: 0px;

  .v-data-table__wrapper {
    max-height: 383px;

    .item-row > .recipient {
      width: 230px;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>

<script>
import { getDateWithTime } from "@/commons/utils/moment";

export default {
  props: {
    recipients: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("mail.수신자"),
          sortable: false,
          align: "center",
          cellClass: "recipient",
          width: 230,
          value: "recipient"
        },
        {
          text: this.$t("mail.77"),
          sortable: false,
          align: "center",
          width: 250,
          value: "resultCode"
        },
        {
          text: this.$t("mail.78"),
          sortable: false,
          align: "center",
          width: 200,
          value: "sendTime"
        }
      ]
    };
  },
  methods: {
    getDateWithTime(time) {
      return getDateWithTime(time);
    },
    getResultMsgClass(status) {
      return status == 0 ? "primary--text" : "red--text";
    },
    getResultMsg({ status, replyCode }) {
      if (replyCode == 401) return this.$t("mail.79");
      if (replyCode == 550) return this.$t("mail.80");
      if (replyCode == 501) return this.$t("mail.81");
      if (status == 1) return this.$t("mail.82");
      return this.$t("mail.83");
    }
  }
};
</script>
