<template>
  <div class="subtitle-2 d-flex mb-1">
    <div class="cr-label">{{ title }}</div>
    <div v-if="value" class="d-flex flex-wrap">
      <span>{{ value }}</span>
    </div>
    <div
      v-else-if="values.length"
      v-for="value in values"
      :key="value"
      class="d-flex flex-wrap mr-3"
    >
      <span>{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    values: {
      type: Array,
      default: () => []
    }
  }
};
</script>
