<template>
  <div
    class="d-flex justify-space-between mt-1 cr-p-r user-select-text"
    style="line-height: initial;"
  >
    <!-- 수신자 보기 토글 버튼 -->
    <v-btn
      v-if="isPanelOpen"
      icon
      x-small
      class="cr-adr-expand-btn cr-view-print-hide"
      @click.stop="$emit('update:showRecipients', !showRecipients)"
    >
      <v-icon>
        {{ showRecipients ? "mdi-menu-down" : "mdi-menu-right" }}
      </v-icon>
    </v-btn>

    <span class="text-truncate">
      <!-- 예약메일 문구 -->
      <span class="primary--text">
        {{ isPanelOpen && item.mailType === "RESERVE" ? $t("mail.예약") : "" }}
      </span>
      <!-- 보낸메일 -->
      <span
        class="primary--text"
        @click.stop="showReceiptDialog()"
        style="cursor:pointer"
      >
        {{ isPanelOpen && item.mailType === "SENT" ? $t("mail.201") : "" }}
      </span>
      <!-- 미리보기 / 날짜 -->
      {{ isPanelOpen ? item.receivedTimeMillis : contentPreview }}
      {{ !isPanelOpen && contentPreview.length == 80 ? "..." : "" }}

      <span v-if="item.eachSend == 1" class="ml-2">
        <v-chip
          class="red white--text"
          style="height:auto; padding:3px 3px 2px; font-size:11px; color:#FFF !important; border-radius:4px; line-height:1;"
          >{{ $t("mail.493") }}</v-chip
        >
      </span>
    </span>

    <span v-if="!isPanelOpen" class="cr-receivedTimeMillis">
      {{ item.receivedTimeMillis }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
// 받는사람, 참조 보기
.cr-adr-expand-btn {
  position: absolute;
  top: 0px;
  left: -22px;
  > .v-btn__content > i {
    font-size: 15px;
  }
}

// 받은시간
.cr-receivedTimeMillis {
  min-width: 140px;
  margin-left: 12px;
  flex-shrink: 0;
}
</style>

<script>
import i18n from "@/_locales";
import { mapActions, mapMutations } from "vuex";
import { getReceipt } from "@/mail/api/receipt.api";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isPanelOpen: {
      type: Boolean,
      default: false
    },
    showRecipients: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contentPreview() {
      return this.item?.contentPreview || "";
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("mailDialog", ["SET_DIALOG"]),

    async showReceiptDialog() {
      const { data } = await getReceipt({ mailId: this.item.mailId });
      if (!data.content) {
        await this.openSnackbar({ message: i18n.t("mail.391"), type: "ERROR" });
        return;
      }

      // 수신 확인 정보 로드
      this.SET_DIALOG({
        headline: i18n.t("mail.수신확인"),
        show: true,
        type: "recipientDetail",
        params: {
          item: {
            ...this.item,
            receipt: data.content
          }
        },
        showConfirmBtn: false,
        btnTitles: {
          cancel: i18n.t("common.닫기")
        }
      });
    }
  }
};
</script>
