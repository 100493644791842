<template>
  <div class="cr-rcpt-tree-wrapper">
    <ChangeBtn v-bind="$props" />
    <TreeView v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-rcpt-tree-wrapper {
  width: 270px;
  height: 100%;
  padding-right: 6px;
}
</style>

<script>
import ChangeBtn from "./ChangeBtn.vue";
import TreeView from "./TreeView.vue";

export default {
  components: { ChangeBtn, TreeView },
  props: {
    isOrgan: {
      type: Boolean,
      default: false
    },
    selectorType: {
      type: String,
      default: "contact"
    }
  }
};
</script>
