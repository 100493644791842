<template>
  <div class="cr-wrapper">
    <div class="cr-option-box">
      <v-tabs v-model="selectedTab">
        <v-tab
          v-for="tab in tabs"
          :key="tab.tabId"
          :disabled="tab.disabled"
          v-show="!tab.hide"
          @click="() => routeMailConfig({ tabId: tab.tabId })"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" class="cr-tabs-items">
        <!-- 읽기 목록 -->
        <v-tab-item :transition="false">
          <MailConfigList
            ref="config_list"
            :listConfig="getListConfig"
            :disabledBtnGroups.sync="disabledBtnGroups"
          />
        </v-tab-item>
        <!-- 쓰기/답장 -->
        <v-tab-item :transition="false">
          <MailConfigWrite
            ref="config_write"
            :writeConfig="getWriteConfig"
            :disabledBtnGroups.sync="disabledBtnGroups"
          />
        </v-tab-item>
        <!-- 서명 -->
        <v-tab-item :transition="false">
          <MailConfigSign />
        </v-tab-item>
        <!-- 관리/백업 -->
        <v-tab-item :transition="false">
          <MailConfigFolder />
        </v-tab-item>
        <!-- 자동분류 -->
        <v-tab-item :transition="false">
          <MailConfigRule />
        </v-tab-item>
        <!-- 스마트 메일함 -->
        <v-tab-item :transition="false">
          <MailConfigSmartMailbox
            ref="config_smartmailbox"
            :smartMailboxConfig="getSmartMailboxConfig"
            :disabledBtnGroups.sync="disabledBtnGroups"
          />
        </v-tab-item>
        <!-- 외부메일 -->
        <v-tab-item :transition="false">
          <MailConfigExternalMail />
        </v-tab-item>
        <!-- POP3/SMTP 설정 -->
        <v-tab-item :transition="false">
          <MailConfigPop3
            ref="config_pop3"
            :pop3Config="getPop3Config"
            :selectedTab="selectedTab"
          />
        </v-tab-item>
        <!-- 메일 자동 전달 -->
        <v-tab-item :transition="false">
          <MailConfigForward
            ref="config_forward"
            :forwardConfig="getPop3Config"
            :selectedTab="selectedTab"
          />
        </v-tab-item>
        <!-- 부재중 응답 설정 -->
        <v-tab-item :transition="false">
          <MailConfigAway
            ref="config_away"
            :getAwayConfig="getAwayConfig"
            :disabledBtnGroups.sync="disabledBtnGroups"
          />
        </v-tab-item>

        <v-row
          v-if="showBtnGroups"
          no-gutters
          class="mt-3 pb-2 pr-2"
          style="max-width: 1029px;"
        >
          <v-col class="d-flex justify-end">
            <v-btn
              text
              depressed
              :disabled="disabledBtnGroups"
              @click="cancel(selectedTab)"
              v-text="$t('common.취소')"
            />
            <v-btn
              class="mr-3"
              color="primary"
              depressed
              :disabled="disabledBtnGroups"
              @click="save"
              v-text="$t('common.저장')"
            />
          </v-col>
        </v-row>
      </v-tabs-items>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-mail-main {
  .cr-wrapper {
    height: 100%;
    /* 설정을 선택하는 부분 */
    .cr-option-box {
      height: 100%;

      .v-list ::v-deep {
        padding: 0px;
      }
      .cr-tabs-items {
        position: absolute;
        top: 48px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        overflow-y: auto;

        // 스위치 style
        ::v-deep .v-window__container {
          height: 100%;
          .cr-swtich-wrapper {
            padding-left: 17px;
            display: flex;
          }
        }
      }

      /* 스위치, 라디오버튼 UI */
      ::v-deep .v-input.v-input--radio-group.v-input--selection-controls,
      ::v-deep .v-input.v-input--switch.v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
      }
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions } from "vuex";
import MailConfigList from "./config/MailConfigList";
import MailConfigWrite from "./config/MailConfigWrite";
import MailConfigSign from "./config/MailConfigSign";
import MailConfigFolder from "./config/MailConfigFolder";
import MailConfigRule from "./config/MailConfigRule";
import MailConfigSmartMailbox from "./config/MailConfigSmartMailbox";
import MailConfigExternalMail from "./config/MailConfigExternalMail";
import MailConfigPop3 from "./config/MailConfigPop3";
import MailConfigForward from "./config/MailConfigForward";
import MailConfigAway from "./config/MailConfigAway";

export default {
  components: {
    MailConfigList,
    MailConfigWrite,
    MailConfigSign,
    MailConfigFolder,
    MailConfigRule,
    MailConfigSmartMailbox,
    MailConfigExternalMail,
    MailConfigPop3,
    MailConfigForward,
    MailConfigAway
  },
  mounted() {
    // 관리자 설정에 따른 메뉴(POP3, IMAP) 설정
    const pop3Tab = this.tabs.find(e => e.tabId == "pop3");
    if (
      this.getMailServiceConfig.pop3 == 1 &&
      this.getMailServiceConfig.imap == 1
    ) {
      // pop3, imap
      pop3Tab.name = i18n.t("mail.452", { value: "POP3/IMAP" });
    } else if (this.getMailServiceConfig.pop3 == 1) {
      // pop3
      pop3Tab.name = i18n.t("mail.452", { value: "POP3" });
    } else if (this.getMailServiceConfig.imap == 1) {
      // imap
      pop3Tab.name = i18n.t("mail.452", { value: "IMAP" });
    } else {
      pop3Tab.hide = true;
    }

    const index = this.tabs.findIndex(
      t => t.tabId == this.getRouteConfigInfo.tabId
    );
    if (index != -1) {
      this.selectedTab = index;
    }
  },
  data() {
    return {
      disabledBtnGroups: true,
      selectedTab: 0,
      tabs: [
        { tabId: "list", name: i18n.t("mail.49") },
        { tabId: "write", name: i18n.t("mail.50") },
        { tabId: "sign", name: i18n.t("mail.51") },
        { tabId: "folder", name: i18n.t("mail.52") },
        { tabId: "rule", name: i18n.t("mail.53") },
        { tabId: "smart_mailbox", name: i18n.t("mail.494") },
        { tabId: "external", name: i18n.t("mail.54") },
        { tabId: "pop3", name: i18n.t("mail.452") },
        { tabId: "forward", name: i18n.t("mail.520") },
        { tabId: "away", name: i18n.t("mail.536") }
      ]
    };
  },
  watch: {
    // 탭 이동시(index)
    selectedTab(next, prev) {
      // 하단 버튼 그룹들이 활성화돼 있을 경우, 설정값을 리셋 해준다.
      if (!this.disabledBtnGroups) {
        this.cancel(prev);
      }
    },
    getRouteConfigInfo({ tabId }) {
      const index = this.tabs.findIndex(t => t.tabId == tabId);
      if (index != -1) {
        this.selectedTab = index;
      }
    }
  },
  computed: {
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),
    ...mapGetters("mailRoute", ["getRouteConfigInfo"]),
    ...mapGetters("mailConfigAway", { getAwayConfig: "config" }),
    ...mapGetters("mailConfig", [
      "getListConfig",
      "getWriteConfig",
      "getPop3Config",
      "getSmartMailboxConfig"
    ]),
    showBtnGroups() {
      const { tabId } = this.tabs[this.selectedTab] || {};
      return (
        tabId == "list" ||
        tabId == "write" ||
        tabId == "smart_mailbox" ||
        tabId == "away"
      );
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailConfig"]),
    ...mapActions("mailConfig", [
      "updateListConfig",
      "updateWriteConfig",
      "updateSmartMailboxConfig"
    ]),
    ...mapActions("mailConfigAway", ["updateAwayConfig"]),
    ...mapActions("mailConfigRule", ["getRuleList"]),
    cancel(tab) {
      switch (tab) {
        // 읽기/목록
        case 0:
          this.$refs.config_list.reset();
          break;
        // 쓰기/답장
        case 1:
          this.$refs.config_write.reset();
          break;
        // 관리/백업
        case 2:
          break;
        // 스마트메일함
        case 5:
          this.$refs.config_smartmailbox.reset();
          break;
        // 부재 중 응답
        case 8:
          this.$refs.config_away.reset();
          break;
        default:
          break;
      }
    },
    save() {
      switch (this.selectedTab) {
        // 읽기/목록
        case 0:
          this.updateListConfig(this.$refs.config_list.config);
          break;
        // 쓰기/답장
        case 1:
          this.updateWriteConfig(this.$refs.config_write.config);
          break;
        // 관리/백업
        case 2:
          break;
        // 스마트메일함
        case 5:
          this.updateSmartMailboxConfig(this.$refs.config_smartmailbox.config);
          break;
        //부재 중 응답
        case 9:
          this.updateAwayConfig(this.$refs.config_away.config);
          break;
        default:
          break;
      }
    }
  }
};
</script>
