<template>
  <div class="cr-rcpt-select-panel">
    <Panel
      v-for="key in panels"
      v-bind="$props"
      v-on="$listeners"
      :key="key"
      @selectPanel="panel => $emit('update:selectedPanel', panel)"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-rcpt-select-panel {
  height: 100%;
  width: 380px;
  overflow: auto;
}
</style>

<script>
import Panel from "./Panel.vue";

export default {
  props: {
    to: {
      type: Array,
      default: () => []
    },
    cc: {
      type: Array,
      default: () => []
    },
    bcc: {
      type: Array,
      default: () => []
    },
    selectedPanel: {
      type: String,
      default: "to"
    }
  },
  components: { Panel },
  data() {
    return {
      panels: ["to", "cc", "bcc"]
    };
  }
};
</script>
