<template>
  <div class="cr-rcpt-list-wrapper">
    <SearchField v-bind="$props" :searchWord.sync="searchWord" />
    <List v-bind="{ ...$props, ...$data }" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-rcpt-list-wrapper {
  width: 400px;
  height: 100%;
  padding-right: 6px;
}
</style>

<script>
import SearchField from "./SearchField.vue";
import List from "./List.vue";

export default {
  components: { SearchField, List },
  props: {
    to: {
      type: Array,
      default: () => []
    },
    cc: {
      type: Array,
      default: () => []
    },
    bcc: {
      type: Array,
      default: () => []
    },
    selectorType: {
      type: String,
      default: "contact"
    },
    selectedPanel: {
      type: String,
      default: "to"
    }
  },
  data() {
    return { searchWord: "" };
  }
};
</script>
