<template>
  <v-card-text>
    <AddFolder
      v-if="dialogType === 'folder'"
      v-bind="$props"
      v-on="$listeners"
    />
    <AddSearchFolder
      v-else-if="dialogType === 'searchFolder'"
      v-bind="$props"
      v-on="$listeners"
    />
    <MoveMail
      v-else-if="dialogType === 'moveMail'"
      v-bind="$props"
      v-on="$listeners"
    />
    <AddRule
      v-else-if="dialogType === 'rule'"
      v-bind="$props"
      v-on="$listeners"
    />
    <AddSign
      v-else-if="dialogType == 'signAdd' || dialogType == 'signEdit'"
      v-bind="$props"
      v-on="$listeners"
    />
    <PreviewSign
      v-else-if="dialogType == 'previewSign'"
      v-bind="$props"
      v-on="$listeners"
    />
    <Backup
      v-else-if="dialogType == 'backup'"
      v-bind="$props"
      v-on="$listeners"
    />
    <Restore
      v-else-if="dialogType == 'restore'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardAddDialog
      v-else-if="dialogType == 'contact'"
      v-bind="$props"
      v-on="$listeners"
    />
    <PreviewMailDialog
      v-else-if="dialogType == 'previewMail'"
      v-bind="$props"
      v-on="$listeners"
    />
    <AddExternalMail
      v-else-if="dialogType == 'externalMail'"
      v-bind="$props"
      v-on="$listeners"
    />
    <ReportMail
      v-else-if="dialogType == 'spamReport'"
      v-bind="$props"
      v-on="$listeners"
    />
    <SpamRestore
      v-else-if="dialogType == 'spamRestore'"
      v-bind="$props"
      v-on="$listeners"
    />
    <FolderColorPicker
      v-else-if="dialogType == 'colorPicker'"
      v-bind="$props"
      v-on="$listeners"
    />
    <LinkShare
      v-else-if="dialogType == 'linkShare'"
      v-bind="$props"
      v-on="$listeners"
    />
    <PostShare
      v-else-if="dialogType == 'postShare'"
      v-bind="$props"
      v-on="$listeners"
    />
    <DriveAttach
      v-else-if="dialogType == 'driveAttach'"
      v-bind="$props"
      v-on="$listeners"
    />
    <RecipientSelector
      v-else-if="dialogType == 'recipient'"
      v-bind="$props"
      v-on="$listeners"
    />
    <ReceiptDetailDialog
      v-else-if="dialogType == 'recipientDetail'"
      v-bind="$props"
      v-on="$listeners"
    />

    <!-- 외부 연계 -->
    <TigrisPostShare
      v-else-if="dialogType == 'postShareForTigris'"
      v-bind="$props"
      v-on="$listeners"
    />
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
import AddFolder from "@/mail/views/components/dialog/AddFolder";
import AddSearchFolder from "@/mail/views/components/dialog/AddSearchFolder";
import MoveMail from "@/mail/views/components/dialog/MoveMail";
import AddRule from "@/mail/views/components/dialog/AddRule";
import AddSign from "@/mail/views/components/dialog/AddSign";
import PreviewSign from "@/mail/views/components/dialog/PreviewSign";
import Backup from "@/mail/views/components/dialog/Backup";
import Restore from "@/mail/views/components/dialog/Restore";
import CardAddDialog from "@/contact/views/components/dialog/CardAddDialog";
import PreviewMailDialog from "@/mail/views/components/dialog/PreviewMailDialog";
import AddExternalMail from "@/mail/views/components/dialog/AddExternalMail";
import ReportMail from "@/mail/views/components/dialog/ReportMail";
import SpamRestore from "@/mail/views/components/dialog/SpamRestore";
import FolderColorPicker from "@/mail/views/components/dialog/FolderColorPicker";
import LinkShare from "@/mail/views/components/dialog/LinkShare";
import PostShare from "@/mail/views/components/dialog/PostShare";
import DriveAttach from "@/mail/views/components/dialog/DriveAttach";
import RecipientSelector from "@/mail/views/components/dialog/RecipientSelector";
import ReceiptDetailDialog from "@/mail/views/components/dialog/ReceiptDetailDialog.vue";

// 외부 연계
import TigrisPostShare from "@/mail/views/components/dialog/linkup/TigrisPostShare";

export default {
  components: {
    AddFolder,
    AddSearchFolder,
    MoveMail,
    AddRule,
    AddSign,
    PreviewSign,
    Backup,
    Restore,
    CardAddDialog,
    PreviewMailDialog,
    AddExternalMail,
    ReportMail,
    SpamRestore,
    FolderColorPicker,
    LinkShare,
    PostShare,
    DriveAttach,
    RecipientSelector,
    ReceiptDetailDialog,

    // 외부 연계
    TigrisPostShare
  },
  props: {
    confirm: {
      default: false,
      type: Boolean
    },
    confirmDisabled: {
      default: false,
      type: Boolean
    },
    persistent: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("mailDialog", ["dialogType"])
  }
};
</script>
