<template>
  <div class="d-flex align-center flex-wrap cr-attach-label pb-2">
    <div class="d-flex align-center">
      <v-btn
        text
        plain
        x-small
        :ripple="false"
        class="cr-attach-expand-btn"
        @click="$emit('update:show', !show)"
      >
        <v-icon class="cr-view-print-hide">
          {{ show ? "mdi-chevron-down" : "mdi-chevron-right" }}
        </v-icon>
      </v-btn>
      <span> {{ $t("mail.159", { value: items.length }) }} </span>
      <span class="cr-attach-size">{{ `(${displayTotalSize})` }}</span>
    </div>
    <a
      v-if="totalSize > 0"
      :download="'all.zip'"
      class="cr-attach-save cr-view-print-hide"
      v-text="$t('mail.160')"
      @click.prevent="downloadAll"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-attach-label {
  font-size: 14px;

  * {
    padding: 0;
    margin: 0;
  }

  .cr-attach-save {
    padding-left: 7px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .cr-attach-expand-btn {
    padding: 0px;
    min-width: 24px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("folder", ["isShare"]),
    ...mapGetters("mailRoute", ["getRouteShareInfo", "getRouteViewInfo"]),
    totalSize() {
      if (this.items.length == 0) return 0;
      return this.items.reduce((a, b) => ({ size: a.size + b.size })).size;
    },
    displayTotalSize() {
      return byteCalculation(this.totalSize);
    },
    isSharedMail() {
      if (this.$route.name !== "popup_mail_view") return this.isShare;

      const { actionObj } = this.getRouteViewInfo;
      const { sfi, owi, sp } = actionObj?.list || {};
      return !!(parseInt(sfi) && parseInt(owi) && parseInt(sp));
    }
  },
  methods: {
    ...mapActions("auth", ["checkToken"]),
    async downloadAll() {
      const [{ mailId }] = this.items;
      if (!mailId) return;
      // 토큰확인
      await this.checkToken();

      const { folderId, ownerId } = this.getRouteShareInfo;
      const token = localStorage.getItem("access_token");
      const baseUrl = `${process.env.VUE_APP_API_SERVER_URL}/api`;

      // 일반 메일
      let url = `${baseUrl}/mail/download/all/${mailId}?access_token=${token}`;
      // 공유 받은 메일
      if (this.isSharedMail) {
        url = `${baseUrl}/share/mails/download/all/${mailId}?folderId=${folderId}&ownerId=${ownerId}&access_token=${token}`;
      }

      window.location.href = url;
    }
  }
};
</script>
