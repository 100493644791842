<template>
  <!-- 외부메일 -->
  <div class="ml-2 pa-2 pb-0 cr-external-wrapper">
    <TableHeaderVue :selectedExternal.sync="selectedExternal" />
    <v-data-table
      ref="tableRef"
      show-select
      hide-default-footer
      :loading="externalLoading"
      :headers="headers"
      :items="externalList"
      :items-per-page="100"
      :item-class="() => 'item-row'"
      v-model="selectedExternal"
      @click:row="(r, { select, isSelected }) => select(!isSelected)"
    >
      <template v-slot:[`item.serverName`]="{ item }">
        <ServerName :item="item" />
      </template>
      <template v-slot:[`item.nameFolder`]="{ item }">
        <NameFolder :item="item" />
      </template>
      <template v-slot:[`item.fetchStatus`]="{ item }">
        <Status :item="item" />
      </template>
      <template v-slot:[`item.fetchDate`]="{ item }">
        <FetchDate :item="item" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <Actions :item="item" />
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.cr-external-wrapper {
  position: absolute;
  max-width: 1029px;
  width: 100%;
  top: 0px;
  bottom: 0px;

  .v-data-table ::v-deep {
    position: absolute;
    top: 62px;
    bottom: 8px;
    left: 8px;
    right: 8px;
    border: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    /* row style */
    .v-data-table__wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      > table {
        > thead > tr > th,
        > tbody > tr > td {
          padding: 0 0 0 16px;
          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);

          &.serverName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            max-width: 150px;
          }

          &.email {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            max-width: 1px;
          }

          &.nameFolder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            max-width: 150px;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import TableHeaderVue from "./external/TableHeader.vue";
import ServerName from "./external/ServerName.vue";
import Status from "./external/Status.vue";
import NameFolder from "./external/NameFolder.vue";
import FetchDate from "./external/FetchDate.vue";
import Actions from "./external/Actions.vue";

export default {
  components: {
    TableHeaderVue,
    ServerName,
    Status,
    NameFolder,
    FetchDate,
    Actions
  },
  mounted() {
    this.getExternalList();

    const additional = this.$route.query.additional
      ? JSON.parse(this.$route.query.additional)
      : null;

    if (additional?.isNew) {
      delete this.$route.query.additional;

      this.SET_DIALOG({
        show: true,
        headline: this.$t("mail.260"),
        type: "externalMail",
        params: { external: additional }
      });
    }
  },
  data() {
    return {
      selectedExternal: [],
      headers: [
        {
          text: this.$t("mail.264"),
          align: "start",
          sortable: false,
          value: "serverName",
          width: 150,
          cellClass: "serverName"
        },
        {
          text: this.$t("mail.265"),
          sortable: false,
          value: "email",
          cellClass: "email"
        },
        {
          text: this.$t("mail.266"),
          sortable: false,
          value: "nameFolder",
          width: 150,
          cellClass: "nameFolder"
        },
        {
          text: this.$t("mail.267"),
          sortable: false,
          value: "fetchStatus",
          width: 200
        },
        {
          text: this.$t("mail.268"),
          sortable: false,
          value: "fetchDate",
          width: 130
        },
        {
          text: this.$t("mail.관리"),
          align: "center",
          sortable: false,
          value: "actions",
          width: 120
        }
      ]
    };
  },
  computed: {
    ...mapGetters("mailConfigExternal", ["externalList", "externalLoading"])
  },
  watch: {
    externalList(n, p) {
      // 삭제시 셀렉 삭제
      this.selectedExternal = n.filter(
        e => this.selectedExternal.findIndex(s => s.id === e.id) !== -1
      );

      // 추가시 스크롤 이동
      if (n.length - p.length !== 1) return;
      setTimeout(() => {
        const { $el } = this.$refs.tableRef;
        const el = $el.querySelector(".v-data-table__wrapper");
        el.scrollTop = el.scrollHeight;
      }, 0);
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mailConfigExternal", ["getExternalList"])
  }
};
</script>
