<template>
  <Toolbar :showCheckbox="false" :items="items" />
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  components: { Toolbar },
  data() {
    return {
      test: true
    };
  },
  computed: {
    ...mapGetters("folder", ["isSharedRoot"]),
    ...mapGetters("mail", ["getSelectedMails"]),
    items() {
      const { length } = this.getSelectedMails;
      return [
        {
          title: i18n.t("mail.172"),
          disabled: length == 0,
          click: this.isSharedRoot ? this.cancelShare : this.deleteShare
        }
      ];
    }
  },
  methods: {
    ...mapMutations("mail", ["SET_SELECTED_MAILS"]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("share", ["shareDialog", "toCancelShare", "toDeleteShare"]),
    cancelShare() {
      this.confirm({
        message: i18n.t("mail.173"),
        callback: async () => {
          const result = await this.toCancelShare({
            cancelList: this.getSelectedMails.map(item => item.id),
            moduleType: "MAIL"
          });

          // const { actionObj, page } = this.$route.params;
          if (result) {
            this.SET_SELECTED_MAILS([]);
            // 갱신
            this.$router.push(this.$router.currentRoute);
          }
        }
      });
    },
    deleteShare() {
      this.confirm({
        message: i18n.t("mail.174"),
        callback: async () => {
          const result = await this.toDeleteShare({
            objectIds: this.getSelectedMails.map(item => item.id),
            moduleType: "MAIL"
          });
          if (result) {
            this.SET_SELECTED_MAILS([]);
            // 갱신
            this.$router.push(this.$router.currentRoute);
          }
        }
      });
    }
  }
};
</script>
