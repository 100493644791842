<!--
  v-dialog / persistent : 밖에화면 눌렀을때 안꺼짐
  부모 컴포넌트에서 dialog 컨트롤
  :value="dialog"
  @input="$emit('update:dialog', false)"
-->
<template>
  <v-dialog
    scrollable
    :width="maxWidth"
    :max-width="maxWidth"
    :value="showDialog"
    :persistent="persistent"
    :retain-focus="false"
    @input="CLOSE_DIALOG"
  >
    <v-card>
      <v-card-title v-if="headline" class="text-h6 font-weight-bold">
        <span>{{ headline }}</span>
      </v-card-title>
      <DialogContent
        :confirm.sync="confirm"
        :confirmDisabled.sync="confirmDisabled"
        :persistent.sync="persistent"
        :style="contentStyle"
      />
      <v-card-actions v-if="showActions">
        <v-btn
          v-if="leftBtn.use"
          text
          :color="leftBtn.color"
          @click="leftBtn.func"
        >
          {{ leftBtn.title }}
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn text outlined @click="CLOSE_DIALOG">
          {{ btnTitles.cancel }}
        </v-btn>
        <v-btn
          v-show="showConfirmBtn"
          outlined
          color="accent"
          :disabled="confirmDisabled"
          @click="confirm = true"
        >
          {{ btnTitles.confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-dialog__content >>> .v-dialog {
  overflow: hidden;
}
.v-dialog__content >>> .v-dialog > .v-card > .v-card__text {
  overflow: auto;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import DialogContent from "@/mail/views/components/dialog";

export default {
  components: { DialogContent },
  data: () => ({
    // 확인 버튼 클릭
    confirm: false,
    // 확인 버튼 Disabled 여부
    confirmDisabled: false,
    // 다이얼로그 외 영역 클릭시 꺼짐 여부 (true: 안꺼짐)
    persistent: false
  }),
  computed: {
    ...mapGetters("mailDialog", [
      "headline",
      "showDialog",
      "dialogType",
      "params",
      "btnTitles",
      "showConfirmBtn",
      "leftBtn",
      "showActions"
    ]),
    maxWidth() {
      switch (this.dialogType) {
        case "recipient":
          return 1100;
        case "driveAttach":
          return 1000;
        case "externalMail":
        case "searchFolder":
        case "previewMail":
        case "postShare":
          return 800;
        case "rule":
        case "signAdd":
        case "signEdit":
        case "previewSign":
          return 700;
        case "backup":
        case "restore":
        case "spamRestore":
        case "recipientDetail":
          return 600;
        case "contact":
        case "spamReport":
        case "linkShare":
          return 500;
        // 외부 연계
        case "postShareForTigris":
          return 800;

        default:
          return 400;
      }
    },
    contentStyle() {
      switch (this.dialogType) {
        case "previewMail":
          return "height: 700px;";
        default:
          return "";
      }
    }
  },
  watch: {
    dialogType() {
      this.confirm = false;
      this.confirmDisabled = false;
      this.persistent = false;
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"])
  }
};
</script>
