<template>
  <v-row class="ma-0 mb-2">
    <v-col class="px-2 py-0 mb-1" cols="12">
      <div
        class="cr-info text-caption grey--text text--darken-2"
        v-text="$t('mail.283')"
      ></div>
    </v-col>
    <v-col class="px-2 py-0 mb-1" cols="12">
      <div
        class="cr-info text-caption grey--text text--darken-2"
        v-text="$t('mail.284')"
      ></div>
    </v-col>
    <v-col class="px-2 py-0 mb-4" cols="12">
      <div
        class="cr-info text-caption grey--text text--darken-2"
        v-text="$t('mail.285')"
      ></div>
    </v-col>

    <div class="d-flex align-center mb-4 px-1">
      <div class="grey--text text--darken-3 font-weight-black mr-1">
        <span v-text="$t('mail.47')" class="mr-1"></span>:
      </div>
      <div class="text-subtitle-2 grey--text text--darken-2">
        {{ params.folder.nameFolder }}
      </div>
    </div>
    <div
      class="d-flex align-center justify-space-between mb-2 px-1"
      style="width: 100%;"
    >
      <div
        class="grey--text text--darken-3 font-weight-black"
        v-text="$t('mail.286')"
      ></div>
      <div class="grey--text text--darken-2">
        <span v-text="$t('mail.전체')"></span>
        <span class="blue--text text--darken-2">
          {{ byteCalculation(fileSize) }}
        </span>
      </div>
    </div>
    <div style="width:100%; border: 1px solid rgba(0, 0, 0, 0.12);">
      <v-list-item class="py-2">
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-2 grey--text text--darken-2">
            {{ isExistFile ? targetFile.name : $t("mail.287") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            text
            outlined
            :ripple="false"
            @click="openFileInput"
            v-text="$t('mail.425')"
          >
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </div>
    <input
      v-show="false"
      type="file"
      ref="fileInput"
      accept="message/rfc822, .zip"
      @change="changeFileInput"
    />
    <v-overlay :value="overlay">
      <v-progress-circular
        class="mb-5 mx-auto d-flex justify-center"
        indeterminate
        size="64"
      ></v-progress-circular>
      <div
        class="font-weight-bold"
        style="text-align:center;"
        v-html="$t('mail.288')"
      ></div>
    </v-overlay>
  </v-row>
</template>

<script>
import i18n from "@/_locales";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { mailRestore } from "@/mail/api/mail.api";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    overlay: false,
    file: [],
    fileSize: 0,
    useFileExt: ["eml", "zip"]
  }),
  watch: {
    async confirm(value) {
      if (value) {
        // 유효성 검사
        const check = this.validationCheck();
        if (!check) return this.$emit("update:confirm", false);

        // 복원
        this.overlay = true;
        const params = {
          part: this.targetFile,
          folderId: this.params.folder.id
        };

        const { data, status } = await mailRestore(params);
        const messages = {
          ERROR: i18n.t("mail.289"),
          SUCCESS: i18n.t("mail.290")
        };
        const type =
          status != 200 ? "ERROR" : data.result ? "SUCCESS" : "ERROR";

        if (type == "SUCCESS") {
          this.SET_FOLDER_META(data.folder);
        }

        this.openSnackbar({
          type,
          message: messages[type]
        });
        this.overlay = false;
        this.CLOSE_DIALOG();
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["isOverQuota"]),
    ...mapGetters("mailDialog", ["params"]),
    isExistFile() {
      return this.file.length > 0;
    },
    targetFile() {
      return this.file[0];
    }
  },
  methods: {
    ...mapMutations("folder", ["SET_FOLDER_META"]),
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    byteCalculation(size) {
      return byteCalculation(size);
    },
    openFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    changeFileInput(e) {
      const file = e.target.files;
      if (file.length > 0) {
        // 파일 확장자 체크
        const extCheck = this.extValidationCheck(file[0]);
        if (!extCheck) return;

        // 파일 사이즈 체크
        const sizeCheck = this.sizeValidationCheck(file[0]);
        if (!sizeCheck) return;

        this.file = file;
        this.fileSize = file[0].size;
      }
    },
    validationCheck() {
      // 1. 파일 등록 했는지 여부
      if (!this.targetFile) {
        this.openSnackbar({
          message: i18n.t("mail.287")
        });

        return false;
      }
      // 2. 파일의 확장자 검사
      const extCheck = this.extValidationCheck(this.targetFile);
      if (!extCheck) return false;

      // 3. 유저의 총 용량 검사
      if (this.isOverQuota) {
        this.openSnackbar({
          message: i18n.t("mail.3"),
          type: "ERROR"
        });
        return false;
      }

      return true;
    },
    // 파일 용량 검사
    sizeValidationCheck(file) {
      // 500MB
      const maxSize = 524288000 * 2; // TODO 임시로 1GB로 변경
      if (file.size > maxSize) {
        this.openSnackbar({
          message: i18n.t("mail.3")
        });
        return false;
      }
      return true;
    },

    // 파일 확장자 검사
    extValidationCheck(file) {
      const fileExt = file.name
        .split(".")
        .pop()
        .toLowerCase();
      const index = this.useFileExt.findIndex(ext => ext == fileExt);

      if (index < 0) {
        this.openSnackbar({
          message: i18n.t("mail.291")
        });
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-info {
  position: relative;
}
.cr-info::before {
  position: absolute;
  top: 9px;
  left: -5px;
  width: 2px;
  height: 2px;
  background-color: #767676;
  border-radius: 50%;
  content: "";
}
</style>
