<template>
  <v-container
    v-if="showSearchDetail"
    :style="`max-width: ${mainWidth}px`"
    :class="`cr-breakpoint-${breakpoint}`"
  >
    <div class="cr-s-d-1">
      <div class="cr-s-d-1-1">
        <From />
        <To />
      </div>
      <div class="cr-s-d-1-2">
        <Content />
        <Period />
      </div>
      <div v-if="breakpoint == 'lg'" class="cr-s-d-1-3">
        <Tag />
        <Mailbox :folderId.sync="folderId" />
      </div>
    </div>

    <div v-if="breakpoint !== 'lg'" class="cr-s-d-1-3">
      <Tag />
      <Mailbox :folderId.sync="folderId" />
    </div>

    <Etc :folderId="folderId" />
  </v-container>
</template>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 0px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);

  // 상세검색 라벨
  .cr-detail-label {
    min-width: 74px;
    color: rgba(0, 0, 0, 0.6);
  }
  // 상세검색 인풋
  .v-input {
    font-size: 14px !important;
    input {
      line-height: 21px;
    }
  }

  .cr-s-d-1 {
    display: flex;
    width: 100%;
    .cr-s-d-1-1,
    .cr-s-d-1-2,
    .cr-s-d-1-3 {
      width: 33%;
      min-width: 409px;
      padding-right: 4px;
    }
    .cr-s-d-1-3 {
      padding-right: 0px;
    }
  }

  &.cr-breakpoint-md {
    .cr-s-d-1-1 {
      width: 49%;
      padding-right: 10px;
    }
    .cr-s-d-1-2 {
      width: 50%;
      padding-right: 0px;
    }
    .cr-s-d-1-3 {
      display: flex;
      width: 100%;
      .cr-search-detail-tag-wrapper {
        width: 49%;
        margin-top: 4px;
        padding-right: 10px;
      }
      .cr-s-d-mbx {
        width: 50%;
        padding-right: 0px;
      }
    }
  }

  &.cr-breakpoint-xs {
    position: absolute;
    background: #fff;
    top: 0px;
    left: 0px;
    width: calc(100% - 2px);
    z-index: 2;
    box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2),
      0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12) !important;
    .cr-s-d-1 {
      flex-direction: column;
      .cr-s-d-1-1,
      .cr-s-d-1-2 {
        width: 99%;
        padding-right: 0px;
      }
      .cr-s-d-1-2 {
        padding-top: 4px;
      }
    }
    .cr-s-d-1-3 {
      width: 100%;
      margin-top: 4px;
      padding-right: 0px;
      .cr-s-d-mbx,
      .cr-search-detail-tag-wrapper {
        width: 99%;
        padding-right: 0px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import From from "./detail/From";
import Content from "./detail/Content";
import To from "./detail/To";
import Period from "./detail/Period";
import Mailbox from "./detail/Mailbox";
import Tag from "./detail/Tag";
import Etc from "./detail/Etc";

export default {
  components: { From, Content, To, Period, Mailbox, Tag, Etc },
  data({ $store: { getters } }) {
    const { folderId, actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};

    return {
      folderId: parseInt(search?.folderId || folderId) || -1
    };
  },
  computed: {
    ...mapGetters("folder", ["getTrashId"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailLayout", [
      "mainWidth",
      "showSearchDetail",
      "useSearchHistory"
    ]),

    breakpoint() {
      if (this.mainWidth < 850) return "xs";
      if (this.mainWidth < 1300) return "md";
      return "lg";
    }
  },
  watch: {
    getRouteListInfo({ folderId, actionObj }) {
      const { search } = actionObj || {};
      this.folderId = parseInt(search?.folderId || folderId) || -1;
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailList"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    searchDetail() {
      const { getTrashId: trashFolderId, useSearchHistory } = this;
      let bool = false;
      let search = { type: "detail", trashFolderId, useSearchHistory };
      this.$children.forEach(c => {
        const { valid, params } = c.getParams();
        search = { ...search, ...params };
        bool = bool || valid;
      });

      if (!bool) {
        this.openSnackbar({ message: this.$t("mail.35"), type: "VALIDATION" });
        return;
      }

      this.routeMailList({
        name: "mail_list_action",
        page: 1,
        folderId: this.folderId,
        actionObj: { search }
      });
    }
  }
};
</script>
