<template>
  <Toolbar
    ref="toolbar"
    :showCheckbox="false"
    :items="items"
    :rightItems="rightItems"
  />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  components: { Toolbar },
  mounted() {
    setTimeout(() => {
      // toolbar 공백 초기값 11, dots 44
      let sumWidth = 55;
      // 오른쪽 메뉴와의 공백을 40정도로 준다.
      let rightWidth = 40;
      const widthArr = {};
      const { $refs } = this.$refs?.toolbar || {};
      if ($refs) {
        Object.keys($refs).forEach(key => {
          const [itemRef] = $refs[key] || [];
          if (!itemRef) return;

          if (key.startsWith("right_")) {
            rightWidth += itemRef.$el.offsetWidth;
            return;
          }

          if (key != "dots") sumWidth += itemRef.$el.offsetWidth;
          widthArr[key] = sumWidth;
        });
      }

      this.widthArr = widthArr;
      this.rightWidth = rightWidth;
    }, 0);
  },
  data() {
    return { widthArr: [], rightWidth: 0 };
  },
  computed: {
    ...mapGetters("auth", ["isOverQuota", "getDomainStatus"]),
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),
    ...mapGetters("mailLayout", ["mainWidth"]),
    ...mapGetters("mailConfig", ["getPreviewBeforeSend"]),
    ...mapGetters("mailCompose", [
      "loading",
      "priority",
      "senderType",
      "showOnlySubject",
      "isWriteToMe"
    ]),
    isSecureMail() {
      return this.getMailServiceConfig.secureMail == 1;
    },
    disabled() {
      return this.loading || this.isOverQuota || !this.getDomainStatus;
    },
    items() {
      return [
        {
          ref: "send",
          type: "btn-outline",
          textStyle: "font-weight: bold;",
          title: this.isWriteToMe
            ? this.$t("common.저장")
            : this.$t("mail.보내기"),
          disabled: this.disabled,
          click: this.send
        },
        {
          ref: "reserve",
          title: this.$t("mail.발송예약"),
          disabled: this.disabled,
          showItem: !this.isWriteToMe && this.senderType !== "EXTERNAL",
          click: () => this.SET_SHOW_RESERVE_MODAL(true)
        },
        {
          ref: "drafts",
          title: this.$t("mail.임시저장"),
          disabled: this.disabled,
          click: this.saveMail
        },
        {
          ref: "preview",
          title: this.$t("mail.미리보기"),
          disabled: this.disabled,
          click: this.openPreviewMailDialog
        },
        {
          ref: "secure",
          title: this.$t("mail.보안메일"),
          disabled: this.disabled,
          showItem: !this.isWriteToMe && this.isSecureMail,
          click: () => this.SET_SHOW_SECURE_MODAL(true)
        },
        {
          ref: "dots",
          title: "dots",
          tooltip: this.$t("mail.더보기"),
          type: "menu",
          menuIcon: "mdi-dots-horizontal",
          vModel: "dots",
          children: [
            {
              ref: "send",
              textStyle: "font-weight:bold",
              disabled: this.disabled,
              title: this.isWriteToMe
                ? this.$t("common.저장")
                : this.$t("mail.보내기"),
              click: this.send
            },
            {
              ref: "reserve",
              title: this.$t("mail.발송예약"),
              disabled: this.disabled,
              showItem: !this.isWriteToMe && this.senderType !== "EXTERNAL",
              click: () => this.SET_SHOW_RESERVE_MODAL(true)
            },
            {
              ref: "drafts",
              title: this.$t("mail.임시저장"),
              disabled: this.disabled,
              click: () => this.checkAttach({ funcStr: "saveDraftMail" })
            },
            {
              ref: "preview",
              title: this.$t("mail.미리보기"),
              disabled: this.disabled,
              click: this.openPreviewMailDialog
            },
            {
              ref: "secure",
              title: this.$t("mail.보안메일"),
              disabled: this.disabled,
              showItem: !this.isWriteToMe && this.isSecureMail,
              click: () => this.SET_SHOW_SECURE_MODAL(true)
            }
          ].filter(
            i => this.widthArr[i.ref] >= this.mainWidth - this.rightWidth
          )
        }
      ].filter(i => {
        if (this.mainWidth == 0 || this.widthArr.length == 0) return true;
        if (i.title == "dots") {
          return this.widthArr[i.ref] >= this.mainWidth - this.rightWidth;
        }
        return this.widthArr[i.ref] < this.mainWidth - this.rightWidth;
      });
    },
    rightItems() {
      return [
        {
          ref: "right_swap",
          title: this.isWriteToMe
            ? this.$t("mail.메일쓰기")
            : this.$t("mail.내게쓰기"),
          tooltip: this.isWriteToMe
            ? this.$t("mail.메일쓰기")
            : this.$t("mail.내게쓰기"),
          btnIcon: "mdi-swap-horizontal",
          disabled: this.disabled,
          click: () => this.routeMailWrite({ t: this.isWriteToMe ? 0 : 6 })
        },
        {
          ref: "right_showOnlySubject",
          title: this.$t("mail.162"),
          tooltip: this.$t("mail.162"),
          disabled: this.disabled,
          btnIcon: this.showOnlySubject ? "mdi-arrow-down" : "mdi-arrow-up",
          click: this.TOGGLE_SHOW_ONLY_SUBJECT
        },
        {
          ref: "right_newWindow",
          title: this.$t("mail.163"),
          tooltip: this.$t("mail.163"),
          btnIcon: "mdi-open-in-new",
          disabled: this.disabled,
          showItem: !this.isPopUp && !this.$vuetify.breakpoint.xs,
          click: () => {
            this.checkAttach({
              funcStr: "saveDraftMail",
              params: { openPopup: true }
            });
          }
        }
      ];
    },
    isPopUp() {
      return this.$route.name.includes("popup_mail_");
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapMutations("mailCompose", [
      "SET_CONTENT",
      "SET_SHOW_RESERVE_MODAL",
      "SET_SHOW_SECURE_MODAL",
      "TOGGLE_SHOW_ONLY_SUBJECT"
    ]),
    ...mapActions("mailRoute", ["routeMailWrite"]),
    ...mapActions("mailCompose", ["sendParamsValidation", "checkAttach"]),
    openPreviewMailDialog() {
      // 브라우저 성능 이슈로 본문은 여기서 따로 넣어줌
      this.SET_CONTENT(window.tinymce.activeEditor.getContent());
      this.SET_DIALOG({
        show: true,
        type: "previewMail",
        headline: this.$t("mail.미리보기"),
        btnTitles: {
          confirm: this.isWriteToMe
            ? this.$t("common.저장")
            : this.$t("mail.보내기")
        }
      });
    },
    send() {
      // 발송 전 미리보기 0: 사용안함, 1: 중요메일, 2: 모든메일
      if (
        !this.getPreviewBeforeSend ||
        (this.getPreviewBeforeSend == 1 && this.priority !== 1)
      ) {
        // 브라우저 성능 이슈로 본문은 여기서 따로 넣어줌
        return this.sendParamsValidation();
      }
      return this.openPreviewMailDialog();
    },
    saveMail() {
      // 브라우저 성능 이슈로 본문은 여기서 따로 넣어줌
      this.checkAttach({ funcStr: "saveDraftMail" });
    }
  }
};
</script>
