<template>
  <div>
    <v-container class="pa-0 cr-cal-container" v-if="startDate != null">
      <v-row no-gutters>
        <!-- 달력 아이콘 -->
        <v-col cols="12" lg="2" md="2" sm="2">
          <div class="grey lighten-4 cr-cal-icon-container">
            <div class="cal_wrapper">
              <div class="cal-month">
                <span v-if="!isShareCalendar">{{ getMonth(startDate) }}</span>
                <span v-else v-text="$t('mail.135')"></span>
              </div>
              <div class="cal-date">
                <div v-if="!isShareCalendar">
                  <strong>{{ getDate(startDate) }}</strong>
                  <span>{{ getDayOfWeek(startDate) }}</span>
                </div>
                <div v-else>
                  <v-icon class="my-3" large>mdi-account-multiple-plus</v-icon>
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <!-- 내용 -->
        <v-col
          cols="12"
          lg="10"
          md="10"
          sm="10"
          class="cr-cal-content-container"
          :class="{ 'text-center': isShareCalendar }"
        >
          <v-list dense v-if="!isShareCalendar">
            <v-list-item dense>
              <span class="red--text text--accent-4 mr-2">{{ getType }}</span>
              <span class="font-weight-bold">{{ calendarData.summary }}</span>
            </v-list-item>
            <!-- 일시 -->
            <v-list-item dense class="cr_cal_row">
              <v-list-item-content class="pa-0">
                <v-list-item-subtitle>
                  <span
                    class="text--secondary cr_label"
                    v-text="$t('mail.136')"
                  ></span>
                  <span>{{ getStartDateText }}</span>
                  ~
                  <span>{{ getEndDateText }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- 주최자 -->
            <v-list-item dense class="cr_cal_row">
              <v-list-item-content class="pa-0">
                <v-list-item-subtitle>
                  <span
                    class="text--secondary cr_label"
                    v-text="$t('mail.137')"
                  ></span>
                  <span>{{ calendarData.organizerName }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense class="cr_cal_row">
              <!-- 초대 응답 -->
              <v-list-item-content
                class="pa-0"
                v-if="
                  calendarData.calMethod === 'REQUEST' && calendarData.isExist
                "
              >
                <v-btn-toggle v-model="partStat" mandatory color="primary">
                  <v-btn
                    small
                    outlined
                    class="px-6 py-4"
                    :value="partStats.accept"
                    @click="sendCalReply(partStats.accept)"
                    v-text="$t('common.수락')"
                  >
                  </v-btn>

                  <v-btn
                    small
                    outlined
                    class="px-6 py-4"
                    :value="partStats.decline"
                    @click="sendCalReply(partStats.decline)"
                    v-text="$t('common.거절')"
                  >
                  </v-btn>

                  <v-btn
                    small
                    outlined
                    class="px-6 py-4"
                    :value="partStats.tentative"
                    @click="sendCalReply(partStats.tentative)"
                    v-text="$t('common.미정')"
                  >
                  </v-btn>
                  <v-btn
                    small
                    outlined
                    class="px-6 py-4"
                    :value="partStats.needsAction"
                    v-show="false"
                    v-text="$t('mail.138')"
                  >
                  </v-btn>
                </v-btn-toggle>
              </v-list-item-content>

              <!-- 수정 요청 -->
              <v-list-item-content
                class="pa-0"
                v-else-if="
                  calendarData.calMethod === 'COUNTER' && calendarData.isExist
                "
              >
                <v-btn-toggle v-model="partStat" mandatory>
                  <v-btn
                    :value="partStats.accept"
                    small
                    @click="sendCounterReply(partStats.accept)"
                    v-text="$t('common.수락')"
                  >
                  </v-btn>
                  <v-btn
                    :value="partStats.decline"
                    small
                    @click="sendCounterReply(partStats.decline)"
                    v-text="$t('common.거절')"
                  >
                  </v-btn>
                  <v-btn
                    :value="partStats.needsAction"
                    small
                    v-show="false"
                    v-text="$t('mail.138')"
                  >
                  </v-btn>
                </v-btn-toggle>
              </v-list-item-content>

              <v-list-item-content v-else class="pa-0 cr_desc">
                {{ getDescription }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card
            elevation="0"
            class="d-flex align-content-center flex-wrap"
            v-else
            height="150"
          >
            <v-card elevation="0">
              {{ getDescription }}
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions } from "vuex";
import {
  sendCalReplyMail,
  sendCalCounterReplyMail
} from "@/calendar/api/cal.api";
import { getCalendarDataInMailView } from "@/mail/api/mail.api";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import moment from "moment";
const DAY_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT = "YYYY-MM-DD HH:mm";
export default {
  props: ["mailId"],
  data: () => ({
    calendarData: null,
    startDate: null,
    endDate: null,
    isAllDay: false,
    isShareCalendar: false,
    partStat: "NEEDS-ACTION",
    month: "",
    day: "",
    dayOfWeek: "",
    dateRange: "",
    summary: "",
    partStatStr: {
      "NEEDS-ACTION": i18n.t("mail.139"),
      ACCEPTED: i18n.t("common.수락"),
      DECLINED: i18n.t("common.거절"),
      TENTATIVE: i18n.t("common.미정")
    },
    partStats: CAL_CONSTANTS.ATTENDEE_PARTSTAT
  }),
  async created() {
    await this.initData();
  },
  computed: {
    getStartDateText() {
      if (this.startDate == null) return "";
      return this.isAllDay
        ? this.startDate.format(DAY_FORMAT)
        : this.startDate.format(DATE_FORMAT);
    },
    getEndDateText() {
      if (this.endDate == null) return "";
      return this.isAllDay
        ? moment(this.endDate)
            .subtract(1, "days")
            .format(DAY_FORMAT)
        : this.endDate.format(DATE_FORMAT);
    },
    getDescription() {
      let description = "";
      switch (this.calendarData.calMethod) {
        case "REPLY":
          description = i18n.t("mail.140", {
            attendee: this.calendarData.attendeeName,
            action: this.partStatStr[this.calendarData.partStat]
          });
          break;
        case "DECLINECOUNTER":
          description = i18n.t("mail.141", {
            value: this.calendarData.organizerName
          });
          break;
        case "CANCEL":
          description = i18n.t("mail.142", {
            value: this.calendarData.organizerName
          });
          break;
        case "SHARE":
          description = i18n.t("mail.143", {
            value: this.calendarData.organizerName
          });
          break;
        default:
          if (!this.calendarData.isExist) {
            description = i18n.t("mail.144");
          }
          break;
      }
      return description;
    },
    getType() {
      let typeText = "";
      switch (this.calendarData.calMethod) {
        case "REPLY":
          typeText = i18n.t("mail.145");
          break;
        case "REQUEST":
          typeText = i18n.t("mail.146");
          break;
        case "CANCEL":
          typeText = i18n.t("mail.147");
          break;
        case "COUNTER":
          typeText = i18n.t("mail.148");
          break;
        case "DECLINECOUNTER":
          typeText = i18n.t("mail.149");
          break;
        default:
          break;
      }
      return typeText;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    async initData() {
      const response = await getCalendarDataInMailView({
        mailId: this.mailId
      });

      if (response.status == 200) {
        const calData = response.data;
        this.calendarData = calData;
        this.startDate = moment(calData.startDt);
        this.endDate = moment(calData.endDt);
        this.partStat = calData.partStat;
        this.isAllDay = calData.isAllDay;
      }
    },
    async sendCalReply(replyType) {
      if (replyType === this.partStat) return;

      const param = {
        iCal: this.calendarData.iCal,
        partStat: replyType,
        mailId: this.mailId,
        organizerAdr: this.calendarData.organizerAdr
      };
      const response = await sendCalReplyMail(param);
      let message = i18n.t("mail.150");
      let type = "SUCCESS";
      if (response.status != 200) {
        message = i18n.t("mail.151");
        type = "ERROR";
      }
      this.openSnackbar({
        message: message,
        type: type
      });
    },
    async sendCounterReply(partStat) {
      if (this.partStat === partStat) {
        return;
      }
      const param = {
        counterEmail: this.calendarData.attendeeAdr,
        mailId: this.mailId,
        partStat: partStat
      };
      const response = await sendCalCounterReplyMail(param);
      let message = i18n.t("mail.150");
      let type = "SUCCESS";
      if (response.status != 200) {
        message = i18n.t("mail.151");
        type = "ERROR";
      }
      this.openSnackbar({
        message: message,
        type: type
      });
    },
    getDayOfWeek(date) {
      return date.format("dddd");
    },
    getMonth(date) {
      return date.format("MMM");
    },
    getDate(date) {
      return date.format("DD");
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-cal-container {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.cr-cal-content-container ::v-deep {
  .cr_label {
    display: inline-block;
    min-width: 50px;
  }
  .cr_cal_row {
    min-height: 24px;
  }
  .cr_desc {
    font-size: 13px;
  }
}
.cr-cal-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.cal_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 99px;
  width: 70px;
  text-align: center;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 #c8c8c8;
}
.cal-month {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: #5270cf;
  padding: 1px 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: -1px;
  position: relative;
}
.cal-date {
  font-size: 12px;
  text-align: center;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0px 0px 2px;
  strong {
    font-size: 28px;
  }
  span {
    display: block;
    font-size: 14px;
    border-top: 1px dotted #ddd;
    margin: 0px 6px;
  }
}
</style>
