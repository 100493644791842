<template>
  <!-- TODO: 작은화면 UI변경 필요 -->
  <div class="d-flex" style="min-width: 950px;">
    <v-list class="cr-drive-list" :class="{ 'pt-3': !loading }">
      <v-progress-linear v-show="loading" indeterminate color="primary" />

      <BackButton :folder="currentFolder" :loading="loading" @back="back" />
      <ListItem
        v-for="item in list"
        :key="item.id"
        :item="item"
        :loading="loading"
        :selectedFiles.sync="selectedFiles"
        @click="click"
      />
    </v-list>
    <SelectBox
      :selectedFiles.sync="selectedFiles"
      :totalFileCnt="totalFileCnt"
      :totalTempSize="totalTempSize"
      :totalLargeTempSize="totalLargeTempSize"
      @chagneType="chagneType"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-drive-list {
  width: 450px;
  min-width: 450px;
  min-height: 600px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Vue from "vue";
import ListItem from "./drive/ListItem.vue";
import BackButton from "./drive/BackButton.vue";
import SelectBox from "./drive/SelectBox.vue";
import {
  getFiles,
  getRootFolder,
  getDriveList,
  getDriveFolderById
} from "@/drive/api/drive.api";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  components: { ListItem, BackButton, SelectBox },
  async mounted() {
    const { status, data } = await getRootFolder();
    if (status !== 200) return this.serverError();

    const fileName = this.$t("mail.128");
    this.currentFolder = { ...data, fileName };
    this.getList(1);

    // type 8 이고 ids 존재한다면 파일조회
    const { actionObj } = this.getRouteWriteInfo;
    const { t: type, is: fileIds } = actionObj;
    if (parseInt(type) == 8 && fileIds?.length) {
      // is에 파일들이 이미 첨부되어잇으면 제외
      const attached = this.attachFiles.filter(f => fileIds.indexOf(f?.id));
      if (attached.length) return;

      const { data, status } = await getFiles({ fileIds });
      if (status !== 200) return;

      data?.forEach(f => (this.selectedFiles = f));
    }
  },
  data() {
    return {
      list: [],
      selectedList: [],
      currentFolder: {},

      // 리스트 정보
      loading: true,
      page: 0,
      pageSize: 20,
      blockSize: 3,
      totalElements: 0,
      totalPages: 0
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteWriteInfo"]),
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),
    ...mapGetters("mailFile", {
      attachFiles: "items",
      totalTS: "totalTempSize",
      totalLTS: "totalLargeTempSize"
    }),
    totalFileCnt() {
      return this.attachFiles.length + this.selectedList.length;
    },
    totalTempSize() {
      return (
        this.selectedFiles.reduce(
          (p, i) => p + (i.uploadType !== "TEMP_FILE" ? 0 : i.fileSize),
          0
        ) + this.totalTS
      );
    },
    totalLargeTempSize() {
      return (
        this.selectedFiles.reduce(
          (p, i) => p + (i.uploadType === "TEMP_FILE" ? 0 : i.fileSize),
          0
        ) + this.totalLTS
      );
    },
    selectedFiles: {
      get() {
        return this.selectedList;
      },
      set(file) {
        let message = this.$t("mail.130");
        const { id, fileSize } = file;
        const idx = this.selectedList.findIndex(f => f.id == id);
        if (idx !== -1) return this.selectedList.splice(idx, 1);

        // 개수 확인
        if (this.totalFileCnt > 19) {
          return this.openSnackbar({ message, type: "ERROR" });
        }

        // 첨부파일 최대 용량 확인
        let uploadType = "TEMP_FILE";
        const totalTempSize = this.totalTempSize + fileSize;
        const totalLargeTempSize = this.totalLargeTempSize + fileSize;
        const { largeFileMaxSize: max } = this.getMailServiceConfig;

        if (totalTempSize > 20971520 || fileSize > 20971520) {
          uploadType = "LARGE_TEMP_FILE";
          if (totalLargeTempSize > max) {
            message = this.$t("mail.131");
            return this.openSnackbar({ message, type: "ERROR" });
          }
        }

        this.selectedList.push({ ...file, uploadType });
      }
    }
  },
  watch: {
    currentFolder({ fileName }) {
      this.SET_HEADLINE(fileName);
    },
    async confirm(next) {
      if (
        !next ||
        (await this.checkSystem("domain")) ||
        (await this.checkSystem("quota"))
      ) {
        this.$emit("update:confirm", false);
        return;
      }

      const result = await this.uploadFromDrive(this.selectedFiles);
      if (result) return this.CLOSE_DIALOG();
      this.$emit("update:confirm", false);
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG", "SET_HEADLINE"]),
    ...mapActions("mailFile", ["uploadFromDrive"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("auth", ["checkSystem"]),
    serverError() {
      this.openSnackbar({ message: this.$t("common.7"), type: "ERROR" });
      this.CLOSE_DIALOG();
    },

    /**
     * 선택된 파일 업로드 타입 변경
     */
    chagneType(file) {
      let message = this.$t("common.43");
      const { id, uploadType: uType, fileSize } = file;
      const idx = this.selectedList.findIndex(f => f.id === id);
      if (idx === -1) return this.openSnackbar({ message, type: "ERROR" });

      let uploadType = "TEMP_FILE";
      if (uType === "TEMP_FILE") {
        uploadType = "LARGE_TEMP_FILE";
        const { largeFileMaxSize: max } = this.getMailServiceConfig;

        if (this.totalLargeTempSize + fileSize > max) {
          message = this.$t("mail.416");
          return this.openSnackbar({ message, type: "ERROR" });
        }
      } else if (this.totalTempSize + fileSize > 20971520) {
        message = this.$t("mail.417");
        return this.openSnackbar({ message, type: "ERROR" });
      }

      Vue.set(this.selectedList, idx, { ...file, uploadType });
    },
    /**
     * 목록 클릭
     */
    async click(item) {
      const { fileType } = item;
      if (fileType === "FILE") {
        this.selectedFiles = item;
      }

      if (fileType === "DIRECTORY") {
        this.loading = true;
        this.currentFolder = item;
        this.list = [];
        this.getList(1);
      }
    },
    /**
     * 상위 드라이브 폴더로 이동
     */
    async back() {
      this.loading = true;
      this.list = [];

      const { parentId } = this.currentFolder;
      const { data, status } = await getDriveFolderById(parentId);
      if (status !== 200) return this.serverError();

      this.currentFolder = data;
      this.getList(1);
    },
    /**
     * 드라이브 목록 가져오기
     */
    async getList(page) {
      const { pageSize } = this;
      const { id: folderId } = this.currentFolder;

      const params = { folderId, pageSize, page: page - 1 };
      const { status, data } = await getDriveList(params);
      if (status !== 200) return this.serverError();

      this.list = data.content;
      this.loading = false;
    }
  }
};
</script>
