<template>
  <div
    class="d-flex pt-3 align-center justify-space-between cr-expansion-title"
  >
    <div class="d-flex align-center">
      <div class="d-flex cr-subject pr-1" v-highlight="subject" />
      <PopupViewBtn
        v-if="showPopupViewBtn"
        :item="getBasedMail"
        :isView="true"
      />
    </div>

    <div class="d-flex justify-end cr-view-print-hide">
      <!-- 다음 메일 보기 버튼 -->
      <Btn
        v-if="showPrevNextBtn"
        icon="mdi-chevron-up"
        :tooltip="$t('mail.55')"
        :disabled="disabledNextBtn"
        @click:action="nextMail"
      />

      <!-- 이전 메일 보기 버튼 -->
      <Btn
        v-if="showPrevNextBtn"
        class="mr-1"
        icon="mdi-chevron-down"
        :tooltip="$t('mail.56')"
        :disabled="disabledPrevBtn"
        @click:action="prevMail"
      />

      <!-- 모든 메일 패널 열기 -->
      <Btn
        v-if="getViewMails.length > 1"
        :icon="`mdi-${isAllExpansion ? 'minus' : 'plus'}-box-multiple-outline`"
        :tooltip="isAllExpansion ? $t('mail.58') : $t('mail.57')"
        @click:action="allExpansion"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-expansion-title {
  min-height: 40px;
  padding-left: 5px;

  .cr-subject {
    line-height: 1.4;
    font-size: 18px;
    font-weight: 600;
    color: #323130;
    user-select: text;
  }

  .cr-expansion-all-btn {
    position: absolute;
    right: 0px;
    margin: 8px 0px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Btn from "./Btn";
import PopupViewBtn from "../../list/td/PopupViewBtn.vue";

export default {
  components: { Btn, PopupViewBtn },
  props: {
    panel: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("mail", ["getMails", "getTotalPages"]),
    ...mapGetters("mailView", ["getViewMails", "getBasedMail"]),
    ...mapGetters("mailRoute", ["getRouteListInfo", "getRouteViewInfo"]),
    subject() {
      return this.getBasedMail?.subject || "";
    },
    isAllExpansion() {
      return this.panel.length === this.getViewMails.length;
    },
    showPopupViewBtn() {
      return !this.$route.name.includes("popup_mail");
    },
    showPrevNextBtn() {
      if (!this.getMails.length) return false;

      const { folderId: listFolderId, page: listPage } = this.getRouteListInfo;
      const { folderId: viewFolderId, page: viewPage } = this.getRouteViewInfo;
      return listFolderId == viewFolderId && listPage == viewPage;
    },
    disabledNextBtn() {
      const { mailId } = this.getBasedMail;
      const { page } = this.getRouteListInfo;
      const idx = this.getMails.findIndex(m => m.mailId == mailId);

      return page == 1 && idx == 0;
    },
    disabledPrevBtn() {
      const { mailId } = this.getBasedMail;
      const { page } = this.getRouteListInfo;
      const idx = this.getMails.findIndex(m => m.mailId == mailId);

      return page == this.getTotalPages && idx + 1 == this.getMails.length;
    }
  },
  methods: {
    ...mapActions("mailView", ["prevMail", "nextMail", "getMailView"]),
    allExpansion() {
      let panel = [];
      if (!this.isAllExpansion) {
        this.getViewMails.forEach(m => {
          if (m.content) return;
          this.getMailView(m.mailId);
        });

        panel = this.getViewMails.map((e, idx) => idx);
      }

      this.$emit("update:panel", panel);
    }
  }
};
</script>
