<template>
  <div class="cr-envelope-container">
    <div class="d-flex align-center">
      <v-subheader>
        {{ $t("mail.102") }}
      </v-subheader>

      <v-btn v-show="isNotEmptyFiles" icon x-small @click="expandClick">
        <v-icon>
          {{ showAttachList ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </div>

    <div class="cr-input-wrapper">
      <DraggableArea
        :hover="hover"
        @drop="drop"
        @dragenter="dragenter"
        @dragleave="dragleave"
        @addFiles="files => setFiles(files)"
      />
      <AttachInfo v-if="showAttachInfo" />
      <AttachList
        v-show="isNotEmptyFiles && showAttachList"
        ref="list"
        @drop="drop"
        @dragenter="dragenter"
        @dragleave="dragleave"
      />
      <FileUploader />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import FileUploader from "./FileUploader";
import DraggableArea from "./draggable";
import AttachInfo from "./draggable/AttachInfo.vue";
import AttachList from "./list";

export default {
  components: { DraggableArea, AttachInfo, AttachList, FileUploader },
  mounted() {
    this.SET_SHOW_ATTACH_LIST(this.isNotEmptyFiles);
  },
  data() {
    return { counter: 0, hover: false };
  },
  computed: {
    ...mapGetters("mailFile", ["isNotEmptyFiles"]),
    ...mapGetters("mailCompose", ["showAttachList"]),
    showAttachInfo() {
      const { smAndDown } = this.$vuetify.breakpoint;
      return this.isNotEmptyFiles && this.showAttachList && smAndDown;
    }
  },
  watch: {
    isNotEmptyFiles(isNotEmptyFiles) {
      this.SET_SHOW_ATTACH_LIST(isNotEmptyFiles);
    }
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_SHOW_ATTACH_LIST"]),
    ...mapActions("mailFile", ["setFiles"]),
    // 파일 드래그앤드랍 부분
    drop(e) {
      e.preventDefault();

      this.counter = 0;
      this.hover = false;

      if (!e.dataTransfer.files) return;
      this.setFiles(e.dataTransfer.files);
    },
    dragenter(e) {
      e.preventDefault();

      this.counter += 1;
      this.hover = true;
    },
    dragleave(e) {
      e.preventDefault();

      this.counter -= 1;
      if (this.counter === 0) {
        this.hover = false;
      }
    },
    expandClick() {
      this.SET_SHOW_ATTACH_LIST(!this.showAttachList);
    }
  }
};
</script>
