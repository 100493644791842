<template>
  <div>
    <div class="pt-1">
      <span>{{ $t("mail.261", { value: item.fetchServerType }) }}:</span>
      <span :class="cls[item.fetchStatus]">
        {{ $t(`mail.${status[item.fetchStatus]}`) }}
      </span>
    </div>
    <div v-if="item.useSmtp == 1" class="pb-1">
      <span>{{ $t("mail.262") }}:</span>
      <span :class="cls[item.smtpStatus]">
        {{ $t(`mail.${status[item.smtpStatus]}`) }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* 경고 */
.warning-text {
  color: red;
  font-weight: bold;
}
</style>

<script>
export default {
  props: { item: { type: Object, default: () => ({}) } },
  data() {
    return {
      cls: {
        SUCCEEDED_CERTIFICATE: "warning-text primary--text",
        FAILED_CERTIFICATE: "warning-text"
      },
      status: {
        SUCCEEDED_CERTIFICATE: 270,
        FAILED_CERTIFICATE: 271,
        PROGRESS_FETCH: 272,
        COMPLETE_FETCH: 273
      }
    };
  }
};
</script>
