<template>
  <v-menu offset-y v-model="showMenu" min-width="320" width="320">
    <template v-slot:activator="{ attrs }">
      <Activator
        v-bind="{ ...attrs, ...$props }"
        :class="classStr"
        :style="styleStr"
        :fullEmail="fullEmail"
        :displayEmail="displayEmail"
        :showMenu.sync="showMenu"
        :activatorWidth.sync="activatorWidth"
      />
    </template>

    <MenuList
      style="width:320px;"
      v-bind="$props"
      :fullEmail="fullEmail"
      :displayEmail="displayEmail"
      :showMenu.sync="showMenu"
    />
  </v-menu>
</template>

<script>
import Activator from "./Activator.vue";
import MenuList from "./MenuList.vue";

export default {
  components: { Activator, MenuList },
  props: {
    cls: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "list"
    },
    address: {
      type: String,
      default: ""
    },
    personal: {
      type: String,
      default: ""
    },
    countryCode: {
      type: String,
      default: ""
    },
    ruleSelection: {
      type: String,
      default: "from"
    },
    propStyle: {
      type: String,
      default: ""
    },
    readStatus: {
      type: Number,
      default: 0
    }
  },
  data() {
    return { showMenu: false, activatorWidth: 0 };
  },
  computed: {
    fullEmail() {
      const { personal, address } = this;
      if (!personal) return address;
      return `${personal} <${address}>`;
    },
    displayEmail() {
      if (this.type == "view") return this.fullEmail;
      return this.personal || this.address;
    },
    classStr() {
      let cls = "d-flex align-center ";
      if (this.cls) cls += this.cls;
      return cls;
    },
    styleStr() {
      let style = "line-height: 1.5; min-width: 0px; ";
      if (this.type == "view") style += "user-select: text; ";
      if (this.propStyle) style += this.propStyle;
      return style;
    }
  }
};
</script>
