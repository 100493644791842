<template>
  <div class="cr-wrapper write" v-resize="resize">
    <Notice v-if="!showSendResult" :showNotice="showNotice" />
    <ViewLoader v-if="loading" style="height: 100%;" />
    <WriteToolbarModal />

    <SendResult v-if="showSendResult" />
    <Write
      v-else
      ref="write"
      :showNotice="showNotice"
      :tabSize.sync="tabSize"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Write from "./write";
import Notice from "./write/notice";
import ViewLoader from "./view/ViewLoader.vue";
import SendResult from "./write/result";
import WriteToolbarModal from "./write/modal";

export default {
  components: {
    Write,
    Notice,
    ViewLoader,
    SendResult,
    WriteToolbarModal
  },
  async mounted() {
    const { name } = this.$route;
    if (!name.includes("mail_write")) return;
    await this.initCompose();
  },
  data() {
    return { tabSize: 0 };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteWriteInfo"]),
    ...mapGetters("mailConfig", ["getUseTab", "getAutoSave"]),
    ...mapGetters("mailLayout", ["showSearchDetail", "showConfigDrawer"]),
    ...mapGetters("mailCompose", [
      "loading",
      "isReserve",
      "isSecure",
      "isWriteToMe",
      "MWEObserver",
      "showSendResult"
    ]),
    showNotice() {
      const { actionObj } = this.getRouteWriteInfo;
      const { t: type } = actionObj;
      return (this.getAutoSave && type < 7) || this.isReserve || this.isSecure;
    }
  },
  watch: {
    $route({ name }) {
      if (name !== "mail_write") return;
      this.resize();
    },
    // 탭 사용 여부에 따른 에디터 크기조절
    getUseTab(useTab) {
      // 탭 사용 여부 변경시 리사이즈 함수에서 크기측정이 정확하지않아 여기서 설정해준다.
      this.tabSize = useTab ? -40 : 40;
      this.resize();
    },
    // 자동 저장
    getAutoSave() {
      this.resize();
    },
    // 상세검색
    showSearchDetail() {
      this.resize();
    },
    // 환경설정
    showConfigDrawer() {
      this.resize();
    },
    /**
     * 받는사람, 참조, 숨은참조, 숨은참조보기,
     * 첨부파일목록보기, 예약메일알림, 제목만보기
     */
    MWEObserver() {
      this.resize();
    },
    isWriteToMe() {
      this.resize();
    }
  },
  methods: {
    ...mapActions("mailCompose", ["initCompose"]),
    resize() {
      if (!this.$refs.write) return;
      this.$refs.write.resetEditorHeight();
    }
  }
};
</script>
