<template>
  <v-card elevation="0">
    <v-tabs v-model="tab" :height="getUseTab ? 40 : 0">
      <Tab v-for="t in tabs" :key="t.tabId" :tab="t" @tabClick="tabClick" />
    </v-tabs>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card::v-deep {
  .v-tabs .v-tabs-bar .v-slide-group__wrapper {
    .v-slide-group__content .v-tabs-slider-wrapper {
      width: 200px !important;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Tab from "./Tab.vue";

export default {
  components: { Tab },
  computed: {
    ...mapGetters("mailConfig", ["getUseTab", "getSplitView"]),
    ...mapGetters("mailTab", ["tabs", "selectedTab", "moveToTabId"]),
    ...mapGetters("mailRoute", [
      "getRouteListInfo",
      "getRouteWriteInfo",
      "getRouteViewInfo",
      "getRouteConfigInfo"
    ]),
    tab: {
      get() {
        return this.selectedTab;
      },
      set() {}
    }
  },
  watch: {
    tabs(nTabs, pTabs) {
      // 탭 변경시 메일보기 탭 삭제되면 메일보기 정보도 삭제
      const nIdx = nTabs.findIndex(t => t.tabId === "view");
      const pIdx = pTabs.findIndex(t => t.tabId === "view");
      if (pIdx >= 0 && nIdx == -1) {
        this.RESET_ROUTE_VIEW();
        this.RESET_VIEW_STATE();
      }

      // 바로 앞 탭으로 라우팅
      if (this.moveToTabId) {
        this.tabClick(this.moveToTabId);
        this.SET_MOVE_TO_TAB_ID("");
      }
    }
  },
  methods: {
    ...mapMutations("mailTab", ["SET_TAB", "SET_MOVE_TO_TAB_ID"]),
    ...mapMutations("mailView", ["RESET_VIEW_STATE"]),
    ...mapMutations("mailRoute", ["SET_IS_TAB", "RESET_ROUTE_VIEW"]),
    ...mapActions("mailRoute", [
      "redirectMailList",
      "routeMailView",
      "routeMailWrite",
      "routeMailConfig"
    ]),
    tabClick(tabId) {
      this.SET_IS_TAB(true);
      const { name: currentRouteName } = this.$route;
      const { folderId, page } = this.getRouteListInfo;

      if (tabId === "list") {
        // 탭 사용 안하고 현재 메일쓰기화면에서 이함수로 목록으로 갈때
        if (!this.getUseTab && currentRouteName !== "mail_view") {
          this.SET_TAB(this.tabs.at(-1));
          this.SET_IS_TAB(false);
          return;
        }

        // 메일보기, 쓰기, 설정
        this.redirectMailList();
      }

      if (tabId === "write") {
        this.routeMailWrite({});
      }

      if (tabId === "view") {
        const { actionObj } = this.getRouteViewInfo;
        const { basedId, selectedDataId } = actionObj.view;
        this.routeMailView({ folderId, page, basedId, selectedDataId });
      }

      if (tabId === "config") {
        const { tabId } = this.getRouteConfigInfo;
        this.routeMailConfig({ folderId, page, tabId });
      }
    }
  }
};
</script>
