<template>
  <div>
    <v-row class="ma-0 cr-preview-box">
      <v-col class="pa-0" cols="12">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-bold">{{ sign.title }}</span>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="priview" v-html="sign.contents"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapMutations } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data(vue) {
    const getters = vue.$store.getters;
    const { sign = {} } = getters["mailDialog/params"];

    return {
      sign
    };
  },
  watch: {
    async confirm(n) {
      if (n) {
        this.SET_DIALOG({
          headline: i18n.t("mail.230"),
          show: true,
          type: "signEdit",
          btnTitles: { confirm: i18n.t("common.확인") }
        });
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"])
  }
};
</script>
<style lang="scss" scoped>
.cr-preview-box {
  min-width: 350px;
  .priview ::v-deep p {
    padding: 0px;
    margin: 0px;
  }
}
</style>
