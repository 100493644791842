var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-2 pa-2 pb-0 cr-external-wrapper"},[_c('TableHeaderVue',{attrs:{"selectedExternal":_vm.selectedExternal},on:{"update:selectedExternal":function($event){_vm.selectedExternal=$event},"update:selected-external":function($event){_vm.selectedExternal=$event}}}),_c('v-data-table',{ref:"tableRef",attrs:{"show-select":"","hide-default-footer":"","loading":_vm.externalLoading,"headers":_vm.headers,"items":_vm.externalList,"items-per-page":100,"item-class":function () { return 'item-row'; }},on:{"click:row":function (r, ref) {
	var select = ref.select;
	var isSelected = ref.isSelected;

	return select(!isSelected);
}},scopedSlots:_vm._u([{key:"item.serverName",fn:function(ref){
var item = ref.item;
return [_c('ServerName',{attrs:{"item":item}})]}},{key:"item.nameFolder",fn:function(ref){
var item = ref.item;
return [_c('NameFolder',{attrs:{"item":item}})]}},{key:"item.fetchStatus",fn:function(ref){
var item = ref.item;
return [_c('Status',{attrs:{"item":item}})]}},{key:"item.fetchDate",fn:function(ref){
var item = ref.item;
return [_c('FetchDate',{attrs:{"item":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Actions',{attrs:{"item":item}})]}}],null,true),model:{value:(_vm.selectedExternal),callback:function ($$v) {_vm.selectedExternal=$$v},expression:"selectedExternal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }