<template>
  <v-row no-gutters class="mt-3 pb-2" style="max-width: 1029px;">
    <v-col class="d-flex">
      <!-- 삭제 -->
      <v-btn
        class="mr-1"
        color="red"
        outlined
        :disabled="disabled"
        @click="deleteExternalMail(externalIds)"
      >
        {{ $t("common.삭제") }}
      </v-btn>
      <!-- 가져오기 -->
      <v-btn
        color="primary"
        outlined
        :disabled="disabled"
        @click="fetchExternalMail(externalIds)"
      >
        {{ $t("mail.259") }}
      </v-btn>
      <v-spacer />
      <!-- 외부메일 등록 -->
      <v-btn text outlined :disabled="registDisabled" @click="openAddExternal">
        {{ $t("mail.260") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.v-btn.v-btn--outlined {
  border-radius: 0px;
  border-color: rgba(0, 0, 0, 0.12);
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    selectedExternal: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("mailConfigExternal", ["externalTotalElements"]),
    disabled() {
      return this.selectedExternal.length === 0;
    },
    registDisabled() {
      return this.externalTotalElements >= 100;
    },
    externalIds() {
      return this.selectedExternal.map(({ id }) => id);
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mailConfigExternal", [
      "deleteExternalMail",
      "fetchExternalMail"
    ]),
    openAddExternal() {
      this.SET_DIALOG({
        show: true,
        headline: this.$t("mail.260"),
        type: "externalMail"
      });
    }
  }
};
</script>
