<template>
  <span class="grey--text">
    {{ size }}
  </span>
</template>

<script>
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    size() {
      const { size = 0 } = this.item;
      return byteCalculation(size);
    }
  }
};
</script>
