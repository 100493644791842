<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <!-- 메일 첫 화면 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.60") }}
      </v-col>

      <v-col cols="12" sm="8">
        <MailboxSelect
          style="max-width: 400px;"
          :folderId="config.firstScreenFolderId"
          @change="changeFirstScreen"
        />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 메일 목록 개수 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.61") }}
      </v-col>
      <v-col cols="12" sm="8">
        <v-select
          style="max-width: 400px;"
          dense
          outlined
          hide-details
          ref="pageSizeRef"
          item-text="text"
          item-value="value"
          :menu-props="{ 'offset-y': true, closeOnClick: true }"
          :items="pageSizeList"
          v-model="config.pageSize"
          @click:append.stop="$refs.pageSizeRef.activateMenu()"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 목록/본문 좌우 분할 보기 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.242") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.splitView" />
      </v-col>

      <v-col
        v-if="config.splitView == 0"
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.64") }}
      </v-col>
      <v-col
        v-if="config.splitView == 0"
        cols="12"
        sm="8"
        class="cr-swtich-wrapper"
      >
        <v-switch hide-details inset v-model="config.popupRead" />
      </v-col>

      <v-col
        v-if="config.splitView == 1"
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.65") }}
      </v-col>
      <v-col
        cols="12"
        sm="8"
        v-if="config.splitView == 1"
        class="cr-swtich-wrapper"
      >
        <v-switch hide-details inset v-model="config.showFirstMail" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 퀵메뉴 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.243") }}
      </v-col>
      <v-col cols="12" sm="8" class="pl-3 cr-swtich-wrapper">
        <div class="d-flex flex-wrap">
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.folder_IMPORTANT')"
            :value="quickMenuEnum.IMPORTANT"
            v-model="config.quickMenu"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.folder_UNREAD')"
            :value="quickMenuEnum.UNREAD"
            v-model="config.quickMenu"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.folder_READ')"
            :value="quickMenuEnum.READ"
            v-model="config.quickMenu"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.folder_ATTACH')"
            :value="quickMenuEnum.ATTACH"
            v-model="config.quickMenu"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.folder_EXCHANGED')"
            :value="quickMenuEnum.EXCHANGED"
            v-model="config.quickMenu"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.folder_INTERNAL')"
            :value="quickMenuEnum.INTERNAL"
            v-model="config.quickMenu"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0"
            :label="$t('mail.folder_CONTACT')"
            :value="quickMenuEnum.CONTACT"
            v-model="config.quickMenu"
          />
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 펼쳐보기 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.244") }}
      </v-col>
      <v-col cols="12" sm="8" class="pl-3 cr-swtich-wrapper">
        <div class="d-flex flex-wrap">
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.245')"
            :value="0"
            v-model="config.folderExpand"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0 mr-3"
            :label="$t('mail.246')"
            :value="1"
            v-model="config.folderExpand"
          />
          <v-checkbox
            hide-details
            class="ma-0 pa-0"
            :label="$t('mail.247')"
            :value="2"
            v-model="config.folderExpand"
          />
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 그룹보기 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.248") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.groupView" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 탭 사용 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.68") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.useTab" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 태그 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.247") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.useTag" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 메일 자동 태깅 -->
    <div v-if="config.useTag">
      <v-row class="ma-0">
        <v-col
          class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
          cols="12"
          sm="4"
        >
          {{ $t("mail.508") }}
        </v-col>
        <v-col cols="12" sm="8" class="cr-swtich-wrapper d-block">
          <v-switch hide-details inset v-model="config.autoTagging" />
          <div
            class="text-caption grey--text mt-2"
            v-html="$t('mail.509')"
          ></div>
        </v-col>
      </v-row>
      <v-divider />
    </div>
    <!-- 검색 편지함 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.246") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.useSearchFolder" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 공유 편지함 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.577") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.useShareFolder" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 제목 검색 조건 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.592") }}
      </v-col>
      <v-col cols="12" sm="8">
        <v-select
          style="max-width: 400px;"
          dense
          outlined
          hide-details
          item-text="text"
          item-value="value"
          :menu-props="{ 'offset-y': true, closeOnClick: true }"
          :items="subjectSearchOperatorList"
          v-model="config.subjectSearchOperator"
        ></v-select>
        <div class="mt-2 caption text--black">
          <div v-if="config.subjectSearchOperator === 0">
            {{ $t("mail.595") }}
            <br />
            {{ $t("mail.596") }}
          </div>
          <div v-if="config.subjectSearchOperator === 1">
            {{ $t("mail.597") }}
            <br />
            {{ $t("mail.598") }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>

<script>
import { quickMenuEnum } from "@/mail/constant/folderType";
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";
import i18n from "@/_locales";

export default {
  components: { MailboxSelect },
  props: {
    listConfig: {
      default: () => {},
      type: Object
    }
  },
  data: ({ listConfig }) => ({
    config: { ...listConfig },
    quickMenuEnum,
    pageSizeList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "30", value: 30 },
      { text: "40", value: 40 },
      { text: "50", value: 50 }
    ],
    subjectSearchOperatorList: [
      { text: i18n.t("mail.593"), value: 0 },
      { text: i18n.t("mail.594"), value: 1 }
    ]
  }),
  watch: {
    config: {
      deep: true,
      handler(config) {
        Object.keys(config).forEach(key => {
          if (typeof config[key] == "boolean") {
            config[key] = config[key] ? 1 : 0;
          }
        });

        this.$emit(
          "update:disabledBtnGroups",
          JSON.stringify(config) === JSON.stringify(this.listConfig)
        );
      }
    },
    listConfig() {
      this.reset();
    }
  },
  methods: {
    reset() {
      this.config = { ...this.listConfig };
    },
    changeFirstScreen(folder) {
      this.config.firstScreenFolderId = folder.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
}
.cr-config-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.v-data-table >>> tbody > tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}

.v-list-item > .v-list-item__content {
  padding: 12px 10px;
}
</style>
