<template>
  <tr
    class="item-row cr-base-row"
    :class="$vuetify.breakpoint.xs ? 'cr-mobile' : ''"
    @click="click"
  >
    <!-- 체크박스 -->
    <td class="text-start checkbox">
      <Checkbox :item="item" @selectItem="$emit('selectFunc', item)" />
    </td>
    <td class="text-start nameFolder">
      <div class="text-truncate">
        <v-icon v-if="!$vuetify.breakpoint.xs" class="mr-2">mdi-folder</v-icon>
        {{ item.nameFolder }}
      </div>
    </td>
    <td v-if="isSharedRoot" class="text-start owner">
      <div class="text-truncate">
        {{ item.owner }}
      </div>
    </td>
    <td v-if="isSharedRoot" class="text-start permission">
      <div>
        {{ permission }}
      </div>
    </td>
    <td v-else>
      <div class="d-flex justify-center">
        <v-btn
          small
          text
          min-width="40"
          class="px-1 mr-1"
          @click.stop="openShareDialog(item)"
        >
          {{ $t("common.수정") }}
        </v-btn>
        <v-btn
          small
          text
          min-width="40"
          class="px-1 mr-1"
          @click.stop="deleteShare(item)"
        >
          {{ $t("common.취소") }}
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
tr.cr-base-row {
  /* 마우스 오버 */
  &:hover {
    background-color: #efefef70 !important;
  }
  > td {
    &.text-start.checkbox {
      overflow: inherit;
      max-width: 38px;
      min-width: 38px;
      width: 38px;
    }
    &.text-start.owner {
      max-width: 200px;
      min-width: 200px;
      width: 200px;
    }
    &.text-start.nameFolder {
      max-width: 1px;
      width: 100%;
    }
  }
}

tr.cr-base-row.cr-mobile {
  /* 마우스 오버 */
  &:hover {
    background-color: #efefef70 !important;
  }
  > td {
    &.text-start.checkbox {
      overflow: inherit;
      max-width: 38px;
      min-width: 38px;
      width: 38px;
    }
    &.text-start.owner {
      max-width: 100px;
      min-width: 100px;
      width: 100px;
    }
    &.text-start.nameFolder {
      max-width: 1px;
      width: 100%;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Checkbox from "../td/Checkbox";

export default {
  components: {
    Checkbox
  },
  props: {
    item: {
      default: () => {},
      type: Object
    },
    parentItem: {
      default: () => {},
      type: Object
    },
    closedGroup: {
      default: () => [],
      type: Array
    },
    getSplitView: {
      default: 0,
      type: Number
    },
    isSent: {
      default: false,
      type: Boolean
    },
    isDrafts: {
      default: false,
      type: Boolean
    },
    isShareRoot: {
      default: false,
      type: Boolean
    },
    isShare: {
      default: false,
      type: Boolean
    },
    isSelected: {
      default: false,
      type: Boolean
    },
    isExpanded: {
      default: false,
      type: Boolean
    },
    isCreatedGroup: {
      default: false,
      type: Boolean
    },
    isExchange: {
      default: false,
      type: Boolean
    },
    exchangeCls: {
      default: "",
      type: String
    }
  },
  computed: {
    ...mapGetters("folder", ["isSharedRoot"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    permission() {
      switch (this.item.permission) {
        case 2:
          return this.$t("common.읽기");
        case 3:
          return this.$t("common.편집");
        default:
          return "";
      }
    }
  },
  methods: {
    ...mapMutations("mail", ["INIT_PAGE", "SET_SELECTED_MAILS"]),
    ...mapActions("share", ["shareDialog", "toDeleteShare"]),
    ...mapActions("mailRoute", ["routeMailList"]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    click() {
      const { id: sfi, userId: owi, permission: sp } = this.item;
      this.INIT_PAGE();

      if (!this.isSharedRoot) {
        this.routeMailList({ folderId: sfi, page: 1 });
        return;
      }
      this.routeMailList({
        ...this.getRouteListInfo,
        name: "mail_list_action",
        page: 1,
        actionObj: { sfi, owi, sp }
      });
    },
    // 공유 다이얼로그 열기
    openShareDialog(folder) {
      this.shareDialog({
        moduleType: "MAIL",
        shareItem: { id: folder.id, name: folder.nameFolder },
        sendMessage: false
      });
    },
    deleteShare() {
      this.confirm({
        message: "해당 메일함에 대한 모든 공유를 취소 하시겠습니까?",
        callback: async () => {
          const result = await this.toDeleteShare({
            objectIds: [this.item.id],
            moduleType: "MAIL"
          });
          if (result) {
            this.SET_SELECTED_MAILS([]);
            // 갱신
            this.$router.push(this.$router.currentRoute);
          }
        }
      });
    }
  }
};
</script>
