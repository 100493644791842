<template>
  <v-navigation-drawer
    absolute
    right
    v-if="drawer"
    v-model="drawer"
    :width="configDrawerWidth"
    class="cr-mail-setting"
    :class="{ 'cr-md-down': $vuetify.breakpoint.mdAndDown }"
  >
    <v-card class="mx-auto" :width="configDrawerWidth" height="100%">
      <div class="d-flex flex-row justify-space-between px-6 pt-4">
        <div class="font-weight-bold text-h6" v-text="$t('common.설정')"></div>
        <div>
          <v-btn icon @click="SET_SHOW_CONFIG_DRAWER(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <v-card-actions class="px-6">
        <v-btn
          depressed
          color="primary"
          style="flex:1"
          @click="
            routeMailConfig({ ...getRouteListInfo, tabId: 'list' });
            SET_SHOW_CONFIG_DRAWER(false);
          "
        >
          {{ $t("mail.69") }}
        </v-btn>
        <!-- 설정 마법사
        <v-btn
          depressed
          class="pa-0"
          style="min-width:46px;"
          @click="SET_SHOW_CONFIG_DRAWER(false)"
        >
          <v-icon>mdi-auto-fix</v-icon>
        </v-btn>
        -->
      </v-card-actions>
      <v-divider />

      <div style="height:calc(100% - 108px); overflow-y:auto;">
        <v-list-item>
          <v-list-item-content class="px-2">
            <v-list-item-title class="mb-2">
              {{ $t("mail.60") }}
            </v-list-item-title>
            <MailboxSelect
              width="271px"
              :folderId="getFirstScreenFolderId"
              @change="changeFirstScreen"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="px-2">
            <v-list-item-title class="mb-2">
              {{ $t("mail.61") }}
            </v-list-item-title>
            <v-select
              dense
              outlined
              hide-details
              ref="pageSizeRef"
              item-text="text"
              item-value="value"
              :items="pageSizeList"
              :menu-props="{ 'offset-y': true, closeOnClick: true }"
              v-model="pageSize"
              @change="updatePageSize"
              @click:append.stop="$refs.pageSizeRef.activateMenu()"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-4" />

        <v-list-item>
          <v-list-item-content class="px-2">
            <v-list-item-title class="mb-2">
              {{ $t("mail.62") }}
            </v-list-item-title>
            <v-select
              dense
              outlined
              hide-details
              ref="autoSaveRef"
              item-text="text"
              item-value="value"
              :items="autoSaveList"
              :menu-props="{ 'offset-y': true, closeOnClick: true }"
              v-model="autoSave"
              @change="updateAutoSave"
              @click:append.stop="$refs.autoSaveRef.activateMenu()"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-4" />

        <v-list-item>
          <v-list-item-content class="px-3">
            <v-switch
              class="mt-0"
              hide-details
              inset
              :label="$t('mail.63')"
              v-model="splitView"
            />
            <v-switch
              v-if="splitView == 0"
              hide-details
              inset
              :label="$t('mail.64')"
              v-model="popupRead"
            />
            <v-switch
              v-if="splitView == 1"
              hide-details
              inset
              :label="$t('mail.65')"
              v-model="showFirstMail"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-4" />

        <v-list-item>
          <v-list-item-content class="px-3">
            <v-switch
              class="mt-0"
              hide-details
              inset
              :label="$t('mail.66')"
              v-model="popupWrite"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-4" />

        <v-list-item>
          <v-list-item-content class="px-3">
            <v-switch
              class="mt-0"
              hide-details
              inset
              :label="$t('mail.67')"
              v-model="groupView"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-4" />

        <v-list-item>
          <v-list-item-content class="px-3">
            <v-switch
              class="mt-0"
              hide-details
              inset
              :label="$t('mail.68')"
              v-model="useTab"
            />
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.v-navigation-drawer.cr-mail-setting::v-deep {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 8px 0px 0px;
  &.cr-md-down {
    z-index: 3 !important;
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { virtualFolderConverter } from "@/mail/constant/folderType";
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";

export default {
  components: {
    MailboxSelect
  },
  data: () => ({
    autoSaveList: [
      { text: i18n.t("mail.70"), value: 0 },
      { text: i18n.t("mail.71"), value: 30 },
      { text: i18n.t("mail.72"), value: 60 },
      { text: i18n.t("mail.73"), value: 180 },
      { text: i18n.t("mail.74"), value: 300 }
    ],
    pageSizeList: [
      { text: "4", value: 4 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "30", value: 30 },
      { text: "40", value: 40 },
      { text: "50", value: 50 }
    ]
  }),
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailLayout", ["showConfigDrawer", "configDrawerWidth"]),
    ...mapGetters("mailConfig", [
      "getFirstScreenFolderId",
      "getPageSize",
      "getSplitView",
      "getPopupRead",
      "getShowFirstMail",
      "getPopupWrite",
      "getGroupView",
      "getUseTab",
      "getAutoSave"
    ]),
    drawer: {
      get() {
        return this.showConfigDrawer;
      },
      set(drawer) {
        this.SET_SHOW_CONFIG_DRAWER(drawer);
      }
    },
    pageSize: {
      get() {
        return this.getPageSize;
      },
      set(pageSize) {
        this.SET_MAIL_CONFIG({ pageSize: pageSize });
      }
    },
    autoSave: {
      get() {
        return this.getAutoSave;
      },
      set(autoSave) {
        this.SET_MAIL_CONFIG({ autoSave: autoSave });
      }
    },
    splitView: {
      get() {
        return this.getSplitView;
      },
      set() {
        this.updateSplitView();
      }
    },
    popupRead: {
      get() {
        return this.getPopupRead;
      },
      set() {
        this.updatePopupRead();
      }
    },
    showFirstMail: {
      get() {
        return this.getShowFirstMail;
      },
      set() {
        this.updateShowFirstMail();
      }
    },
    popupWrite: {
      get() {
        return this.getPopupWrite;
      },
      set() {
        this.updatePopupWrite();
      }
    },
    groupView: {
      get() {
        return this.getGroupView;
      },
      set() {
        this.updateGroupView();
      }
    },
    useTab: {
      get() {
        return this.getUseTab;
      },
      set() {
        this.updateUseTab();
      }
    }
  },
  methods: {
    ...mapMutations("mailLayout", ["SET_SHOW_CONFIG_DRAWER"]),
    ...mapMutations("mailConfig", ["SET_MAIL_CONFIG"]),
    ...mapActions("mailRoute", ["routeMailConfig"]),
    ...mapActions("mailConfig", [
      "updateFirstScreen",
      "updatePageSize",
      "updateSplitView",
      "updatePopupRead",
      "updateShowFirstMail",
      "updatePopupWrite",
      "updateGroupView",
      "updateUseTab",
      "updateAutoSave"
    ]),
    virtualFolderConverter(folderId) {
      return virtualFolderConverter(folderId);
    },
    async changeFirstScreen(folder) {
      await this.SET_MAIL_CONFIG({ firstScreenFolderId: folder.id });
      await this.updateFirstScreen();
    }
  }
};
</script>
