<template>
  <v-row>
    <v-col cols="12" sm="4" md="4" class="pa-1">
      <v-select
        dense
        outlined
        hide-details
        ref="periodRef"
        :label="$t('mail.기간')"
        v-model="periodSelectItem"
        :items="periodSelectItems"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        @click:append.stop="$refs.periodRef.activateMenu()"
      />
    </v-col>
    <v-col cols="12" sm="4" md="4" class="pa-1">
      <v-menu
        offset-y
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            hide-details
            readonly
            autocomplete="off"
            v-model="startDate"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="startDate" no-title scrollable color="primary">
          <v-spacer></v-spacer>
          <v-btn text outlined @click="startMenu = false">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn text outlined color="accent" @click="startDateClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="pa-1">
      <v-menu
        offset-y
        ref="endMenu"
        v-model="endMenu"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        min-width="auto"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            hide-details
            readonly
            autocomplete="off"
            v-model="endDate"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="endDate" no-title scrollable color="primary">
          <v-spacer></v-spacer>
          <v-btn text outlined @click="endMenu = false">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn text outlined color="accent" @click="endDateClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import i18n from "@/_locales";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  props: {
    timeRange: {
      default: () => [],
      type: Array
    }
  },
  mounted() {
    const [start, end] = this.timeRange;
    if (start) {
      this.startDate =
        getDateWithoutTime(new Date(start).getTime(), "YYYY-MM-DD") || "";
    }

    if (end) {
      this.endDate =
        getDateWithoutTime(new Date(end).getTime(), "YYYY-MM-DD") || "";
    }

    if ((start || end) && this.periodSelectItem == 0) {
      this.periodSelectItem = 6;
    } else if (!start && !end) {
      this.periodSelectItem = 0;
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      startMenu: false,
      endMenu: false,
      periodSelectItem: 0,
      periodSelectItems: [
        { text: i18n.t("mail.전체"), value: 0 },
        { text: i18n.t("mail.39"), value: 1 },
        { text: i18n.t("mail.40"), value: 2 },
        { text: i18n.t("mail.41"), value: 3 },
        { text: i18n.t("mail.42"), value: 4 },
        { text: i18n.t("mail.43"), value: 5 },
        { text: i18n.t("mail.32"), value: 6 }
      ]
    };
  },
  watch: {
    periodSelectItem(value, prevValue) {
      const date = new Date();
      const m = date.getMonth();

      switch (value) {
        // 1 주일
        case 1:
          date.setDate(date.getDate() - 7);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 1 개월
        case 2:
          date.setMonth(m - 1);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 3 개월
        case 3:
          date.setMonth(m - 3);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 6 개월
        case 4:
          date.setMonth(m - 6);
          if (date.getMonth() == m) date.setDate(0);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        // 1년
        case 5:
          date.setFullYear(date.getFullYear() - 1);
          this.startDate = getDateWithoutTime(date.getTime(), "YYYY-MM-DD");
          this.endDate = getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD");
          break;
        case 6:
          if (prevValue != 0) {
            this.startDate = "";
            this.endDate = "";
          }
          break;
        default:
          this.startDate = "";
          this.endDate = "";
          break;
      }
    }
  },
  methods: {
    startDateClick() {
      this.periodSelectItem = 6;
      const { startDate, endDate } = this;

      if (endDate && startDate > endDate) {
        this.startDate = endDate;
        this.$refs.startMenu.save(endDate);

        this.endDate = startDate;
        this.$refs.endMenu.save(startDate);
      } else {
        this.$refs.startMenu.save(startDate);
      }
    },
    endDateClick() {
      this.periodSelectItem = 6;
      const { startDate, endDate } = this;

      if (startDate && startDate > endDate) {
        this.startDate = endDate;
        this.$refs.startMenu.save(endDate);

        this.endDate = startDate;
        this.$refs.endMenu.save(startDate);
      } else {
        this.$refs.endMenu.save(endDate);
      }
    }
  }
};
</script>
