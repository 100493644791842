<template>
  <v-row class="ma-0" v-if="visible">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
      {{ $t("mail.334") }}
    </v-col>
    <v-col cols="12" sm="8" class="pl-0">
      <MailboxSelect
        ref="mailboxRef"
        :folderId="folderId"
        :useAddFolder="true"
        :showAllFolder="false"
        :showTOMEFolder="false"
        :showSENTFolder="false"
        :showDELETEDFolder="false"
        @change="change"
      />
    </v-col>
  </v-row>
</template>

<script>
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";

export default {
  components: { MailboxSelect },
  props: { item: { type: Object, default: () => ({}) } },
  data({ $store: { getters }, item: { folderId, fetchServerType } }) {
    return {
      folderId: folderId || getters["folder/getInboxId"],
      visible: fetchServerType === "IMAP" ? false : true
    };
  },
  methods: {
    change(folder) {
      if (!folder?.id) return;
      this.folderId = folder.id;
    }
  },
  watch: {
    item({ fetchServerType }) {
      this.visible = fetchServerType === "POP3";
    }
  }
};
</script>
