<template functional>
  <cr-fragment>
    <!-- 분류 row -->
    <!-- <component
      v-if="props.item.isCreatedDivision"
      :is="props.components.DivisionTr"
      v-bind="props"
      v-on="listeners"
    /> -->
    <!-- 그룹보기 row -->
    <component
      v-if="props.isCreatedGroup"
      :is="props.components.GroupTr"
      v-bind="props"
      v-on="listeners"
    />
    <component
      v-if="props.isShareRoot"
      :is="props.components.ShareTr"
      v-bind="props"
      v-on="listeners"
    />

    <!-- 기본 메일 row -->
    <component
      v-if="!props.isMinimum && !props.isShareRoot"
      v-bind="props"
      v-on="listeners"
      :is="props.components.BaseTr"
      :isSelected="listeners.isSelected(props.item)"
    />
    <!-- 기본 메일 주고받은 메일 row -->
    <cr-fragment v-if="!props.isMinimum && props.isExpanded && !props.isSent">
      <component
        v-bind="props"
        v-on="listeners"
        v-for="(i, idx) in props.item.exchange"
        :key="`${i.mailId}_${idx}_${i.mailId * idx}`"
        :is="props.components.BaseTr"
        :item="i"
        :parentItem="props.item"
        :isSelected="listeners.isSelected(i)"
        :isExchange="true"
        :exchangeCls="
          idx != props.item.exchange.length - 1 ? 'cr-exchange-row' : ''
        "
      />
    </cr-fragment>

    <!-- 최소 사이즈 row -->
    <component
      v-if="props.isMinimum && !props.isShareRoot"
      :is="props.components.MinimumTr"
      v-bind="props"
      v-on="listeners"
      :isSelected="listeners.isSelected(props.item)"
    />
    <!-- 최소 사이즈 주고받은 메일 row -->
    <cr-fragment v-if="props.isMinimum && props.isExpanded && !props.isSent">
      <component
        v-for="(i, idx) in props.item.exchange"
        :key="`${i.mailId}_${idx}_${i.mailId * idx}`"
        :is="props.components.MinimumTr"
        :item="i"
        :parentItem="props.item"
        :isExchange="true"
        :isSelected="listeners.isSelected(i)"
        :exchangeCls="
          idx != props.item.exchange.length - 1 ? 'cr-exchange-row' : ''
        "
        v-bind="props"
        v-on="listeners"
      />
    </cr-fragment>

    <!-- 수신확인 -->
    <cr-fragment v-if="props.isExpanded && props.isSent">
      <component
        :is="props.components.ReceiptDetail"
        :item="props.item"
        :key="`${props.item.mailId}_receipt`"
        v-bind="props"
        v-on="listeners"
      />
    </cr-fragment>
  </cr-fragment>
</template>

<script>
// import { mapGetters } from "vuex";
// tr
import GroupTr from "./GroupTr";
// import DivisionTr from "./DivisionTr";
import BaseTr from "./BaseTr";
import MinimumTr from "./MinimumTr";
import ShareTr from "./ShareTr";
import ReceiptDetail from "../ReceiptDetail";

export default {
  functional: true,
  props: {
    item: {
      default: () => {},
      type: Object,
      description: "메일데이터"
    },
    hoverItem: {
      default: () => {},
      type: Object,
      description: "hover된 메일데이터"
    },
    closedGroup: {
      default: () => [],
      type: Array,
      description: "닫힌 그룹 목록"
    },
    // closedDivision: {
    //   default: () => [],
    //   type: Array,
    //   description: "닫힌 분류 목록"
    // },
    getSplitView: {
      default: 0,
      type: Number,
      description: "분할화면 0: 목록만, 1: 좌우분할"
    },
    resizeWidth: {
      default: 0,
      type: Number,
      description: "좌우분할 목록 사이즈"
    },
    isSent: {
      default: false,
      type: Boolean,
      description: "현재 메일함이 보낸메일함인지 여부"
    },
    isDrafts: {
      default: false,
      type: Boolean,
      description: "현재 메일함이 임시저장함인지 여부"
    },
    isShareRoot: {
      default: false,
      type: Boolean
    },
    isShare: {
      default: false,
      type: Boolean,
      description: "현재 메일함이 공유 메일함인지 여부"
    },
    isSpam: {
      default: false,
      type: Boolean,
      description: "현재 메일함이 스팸 메일함인지 여부"
    },
    isMinimum: {
      default: false,
      type: Boolean,
      description: "목록이 최소사이즈인지 여부"
    },
    isExpanded: {
      default: false,
      type: Boolean,
      description: "row확장 여부"
    },
    isCreatedGroup: {
      default: false,
      type: Boolean,
      description: "그룹 row 생성 여부"
    },
    // isCreatedDivision: {
    //   default: false,
    //   type: Boolean,
    //   description: "분류 row 생성 여부"
    // },
    components: {
      type: Object,
      default() {
        return {
          GroupTr,
          // DivisionTr,
          BaseTr,
          MinimumTr,
          ShareTr,
          ReceiptDetail
        };
      }
    }
  },
  computed: {
    // ...mapGetters("folder", ["isSent", "isDrafts", "isShareRoot"])
  }
};
</script>
