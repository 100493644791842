<template>
  <!-- v-show="
      closedGroup.findIndex(
        g => g == (isExchange ? parentItem.group : item.group)
      ) == -1 &&
        closedDivision.findIndex(
          d => d == (isExchange ? parentItem.division : item.division)
        ) == -1
    " -->
  <tr
    v-show="
      closedGroup.findIndex(
        g => g == (isExchange ? parentItem.group : item.group)
      ) == -1
    "
    :class="getTrClass"
    :data-id="item.mailId"
    :data-dataId="item.dataId"
  >
    <!-- 체크박스 -->
    <td v-if="!isExchange" class="text-start checkbox">
      <Checkbox :item="item" @selectItem="$emit('selectFunc', item)" />
    </td>
    <!-- 타임라인 -->
    <td v-else class="text-start checkbox">
      <v-hover v-slot:default="{ hover }">
        <div style="position: relative;">
          <v-timeline
            :class="
              `${getSplitView == 0 ? 'pt-0' : ''}${
                hover || isSelected ? ' hover' : ''
              }`
            "
            dense
          >
            <v-timeline-item
              class="pb-0"
              :class="getSplitView == 1 ? 'cr-split' : ''"
              right
              small
              :color="isSelectedView ? 'primary' : 'grey'"
            />
          </v-timeline>
          <Checkbox
            v-if="hover || isSelected"
            :item="item"
            class="cr-exchange-checkbox"
            :class="getSplitView == 1 ? 'cr-split' : ''"
            @selectItem="$emit('selectFunc', item)"
          />
        </div>
      </v-hover>
    </td>
    <!-- 중요표시 -->
    <td class="text-start px-2 importantStatus">
      <Important :mail="item" />
    </td>
    <!-- 읽음표시 -->
    <td class="text-start px-2 readStatus">
      <ReadStatusBtn :item="item" />
    </td>
    <!-- 첨부파일표시 -->
    <td class="text-start px-2 includesAttach">
      <IncludesAttachBtn v-if="item.includesAttach == 1" :item="item" />
    </td>
    <!-- 메일주소 -->
    <td v-if="!getSplitView || isDrafts || isSpam" class="text-start adrFrom">
      <AdrEmail
        :address="address"
        :personal="personal"
        :ruleSelection="isDrafts || isSent ? 'to' : 'from'"
        :readStatus="item.readStatus"
        :type="isSent ? 'view' : 'list'"
        :countryCode="item.countryCode"
      />
    </td>
    <!-- 수신확인 -->
    <td v-if="isSent" class="text-start sent">
      <ReceiptBtn
        v-if="isSent && !isReserve && !isReserveCancel"
        :item="item"
        :isExpanded="isExpanded"
        v-on="$listeners"
      />
    </td>
    <!-- 제목 -->
    <td
      class="text-start subject"
      :class="getSplitView == 1 && !isDrafts && !isSpam ? 'py-2' : ''"
      @click="$emit('mailRowClick', item)"
    >
      <div
        class="cr-subject-wrapper"
        :class="{ sent: isSent, 'cr-split-1': getSplitView }"
      >
        <!-- 주고받은 메일보기 버튼 -->
        <ExpandBtn
          v-if="
            !isExchange &&
              !isSent &&
              !isDrafts &&
              !isSpam &&
              !isShare &&
              item.exchangedMail
          "
          :class="
            `${!getSplitView ? 'cr-expand-btn' : 'cr-expand-btn cr-split-1'} ${
              showTags ? ' cr_tags' : ''
            }`
          "
          :item="item"
          :isExpanded="isExpanded"
          :isSent="isSent"
          v-on="$listeners"
        />
        <div
          class="cr-subjectField d-flex"
          v-if="getSplitView == 1 && !isDrafts && !isSpam"
        >
          <AdrEmail
            :address="address"
            :personal="personal"
            :ruleSelection="isDrafts || isSent ? 'to' : 'from'"
            :readStatus="item.readStatus"
            :type="isSent ? 'view' : 'list'"
            :countryCode="item.countryCode"
          />
        </div>
        <div class="cr-subjectField-wrapper">
          <div class="cr-subjectField grey--text">
            <v-chip
              v-if="isSpam"
              color="red"
              label
              small
              outlined
              class="mr-1 px-1 cr-name-folder"
              v-bind:title="item.patternTag"
            >
              {{ item.patternTag }}
            </v-chip>
            <v-chip
              v-if="isBlockedMail"
              color="red"
              label
              small
              outlined
              class="mr-1 px-1 cr-name-folder"
              v-bind:title="
                item.mailType == 'REPORT_MAIL' ? $t('mail.198') : $t('mail.154')
              "
            >
              {{
                item.mailType == "REPORT_MAIL" ? $t("mail.199") : $t("mail.200")
              }}
            </v-chip>
            <v-chip
              v-if="isPromotionMail"
              :color="getPromotionColor"
              label
              small
              outlined
              class="mr-1 px-1 cr-name-folder"
              v-bind:title="getPromotionTooltip"
            >
              {{ getPromotionTitle }}
            </v-chip>
            <v-chip
              v-if="nameFolder"
              label
              small
              outlined
              class="mr-1 px-1 cr-name-folder"
            >
              {{ nameFolder }}
            </v-chip>
            <v-chip
              v-show="showSent"
              label
              small
              class="mr-1 px-1 cr-sent-mail"
            >
              <span v-text="$t('mail.201')"></span>
            </v-chip>
            <v-icon v-if="item.priority == 1" color="red" small>
              mdi-exclamation-thick
            </v-icon>
            <span :class="subjectClass" v-highlight="item.subject"></span>
            <span v-show="item.contentPreview">
              - {{ item.contentPreview }}
            </span>
          </div>

          <PopupViewBtn :item="item" />
        </div>

        <div>
          <v-chip
            label
            small
            :color="color"
            :text-color="invertColor(color)"
            class="mr-1 cr-tag-chip"
            v-for="({ tag, color }, idx) in tags"
            :key="idx"
            @click.stop="routeTag(`t_${tag}`)"
          >
            {{ tag }}
          </v-chip>
        </div>
      </div>
    </td>
    <!-- 받은시간 -->
    <td
      class="text-right pr-2 receivedTimeMillis grey--text"
      :class="isReserve ? 'primary--text' : ''"
      @click="$emit('mailRowClick', item)"
    >
      <span
        v-show="isReserve || isReserveCancel"
        :class="`${isReserveCancel ? 'text-decoration-line-through' : ''}`"
        class="primary--text"
      >
        {{ isReserve || isReserveCancel ? $t("mail.예약") : "" }}
      </span>
      {{ item.receivedTimeMillis }}
    </td>
    <!-- 메일크기 -->
    <td
      v-if="!getSplitView || isDrafts || isSpam"
      class="text-start emlSize grey--text"
      @click="$emit('mailRowClick', item)"
    >
      {{ item.emlSize }}
    </td>
  </tr>
</template>

<style lang="scss" scoped>
tr.cr-base-row {
  /* 마우스 오버 */
  &:hover {
    background-color: #efefef70 !important;
  }
  > td {
    &.text-start.checkbox,
    &.text-start.importantStatus,
    &.text-start.readStatus,
    &.text-start.includesAttach {
      overflow: inherit;
      max-width: 38px;
      min-width: 38px;
      width: 38px;
    }
    &.text-start.adrFrom {
      max-width: 160px;
      min-width: 160px;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
    }
    &.text-start.sent {
      max-width: 191px;
      min-width: 191px;
      width: 191px;
      overflow: hidden;
    }
    &.text-start.subject {
      cursor: pointer;
      max-width: 1px;
      width: 100%;
      > .cr-subject-wrapper {
        position: relative;
        padding-left: 26px;
        &.sent {
          padding-left: 0px;
        }

        > .cr-expand-btn {
          position: absolute;
          top: -8px;
          left: -14px;

          &.cr_tags,
          &.cr-split-1 {
            // top: 5px;
            top: calc(50% - 18px);
          }
        }
        .cr-subjectField-wrapper {
          display: flex;
          align-items: center;

          .cr-subjectField {
            position: relative;
            min-width: 0px;
            padding-right: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            > .v-icon.mdi-exclamation-thick {
              padding-bottom: 2px;
            }
            > .subject {
              color: rgba(0, 0, 0, 0.87);
            }
          }
        }

        .cr-tag-chip:hover {
          opacity: 0.3;
        }
      }
    }
    &.text-right.receivedTimeMillis {
      cursor: pointer;
      min-width: 165px;
      width: 165px;
    }
    &.text-start.emlSize {
      cursor: pointer;
      min-width: 110px;
      width: 110px;
    }
  }

  /* 주고받은 메일 부분 */
  &.expand {
    background: rgba(210, 210, 210, 0.12);

    &.cr-exchange-row > td {
      border-bottom: none !important;
    }

    /* 선위치 */
    .v-timeline.v-timeline--dense.theme--light::before {
      left: calc(12px - 1px);
    }

    .v-timeline ::v-deep {
      padding-top: 1px;
      /* 동그라미 위치 */
      .v-timeline-item.cr-split {
        min-height: 60px;
      }
      .v-timeline-item {
        min-height: 48px;
        .v-timeline-item__divider {
          padding-left: 2px;
          min-width: 22px;
          /* 동그라미 사이즈 */
          > .v-timeline-item__dot--small {
            height: 18px;
            left: calc(50% - 12px);
            width: 18px;
            margin-bottom: 1px;
            > .v-timeline-item__inner-dot {
              height: 12px;
              margin: 3px;
              width: 12px;
            }
          }
        }
      }
    }
    /* hover */
    .v-timeline.hover ::v-deep {
      .v-timeline-item.cr-split {
        min-height: 60px;
      }
      .v-timeline-item {
        min-height: 48px;
        .v-timeline-item__divider {
          display: none;
        }
      }
    }
    .v-input--checkbox.cr-exchange-checkbox.cr-split {
      top: 21px;
    }
    .v-input--checkbox.cr-exchange-checkbox ::v-deep {
      position: absolute;
      top: 14px;
      left: 2px;
      i.mdi-checkbox-blank-outline::before {
        content: "\F0130";
      }
      i.mdi-checkbox-marked::before {
        content: "\F0133";
      }
      .v-input__control .v-input__slot .v-input--selection-controls__input {
        background-color: #fff;
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }
    }
  }

  /* 메일보기 일때 배경색 */
  &.cr-selected-panel {
    background-color: #efefef !important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "@/_locales";
import { invertColor } from "@/commons/utils/invertColor";
import AdrEmail from "@/mail/views/components/main/commons/adrEmail";
import Important from "../../commons/Important";

import Checkbox from "../td/Checkbox";
import ExpandBtn from "../td/ExpandBtn";
import ReceiptBtn from "../td/ReceiptBtn";
import ReadStatusBtn from "../td/ReadStatusBtn";
import IncludesAttachBtn from "../td/IncludesAttachBtn";
import PopupViewBtn from "../td/PopupViewBtn";

export default {
  components: {
    AdrEmail,
    Important,
    Checkbox,
    ExpandBtn,
    ReceiptBtn,
    ReadStatusBtn,
    IncludesAttachBtn,
    PopupViewBtn
  },
  props: {
    item: {
      default: () => {},
      type: Object
    },
    parentItem: {
      default: () => {},
      type: Object
    },
    closedGroup: {
      default: () => [],
      type: Array
    },
    // closedDivision: {
    //   default: () => [],
    //   type: Array,
    //   description: "닫힌 분류 목록"
    // },
    getSplitView: {
      default: 0,
      type: Number
    },
    isSent: {
      default: false,
      type: Boolean
    },
    isDrafts: {
      default: false,
      type: Boolean
    },
    isSpam: {
      default: false,
      type: Boolean
    },
    isSelected: {
      default: false,
      type: Boolean
    },
    isExpanded: {
      default: false,
      type: Boolean
    },
    isCreatedGroup: {
      default: false,
      type: Boolean
    },
    isExchange: {
      default: false,
      type: Boolean
    },
    exchangeCls: {
      default: "",
      type: String
    }
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo", "getRouteViewInfo"]),
    ...mapGetters("folder", [
      "getTags",
      "getFolder",
      "isShare",
      "currentFolder"
    ]),
    subjectClass() {
      let cls = "subject ";
      if (this.isSpam) return cls;

      cls += this.item.readStatus ? " " : "font-weight-bold ";
      cls += this.isBlockedMail ? "red--text" : "";

      return cls;
    },
    isBlockedMail() {
      return (
        this.item.mailType == "REPORT_MAIL" ||
        this.item.mailType == "SYSTEM_BLOCK"
      );
    },
    isPromotionMail() {
      return ["PROMOTION", "SOCIAL"].includes(this.item.mailType);
    },
    getPromotionColor() {
      if (this.item.mailType == "SOCIAL") return "#FD1D53";
      return "#0080FF";
    },
    getPromotionTitle() {
      if (this.item.mailType == "SOCIAL") return i18n.t("mail.505");
      return i18n.t("mail.503");
    },
    getPromotionTooltip() {
      if (this.item.mailType == "SOCIAL") return i18n.t("mail.506");
      return i18n.t("mail.504");
    },
    getTrClass() {
      let classStr = "item-row cr-base-row";
      if (this.isExchange) classStr += ` expand ${this.exchangeCls}`;
      if (this.isSelectedView) classStr += " cr-selected-panel";

      if (
        !this.isShare &&
        this.item.folderType !== "DRAFTS" &&
        this.item.folderType !== "TOME"
      ) {
        classStr += " cr-draggable-list";
      }

      return classStr;
    },
    isReserve() {
      return this.item.mailType == "RESERVE";
    },
    isReserveCancel() {
      return this.item.mailType == "RESERVE_CANCEL";
    },
    isSelectedView() {
      return (
        this.getRouteViewInfo?.actionObj?.view?.selectedDataId ==
        this.item.dataId
      );
    },
    nameFolder() {
      const folder = this.getFolder(this.item.folderId);
      if (!folder) return "";
      if (this.getRouteListInfo.folderId > 0) {
        if (this.currentFolder.id !== folder.id) return folder.nameFolder;
        return "";
      }
      return folder.nameFolder;
    },
    address() {
      return this.isDrafts || this.isSent
        ? this.item.adrTo?.address
        : this.item.adrFrom?.address;
    },
    personal() {
      return this.isDrafts || this.isSent
        ? this.item.adrTo?.personal
        : this.item.adrFrom?.personal;
    },
    showSent() {
      if (this.isSent) return false;
      const folder = this.getFolder(this.item.folderId);
      return this.item.mailType == "SENT" && folder?.folderType !== "SENT";
    },
    showTags() {
      return this.tags.length > 0;
    },
    tags() {
      const { tags } = this.item;
      if (!tags) return [];
      return tags?.split(",")?.map(tag => {
        const [filteredTag] = this.getTags.filter(t => t.tag === tag);
        return {
          tag: filteredTag?.tag || tag,
          color: filteredTag?.color || "#E0E0E0"
        };
      });
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeTag"]),
    invertColor(hex) {
      return invertColor(hex);
    }
  }
};
</script>
