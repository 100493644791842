<template>
  <div class="cr-envelope-container">
    <v-subheader>
      {{ $t("mail.숨은참조") }}
    </v-subheader>

    <div class="cr-input-wrapper">
      <Autocomplete
        ref="autocompleteRef"
        key="bcc"
        class="cr-dropzone-autocomplete-input"
        :selection.sync="selection_bcc"
        :crDraggable="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  computed: {
    ...mapGetters("mailCompose", ["bcc"]),
    selection_bcc: {
      get() {
        return this.bcc.map(b => ({ ...b }));
      },
      set(bcc) {
        return this.SET_RECIPIENT({ bcc });
      }
    }
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_RECIPIENT"])
  }
};
</script>
