<template>
  <div class="cr-wrapper">
    <!-- 좌우 분할 -->
    <div :class="resizableClass">
      <Resizable
        :active="active"
        :minWidth="minWidth"
        :maxWidth="maxWidth"
        :width="width"
        :minHeight="minHeight"
        :maxHeight="maxHeight"
        :height="height"
        @resize:move="resizeMove"
      >
        <MailList :resizeWidth="width" :key="getRouteListInfo.folderId" />
      </Resizable>
    </div>
    <div class="cr-split-component left-and-right" :style="style">
      <MailView :key="getViewKey" :resizeWidth="width" />
    </div>
  </div>
</template>

<style scoped>
.cr-split-component {
  position: relative;
  height: 100%;
  display: inline-block;
}
.cr-split-component.left-and-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* overflow-x: auto; */
}
.cr-split-component.up-and-down {
  height: auto;
}
</style>

<script>
import { mapGetters } from "vuex";
import storage from "@/commons/api/storage";
import Resizable from "./split/Resizable";
import MailList from "./MailList";
import MailView from "./MailView";

export default {
  components: { Resizable, MailList, MailView },
  mounted() {
    this.resizing();
  },
  data() {
    return {
      active: ["r"],
      minWidth: 400,
      maxWidth: 0,
      rightMaxWidth: 240,
      width: 0,
      minHeight: 0,
      maxHeight: 0,
      height: 0,
      clientWidth: 0,
      style: {}
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo", "getRouteViewInfo"]),
    ...mapGetters("mailLayout", ["mainWidth", "naviInfo"]),
    ...mapGetters("mailConfig", ["getSplitView"]),
    getViewKey() {
      return this.getRouteViewInfo.actionObj?.view?.dataId;
    },
    resizableClass() {
      return this.getSplitView == 1
        ? "cr-split-component"
        : "cr-split-component up-and-down";
    }
  },
  watch: {
    mainWidth() {
      this.resizing();
    },
    naviInfo() {
      if (this.$vuetify.breakpoint.lgAndUp) return;
      this.resizing();
    }
  },
  methods: {
    // resizable component에서 style변경시 호출
    resizeMove({ width, height }) {
      if (this.getSplitView == 1) storage.setRightAndLeftSplitWidth(width);
      else storage.setUpAndDowmSplitHeight(height);

      this.width = width;
      this.style = { left: `${this.width}px` };
    },
    resizing() {
      if (!this.naviInfo.naviWidth) return;

      const clientWidth = this.mainWidth;
      const storageWidth = storage.getRightAndLeftSplitWidth();
      const maxWidth = clientWidth - this.rightMaxWidth;
      let width = storageWidth > 0 ? parseInt(storageWidth) : 400;

      // 최대치일때
      if (this.width && this.width == this.maxWidth) {
        width = maxWidth;
        // 최소가 아닐때
      } else if (this.width && this.width !== this.minWidth) {
        const ratio = clientWidth / this.clientWidth;
        width = Math.round(ratio * this.width);
        if (width <= this.minWidth) {
          width = this.minWidth;
        } else if (width >= maxWidth) {
          width = maxWidth;
        }
      }

      storage.setRightAndLeftSplitWidth(width);
      this.width = width;
      this.clientWidth = clientWidth;
      this.maxWidth = maxWidth;
      this.style = { left: `${this.width}px` };
    }
  }
};
</script>
