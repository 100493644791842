<template>
  <div
    class="cr-file-list-wrapper"
    @drop="e => $emit('drop', e)"
    @dragenter="e => $emit('dragenter', e)"
    @dragleave="e => $emit('dragleave', e)"
    @dragover.prevent
  >
    <v-data-table
      dense
      show-select
      fixed-header
      hide-default-footer
      item-key="key"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :headers="headers"
      :items="attachFiles"
      v-model="selectedItems"
      @click:row="(i, r) => r.select(!r.isSelected)"
      @dragenter="e => $emit('dragenter', e)"
      @dragleave="e => $emit('dragleave', e)"
      @dragover.prevent
    >
      <template v-slot:[`item.name`]="{ item }">
        <Name :item="item" />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <Status :item="item" />
      </template>
      <template v-slot:[`item.size`]="{ item }">
        <Size :item="item" />
      </template>
      <template v-slot:[`item.uploadType`]="{ item }">
        <Type :item="item" />
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.cr-file-list-wrapper {
  padding-top: 8px;

  &.cr-mobile {
    width: 100%;
    margin: 25px 0px 8px 0px;
  }

  .v-data-table::v-deep {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;

    .v-data-table__wrapper {
      height: 160px;
      overflow-y: auto;
      overflow-x: hidden;

      > table {
        table-layout: fixed;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        > thead > tr > th,
        > tbody > tr > td {
          padding: 0 0 0 16px;
          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import Name from "./Name.vue";
import Status from "./Status.vue";
import Size from "./Size.vue";
import Type from "./Type.vue";

export default {
  components: { Name, Status, Size, Type },
  computed: {
    ...mapGetters("mailFile", {
      attachFiles: "items",
      selectedAttach: "selectedItems"
    }),
    selectedItems: {
      get() {
        return this.selectedAttach;
      },
      set(items) {
        return this.SET_SELECTED_ITEMS(items);
      }
    },
    headers() {
      const sm = ["data-table-select", "name", "status"];
      const md = ["data-table-select", "name", "status", "uploadType"];
      return [
        {
          value: "data-table-select",
          text: "",
          sortable: false,
          width: 48
        },
        {
          value: "name",
          text: this.$t("mail.114"),
          sortable: false
        },
        {
          value: "status",
          text: this.$t("mail.115"),
          sortable: false,
          width: 100
        },
        {
          value: "size",
          text: this.$t("mail.116"),
          sortable: false,
          width: 120
        },
        {
          value: "uploadType",
          text: this.$t("mail.117"),
          sortable: false,
          width: 180
        }
      ].filter(h => {
        if (this.$vuetify.breakpoint.smAndDown) {
          return sm.includes(h.value);
        }
        if (this.$vuetify.breakpoint.md) {
          return md.includes(h.value);
        }
        return this.$vuetify.breakpoint.lgAndUp;
      });
    }
  },
  methods: {
    ...mapMutations("mailFile", ["SET_SELECTED_ITEMS"])
  }
};
</script>
