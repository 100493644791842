<template>
  <div :class="cls" @click="$emit('selectPanel', $vnode.key)">
    <div class="cr-seleted-title font-weight-bold">
      <span>{{ title }}</span>
      <span class="ml-1 blue--text text--darken-1">
        {{ totalCnt }}
      </span>
    </div>

    <div class="py-1 cr-chips">
      <Chip
        v-bind="$props"
        v-on="$listeners"
        v-for="chip in chips"
        :key="chip.key"
        :chip="chip"
        :vnodeKey="$vnode.key"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-cell {
  padding: 6px;
  border: 2px solid rgba(0, 0, 0, 0.06);

  &.to {
    height: 250px;
    border-bottom-color: transparent;
    .cr-chips {
      overflow-y: auto;
      max-height: 210px;
    }
  }
  &.cc {
    height: 200px;
    border-bottom-color: transparent;
    .cr-chips {
      overflow-y: auto;
      max-height: 160px;
    }
  }
  &.bcc {
    height: 150px;
    .cr-chips {
      overflow-y: auto;
      max-height: 110px;
    }
  }
  &.cr-selected {
    border: 2px solid var(--v-primary-base);
  }
}
</style>

<script>
import Chip from "./Chip.vue";

export default {
  components: { Chip },
  props: {
    to: {
      type: Array,
      default: () => []
    },
    cc: {
      type: Array,
      default: () => []
    },
    bcc: {
      type: Array,
      default: () => []
    },
    selectedPanel: {
      type: String,
      default: ""
    }
  },
  computed: {
    cls() {
      const { key } = this.$vnode;
      let cls = `cr-cell ${key}`;
      if (this.selectedPanel == key) {
        cls += " cr-selected";
      }

      return cls;
    },
    title() {
      switch (this.$vnode.key) {
        case "cc":
          return this.$t("mail.참조");
        case "bcc":
          return this.$t("mail.숨은참조");
        default:
          return this.$t("mail.받는사람");
      }
    },
    chips() {
      return this[this.$vnode.key] || [];
    },
    totalCnt() {
      return this.chips.length || 0;
    }
  }
};
</script>
