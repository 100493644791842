<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- <v-radio-group row v-model="folderType" mandatory>
          <v-radio label="일반" :value="privateFolderType.PRIVATE"></v-radio>
          <v-radio
            label="보안"
            :value="privateFolderType.PRIVATE_SECURE"
          ></v-radio>
          <v-radio
            label="영구보관"
            :value="privateFolderType.PRIVATE_PERMANENT"
          ></v-radio>
        </v-radio-group> -->
        <v-text-field
          required
          autofocus
          autocomplete="off"
          ref="newNameFolder"
          :label="label"
          v-model="nameFolder"
          v-on:keyup.enter="() => $emit('update:confirm', true)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { privateFolderType } from "@/mail/constant/folderType";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data(vue) {
    const getters = vue.$store.getters;
    const { targetFolder: folder } = getters["mailDialog/params"];

    return {
      label: folder ? folder.nameFolder : `${i18n.t("mail.252")}*`,
      nameFolder: "",
      folderType: privateFolderType.PRIVATE,
      privateFolderType
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    ...mapGetters("folder", ["getOpenFolders"])
  },
  watch: {
    confirm(next) {
      if (next) {
        this.addFolderFunc();
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("folder", ["SET_OPEN_FOLDERS"]),
    ...mapActions("folder", ["addFolder", "updateFolderName"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    // 중복 방지 위해 엔터와 확인 버튼 둘다 confirm변경
    async addFolderFunc() {
      const { nameFolder, folderType, params } = this;
      const { parentFolder, targetFolder } = params;
      this.nameFolder = nameFolder.trim();

      if (!this.nameFolder) {
        this.openSnackbar({
          message: i18n.t("mail.330"),
          type: "VALIDATION"
        });
        this.$emit("update:confirm", false);
        return this.$refs.newNameFolder.focus();
      }

      // 이름 변경
      if (targetFolder) {
        await this.updateFolderName({
          nameFolder: this.nameFolder,
          folderId: targetFolder.id
        });

        // 메일함 추가
      } else {
        await this.addFolder({
          nameFolder: this.nameFolder,
          folderType,
          parentId: parentFolder.id
        });
        this.SET_OPEN_FOLDERS([...this.getOpenFolders, parentFolder]);
      }

      this.CLOSE_DIALOG();
    }
  }
};
</script>
