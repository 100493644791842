<template>
  <div style="display: contents">
    <v-row class="ma-0">
      <v-col class="cr-config-title" cols="4" sm="4">
        {{ $t("mail.338") }}
      </v-col>
      <v-col
        cols="8"
        sm="8"
        class="pl-0"
        :class="$vuetify.breakpoint.xs ? 'd-flex justify-end pr-0' : ''"
      >
        <v-switch hide-details inset class="my-1 pb-1" v-model="useSmtp" />
      </v-col>
    </v-row>

    <v-row v-if="useSmtp" class="ma-0">
      <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
        {{ $t("mail.332") }}
      </v-col>
      <v-col cols="12" sm="4" class="pl-0">
        <v-text-field
          ref="smtpServerRef"
          dense
          outlined
          hide-details
          placeholder="smtp.your_domain.com"
          :readonly="serverName !== 'custom'"
          v-model="smtpServer"
        />
      </v-col>
      <v-col cols="12" sm="4" class="pl-0">
        <v-text-field
          ref="smtpPortRef"
          outlined
          dense
          hide-details
          label="smtp"
          type="number"
          v-model="smtpPort"
        />
      </v-col>

      <!--
      <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
        {{ $t("mail.339") }}
      </v-col>
       <v-col cols="12" sm="8" class="pl-0">
        <v-text-field
          ref="senderNameRef"
          outlined
          dense
          hide-details
          v-model="senderName"
        />
      </v-col> 
      -->

      <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
        {{ $t("common.비밀번호") }}
      </v-col>
      <v-col cols="12" sm="8" class="pl-0">
        <v-text-field
          ref="smtpPasswordRef"
          outlined
          dense
          hide-details
          type="password"
          v-model="smtpPassword"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ item }) {
    const {
      serverName,
      useSmtp,
      smtpServer = "",
      smtpPort,
      senderName = "",
      smtpPassword = ""
    } = item;

    return {
      serverName,
      useSmtp: !!useSmtp,
      smtpServer,
      smtpPort: smtpPort || 465,
      senderName,
      smtpPassword
    };
  },
  watch: {
    useSmtp(n) {
      if (!n) return;
      setTimeout(() => {
        if (this.serverName === "custom") {
          this.$refs.smtpServerRef.focus();
          return;
        }
        // this.$refs.senderNameRef.focus();
      }, 0);
    },
    item({ serverName: n }, { serverName: p }) {
      if (n === p) return;

      this.serverName = n;
      this.smtpPort = 465;
      switch (n) {
        case "naver":
          this.smtpServer = "smtp.naver.com";
          break;
        case "daum":
          this.smtpServer = "smtp.daum.net";
          break;
        case "gmail":
          this.smtpServer = "smtp.gmail.com";
          break;
        case "nate":
          this.smtpServer = "smtp.mail.nate.com";
          break;
        default:
          this.smtpServer = "";
          break;
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    validation() {
      if (!this.useSmtp) return true;
      if (isBlank(this.smtpServer)) {
        this.$refs.smtpServerRef.focus();
        this.openSnackbar({ message: this.$t("mail.353"), type: "VALIDATION" });
        return false;
      }

      if (isBlank(this.smtpPort)) {
        this.$refs.smtpPortRef.focus();
        this.openSnackbar({ message: this.$t("mail.354"), type: "VALIDATION" });
        return false;
      }

      const { id } = this.item;
      if (!id && isBlank(this.smtpPassword)) {
        this.$refs.smtpPasswordRef.focus();
        this.openSnackbar({ message: this.$t("mail.351"), type: "VALIDATION" });
        return false;
      }

      return true;
    }
  }
};
</script>
