<template>
  <div v-if="currentProcess == process.CONVERTING" class="cr-share-info">
    <!-- 에러 발생시 -->
    <div v-if="error" class="d-flex flex-column justify-center align-center">
      <v-icon color="error" size="150" class="pb-4">mdi-alert-circle</v-icon>
      <div class="text-center subtitle-1" v-html="$t('mail.512')" />
    </div>
    <!-- 변환중 -->
    <v-container v-else>
      <v-row class="pt-5" align-content="center" justify="center">
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
          v-text="$t('mail.513')"
        ></v-col>
        <v-col cols="3">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- 게시글 작성창 -->
  <PostWriteDialog
    v-else-if="currentProcess == process.CONVERTED"
    v-bind="$props"
    v-on="$listeners"
    @onSaved="onSaved"
  />
  <!-- 게시글 공유 완료 -->
  <div
    v-else-if="currentProcess == process.SAVED"
    class="cr-share-info d-flex flex-column align-center"
  >
    <v-icon color="green" size="150" class="pb-4">mdi-check-circle</v-icon>
    <p class="subtitle-1 " v-html="$t('mail.514')"></p>
    <p class="subtitle-1" v-html="$t('mail.515')"></p>
    <v-btn
      text
      color="primary"
      elevation="0"
      @click="goPost"
      class="font-weight-bold"
    >
      {{ $t("common.확인") }}
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-share-info {
  min-height: 300px;
  padding: 70px;
}
</style>

<script>
import i18n from "@/_locales";
import { getPostForm } from "@/mail/api/boardShare.api.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import PostWriteDialog from "@/board/views/components/dialog/PostWriteDialog";
export default {
  components: { PostWriteDialog },
  props: {
    confirm: {
      default: false,
      type: Boolean
    },
    confirmDisabled: {
      default: false,
      type: Boolean
    },
    persistent: {
      default: false,
      type: Boolean
    }
  },
  async mounted() {
    this.$emit("update:confirmDisabled", true);
    this.$emit("update:persistent", true);
    this.SET_SHOW_CONFIRM_BTN(false);
    this.currentProcess = this.process.CONVERTING;

    const { mailId, includeAttach } = this.params;

    // 메일 -> 게시글 변환 요청
    const { status, data } = await getPostForm({ mailId, includeAttach });
    if (status != 200) {
      this.error = true;
      this.$emit("update:confirmDisabled", false);
      return;
    }

    setTimeout(() => {
      this.currentProcess = this.process.CONVERTED;
      this.SET_BOARD_DIALOG({ type: "sharePost", params: { post: data } });
    }, 500);

    this.$emit("update:confirmDisabled", false);
  },
  data() {
    return {
      // 공유 상태 [변환중, 변환완료, 저장완료]
      process: {
        CONVERTING: "CONVERTING",
        CONVERTED: "CONVERTED",
        SAVED: "SAVED"
      },
      currentProcess: process.CONVERTING,
      error: false,
      postId: 0
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"])
  },
  methods: {
    ...mapActions("boardPost", ["uploadFile"]),
    ...mapMutations("boardDialog", { SET_BOARD_DIALOG: "SET_DIALOG" }),
    ...mapMutations("mailDialog", [
      "CLOSE_DIALOG",
      "SET_SHOW_CONFIRM_BTN",
      "SET_CONFIRM_BTN_TITLE",
      "SET_CANCEL_BTN_TITLE"
    ]),
    onSaved({ id = 0 }) {
      if (id > 0) {
        this.currentProcess = this.process.SAVED;
        this.postId = id;
      }
      this.$emit("update:confirmDisabled", false);
    },
    goPost() {
      this.$router.push({
        name: "board_post_view",
        params: {
          postId: this.postId,
          actionObj: JSON.stringify({
            boardId: "all"
          })
        }
      });
    }
  },
  watch: {
    error(val) {
      if (val) this.SET_SHOW_CONFIRM_BTN(false);
    },
    currentProcess(val) {
      switch (val) {
        case this.process.CONVERTING:
        case this.process.SAVED:
          this.SET_SHOW_CONFIRM_BTN(false);
          this.SET_CANCEL_BTN_TITLE(i18n.t("common.닫기"));
          break;
        case this.process.CONVERTED:
          if (!this.error) {
            this.SET_SHOW_CONFIRM_BTN(true);
            this.SET_CONFIRM_BTN_TITLE(i18n.t("common.저장"));
          }
          break;
      }
    },
    confirm(val) {
      // 변환중 에러 발생시
      if (val && this.error) {
        this.CLOSE_DIALOG();
      }
    }
  },
  destroyed() {
    // 사용했던 dialog 옵션 원복
    this.$emit("update:persistent", false);
    this.$emit("update:confirmDisabled", false);
  }
};
</script>
