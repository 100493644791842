<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <!-- 사용 여부 -->
    <v-row class="ma-0">
      <v-col
        class="d-flex align-center cr-config-title grey--text text--darken-2"
        cols="4"
      >
        {{ $t("mail.520") }}
      </v-col>
      <v-col cols="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="useYN" />
      </v-col>
    </v-row>
    <v-divider />
    <div v-if="useYN">
      <!-- 원본 메일 저장 -->
      <v-row class="ma-0">
        <v-col
          class="d-flex align-center cr-config-title grey--text text--darken-2"
          cols="4"
        >
          {{ $t("mail.521") }}
        </v-col>
        <v-col cols="8" class="cr-swtich-wrapper">
          <v-switch hide-details inset v-model="saveOriginal" />
        </v-col>
      </v-row>
      <v-divider />
      <!-- 전달 받을 메일 주소 -->
      <v-row class="ma-0">
        <v-col
          class="d-flex align-center cr-config-title grey--text text--darken-2"
          cols="5"
          sm="4"
          md="4"
        >
          {{ $t("mail.522") }}
        </v-col>
        <v-col cols="7" sm="8" md="8">
          <div style="width:100%">
            <Autocomplete
              ref="autocompleteRef"
              key="to"
              class="cr-dropzone-autocomplete-input"
              :selection.sync="selection"
              :rsetData="true"
              :useChips="false"
              :excludeLoginUser="true"
              :placeholder="$t('mail.523')"
              :crDraggable="false"
              :typeList="['MEMBER']"
            />
          </div>
          <div style="width:100%">
            <div v-for="item in targets" :key="item.address" class="mt-3">
              <v-chip
                label
                link
                close
                close-icon="mdi-close"
                :color="getColor(item)"
                @click:close="removeItem(item)"
                >{{ item.address }}</v-chip
              >
              <span class="caption ml-1">
                {{ getDescription(item) }}
              </span>

              <span class="ml-1" v-if="showResendBtn(item)">
                <v-btn text small depressed @click="resend(item)">
                  {{ $t("mail.531") }}
                </v-btn>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider />
    </div>
    <v-row no-gutters class="mt-3 pb-2 pr-2" style="max-width: 1029px;">
      <v-col cols="7" class="d-flex pl-2 caption">
        <div>
          {{ $t("mail.532") }}
          <br />
          <span
            style="cursor:pointer;"
            :class="
              reloadButtonEnabled
                ? 'indigo--text text-decoration-underline'
                : 'indigo--grey'
            "
            @click="reload"
          >
            {{ $t("mail.533") }}
          </span>
          <br />
          {{ $t("mail.534") }}
        </div>
      </v-col>
      <v-col cols="5" class="d-flex justify-end">
        <v-btn text depressed :disabled="disabledBtn" @click="reset">{{
          $t("common.취소")
        }}</v-btn>
        <v-btn
          class="mr-3"
          color="primary"
          depressed
          :disabled="disabledBtn"
          @click="save"
        >
          {{ $t("common.저장") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
}
.cr-config-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.v-list-item > .v-list-item__content {
  padding: 12px 10px;
}

.v-btn {
  border-radius: 0px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";
import { getDateWithTime } from "@/commons/utils/moment";
import { equalsIgnoreCaseEmail } from "@/commons/utils/validation";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      useYN: 0,
      saveOriginal: 1,
      targets: [],
      selection: [],
      reloadButtonEnabled: true
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("mailConfigForward", ["getForwardConfig"]),
    disabledBtn() {
      if ((this.useYN ? 1 : 0) !== this.getForwardConfig.useYN) return false;
      if ((this.saveOriginal ? 1 : 0) !== this.getForwardConfig.saveOriginal)
        return false;
      if (
        JSON.stringify(this.targets) !==
        JSON.stringify(this.getForwardConfig.targets)
      )
        return false;

      return true;
    }
  },
  watch: {
    getForwardConfig(config) {
      this.useYN = config.useYN;
      this.saveOriginal = config.saveOriginal;
      this.targets = [...config.targets];
    },
    selection(item) {
      if (item.length == 0) return;
      if (item[item.length - 1].valid == false) return;

      this.addTarget(item[item.length - 1].value);
      this.cleanSelection();
    }
  },
  methods: {
    ...mapActions("mailConfigForward", [
      "initialize",
      "saveForward",
      "resendApprovalMail"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    getColor(item) {
      return item.agree == 1 ? "blue lighten-5" : "";
    },
    getDescription(item) {
      if (item.agree == 0 && item.updatedTimeMillis <= 0) {
        return i18n.t("mail.525");
      }

      if (item.agree == 0 && item.updatedTimeMillis > 0) {
        return i18n.t("mail.530", {
          value: getDateWithTime(item.updatedTimeMillis)
        });
      }

      if (item.agree == 1 && item.updatedTimeMillis > 0) {
        return i18n.t("mail.524", {
          value: getDateWithTime(item.updatedTimeMillis)
        });
      }
    },
    showResendBtn(item) {
      if (item.agree !== 0) return false;
      if (item.updatedTimeMillis <= 0) return false;

      /*
       * 요청 메일 재발송 시간은 최소 5분으로 제한 한다
       */
      const gap = new Date().getTime() - item.updatedTimeMillis;
      const limit = 1000 * 60 * 5; // 5min
      return gap > limit;
    },
    async cleanSelection() {
      this.selection = [];
    },
    addTarget(address) {
      const { username } = this.getUserInfo;
      const equals = equalsIgnoreCaseEmail(username, address);
      if (equals) {
        // 본인 추가
        this.openSnackbar({
          type: "ERROR",
          message: i18n.t("mail.535")
        });
        return;
      }

      const idx = this.targets.findIndex(e => e.address === address);
      if (idx > -1) return;

      this.targets.push({
        agree: 0,
        address: address
      });
    },
    removeItem(item) {
      this.targets = this.targets.filter(
        origin => origin.address !== item.address
      );
    },
    reload() {
      if (!this.reloadButtonEnabled) return;

      this.reloadButtonEnabled = false;
      this.initialize();
      setTimeout(() => {
        this.reloadButtonEnabled = true;
        this.openSnackbar({
          type: "SUCCESS",
          message: i18n.t("common.81")
        });
      }, 1500);
    },
    resend(item) {
      this.resendApprovalMail(item.address);
    },
    reset() {
      this.initialize();
    },
    confirmDialog() {
      this.confirm({
        headline: "",
        message: i18n.t("mail.526"),
        callback: () => {
          this.useYN = 0;
          this.save();
        }
      });
    },
    save() {
      setTimeout(() => {
        if (this.useYN && this.targets.length == 0) {
          this.confirmDialog();
          return;
        }

        this.saveForward({
          options: {
            useYN: this.useYN ? 1 : 0,
            saveOriginal: this.saveOriginal ? 1 : 0
          },
          targets: this.targets
        });
      }, 200);
    }
  }
};
</script>
