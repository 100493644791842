<template>
  <div style="padding-top: 8px">
    <editor
      ref="tinyRef"
      id="tiny"
      :initial-value="content"
      :init="tinymceInit"
    />
  </div>
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/bbcode";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/legacyoutput";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/print";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/tabfocus";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/toc";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";
require("./paste/plugin.js");

import { mapGetters, mapActions } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { uploadFile } from "@/mail/api/file.api";
import uploadType from "@/mail/constant/uploadType";
import html2canvas from "html2canvas";
import i18n from "@/_locales";
import { extractText } from "@/commons/utils/htmlUtils";

export default {
  props: {
    height: {
      default: 619,
      type: Number
    }
  },
  components: { editor: Editor },
  data() {
    return {
      rootBlockStyle: "",
      tinymceInit: {
        forced_root_block: "p",
        forced_root_block_attrs: {
          style: this.rootBlockStyle
        },

        height: 619,
        resize: false,
        menubar: false,
        branding: false,
        elementpath: false,
        automatic_uploads: false,
        image_description: false,
        image_dimensions: true,
        /*
         * 이 옵션을 사용하면 MS Word 및 유사한 Office 제품군 제품의 내용을 붙여넣을 때 유지할 스타일을 지정할 수 있습니다.
         * 이 옵션은 공백으로 구분된 CSS 스타일 이름 목록으로 설정하거나, 모든 스타일을 유지하려면 all 로 설정할 수 있습니다.
         */
        paste_retain_style_properties: "all",
        /*
         * 이 옵션은 붙여넣은 내용에서 이미지(인라인 이미지) SRC 가 data:url 를 허용 할지 결정합니다.
         */
        paste_data_images: true, // 데이터 이미지(data:url) 허용 여부
        // image 붙여넣기 blob사용 X
        images_dataimg_filter: function(img) {
          return img.hasAttribute("internal-blob");
        },
        base_url: "/js",
        image_prepend_url: `${this.imageUrl}/`,
        file_picker_types: "image",
        file_picker_callback: this.test,
        plugins: [
          "lists image autolink hr charmap fullscreen link preview",
          "table quickbars paste code"
        ],
        checkTable: this.checkTable,

        quickbars_insert_toolbar: false,
        quickbars_selection_toolbar:
          "bold italic underline | quicklink lineheight",
        toolbar:
          "ai | fontselect fontsizeselect | \
             bold italic strikethrough underline forecolor backcolor lineheight | \
             alignleft aligncenter alignright | \
             bullist numlist outdent indent | \
             image link table hr quickbars | \
             fullscreen | \
             undo redo | code",

        font_formats: i18n.t("common.font-family"),
        fontsize_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt",
        content_style: "p { margin: 0px; }",
        table_default_styles: {
          "word-break": "break-all",
          "border-collapse": "collapse",
          width: "100%"
        },
        setup: function(editor) {
          if (!this.useAI) return;
          const vm = this;

          // AI
          editor.ui.registry.addIcon(
            "creationIcon",
            `<svg viewBox="0 0 24 24"><path d="M9 4L11.5 9.5L17 12L11.5 14.5L9 20L6.5 14.5L1 12L6.5 9.5L9 4M9 8.83L8 11L5.83 12L8 13L9 15.17L10 13L12.17 12L10 11L9 8.83M19 9L17.74 6.26L15 5L17.74 3.75L19 1L20.25 3.75L23 5L20.25 6.26L19 9M19 23L17.74 20.26L15 19L17.74 17.75L19 15L20.25 17.75L23 19L20.25 20.26L19 23Z" /></svg>`
          );

          editor.ui.registry.addMenuButton("ai", {
            class: "creationIcon",
            icon: "creationIcon",
            text: "AI로 글쓰기",
            fetch: function(callback) {
              const items = [
                {
                  type: "menuitem",
                  text: "메일 제목 만들기",
                  onAction: function() {
                    vm.openAIDialog("SUBJECT");
                  }
                },
                {
                  type: "menuitem",
                  text: "철자와 문법 수정",
                  onAction: function() {
                    vm.openAIDialog("SPELLING");
                  }
                }
              ];
              callback(items);
            }
          });
        }.bind(this)
      }
    };
  },
  computed: {
    ...mapGetters("ai", ["useAI"]),
    ...mapGetters("mailCompose", ["content"]),
    ...mapGetters("mailConfig", ["getWriteConfig"]),
    imageUrl() {
      return process.env.VUE_APP_API_SERVER_URL;
    }
  },
  watch: {
    content(content) {
      const { initFontStyle, initFontSize } = this.getWriteConfig;

      this.rootBlockStyle = `font-family: ${initFontStyle}; font-size: ${initFontSize};`;
      getTinymce().EditorManager.execCommand("fontName", false, initFontStyle);
      getTinymce().EditorManager.execCommand("fontSize", false, initFontSize);
      getTinymce().activeEditor.setContent(content);
    },
    height(height) {
      this.$refs.tinyRef.$parent.$el.querySelector(
        ".tox.tox-tinymce"
      ).style = `height: ${height}px`;
    }
  },
  methods: {
    ...mapActions("ai", ["showAIDialog"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    // AI
    getText() {
      const htmlString = getTinymce().activeEditor.getContent();
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const targetElement = doc.querySelector(".sep-contents");

      // 클래스가 "sep-contents" 인 요소의 오쪽 텍스트를 추출
      let content = "";
      let currentElement = targetElement?.previousSibling ?? null;
      if (currentElement !== null) {
        while (currentElement) {
          if (
            currentElement.nodeType === Node.ELEMENT_NODE ||
            currentElement.nodeType === Node.TEXT_NODE
          ) {
            content =
              currentElement.nodeType === Node.ELEMENT_NODE
                ? currentElement.outerHTML + content
                : currentElement.textContent + content;
          }
          currentElement = currentElement.previousSibling;
        }
      } else {
        content = htmlString;
      }
      return content;
    },
    openAIDialog(type) {
      this.showAIDialog({
        type: type,
        contents: extractText(this.getText()),
        run: true
      });
    },
    // parameter -- value, meta
    test(callback) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");

      input.onchange = async function() {
        const file = this.files[0];
        const { data, status } = await uploadFile(
          file,
          uploadType.TEMP_FILE,
          {}
        );
        if (status != 200 || data.status != 2) {
          // 이미지업로드실패
          return;
        }

        let { protocol, hostname, port } = window.location;
        if (!port) {
          port = protocol == "http:" ? 80 : 443;
        }

        const host_url = `${protocol}//${hostname}:${port}`;
        const url = `${host_url}/api/mail/image/file/${
          data.id
        }?access_token=${localStorage.getItem("access_token")}`;

        // 서버통해서
        callback(`${url}`, { alt: "image file" });
      };
      input.click();
    },
    checkTable(e, next) {
      const html = e.clipboardData.getData("text/html");
      const doc = new DOMParser().parseFromString(html, "text/html");
      const [htmlEl] = doc.getElementsByTagName("html");
      const [google] = doc.getElementsByTagName("google-sheets-html-origin");

      // ms - xmlns:o="urn:schemas-microsoft-com:office:office"
      // google - google-sheets-html-origin
      const isOffice =
        htmlEl.getAttribute("xmlns:o") ==
          "urn:schemas-microsoft-com:office:office" || google;

      if (!isOffice) {
        const imgList = doc.querySelectorAll('img[src*="/api/mail/image/cid"]');
        // 오피스 문서
        if (imgList.length === 0) return next();

        // CID 가 포함된 경우
        this.checkImage(doc, imgList, next);
        return;
      }

      const {
        top: iframeElementTop,
        left: iframeElementLeft
      } = window.tinymce.activeEditor.iframeElement.getBoundingClientRect();
      const {
        top: selectionTop,
        left: selectionLeft
      } = window.tinymce.activeEditor.selection.getBoundingClientRect();
      this.positioningMenu({
        x: iframeElementLeft + selectionLeft,
        y: iframeElementTop + selectionTop,
        itemList: [
          {
            label: i18n.t("common.5"),
            func: () => {
              const div = document.createElement("div");
              div.style.display = "inline-block";
              div.innerHTML = html;
              document.body.appendChild(div);
              html2canvas(div).then(function(canvas) {
                window.tinymce.activeEditor.selection.setContent(
                  `<img src="${canvas.toDataURL()}" />`
                );
                window.tinymce.activeEditor.focus();
              });
              document.body.removeChild(div);
            }
          },
          {
            label: i18n.t("common.6"),
            func: () => {
              this.applyStyle(html, htmlEl);
              this.optimizeStyles(htmlEl);

              const [t] = htmlEl.getElementsByTagName("table");
              if (t) t.border = "1";

              next({
                "text/html": new XMLSerializer().serializeToString(htmlEl)
              });
            }
          }
        ]
      });
    },
    applyStyle(htmlText, htmlEl) {
      const styleMatches = htmlText.match(/<style[^>]*>([\s\S]*?)<\/style>/g);
      if (!styleMatches) {
        console.log("No <style> tags found in the HTML text.");
        return;
      }
      styleMatches.forEach(styleTag => {
        const matchResult = styleTag.match(/<style[^>]*>([\s\S]*?)<\/style>/);
        if (!matchResult) {
          console.log("Could not extract CSS from <style> tag.");
          return;
        }
        const cssText = matchResult[1].replace(/\/\*[\s\S]*?\*\//g, "").trim();
        const rules = cssText.split("}");
        rules.forEach(rule => {
          if (rule.trim() === "") return;
          const [selectors, styles] = rule.split("{");

          const selectorText = selectors.split(",");
          selectorText.forEach(selector => {
            if (!selector || !styles) return;
            if (selector.indexOf("@") > -1) return;
            if (selector.indexOf("<") > -1) return;
            if (selector.indexOf("*") > -1) return;

            htmlEl.querySelectorAll(selector.trim()).forEach(el => {
              const style = styles
                .replace(/[\n\r\t]+/g, " ")
                .replace(/:(.*?);/g, ':"$1";')
                .trim();

              el.style.cssText += style;
              el.className = "";
            });
          });
        });
      });
    },
    optimizeStyles(el) {
      // 보더 스타일 제거
      el.querySelectorAll("*").forEach(element => {
        if (element.style.border === "none") {
          element.style.border = "";
        }
      });

      // 로컬 이미지 제거
      const images = el.querySelectorAll("img");
      images.forEach(image => {
        if (image.src.startsWith("file://")) {
          image.remove();
        }
      });
    },
    checkImage(doc, imgList, next) {
      // 모든 이미지를 Base64로 변환하는 프로미스 배열 생성
      const promises = Array.from(imgList).map(img => {
        return this.convertImageToBase64(img.src).then(base64 => {
          img.setAttribute("src", base64);
          img.removeAttribute("data-mce-src");
        });
      });

      // 모든 이미지 변환이 완료된 후 처리
      Promise.all(promises)
        .then(() => {
          next({ "text/html": new XMLSerializer().serializeToString(doc) });
        })
        .catch(error => {
          console.error("이미지 변환 오류:", error);
        });
    },
    convertImageToBase64: function(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        // 'crossOrigin' 속성을 'anonymous' 로 설정하여, CORS 정책을 준수하면서 다른 출처의 이미지를 불러옵니다.
        // 이 설정은 이미지 요청 시 추가적인 사용자 자격 증명을 보내지 않습니다.
        // 웹 페이지는 CORS 헤더가 적절히 설정된 경우에만 이 이미지에 접근할 수 있습니다.
        img.crossOrigin = "anonymous";
        img.onload = function() {
          const canvas = document.createElement("canvas");
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          canvas.getContext("2d").drawImage(this, 0, 0);

          const dataURL = canvas.toDataURL("image/png");
          canvas.remove();
          resolve(dataURL);
        };
        img.onerror = function() {
          reject(new Error("error."));
        };
        img.src = url;
      });
    }
  }
};
</script>

<style scoped>
.mce-item-table {
  border: 0px !important;
}
</style>
<style lang="scss" scoped>
// 에디터 하단 상자 제거
::v-deep .tox.tox-tinymce {
  border: 1px solid rgba(0, 0, 0, 0.12);
  .tox-statusbar {
    display: none;
  }
}

/* AI 툴바 */
::v-deep .tox .tox-editor-header {
  .tox-toolbar__primary .tox-toolbar__group:first-child {
    .tox-icon {
      width: 24px;
      svg {
        fill: var(--v-primary-base);
      }
    }
  }
}

/* 툴바 버튼 */
::v-deep .tox .tox-tbtn {
  border-radius: 0px;
  &:hover,
  &:active,
  &:focus {
    background: #eeeeee;
  }
  /* 툴바 버튼 아이콘 컬러 */
  .tox-icon svg {
    fill: rgba(0, 0, 0, 0.54);
  }
}
/* 툴바 메뉴 */
.tox ::v-deep .tox-tiered-menu {
  border: 1px solid red;
}
::v-deep .tox .tox-menu {
  border-width: 0px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
</style>
