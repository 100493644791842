<template>
  <v-btn
    icon
    :disabled="isSpam"
    @click.stop="updateReadStatus({ mails: [item] })"
  >
    <v-icon v-if="item.readStatus == 1" color="grey lighten-1">
      mdi-email-open-outline
    </v-icon>
    <v-icon v-else color="grey lighten-1">mdi-email</v-icon>

    <v-icon
      v-if="item.replyStatus && !isMinimum"
      color="red lighten-1"
      :class="{
        'cr-reply': item.replyStatus && !item.forwardStatus,
        'cr-reply-forward': item.replyStatus && item.forwardStatus
      }"
    >
      mdi-arrow-left-bold
    </v-icon>
    <v-icon
      v-if="item.forwardStatus && !isMinimum"
      color="blue lighten-1"
      :class="{
        'cr-forward': item.forwardStatus && !item.replyStatus,
        'cr-forward-reply': item.forwardStatus && item.replyStatus
      }"
    >
      mdi-arrow-right-bold
    </v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.cr-reply,
.cr-forward {
  position: absolute;
  top: 5px;
  right: -6px;
  height: 20px !important;
  font-size: 17px !important;
}

.cr-forward {
  left: unset;
  right: -6px;
}

.cr-reply-forward,
.cr-forward-reply {
  position: absolute;
  top: 6px;
  right: 0px;
  height: 20px !important;
  font-size: 16px !important;
}
.cr-forward-reply {
  top: 1px;
  right: -7px;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isMinimum: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("folder", ["isSpam"])
  },
  methods: {
    ...mapActions("mail", ["updateReadStatus"])
  }
};
</script>
