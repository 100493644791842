<template>
  <div class="cr-tool">
    <WriteToolbar v-if="!showSendResult && isShow('write')" />
    <ListToolbar v-if="isShow('list')" />
    <!-- 보낸메일함/임시보관함 -->
    <SentToolbar v-if="isShow('sent')" />

    <ShareToolbar v-if="isShow('share')" />
    <SpamListToolbar v-if="isShow('spam')" />
    <v-divider v-show="showDivider" />
  </div>
</template>

<style lang="scss" scoped>
.cr-tool {
  position: relative;
}
</style>

<script>
import { mapGetters } from "vuex";
import { shareFolderType } from "@/mail/constant/folderType";
import WriteToolbar from "./WriteToolbar";
import ListToolbar from "./ListToolbar";
import SentToolbar from "./SentToolbar";
import ShareToolbar from "./ShareToolbar";
import SpamListToolbar from "./SpamListToolbar";

export default {
  components: {
    WriteToolbar,
    ListToolbar,
    SentToolbar,
    ShareToolbar,
    SpamListToolbar
  },
  computed: {
    ...mapGetters("folder", ["currentFolder"]),
    ...mapGetters("mailCompose", ["showSendResult", "isReserve"]),
    ...mapGetters("mailConfig", ["getSplitView"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    isShare() {
      return !!shareFolderType[this.currentFolder?.folderType];
    },
    showShareTr() {
      const { sfi, owi } = this.getRouteListInfo.actionObj || {};
      return this.isShare && !sfi && !owi;
    },
    isShow: vue => view => {
      const routeName = vue.$route.name.split("_")[1];
      if (!vue.currentFolder) return false;
      const { folderType } = vue.currentFolder;

      if (
        (vue.getSplitView > 0 &&
          routeName != "write" &&
          routeName != "config") ||
        !routeName ||
        routeName == "list"
      ) {
        switch (folderType) {
          case "SENT":
          case "DRAFTS":
            return view == "sent";
          case "SHARED_ROOT":
          case "SHARING_ROOT": {
            if (!vue.showShareTr) return view == "list";
            return view == "share";
          }
          case "SPAM":
            return view == "spam";
        }

        return view == "list";
      }
      if (view == "list" && !routeName) return true;
      return routeName == view;
    },
    showDivider() {
      if (this.isShow("list")) return true;
      if (this.isShow("sent")) return true;
      if (this.isShow("share")) return true;
      if (this.isShow("spam")) return true;

      return false;
    }
  }
};
</script>
