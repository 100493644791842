<template>
  <v-row class="pb-2">
    <v-col cols="12" sm="4" md="4" class="pa-0">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="queryFromModel"
        :label="$t('mail.보낸사람')"
        class="pa-1"
        autocomplete="off"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="pa-0">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="queryToModel"
        :label="$t('mail.받는사람')"
        class="pa-1"
        autocomplete="off"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="pa-0">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="queryCcModel"
        :label="$t('mail.참조')"
        class="pa-1"
        autocomplete="off"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    queryFrom: {
      default: "",
      type: String
    },
    queryTo: {
      default: "",
      type: String
    },
    queryCc: {
      default: "",
      type: String
    }
  },
  computed: {
    queryFromModel: {
      get() {
        return this.queryFrom;
      },
      set(queryFrom) {
        this.$emit("update:queryFrom", queryFrom);
      }
    },
    queryToModel: {
      get() {
        return this.queryTo;
      },
      set(queryTo) {
        this.$emit("update:queryTo", queryTo);
      }
    },
    queryCcModel: {
      get() {
        return this.queryCc;
      },
      set(queryCc) {
        this.$emit("update:queryCc", queryCc);
      }
    }
  }
};
</script>
