<template>
  <v-row class="ma-0 cr-border">
    <v-col
      class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2  font-weight-bold"
      cols="12"
      sm="4"
    >
      {{ $t("mail.539") }}
    </v-col>

    <v-col sm="8" cols="12" class="pl-0 d-flex align-center">
      <v-menu
        offset-y
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :return-value.sync="startTimeMillis"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="max-width:120px"
            outlined
            dense
            solo
            flat
            readonly
            hide-details
            v-model="startTimeMillis"
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startTimeMillis"
          no-title
          scrollable
          color="primary"
          :min="today"
        >
          <v-spacer></v-spacer>
          <v-btn text outlined @click="startMenu = false">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn outlined color="accent" @click="startTimeMillisClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
      <span class="mb-0 mx-1" style="font-size:20px">
        ~
      </span>
      <v-menu
        offset-y
        ref="endMenu"
        v-model="endMenu"
        :close-on-content-click="false"
        :return-value.sync="endTimeMillis"
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mr-3"
            style="max-width:120px"
            outlined
            dense
            solo
            flat
            readonly
            hide-details
            v-model="endTimeMillis"
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="endTimeMillis"
          no-title
          scrollable
          color="primary"
          :min="startTimeMillis"
        >
          <v-spacer></v-spacer>
          <v-btn text outlined @click="endMenu = false">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn outlined color="accent" @click="endTimeMillisClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      today: getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD"),
      startMenu: false,
      endMenu: false
    };
  },
  computed: {
    startTimeMillis: {
      get() {
        const { startTimeMillis } = this.config;
        return moment(startTimeMillis).format("YYYY-MM-DD");
      },
      set(startTimeMillis) {
        startTimeMillis = moment(startTimeMillis).valueOf();
        this.$emit("update:config", { ...this.config, startTimeMillis });
      }
    },
    endTimeMillis: {
      get() {
        const { endTimeMillis } = this.config;
        return moment(endTimeMillis).format("YYYY-MM-DD");
      },
      set(endTimeMillis) {
        endTimeMillis = moment(endTimeMillis).valueOf();
        this.$emit("update:config", { ...this.config, endTimeMillis });
      }
    }
  },
  methods: {
    startTimeMillisClick() {
      this.$store;
      const { startTimeMillis, endTimeMillis } = this;

      if (endTimeMillis && startTimeMillis > endTimeMillis) {
        this.startTimeMillis = endTimeMillis;
        this.$refs.startMenu.save(endTimeMillis);

        this.endTimeMillis = startTimeMillis;
        this.$refs.endMenu.save(startTimeMillis);
      } else {
        this.$refs.startMenu.save(startTimeMillis);
      }
    },
    endTimeMillisClick() {
      const { startTimeMillis, endTimeMillis } = this;

      if (startTimeMillis && startTimeMillis > endTimeMillis) {
        this.startTimeMillis = endTimeMillis;
        this.$refs.startMenu.save(endTimeMillis);

        this.endTimeMillis = startTimeMillis;
        this.$refs.endMenu.save(startTimeMillis);
      } else {
        this.$refs.endMenu.save(endTimeMillis);
      }
    }
  }
};
</script>
