<template>
  <div class="d-flex align-center pl-4">
    <div class="text-subtitle-2 font-weight-bold">
      {{ $t("mail.571") }}
    </div>
    <v-btn small icon @click="RESET_SECURE_STATE" :disabled="showSecureModal">
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("mailCompose", ["showSecureModal"])
  },
  methods: {
    ...mapMutations("mailCompose", ["RESET_SECURE_STATE"])
  }
};
</script>
