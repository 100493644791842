<template>
  <div>
    <div v-show="signStyle == 'BASIC'" class="cr-basic-wrapper">
      <v-row class="ma-0 mb-2 cr-info-box">
        <v-col class="py-0 mb-2" cols="6">
          <div class="mb-2 grey--text text--darken-2" v-text="$t('mail.306')" />
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newTitle"
            :placeholder="$t('mail.307')"
            maxlength="20"
            v-model="title"
          ></v-text-field>
        </v-col>
        <v-col class="py-0 mb-2" cols="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.308')" />
          </div>
          <v-select
            dense
            outlined
            hide-details
            item-text="name"
            item-value="style"
            :items="styleList"
            v-model="signStyle"
            @change="changeStyle"
          ></v-select>
        </v-col>
        <v-col class="py-0 mb-2" cols="12" sm="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.이름')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newName"
            placeholder="이름"
            v-model="name"
          ></v-text-field>
        </v-col>
        <v-col class="py-0 mb-2" cols="12" sm="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.회사명')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newCompanyName"
            placeholder="회사명"
            v-model="companyName"
          ></v-text-field>
        </v-col>

        <v-col class="py-0 mb-2" cols="12" sm="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.부서')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newOrganName"
            placeholder="부서"
            v-model="organName"
          ></v-text-field>
        </v-col>
        <v-col class="py-0 mb-2" cols="12" sm="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.직급')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newGrade"
            placeholder="직급"
            v-model="grade"
          ></v-text-field>
        </v-col>

        <v-col class="py-0 mb-2" cols="12">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.309')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newCompanyAddress"
            placeholder="회사 주소"
            v-model="companyAddress"
          ></v-text-field>
        </v-col>

        <v-col class="py-0 mb-2" cols="12">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.265')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newEmail"
            v-model="email"
          ></v-text-field>
        </v-col>

        <v-col class="py-0 mb-2" cols="6" sm="6">
          <div class="d-flex mb-2">
            <div
              class="grey--text text--darken-2"
              v-text="$t('mail.전화번호')"
            />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newTel"
            v-model="tel"
          ></v-text-field>
        </v-col>

        <v-col class="py-0 mb-2" cols="6" sm="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.핸드폰')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newMobile"
            v-model="mobile"
          ></v-text-field>
        </v-col>

        <v-col class="py-0 mb-2" cols="12">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.310')" />
          </div>
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newAdditional"
            v-model="additional"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="py-0 cr-preview-box" cols="12">
          <v-divider class="mb-3"></v-divider>
          <div
            class="mb-2 grey--text text--darken-2"
            v-text="$t('mail.미리보기')"
          />
          <div class="pa-3 priview" v-html="preview"></div>
        </v-col>
      </v-row>
    </div>
    <div v-show="signStyle == 'FREE'" class="cr-free-wrapper">
      <v-row class="ma-0 cr-info-box">
        <v-col class="py-0 mb-2" cols="6">
          <div class="mb-2 grey--text text--darken-2" v-text="$t('mail.306')" />
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newTitle"
            :placeholder="$t('mail.307')"
            maxlength="20"
            v-model="title"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="6">
          <div class="d-flex mb-2">
            <div class="grey--text text--darken-2" v-text="$t('mail.308')" />
            <v-tooltip
              :top="$vuetify.breakpoint.smAndDown"
              :right="!$vuetify.breakpoint.smAndDown"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-1"
                  color="red accent-3"
                  small
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-exclamation-thick'"
                ></v-icon>
              </template>
              <div class="caption" v-html="$t('mail.311')"></div>
            </v-tooltip>
          </div>
          <v-select
            dense
            outlined
            hide-details
            item-text="name"
            item-value="style"
            :items="styleList"
            v-model="signStyle"
            @change="changeStyle"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ma-0 px-3">
        <div style="width: 100%; padding-top: 8px;">
          <TinymceEditor v-if="isShow" ref="editor" :useCode="true" />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import i18n from "@/_locales";
import TinymceEditor from "@/commons/views/editor/tinymce/TinymceEditor";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getUserDetail, getCompanyInfo } from "@/commons/api/auth.api";

export default {
  components: { TinymceEditor },
  async created() {
    // 추가 일때, 기본값 설정
    if (this.dialogType == "signAdd") {
      await this.basicStyleGetInitData();
    } else {
      // 수정 일때, 기본값 설정
      const { sign: item = {} } = this.params;
      const { title, signStyle, contents } = item;
      this.title = title;
      this.signStyle = signStyle;

      // 기본형 일 경우,
      if (this.signStyle == "BASIC") {
        const domparser = new DOMParser();
        const document = domparser.parseFromString(contents, "text/html");
        const contentsEle = document.body.firstElementChild;

        this.name = contentsEle.querySelector(
          'div[style*="cls: name"]'
        )?.innerText;
        this.companyName = contentsEle.querySelector(
          'div[style*="cls: companyName"]'
        )?.innerText;
        this.grade = contentsEle.querySelector(
          'span[style*="cls: grade"]'
        )?.innerText;
        this.organName = contentsEle.querySelector(
          'span[style*="cls: organName"]'
        )?.innerText;
        this.companyAddress = contentsEle.querySelector(
          'div[style*="cls: companyAddress"]'
        )?.innerText;
        this.email = contentsEle.querySelector(
          'span[style*="cls: email"]'
        )?.innerText;
        this.tel = contentsEle.querySelector(
          'span[style*="cls: tel"]'
        )?.innerText;
        this.mobile = contentsEle.querySelector(
          'span[style*="cls: mobile"]'
        )?.innerText;
        this.additional = contentsEle.querySelector(
          'div[style*="cls: additional"]'
        )?.innerText;
      } else {
        this.editorContent = contents;
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.showEditor = true;
    // }, 0);
    setTimeout(() => {
      this.SET_INIT({
        editorId: "tiny_sign",
        content: this.editorContent,
        height: 345
      });
    }, 10);
  },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    styleList: [
      { style: "BASIC", name: i18n.t("mail.312") },
      { style: "FREE", name: i18n.t("mail.313") }
    ],
    title: "",
    signStyle: "BASIC",
    name: "",
    organName: "",
    grade: "",

    companyName: "",
    companyAddress: "",
    email: "",
    tel: "",
    mobile: "",
    additional: "",

    editorContent: "",
    showEditor: false
  }),
  watch: {
    confirm(n) {
      if (n) {
        const validation = this.validationCheck();
        if (!validation) return;
        this.dialogType == "signAdd" ? this.add() : this.edit();
      }
    }
  },
  computed: {
    ...mapGetters("editor", ["isShow", "getContent"]),
    ...mapGetters("mailDialog", ["dialogType", "params"]),
    // 미리보기 템플릿
    preview() {
      const {
        name,
        organName,
        companyName,
        grade,
        companyAddress,
        email,
        tel,
        mobile,
        additional
      } = this;

      let html = "";

      // 서명 내용
      html +=
        "<div style='border:0px solid #CCC; font-family: 굴림; line-height:1.5; color: #808080; font-size: 12px; letter-spacing: 1px;'>";
      if (name) {
        html += `<div style='color: black; letter-spacing: 2px; font-size: 16px; font-weight: bold; cls: name;'>${name}</div>`;
      }
      if (companyName) {
        html += `<div style='color: rgb(0, 161, 234); font-size: 14px; font-weight: bold; cls: companyName;' >${companyName}</div>`;
      }

      if (grade || organName) {
        html += "<div>";
        if (grade) html += `<span style='cls: grade;'>${grade}</span>`;

        if (grade && organName) html += "<span> | </span>";

        if (organName)
          html += `<span style='cls: organName;'>${organName}</span>`;

        html += "</div>";
      }

      if (companyAddress) {
        html += `<div style='cls: companyAddress;'>${companyAddress}</div>`;
      }

      if (email || tel || mobile) {
        html += "<br />";
        html += "<div>";
        if (email) {
          html += "<div>";
          html += `<span style='font-weight: bold;'>Email. </span>`;
          html += `<span style='cls: email;'>${email}</span>`;
          html += "</div>";
        }
        if (tel) {
          html += `<span style='font-weight: bold;'>Tel. </span>`;
          html += `<span style='padding-right:10px; cls: tel;'>${tel}</span>`;
        }
        if (mobile) {
          html += `<span style='font-weight: bold;'>Mobile. </span>`;
          html += `<span style='cls: mobile;'>${mobile}</span>`;
        }
        html += "</div>";
      }
      if (additional) {
        html += `<div style='cls: additional;'>${additional}</div>`;
      }
      html += "</div>";

      return html;
    }
  },
  methods: {
    ...mapMutations("editor", ["SET_INIT", "SET_CONTENT"]),
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mailConfigSign", ["addSign", "editSign"]),

    // 서명 스타일 변경
    async changeStyle(style) {
      if (style == "BASIC") {
        await this.basicStyleGetInitData();

        // 기본형으로 변경시 팝업 메뉴 제거
        const moreBtn = document.getElementsByClassName(
          "tox-tbtn tox-tbtn--enabled"
        );
        if (moreBtn.length > 0) {
          for (var el of moreBtn) {
            el.click();
          }
        }
      } else {
        this.editorContent = this.preview;
        this.SET_CONTENT(this.preview);
      }
    },

    // 기본형 일 경우 초기 데이터 가져오기
    async basicStyleGetInitData() {
      const { data: userData = {} } = await getUserDetail();
      const { accountName, jobGrade, organizationName, username } = userData;
      const { data: companyData = {} } = await getCompanyInfo();
      const { companyName, information } = companyData;

      this.name = accountName;
      this.organName = organizationName;
      this.grade = jobGrade;
      this.email = username;

      this.companyName = companyName;
      this.companyAddress = information.address;
    },

    // 유효성 체크
    validationCheck() {
      // 제목은 필수 입력 사항
      const { title } = this;
      this.title = title.trim();
      if (!this.title) {
        this.openSnackbar({
          message: i18n.t("mail.314"),
          type: "VALIDATION"
        });
        this.$refs.newTitle.focus();
        this.$emit("update:confirm", false);
        return false;
      }
      return true;
    },

    // 서명 추가
    async add() {
      const result = await this.addSign({
        title: this.title,
        signStyle: this.signStyle,
        contents: this.signStyle == "BASIC" ? this.preview : this.getContent
      });
      if (result) {
        this.CLOSE_DIALOG();
      } else {
        this.$emit("update:confirm", false);
      }
    },

    // 서명 수정
    async edit() {
      const result = await this.editSign({
        id: this.params?.sign?.id,
        title: this.title,
        signStyle: this.signStyle,
        contents: this.signStyle == "BASIC" ? this.preview : this.getContent
      });
      if (result) {
        this.CLOSE_DIALOG();
      } else {
        this.$emit("update:confirm", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-basic-wrapper ::v-deep {
  .cr-info-box {
    height: 200px;
    overflow-y: auto;
  }
  .cr-preview-box {
    height: 223px;
    .priview {
      height: calc(100% - 43px);
      border: 1px solid black;
      max-width: 100%;
      overflow-y: auto;
    }
  }
}
</style>
