<template>
  <div class="d-flex justify-space-between align-center">
    <div class="d-flex adr-from" style="min-width:0px;">
      <!-- 보낸사람 -->
      <AdrEmail
        ref="adrEmail"
        cls="text-h6"
        type="view"
        :address="address"
        :personal="personal"
        :countryCode="item.countryCode"
      />
    </div>

    <!-- 닫혀있을때 -->
    <div v-if="!isPanelOpen" style="min-width: 72px;">
      <ReadStatusBtn :item="item" />
      <Important :mail="item" />
    </div>

    <!-- 열렸을때 -->
    <div v-else-if="fromWidth > 0" class="d-flex cr-view-print-hide">
      <ViewToolbar
        ref="toolRef"
        :key="item.mailId"
        :item="item"
        :toolLimitWidth="toolLimitWidth"
        :style="$vuetify.breakpoint.xs ? 'margin-right: -11px;' : ''"
      />
      <Important v-if="attachPosition == -1" :mail="item" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdrEmail from "@/mail/views/components/main/commons/adrEmail";
import ReadStatusBtn from "@/mail/views/components/main/list/td/ReadStatusBtn";
import Important from "@/mail/views/components/main/commons/Important";
import ViewToolbar from "@/mail/views/components/main/toolbar/ViewToolbar";

export default {
  components: { AdrEmail, ReadStatusBtn, Important, ViewToolbar },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    resizeWidth: {
      type: Number,
      default: 0
    },
    isPanelOpen: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    setTimeout(() => {
      const { adrEmail } = this.$refs;
      this.fromWidth = adrEmail?.activatorWidth || 0;
    }, 0);
  },
  data() {
    return { fromWidth: 0 };
  },
  computed: {
    ...mapGetters("mailConfig", ["getSplitView"]),
    ...mapGetters("mailRoute", ["getRouteViewInfo"]),
    ...mapGetters("mailLayout", ["mainWidth"]),
    address() {
      return this.item?.from?.address ?? "";
    },
    personal() {
      return this.item?.from?.personal ?? "";
    },
    attachPosition() {
      return this.item?.attachPosition || -1;
    },
    toolLimitWidth() {
      let toolLimitWidth = 0;
      if (!this.isPanelOpen) return toolLimitWidth;

      toolLimitWidth = this.mainWidth;
      // 좌우 분할시 목록 크기
      if (this.getSplitView == 1) {
        const w = toolLimitWidth - this.resizeWidth;
        // MailView (.cr-split-1) 최소 크기 870
        toolLimitWidth = w <= 870 ? 870 : w;
      }

      // 36 중요, 90 간격, 17 스크롤 => 143
      return toolLimitWidth - (143 + this.fromWidth);
    }
  }
};
</script>

<style lang="scss" scoped>
.adr-from ::v-deep .font-weight-bold {
  font-size: 18px !important;
}
</style>
