<template>
  <div>{{ fetchDate }}</div>
</template>

<script>
import { getDateWithTime } from "@/commons/utils/moment";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  computed: {
    fetchDate() {
      const { fetchTimeMillis } = this.item;
      return fetchTimeMillis ? getDateWithTime(fetchTimeMillis) : "";
    }
  }
};
</script>
