<template>
  <!-- 첨부파일 정보 -->
  <div class="grey--text caption">
    <span class="mr-1">{{ $t("mail.124") }}</span>
    <span class="mx-1 grey--text text--darken-2">{{ tempFileSize }}</span>
    <span class="mx-1">/</span>
    <span>{{ "20MB" }}</span>

    <span class="mr-1 ml-3">
      <span class="mr-1">{{ $t("mail.125") }}</span>
      <span class="grey--text text--darken-2">
        {{ largeTempFileSize }}
      </span>
      <span class="mx-1">/</span>
      <span>{{ largeTempFileMaxSize }}</span>
    </span>

    <span class="ml-1 mr-3">
      {{ $t("mail.126", { value: attachFiles.length }) }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  computed: {
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),
    ...mapGetters("mailFile", { attachFiles: "items" }),
    ...mapGetters("mailFile", ["totalTempSize", "totalLargeTempSize"]),
    tempFileSize() {
      return byteCalculation(this.totalTempSize);
    },
    largeTempFileSize() {
      return byteCalculation(this.totalLargeTempSize);
    },
    largeTempFileMaxSize() {
      return byteCalculation(this.getMailServiceConfig.largeFileMaxSize);
    }
  }
};
</script>
