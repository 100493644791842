<template>
  <!-- v-show="
      closedGroup.findIndex(
        g => g == (isExchange ? parentItem.group : item.group)
      ) == -1 &&
        closedDivision.findIndex(
          d => d == (isExchange ? parentItem.division : item.division)
        ) == -1
    " -->
  <tr
    v-show="
      closedGroup.findIndex(
        g => g == (isExchange ? parentItem.group : item.group)
      ) == -1
    "
    :class="getTrClass"
    :data-id="item.mailId"
    :data-dataId="item.dataId"
    @mouseover="$emit('mouseover', item)"
    @mouseleave="$emit('mouseleave')"
    @click="$emit('mailRowClick', item)"
  >
    <!-- 체크박스, 메일주소, 날짜, hover 버튼 -->
    <td class="v-data-table__mobile-row pt-1">
      <div
        v-if="isExchange"
        class="v-timeline v-timeline--dense theme--light cr-timeline-minimum"
      />
      <div
        class="d-flex align-center cr-minimum-adrfrom"
        :class="{ exchange: isExchange, 'cr-mobile': $vuetify.breakpoint.xs }"
        :style="
          `max-width: ${resizeWidth - 210}px; width: ${resizeWidth - 210}px;`
        "
      >
        <Checkbox
          v-if="!isExchange"
          :item="item"
          @selectItem="$emit('selectFunc', item)"
        />
        <AdrEmail
          cls="ml-1"
          :address="address"
          :personal="personal"
          :ruleSelection="isDrafts || isSent ? 'to' : 'from'"
          :readStatus="item.readStatus"
          :type="isSent ? 'view' : 'list'"
          :countryCode="item.countryCode"
        />
      </div>
      <div v-if="item === hoverItem" class="cr-floating-btn-group">
        <ReadStatusBtn :item="item" :isMinimum="true" />
      </div>
      <span
        v-else
        style="position: relative;"
        class="pr-4 pl-7"
        :class="{ 'grey--text': item.readStatus, 'primary--text': isReserve }"
      >
        <v-icon
          v-if="item.replyStatus"
          color="red lighten-1"
          :class="{
            'cr-reply': item.replyStatus,
            'cr-reply-forward': item.replyStatus && item.forwardStatus
          }"
        >
          mdi-arrow-left-bold
        </v-icon>
        <v-icon
          v-if="item.forwardStatus"
          color="blue lighten-1"
          :class="{
            'cr-forward': item.forwardStatus,
            'cr-forward-reply': item.replyStatus && item.forwardStatus
          }"
        >
          mdi-arrow-right-bold
        </v-icon>
        {{ item.receivedTimeMillis }}
      </span>
    </td>
    <!-- 제목 -->
    <td class="v-data-table__mobile-row">
      <div class="prepend-area">
        <ExpandBtn
          v-if="
            (!isExchange && !isSent && item.exchangedMail && !isShare) ||
              (isSent && !isReserve && !isReserveCancel)
          "
          class="cr-expand-btn"
          :item="item"
          :isExpanded="isExpanded"
          :isSent="isSent"
          v-on="$listeners"
        />

        <v-hover v-else-if="isExchange && !isSent" v-slot:default="{ hover }">
          <div class="hover-wrapper">
            <v-timeline
              dense
              :class="
                `cr-timeline-minimum ${hover || isSelected ? 'hover' : ''} ${
                  showTags ? 'cr-tags' : ''
                }`
              "
            >
              <v-timeline-item
                right
                small
                :color="isSelectedView ? 'primary' : 'grey'"
              />
            </v-timeline>
            <Checkbox
              v-if="hover || isSelected"
              :item="item"
              :class="`cr-exchange-checkbox ${showTags ? 'cr-tags' : ''}`"
              @selectItem="$emit('selectFunc', item)"
            />
          </div>
        </v-hover>
      </div>

      <div
        class="cr-minimum-subject-wrapper d-flex align-center"
        :class="`${item.includesAttach ? 'ml-6' : 'ml-7'}`"
        :style="
          `max-width: ${resizeWidth - 110}px; width: ${resizeWidth - 110}px;`
        "
      >
        <v-icon v-if="item.includesAttach" class="mr-1" small color="black">
          mdi-paperclip
        </v-icon>
        <div :class="subjectClass" v-highlight="item.subject" />
        <div
          v-if="(isReserve || isReserveCancel) && item !== hoverItem"
          :class="isReserveCancel ? 'text-decoration-line-through' : ''"
          class="primary--text"
          style="position: absolute; right: 15px;"
          v-text="$t('mail.예약')"
        />
        <PopupViewBtn :item="item" />
      </div>
    </td>
    <!-- 내용 미리보기 / 중요표시 -->
    <td
      class="v-data-table__mobile-row grey--text"
      :class="{ 'pb-1': !showTags }"
    >
      <div
        v-if="isExchange"
        class="v-timeline v-timeline--dense theme--light cr-timeline-minimum"
      />
      <div class="cr-minimum-preview-wrapper" :class="{ exchange: isExchange }">
        <div
          class="cr-minimum-preview"
          :style="
            `max-width: ${resizeWidth - 110}px; width: ${resizeWidth - 110}px;`
          "
        >
          <v-chip
            v-if="isBlockedMail"
            color="red"
            label
            small
            outlined
            class="mr-1 px-1 cr-name-folder"
            :class="showTags ? 'mb-1' : ''"
            v-bind:title="
              item.mailType == 'REPORT_MAIL' ? $t('mail.198') : $t('mail.154')
            "
          >
            {{
              item.mailType == "REPORT_MAIL" ? $t("mail.199") : $t("mail.200")
            }}
          </v-chip>
          <v-chip
            v-if="isPromotionMail"
            :color="getPromotionColor"
            label
            small
            outlined
            class="mr-1 px-1 cr-name-folder"
            :class="showTags ? 'mb-1' : ''"
            v-bind:title="getPromotionTooltip"
          >
            {{ getPromotionTitle }}
          </v-chip>
          <v-chip
            v-if="nameFolder"
            label
            small
            outlined
            class="mr-1 px-1 cr-name-folder"
            :class="showTags ? 'mb-1' : ''"
          >
            {{ nameFolder }}
          </v-chip>
          <v-chip
            v-show="showSent"
            label
            small
            class="mr-1 px-1 cr-sent-mail"
            :class="showTags ? 'mb-1' : ''"
          >
            <span v-text="$t('mail.201')"></span>
          </v-chip>
          {{ item.contentPreview || $t("mail.202") }}
        </div>
        <span v-if="item.priority == 1" class="priority">
          <v-icon color="red" size="18">mdi-exclamation-thick</v-icon>
        </span>
        <div class="important"><Important :mail="item" /></div>
      </div>
    </td>
    <!-- 태그 -->
    <td v-if="showTags" class="v-data-table__mobile-row">
      <div
        v-if="isExchange"
        class="v-timeline v-timeline--dense theme--light cr-timeline-minimum"
      />
      <div class="cr-tags-wrapper" :style="`width: ${resizeWidth - 30}px`">
        <div class="cr-tags">
          <v-chip
            label
            small
            :color="color"
            :text-color="invertColor(color)"
            class="mr-1 cr-tag-chip"
            v-for="({ tag, color }, idx) in tags"
            :key="idx"
            @click.stop="routeTag(`t_${tag}`)"
          >
            {{ tag }}
          </v-chip>
        </div>
      </div>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
/* tr */
tr.v-data-table__mobile-table-row.cr-minimum-row {
  &.table-row {
    display: table-row;
    /* 마우스 오버 */
    &.hover {
      background-color: #efefef70 !important;
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgb(60 60 60 / 20%), 0 1px 3px 1px rgb(60 60 60 / 10%);
    }
  }

  /* td */
  .v-data-table__mobile-row {
    position: relative;
    text-align: left;
    min-height: 24px;

    > .cr-minimum-adrfrom {
      max-width: 190px;
      min-width: 190px;
      width: 190px;
      vertical-align: middle;
      line-height: 23px;
      height: 23px;

      &.exchange {
        padding-left: 23px;
      }

      &.cr-mobile {
        min-width: 190px;
      }
    }

    .cr-reply,
    .cr-forward {
      position: absolute;
      top: 4px;
      left: 10px;
      height: 14px !important;
      font-size: 14px !important;
      padding-bottom: 2px;
    }
    .cr-reply-forward {
      top: 6px;
      left: 7px;
      font-size: 13px !important;
    }
    .cr-forward-reply {
      top: 1px;
      left: 13px;
      font-size: 13px !important;
    }

    /* 확장 버튼, 타임라인, 체크박스 등 */
    > .prepend-area {
      position: absolute;
      top: 0px;
      height: 24px;
      width: 24px;
      /* expand 버튼 */
      > .cr-expand-btn {
        height: 24px;
        width: 24px;
      }
    }
    .cr-minimum-subject-wrapper {
      > .cr-minimum-subject {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        line-height: 23px;
        height: 23px;
      }
    }

    > .cr-minimum-preview-wrapper {
      position: relative;
      width: 100%;
      min-height: 24px;
      margin-left: 13px;

      /* 미리보기 옆에 중요도 있는 메일 표시 버튼 */
      > .priority {
        position: absolute;
        right: 36px;
        bottom: 4px;
      }

      /* 미리보기 옆에 중요표시 버튼 */
      > .important {
        position: absolute;
        right: 5px;
        bottom: -4px;
      }
      > .cr-minimum-preview {
        display: inline-block;
        margin-left: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }

    /* mouse hover시 플로팅버튼 */
    > .cr-floating-btn-group ::v-deep {
      position: absolute;
      right: 5px;
      top: 0px;

      &.exchange {
        right: 1px;
      }
    }

    > .cr-tags-wrapper {
      position: relative;
      margin-bottom: 2px;
      margin-top: -3px;
      height: 24px;

      .cr-tags {
        position: absolute;
        left: 28px;
        right: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .cr-tag-chip:hover {
          opacity: 0.3;
        }
      }
    }
  }

  /* 주고받은 메일 부분 */
  &.expand {
    background: rgba(210, 210, 210, 0.12);

    /* 타임라인 선 */
    .cr-timeline-minimum {
      position: absolute;
      left: -20px;
      top: 0px;
      bottom: 0px;

      &.v-timeline ::v-deep {
        padding-top: 21px;
        /* 동그라미 위치 */
        .v-timeline-item .v-timeline-item__divider {
          margin-top: -20px;
          min-width: 24px;
          margin-left: 36px;
          /* 동그라미 사이즈 */
          > .v-timeline-item__dot--small {
            height: 18px;
            left: calc(50% - 12px);
            width: 18px;
            > .v-timeline-item__inner-dot {
              height: 12px;
              margin: 3px;
              width: 12px;
            }
          }
        }
      }

      &.hover.v-timeline ::v-deep {
        .v-timeline-item .v-timeline-item__divider {
          display: none;
        }
      }

      &.cr-tags.v-timeline ::v-deep {
        .v-timeline-item .v-timeline-item__divider {
          margin-top: -8px;
        }
      }
    }

    > td > .prepend-area > .hover-wrapper {
      height: 24px;
      width: 24px;

      /* v-hover에 있는 타임라인 위치 */
      > .cr-timeline-minimum {
        left: -36px;
        top: 0px;
        bottom: 0px;
      }

      /* 체크박스 */
      .v-input--checkbox.cr-exchange-checkbox ::v-deep {
        position: absolute;
        top: 2px;
        left: 2px;
        i.mdi-checkbox-blank-outline::before {
          content: "\F0130";
        }
        i.mdi-checkbox-marked::before {
          content: "\F0133";
        }
        .v-input__control .v-input__slot .v-input--selection-controls__input {
          background-color: #fff;
          border-radius: 50%;
          height: 20px;
          width: 20px;
        }
      }
      .v-input--checkbox.cr-exchange-checkbox.cr-tags ::v-deep {
        top: 12px;
        z-index: 999;
      }
    }
  }

  /* 메일보기 일때 배경색 */
  &.cr-selected-panel {
    background-color: #efefef !important;
  }

  /* 안읽음 표시선 */
  &.unread {
    > td {
      border-left: 3px solid var(--v-primary-base) !important;
    }
    > .v-data-table__mobile-row {
      /* 타임라인 선 */
      > .cr-timeline-minimum {
        left: -23px;
      }
      > .cr-expand-btn {
        left: 9px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import AdrEmail from "@/mail/views/components/main/commons/adrEmail";
import Important from "../../commons/Important";

import Checkbox from "../td/Checkbox";
import ExpandBtn from "../td/ExpandBtn";
import ReadStatusBtn from "../td/ReadStatusBtn";
import PopupViewBtn from "../td/PopupViewBtn";

export default {
  components: {
    AdrEmail,
    Important,
    Checkbox,
    ExpandBtn,
    ReadStatusBtn,
    PopupViewBtn
  },
  props: {
    item: {
      default: () => {},
      type: Object
    },
    parentItem: {
      default: () => {},
      type: Object
    },
    hoverItem: {
      default: () => {},
      type: Object
    },
    closedGroup: {
      default: () => {},
      type: Array
    },
    // closedDivision: {
    //   default: () => [],
    //   type: Array,
    //   description: "닫힌 분류 목록"
    // },
    resizeWidth: {
      default: 0,
      type: Number
    },
    isSent: {
      default: false,
      type: Boolean
    },
    isDrafts: {
      default: false,
      type: Boolean
    },
    isSpam: {
      default: false,
      type: Boolean
    },
    isSelected: {
      default: false,
      type: Boolean
    },
    isExpanded: {
      default: false,
      type: Boolean
    },
    isCreatedGroup: {
      default: false,
      type: Boolean
    },
    isExchange: {
      default: false,
      type: Boolean
    },
    exchangeCls: {
      default: "",
      type: String
    }
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo", "getRouteViewInfo"]),
    ...mapGetters("folder", [
      "getTags",
      "getFolder",
      "isShare",
      "currentFolder"
    ]),
    subjectClass() {
      let cls = "cr-minimum-subject ";

      cls += this.item.readStatus ? " " : "font-weight-bold ";
      cls += this.isBlockedMail ? "red--text" : "";

      return cls;
    },
    isBlockedMail() {
      return (
        this.item.mailType == "REPORT_MAIL" ||
        this.item.mailType == "SYSTEM_BLOCK"
      );
    },
    isPromotionMail() {
      return ["PROMOTION", "SOCIAL"].includes(this.item.mailType);
    },
    getPromotionColor() {
      if (this.item.mailType == "SOCIAL") return "#FD1D53";
      return "#0080FF";
    },
    getPromotionTitle() {
      if (this.item.mailType == "SOCIAL") return this.$t("mail.505");
      return this.$t("mail.503");
    },
    getPromotionTooltip() {
      if (this.item.mailType == "SOCIAL") return this.$t("mail.506");
      return this.$t("mail.504");
    },
    getTrClass() {
      let classStr = "v-data-table__mobile-table-row cr-minimum-row table-row";
      if (this.item === this.hoverItem) classStr += " hover";
      if (this.item.readStatus == 0) classStr += " unread";
      if (this.isExchange) classStr += ` expand ${this.exchangeCls}`;
      if (this.isSelectedView) classStr += " cr-selected-panel";

      if (
        !this.isShare &&
        this.item.folderType !== "DRAFTS" &&
        this.item.folderType !== "TOME"
      ) {
        classStr += " cr-draggable-list";
      }

      return classStr;
    },
    isReserve() {
      return this.item.mailType == "RESERVE";
    },
    isReserveCancel() {
      return this.item.mailType == "RESERVE_CANCEL";
    },
    isSelectedView() {
      return (
        this.getRouteViewInfo?.actionObj?.view?.selectedDataId ==
        this.item.dataId
      );
    },
    nameFolder() {
      const folder = this.getFolder(this.item.folderId);
      if (!folder) return "";
      if (this.getRouteListInfo.folderId > 0) {
        if (this.currentFolder.id !== folder.id) return folder.nameFolder;
        return "";
      }

      return folder.nameFolder;
    },
    address() {
      return this.isDrafts || this.isSent
        ? this.item.adrTo?.address
        : this.item.adrFrom?.address;
    },
    personal() {
      return this.isDrafts || this.isSent
        ? this.item.adrTo?.personal
        : this.item.adrFrom?.personal;
    },
    tags() {
      const { tags } = this.item;
      if (!tags) return [];
      return tags?.split(",")?.map(tag => {
        const [filteredTag] = this.getTags.filter(t => t.tag === tag);
        return {
          tag: filteredTag?.tag || tag,
          color: filteredTag?.color || "#E0E0E0"
        };
      });
    },
    showTags() {
      return this.tags.length > 0;
    },
    showSent() {
      if (this.isSent) return false;
      const folder = this.getFolder(this.item.folderId);
      return this.item.mailType == "SENT" && folder?.folderType !== "SENT";
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeTag"]),
    invertColor(hex) {
      return invertColor(hex);
    }
  }
};
</script>
