<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <!-- 글꼴 설정 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-top cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        <div class="pt-0 pt-sm-2" v-text="$t('mail.210')"></div>
      </v-col>
      <v-col cols="12" sm="8">
        <div class="d-flex mb-3">
          <v-select
            class="mr-3"
            style="max-width: 200px;"
            dense
            outlined
            hide-details
            item-text="text"
            item-value="value"
            :menu-props="{ 'offset-y': true, closeOnClick: true }"
            :items="fontStyleList"
            v-model="config.initFontStyle"
          ></v-select>
          <v-select
            style="max-width: 100px;"
            dense
            outlined
            hide-details
            :menu-props="{ 'offset-y': true, closeOnClick: true }"
            :items="fontSizeList"
            v-model="config.initFontSize"
          ></v-select>
        </div>
        <div class="pa-3" style="border: thin solid rgba(0, 0, 0, 0.12);">
          <p class="mb-1" :style="fontPriviewStyle" v-text="$t('mail.211')"></p>
          <p class="mb-0" :style="fontPriviewStyle">Editor font preview.</p>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 보내는 사람 이름 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        md="4"
        v-text="$t('mail.212')"
      ></v-col>
      <v-col cols="6" md="4" class="pr-0 d-flex">
        <v-text-field
          outlined
          hide-details
          dense
          v-model="senderName"
          @keyup.enter="addSenderName"
        />
        <v-btn
          tile
          depressed
          height="40"
          color="primary"
          @click="addSenderName"
          v-text="$t('common.추가')"
        >
        </v-btn>
      </v-col>
      <v-col cols="6" md="4">
        <v-select
          dense
          outlined
          hide-details
          ref="fromRef"
          :menu-props="{ 'offset-y': true, closeOnClick: true }"
          :items="config.senderNames"
          v-model="config.defaultSenderName"
          @click:append.stop="$refs.fromRef.activateMenu()"
        >
          <template v-slot:selection="{ item }">
            <div>{{ item }}</div>
          </template>
          <template v-slot:item="{ item }">
            <div style="position: relative; width: 100%;">
              {{ item }}
              <v-btn
                v-if="item != config.defaultSenderName"
                icon
                x-small
                style="position: absolute; right: 0px; top: 2px;"
                @click.stop="deleteSenderName(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 새창으로 쓰기 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('mail.66')"
      ></v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.popupWrite" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 말머리 -->
    <v-row class="ma-0">
      <v-col
        class="d-flex align-center cr-config-title grey--text text--darken-2"
        cols="4"
        v-text="$t('mail.213')"
      >
      </v-col>
      <v-col cols="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.useSubjectFirst" />
      </v-col>
      <v-row class="ma-0 d-flex justify-end">
        <v-col
          cols="6"
          md="4"
          class="d-flex pr-0 pt-0"
          v-if="config.useSubjectFirst"
        >
          <v-text-field
            outlined
            hide-details
            dense
            v-model="subjectFirst"
            :disabled="config.subjectFirsts.length >= 5"
            @keyup.enter="addSubjectFirst"
          />
          <v-btn
            tile
            height="40"
            depressed
            color="primary"
            :disabled="config.subjectFirsts.length >= 5"
            @click="addSubjectFirst"
            v-text="$t('common.추가')"
          >
          </v-btn>
        </v-col>
        <v-col cols="6" md="4" class="pt-0" v-if="config.useSubjectFirst">
        </v-col>
      </v-row>
    </v-row>
    <v-row class="ma-0" v-if="config.useSubjectFirst">
      <v-col class="pb-0 pb-sm-3 d-flex align-top" cols="12" sm="4">
        <span
          class="cr-config-title grey--text text--darken-2"
          v-text="$t('mail.214')"
        ></span>
        <span
          class="text-caption pl-2"
          style="padding-top:2px;"
          v-text="$t('mail.215')"
        ></span>
      </v-col>
      <v-col
        v-if="config.subjectFirsts.length > 0"
        cols="12"
        sm="8"
        class="cr-first-list-wrapper"
      >
        <v-list dense>
          <v-list-item
            class="px-0"
            v-for="(subjectFirst, index) in config.subjectFirsts"
            :key="index"
          >
            <v-list-item-content class="pl-1">
              <v-list-item-title
                class="text-black text-subtitle-1"
                v-text="subjectFirst"
              >
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <v-btn
                  small
                  plain
                  :ripple="false"
                  icon
                  @click="deleteUseSubjectFirst(subjectFirst)"
                >
                  <v-icon v-text="'mdi-close'" />
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col v-else cols="12" sm="8" class="cr-first-list-wrapper">
        <div
          class="pa-4 d-flex justify-center cr-no-data"
          v-text="$t('common.3')"
        ></div>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 이모지 -->
    <v-row class="ma-0">
      <v-col
        class="d-flex align-center cr-config-title grey--text text--darken-2"
        cols="4"
        v-text="$t('mail.507')"
      >
      </v-col>
      <v-col cols="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.useSubjectEmoji" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 나를 참조에 포함 -->
    <v-row class="ma-0">
      <v-col
        class="d-flex align-center cr-config-title grey--text text--darken-2"
        cols="5"
        sm="4"
        md="4"
        v-text="$t('mail.216')"
      >
      </v-col>
      <v-col cols="7" sm="8" md="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="includeMe" />
      </v-col>
      <v-col cols="0" sm="4" md="4" class="py-0" />
      <v-col
        v-if="config.includeMe"
        class="d-flex align-center pr-0"
        cols="6"
        sm="3"
        md="3"
      >
        <v-radio-group v-model="config.includeMe" row hide-details>
          <v-radio :label="$t('mail.참조')" :value="1"></v-radio>
          <v-radio :label="$t('mail.숨은참조')" :value="2"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="config.includeMe" cols="6" sm="5" md="5" class="pl-0">
        <v-text-field
          outlined
          hide-details
          dense
          v-model="config.includeMeEmail"
        />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 임시보관 메일 재사용 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('mail.217')"
      >
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.draftReuse" />
      </v-col>
    </v-row>
    <v-divider />
    <!-- 발송 전 미리보기 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('mail.218')"
      >
      </v-col>
      <v-col cols="12" sm="8">
        <v-select
          style="max-width: 400px;"
          dense
          outlined
          hide-details
          item-text="text"
          item-value="value"
          :menu-props="{ 'offset-y': true, closeOnClick: true }"
          :items="previewList"
          v-model="config.previewBeforeSend"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 자동 임시저장 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('mail.219')"
      >
      </v-col>
      <v-col cols="12" sm="8">
        <v-select
          style="max-width: 400px;"
          dense
          outlined
          hide-details
          ref="autoSaveRef"
          item-text="text"
          item-value="value"
          :items="autoSaveList"
          :menu-props="{ 'offset-y': true, closeOnClick: true }"
          v-model="config.autoSave"
          @click:append.stop="$refs.autoSaveRef.activateMenu()"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 제목 템플릿 -->
    <div v-if="useAI">
      <v-row class="ma-0">
        <v-col
          class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
          cols="12"
          sm="4"
        >
          <v-icon class="mr-1" color="primary">mdi-creation-outline</v-icon>
          <span v-text="$t('mail.579')"></span>
        </v-col>
        <v-col cols="12" sm="8">
          <v-select
            style="max-width: 400px;"
            dense
            outlined
            hide-details
            item-text="text"
            item-value="value"
            :menu-props="{ 'offset-y': true, closeOnClick: true }"
            :items="subjectFormatList"
            v-model="config.subjectFormat"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
}
.cr-config-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.v-list-item > .v-list-item__content {
  padding: 12px 10px;
}

.v-btn {
  border-radius: 0px;
}
.cr-first-list-wrapper {
  .v-list ::v-deep {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .cr-no-data {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.38);
  }
}
</style>

<script>
import i18n from "@/_locales";
import { isBlank, isEmail } from "@/commons/utils/validation";
import { mapGetters } from "vuex";

export default {
  props: {
    writeConfig: {
      default: () => {},
      type: Object
    }
  },
  data: ({ writeConfig }) => ({
    config: { ...writeConfig },
    senderName: "",
    subjectFirst: "",
    fontStyleList: [
      { text: i18n.t("common.font_나눔고딕"), value: "나눔고딕" },
      { text: i18n.t("common.font_맑은고딕"), value: "맑은 고딕" },
      { text: i18n.t("common.font_굴림"), value: "굴림" },
      { text: i18n.t("common.font_굴림체"), value: "굴림체" },
      { text: i18n.t("common.font_궁서"), value: "궁서" },
      { text: i18n.t("common.font_궁서체"), value: "궁서체" },
      { text: i18n.t("common.font_돋움"), value: "돋움" },
      { text: i18n.t("common.font_돋움체"), value: "돋움체" },
      { text: i18n.t("common.font_바탕"), value: "바탕" },
      { text: i18n.t("common.font_바탕체"), value: "바탕체" },
      { text: "Arial", value: "arial" },
      { text: "Tahoma", value: "tahoma" },
      { text: "Comic Sans MS", value: "comic sans ms,sans-serif" }
    ],
    fontSizeList: [
      "8pt",
      "9pt",
      "10pt",
      "11pt",
      "12pt",
      "14pt",
      "18pt",
      "24pt",
      "30pt",
      "36pt"
    ],
    previewList: [
      { text: i18n.t("mail.220"), value: 0 },
      { text: i18n.t("mail.221"), value: 1 },
      { text: i18n.t("mail.222"), value: 2 }
    ],
    autoSaveList: [
      { text: i18n.t("mail.70"), value: 0 },
      { text: i18n.t("mail.71"), value: 30 },
      { text: i18n.t("mail.72"), value: 60 },
      { text: i18n.t("mail.73"), value: 180 },
      { text: i18n.t("mail.74"), value: 300 }
    ],
    subjectFormatList: [
      { text: i18n.t("mail.220"), value: "" },
      { text: i18n.t("mail.581"), value: i18n.t("mail.582") },
      { text: i18n.t("mail.583"), value: i18n.t("mail.584") }
    ]
  }),
  computed: {
    ...mapGetters("ai", ["useAI"]),
    includeMe: {
      get() {
        return this.config.includeMe > 0;
      },
      set(includeMe) {
        this.config.includeMe = includeMe ? this.writeConfig.includeMe || 1 : 0;
      }
    },
    fontPriviewStyle() {
      const { initFontStyle, initFontSize } = this.config;
      return `font-family: ${initFontStyle}; font-size: ${initFontSize};`;
    }
  },
  watch: {
    config: {
      deep: true,
      handler(config) {
        if (isBlank(config.includeMeEmail) || !isEmail(config.includeMeEmail)) {
          this.$emit("update:disabledBtnGroups", true);
        } else {
          Object.keys(config).forEach(key => {
            if (typeof config[key] == "boolean") {
              config[key] = config[key] ? 1 : 0;
            }
          });

          this.$emit(
            "update:disabledBtnGroups",
            JSON.stringify(config) == JSON.stringify(this.writeConfig)
          );
        }
      }
    },
    writeConfig() {
      this.reset();
    }
  },
  methods: {
    reset() {
      this.config = { ...this.writeConfig };
    },
    // 보내는 사람 이름 추가
    addSenderName() {
      const senderName = this.senderName.trim();
      if (!senderName) return;
      this.config.senderNames = [...this.config.senderNames, senderName];
      this.config.defaultSenderName = senderName;
      this.senderName = "";
    },
    // 말머리 추가
    addSubjectFirst() {
      const subjectFirst = this.subjectFirst.trim();
      if (!subjectFirst) return;
      this.config.subjectFirsts = [...this.config.subjectFirsts, subjectFirst];
      this.subjectFirst = "";
    },
    // 보내는 사람 이름 삭제
    deleteSenderName(select) {
      this.config.senderNames = [...this.config.senderNames];
      let index = this.config.senderNames.findIndex(name => name == select);
      this.config.senderNames.splice(index, 1);
    },
    // 말머리 삭제
    deleteUseSubjectFirst(select) {
      this.config.subjectFirsts = [...this.config.subjectFirsts];
      let index = this.config.subjectFirsts.findIndex(name => name == select);
      this.config.subjectFirsts.splice(index, 1);
    }
  }
};
</script>
