<template>
  <tr
    v-show="closedDivision.findIndex(d => d == item.division) == -1"
    class="v-row-group__header white"
    @click="$emit('expandGroup', item.group)"
  >
    <td :class="isMinimum ? 'd-flex align-center' : ''" colspan="100">
      <v-btn icon>
        <v-icon v-if="!isExpanded">mdi-menu-right</v-icon>
        <v-icon v-else>mdi-menu-down</v-icon>
      </v-btn>
      {{ item.group }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {
      default: () => {},
      type: Object
    },
    closedGroup: {
      default: () => {},
      type: Array
    },
    closedDivision: {
      default: () => [],
      type: Array,
      description: "닫힌 분류 목록"
    },
    isMinimum: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    isExpanded() {
      return this.closedGroup.findIndex(g => g == this.item.group) == -1;
    }
  }
};
</script>
