<template>
  <div class="d-flex align-center mt-1">
    <div class="cr-detail-label text-subtitle-2">
      {{ $t("mail.받는사람") }}
    </div>

    <v-select
      ref="toRef"
      dense
      outlined
      hide-details
      v-model="toSelectItem"
      :items="toSelectItems"
      :menu-props="{ 'offset-y': true, closeOnClick: true }"
      @click:append.stop="$refs.toRef.activateMenu()"
    />
    <v-text-field
      dense
      outlined
      hide-details
      autocomplete="off"
      v-model="text"
      @keydown.enter="$parent.searchDetail"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  height: 40px;

  &.v-select {
    max-width: 155px;
    margin-right: 5px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  mounted() {
    // url 타고들어왔을때
    const item = this.toSelectItems.find(i => this[i.value]);
    this.toSelectItem = item?.value || "queryToCc";
    this.text = this[this.toSelectItem];
  },
  data({ $store: { getters } }) {
    const { actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};
    let queryToCc = search?.queryToCc;
    if (search?.type === "all.to") queryToCc = search?.query;

    return {
      text: "",
      toSelectItem: "queryToCc",
      queryToCc,
      queryTo: search?.queryTo,
      queryCc: search?.queryCc
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    toSelectItems() {
      return [
        { text: this.$t("mail.30"), value: "queryToCc" },
        { text: this.$t("mail.받는사람"), value: "queryTo" },
        { text: this.$t("mail.참조"), value: "queryCc" }
      ];
    }
  },
  watch: {
    text(text) {
      this[this.toSelectItem] = text;
    },
    toSelectItem(toSelectItem) {
      this.toSelectItems.forEach(i => (this[i.value] = ""));
      this[toSelectItem] = this.text;
    },
    getRouteListInfo({ actionObj }) {
      const { search } = actionObj || {};
      this["queryToCc"] =
        search?.type === "all.to" && search?.query
          ? search?.query
          : search?.queryToCc || "";
      this["queryTo"] = search?.queryTo || "";
      this["queryCc"] = search?.queryCc || "";

      this.text = "";
      const item = this.toSelectItems.find(i => this[i.value]);
      if (!item) return;

      this.toSelectItem = item?.value || "queryToCc";
      this.text = this[this.toSelectItem];
    }
  },
  methods: {
    getParams() {
      const { toSelectItem } = this;
      const params = { queryToCc: "", queryTo: "", queryCc: "" };
      return {
        valid: !isBlank(this[toSelectItem]),
        params: { ...params, [toSelectItem]: this[toSelectItem] }
      };
    }
  }
};
</script>
