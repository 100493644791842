<template>
  <div class="cr-notice" :class="{ show: showNotice }">
    <ReserveNotice v-if="isReserve" />
    <SecureNotice v-if="isSecure" />
    <v-spacer />
    <AutoSave v-if="showAutoSave" />
  </div>
</template>

<style lang="scss" scoped>
.cr-notice {
  display: flex;
  align-items: center;
  border-top: thin solid rgba(0, 0, 0, 0.12);

  &.show {
    height: 35px;
    padding-right: 16px;
    border-top: none;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import AutoSave from "./AutoSave.vue";
import ReserveNotice from "./ReserveNotice.vue";
import SecureNotice from "./SecureNotice.vue";

export default {
  props: {
    showNotice: {
      type: Boolean,
      default: false
    }
  },
  components: { AutoSave, ReserveNotice, SecureNotice },
  computed: {
    ...mapGetters("mailCompose", ["loading", "isReserve", "isSecure"]),
    ...mapGetters("mailConfig", ["getAutoSave"]),
    ...mapGetters("mailRoute", ["getRouteWriteInfo"]),
    showAutoSave() {
      const { actionObj } = this.getRouteWriteInfo;
      const { t: type } = actionObj;
      return !this.loading && this.getAutoSave && type < 7;
    }
  }
};
</script>
