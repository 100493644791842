<template>
  <div class="cr-report-wrapper">
    <!-- 게시판 선택 -->
    <v-row no-gutters class="d-flex align-center px-1 mb-3">
      <v-col class="cr-title" cols="12" sm="3" v-text="$t('mail.292')"> </v-col>
      <v-col cols="12" sm="9">
        <v-select
          dense
          outlined
          hide-details
          :items="contentsItems"
          v-model="selectContents"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex align-center px-1">
      <v-col cols="12">
        <v-textarea
          ref="contentsRef"
          outlined
          no-resize
          height="200"
          hide-details
          tabindex="2"
          :placeholder="$t('mail.292')"
          v-model="contents"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex align-left px-1 pt-2">
      <div v-html="$t('mail.492')"></div>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    contents: i18n.t("mail.293"),
    contentsItems: [
      { value: "unverified", text: i18n.t("mail.294") },
      { value: "maicious", text: i18n.t("mail.295") },
      { value: "direct", text: i18n.t("mail.32") }
    ],
    selectContents: "unverified"
  }),
  mounted() {
    setTimeout(() => {
      this.$refs.contentsRef.focus();
    }, 0);
  },
  watch: {
    async confirm(value) {
      if (value) {
        const targetMail = this.params.mails[0];
        const param = {
          mailId: targetMail.mailId,
          reportContents: this.contents
        };
        await this.reportMaiciousMail(param);

        this.CLOSE_DIALOG();
      }
    },
    selectContents(next) {
      if (next == "unverified") {
        this.contents = i18n.t("mail.293");
      } else if (next == "maicious") {
        this.contents = i18n.t("mail.295");
      } else {
        // this.contents = i18n.t("mail.32");
        this.contents = "";
      }
      setTimeout(() => {
        this.$refs.contentsRef.focus();
      }, 0);
    }
  },
  computed: {
    ...mapGetters("mailDialog", ["params"])
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapActions("mail", ["reportMaiciousMail"])
  }
};
</script>

<style lang="scss" scoped>
.cr-report-wrapper ::v-deep {
  .v-input.v-textarea {
    .v-input__control {
      .v-input__slot {
        padding-right: 13px;
      }
      textarea {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
