<template>
  <div>
    <div class="d-flex align-baseline mt-2 user-select-text">
      <div class="cr-adr-label">
        <span>{{ `${$t("mail.읽은시간")}:` }}</span>
      </div>
      <div>{{ item.readTimeMillis }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
// 받는사람, 참조
.cr-adr-label {
  color: #333;
  float: left;
  width: 80px;
  min-width: 80px;
  margin-right: 8px;
}
</style>
