<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <!-- 안내 -->
    <v-row class="ma-0">
      <v-col class="d-flex mt-2 align-center" cols="12">
        <div class="text-caption d-none d-md-flex grey--text">
          {{ $t("mail.495") }}
        </div>
      </v-col>
    </v-row>
    <v-divider />

    <!-- 사용여부 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.494") }}
        <v-chip
          color="red"
          outlined
          x-small
          class="ml-1"
          style="padding:0px 6px;"
          >beta</v-chip
        >
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.smartMailbox" />
      </v-col>
    </v-row>
    <v-divider />

    <!-- 프로모션 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.496") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch
          hide-details
          inset
          v-model="config.classifyPromotion"
          :disabled="config.smartMailbox == 0"
        />
        <div class="text-caption grey--text mt-2">
          {{ $t("mail.497") }}
        </div>
      </v-col>
    </v-row>
    <v-divider />

    <!-- 소셜 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.498") }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch
          hide-details
          inset
          v-model="config.classifySocial"
          :disabled="config.smartMailbox == 0"
        />
        <div class="text-caption grey--text mt-2">
          {{ $t("mail.499") }}
        </div>
      </v-col>
    </v-row>
    <v-divider />

    <!-- 설명 -->
    <v-row class="ma-0">
      <v-col class="mt-2 align-center" cols="12">
        <div class="text-caption">
          <div v-html="$t('mail.500')"></div>
          <div v-html="$t('mail.501')"></div>
        </div>
        <div class="text-caption mt-2">
          {{ $t("mail.502") }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    smartMailboxConfig: {
      default: () => {},
      type: Object
    }
  },
  data: ({ smartMailboxConfig }) => ({
    config: { ...smartMailboxConfig }
  }),
  watch: {
    config: {
      deep: true,
      handler(config) {
        Object.keys(config).forEach(key => {
          if (typeof config[key] == "boolean") {
            config[key] = config[key] ? 1 : 0;
          }
        });

        this.$emit(
          "update:disabledBtnGroups",
          JSON.stringify(config) == JSON.stringify(this.smartMailboxConfig)
        );

        if (config.smartMailbox == 0) {
          config.classifyPromotion = 0;
          config.classifySocial = 0;
        }
      }
    },
    smartMailboxConfig() {
      this.reset();
    }
  },
  methods: {
    reset() {
      this.config = { ...this.smartMailboxConfig };
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-swtich-wrapper {
  display: block;
  .text-caption {
    line-height: 1;
  }
}
</style>
