<template>
  <v-expansion-panel :class="panelCls" @click="routeChange(false)">
    <v-expansion-panel-header ref="headerRef">
      <ExpansionHeader
        :item="item"
        :isPanelOpen="isPanelOpen"
        :resizeWidth="resizeWidth"
      />

      <!-- expansion panel action buttom 제거 -->
      <template v-slot:actions>
        <div />
      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <div class="cr-contents" @click="routeChange(true)">
        <MailBlock v-if="showMailBlock" :item="item" />
        <MailCalView v-if="showCalView" :mailId="item.mailId" />
        <AIButton v-if="useAI" :item="item" />
        <AttachList v-if="showAttachList" :mail="item" />

        <div ref="content" v-if="item.content" v-html="item.content" />
        <QuoteContent v-if="item.quoteContent" :item="item" />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
.v-expansion-panel {
  font-size: 0.875rem;
  margin: 5px;
  border: thin solid rgba(0, 0, 0, 0.12);
  &.cr-selected-panel {
    border-color: black;
  }

  > .v-expansion-panel-header {
    font-size: 0.875rem;
    padding: 16px 14px 16px 24px;
    &:not(.v-expansion-panel-header--mousedown):focus::before,
    &:hover::before {
      opacity: 0 !important;
    }

    > .cr-expansion-header-wrapper {
      min-width: 0px;
    }
  }
  > .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      padding: 0px;
    }
  }

  &.cr-loading::before {
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.cr-contents {
  padding: 0px 0px 22px 0px;
  user-select: text;
  line-height: 1.7;
  word-break: break-all;
}
.cr-contents ::v-deep p {
  padding: 0px;
  margin: 0px;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ExpansionHeader from "../header";
import MailBlock from "./MailBlock";
import MailCalView from "./MailCalView";
import AttachList from "./attach";
import QuoteContent from "./QuoteContent";
import AIButton from "./AIButton";

export default {
  components: {
    ExpansionHeader,
    MailBlock,
    MailCalView,
    AttachList,
    QuoteContent,
    AIButton
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    resizeWidth: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isPanelOpen: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    setTimeout(() => {
      const { headerRef, content } = this.$refs;
      // v-expansion-panel-header button이라서 ac에서 스페이스바 누르면 active됨 그래서 방지
      if (headerRef) {
        headerRef.$el.addEventListener("keyup", this.preventKeyup, true);
      }

      // print 할때 이미지 로딩시간 체크
      const { view = {} } = this.getRouteViewInfo?.actionObj || {};
      if (!content || !view.printable) return;

      const imgs = content.querySelectorAll("img");
      if (!imgs.length) return;

      this.SET_SHOW(true);
      this.imgCnt = imgs.length;
      imgs.forEach(img => {
        img.onload = () => {
          this.loadedImgCnt += 1;
        };
        img.onerror = () => {
          this.loadedImgCnt += 1;
        };
      });

      setTimeout(() => {
        if (this.completedLoad) return;

        imgs.forEach(img => {
          img.onload = () => {};
          img.onerror = () => {};
        });

        this.imgCnt = 0;
        this.loadedImgCnt = 0;
      }, 60000);
    }, 0);
  },
  destroyed() {
    const { headerRef } = this.$refs;
    if (!headerRef) return;

    headerRef.$el.removeEventListener("keyup", this.preventKeyup, true);
  },
  data() {
    return {
      imgCnt: 0,
      loadedImgCnt: 0
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteViewInfo"]),
    ...mapGetters("ai", ["useAI"]),
    panelCls() {
      const { dataId, loading } = this.item;
      const { selectedDataId } = this.getRouteViewInfo?.actionObj?.view || {};
      let str = "";
      if (dataId == selectedDataId) str += " cr-selected-panel";
      if (loading) str += " cr-loading";

      return str;
    },
    showMailBlock() {
      const { mailType } = this.item ?? {};
      return mailType == "REPORT_MAIL" || mailType == "SYSTEM_BLOCK";
    },
    showCalView() {
      const { mailType } = this.item ?? {};
      return mailType == "CALENDAR";
    },
    showAttachList() {
      const { attachList } = this.item;
      return attachList?.length > 0;
    },
    completedLoad() {
      return this.imgCnt == this.loadedImgCnt;
    }
  },
  watch: {
    completedLoad(bool) {
      if (!bool) return;

      this.SET_SHOW(false);
      // 로더 사라지는 속도 필요
      setTimeout(() => {
        window.print();
      }, 500);
    }
  },
  methods: {
    ...mapMutations("loader", ["SET_SHOW"]),
    ...mapMutations("mailRoute", ["SET_ROUTE_PARAMS"]),
    ...mapActions("mailRoute", ["routeMailView"]),
    preventKeyup(e) {
      e.preventDefault();
    },
    routeChange(isContent) {
      if (this.readonly) return;
      if (!isContent && this.isPanelOpen) {
        return this.$emit("close");
      }

      this.$emit("update:clickedPanel");
      const { dataId: selectedDataId } = this.item;
      const { actionObj = {} } = this.getRouteViewInfo || {};
      const { basedId } = actionObj?.view || {};
      if (this.$route.name !== "popup_mail_view") {
        return this.routeMailView({ basedId, selectedDataId });
      }

      const view = { ...actionObj.view, selectedDataId, printable: false };
      this.SET_ROUTE_PARAMS({
        mail_view: { actionObj: JSON.stringify({ ...actionObj, view }) }
      });
    }
  }
};
</script>
