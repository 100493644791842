<template>
  <v-list-item v-if="show" @click.prevent="$emit('back')">
    <v-list-item-avatar tile class="mr-0 my-3">
      <v-icon size="30" class="mr-2 justify-start">
        mdi-keyboard-backspace
      </v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="text-black text-subtitle-1">
      <v-list-item-title>
        {{ $t("mail.뒤로가기") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    folder: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    show() {
      const { fileType = "ROOT" } = this.folder;
      return fileType !== "ROOT" && !this.loading;
    }
  }
};
</script>
