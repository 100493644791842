<template>
  <div class="d-flex align-center justify-space-between">
    <v-btn icon @click.stop="$emit('expand', item, !isExpanded)">
      <v-icon v-if="!isExpanded">mdi-chevron-right</v-icon>
      <v-icon v-else>mdi-chevron-down</v-icon>
    </v-btn>
    <div
      style="cursor: pointer; width: 100%;"
      class="body-2"
      @click.stop="$emit('expand', item, !isExpanded)"
    >
      {{ getReceiptStr }}
    </div>
    <v-tooltip right :disabled="$vuetify.breakpoint.xs">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="red darken-3"
          style="margin-bottom: 1px;"
          v-bind="attrs"
          v-on="on"
          :disabled="cancelAllSendDisabled"
          @click.stop="cancelAllSend(item.receipt.id)"
        >
          <v-icon>mdi-email-remove-outline</v-icon>
        </v-btn>
      </template>
      <div class="caption" v-text="$t('mail.190')"></div>
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.text-start.sent {
  .cr-receipt-expand-btn {
    width: 125px;
    padding: 0px 12px 0px 0px;
    .cr-receipt-expand-icon {
      font-size: 15px;
      position: absolute;
      right: -6px;
      top: 1px;
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapActions } from "vuex";
import { receiptStatus } from "@/mail/constant/receiptStatus";

export default {
  props: {
    item: {
      default: () => {},
      type: Object
    },
    isExpanded: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    getReceiptStr() {
      switch (this.item?.receipt?.receiptStatus) {
        case receiptStatus.CANCEL:
          return i18n.t("mail.발송취소");
        case receiptStatus.READ:
        case receiptStatus.UNREAD:
          return i18n.t("mail.208", {
            readCount: this.item?.receipt?.readCount || 0,
            totalCount: this.item?.receipt?.totalCount || 0
          });
        default:
          return i18n.t("mail.209");
      }
    },
    cancelAllSendDisabled() {
      const {
        totalCount = 0,
        cancelCount = 0,
        failCount = 0,
        readCount = 0,
        cancelable = 0
      } = this.item?.receipt || {};

      return (
        cancelable == 0 || totalCount == cancelCount + failCount + readCount
      );
    }
  },
  methods: {
    ...mapActions("mailReceipt", ["cancelAllSend"])
  }
};
</script>
