<template>
  <div ref="wrapperRef" :class="wrapperCls">
    <ViewLoader v-if="showViewLoader" />
    <ViewThreads v-else-if="showViewThreads" v-bind="$props" ref="threads" />
    <div
      v-else
      class="subtitle-1 grey--text"
      style="text-align: center; padding-top: 100px;"
      v-html="$t('mail.59')"
    />
  </div>
</template>

<style lang="scss" scoped>
/* 메인 컨텐츠 영역 */
.cr-mail-main {
  .cr-wrapper {
    height: 100%;
    overflow: scroll !important;

    /* 데이타 테이블 */
    .cr-container {
      padding: 0px 12px 12px 12px;
      overflow-y: unset !important;
      &.cr-split-1 {
        min-width: 870px;
      }
    }
  }

  // 탭 사용
  &.cr-tab > .cr-wrapper {
    height: calc(100% - 48px);
  }

  // 검색 상세
  &.cr-search-detail > .cr-wrapper {
    height: calc(100% - 153px);
  }

  // 탭 사용 & 검색 상세
  &.cr-tab.cr-search-detail > .cr-wrapper {
    height: calc(100% - 201px);
  }

  // 검색 상세
  &.cr-search-detail.cr-search-detail-resize > .cr-wrapper {
    height: calc(100% - 193px);
  }

  // 탭 사용 & 검색 상세
  &.cr-tab.cr-search-detail.cr-search-detail-resize > .cr-wrapper {
    height: calc(100% - 241px);
  }
}
</style>

<style lang="scss">
@media print {
  html {
    overflow: auto;

    .v-application .v-main .v-main__wrap .cr-mail-main {
      height: auto !important;
      border-style: none !important;
      box-shadow: none !important;
    }
    .cr-wrapper {
      height: auto !important;
      overflow: unset !important;
    }
    .cr-container {
      overflow: auto !important;
      position: relative !important;
      .cr-subject {
        padding-left: 18px;
      }
      .v-expansion-panel {
        border-style: none;
      }
      .cr-view-print-hide {
        display: none !important;
      }
      .cr-quote-content {
        display: block !important;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewLoader from "./view/ViewLoader.vue";
import ViewThreads from "./view";

export default {
  components: { ViewLoader, ViewThreads },
  props: {
    resizeWidth: {
      default: 0,
      type: Number
    }
  },
  mounted() {
    this.getMailViews();
  },
  computed: {
    ...mapGetters("mailConfig", ["getSplitView"]),
    ...mapGetters("mailRoute", ["getRouteViewInfo"]),
    ...mapGetters("mailView", ["getBasedMailId", "requestParams"]),
    wrapperCls() {
      return { "cr-wrapper": true, "cr-split-1": this.getSplitView == 1 };
    },
    showViewLoader() {
      const { view = {} } = this.getRouteViewInfo?.actionObj || {};
      const { basedId } = view || {};
      return !!parseInt(basedId) && !this.getBasedMailId;
    },
    showViewThreads() {
      const { view = {} } = this.getRouteViewInfo?.actionObj || {};
      const { basedId } = view || {};
      return !!parseInt(basedId) && !!this.getBasedMailId;
    }
  },
  watch: {
    getRouteViewInfo() {
      this.getMailViews();
    }
  },
  methods: {
    ...mapActions("mailView", ["getMailViews"])
  }
};
</script>
