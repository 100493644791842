<template>
  <v-btn icon @click.stop="expandClick">
    <v-icon v-if="!isExpanded">mdi-chevron-right</v-icon>
    <v-icon v-else>mdi-chevron-down</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped></style>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      default: () => {},
      type: Object
    },
    isExpanded: {
      default: false,
      type: Boolean
    },
    isSent: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    ...mapActions("mail", ["getReferencesMailList"]),
    async expandClick() {
      if (this.item.exchangedMail && !this.isExpanded && !this.isSent) {
        await this.getReferencesMailList(this.item.mailId);
      }

      this.$emit("expand", this.item, !this.isExpanded);
    }
  }
};
</script>
