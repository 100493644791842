<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <!-- 사용여부 -->
    <UseAway :config.sync="config" />

    <div v-if="config.useAway">
      <!-- 부재기간 -->
      <AwayPeriod :config.sync="config" />
      <!-- 답장 내용 -->
      <Contents :config.sync="config" />
      <!-- 미리보기 -->
      <Preview :config.sync="config" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-border {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>

<script>
import { mapActions } from "vuex";
import UseAway from "./away/UseAway.vue";
import AwayPeriod from "./away/AwayPeriod.vue";
import Contents from "./away/Contents.vue";
import Preview from "./away/Preview.vue";

export default {
  components: { UseAway, AwayPeriod, Contents, Preview },
  props: {
    getAwayConfig: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.getAwayData();
  },
  data({ getAwayConfig }) {
    return {
      config: { ...getAwayConfig }
    };
  },
  watch: {
    config: {
      deep: true,
      handler(config) {
        Object.keys(config).forEach(key => {
          if (typeof config[key] == "boolean") {
            config[key] = config[key] ? 1 : 0;
          }
        });

        this.$emit(
          "update:disabledBtnGroups",
          JSON.stringify(config) == JSON.stringify(this.getAwayConfig)
        );
      }
    },
    getAwayConfig() {
      this.reset();
    }
  },
  methods: {
    ...mapActions("mailConfigAway", ["getAwayData"]),
    reset() {
      this.config = { ...this.getAwayConfig };
    }
  }
};
</script>
