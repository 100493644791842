<template>
  <div class="cr-recpt-selector-container pt-6">
    <div class="cr-recpt-selector-wrapper">
      <TreeView :selectorType="selectorType" :isOrgan="isOrgan" />
      <List
        v-bind="{ ...$data }"
        :selectorType="selectorType"
        @addRcpt="addRcpt"
        @removeRcpt="removeRcpt"
      />
      <SelectPanel
        v-bind="{ ...$data }"
        :selectedPanel.sync="selectedPanel"
        @removeRcpt="removeRcpt"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-recpt-selector-container {
  display: flex;
  flex-direction: column;
  height: 624px;

  .cr-recpt-selector-wrapper {
    display: flex;
    align-items: center;
    height: 600px;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isEmail } from "@/commons/utils/validation";
import TreeView from "./recipient/tree";
import List from "./recipient/list";
import SelectPanel from "./recipient/selectPanel";

export default {
  components: { TreeView, List, SelectPanel },
  props: {
    confirm: {
      type: Boolean,
      default: false
    }
  },
  data({ $store: { getters } }) {
    const to = [...getters["mailCompose/to"]];
    const cc = [...getters["mailCompose/cc"]];
    const bcc = [...getters["mailCompose/bcc"]];

    return { to, cc, bcc, selectedPanel: "to" };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    selectorType() {
      return this.params.type;
    },
    isOrgan() {
      return this.selectorType === "organ";
    }
  },
  watch: {
    confirm(n) {
      if (!n) return;

      ["to", "cc", "bcc"].forEach(k => {
        let key = 0;
        this.SET_RECIPIENT({
          [k]: this[k].map(r => {
            key += 1;
            return { key, valid: isEmail(r.value), value: r.value };
          })
        });
      });

      this.CLOSE_DIALOG();
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("mailCompose", ["SET_RECIPIENT"]),
    equalsValue(value1, value2) {
      const regex = /[\s"<>]/g;
      return value1.replace(regex, "") === value2.replace(regex, "");
    },
    equalsEmail(email1, email2) {
      return this.getEmail(email1) === this.getEmail(email2);
    },
    getEmail(email) {
      const [name, address] = email.split("<");

      if (address) return address.replace(">", "");
      return name;
    },
    addRcpt(rcpt) {
      // 중복 체크
      ["to", "cc", "bcc"].forEach(key => {
        this[key] = this[key].filter(r => {
          if (!rcpt.hasGroup) return !this.equalsEmail(r.value, rcpt.value);
          return (
            !this.equalsValue(r.value, rcpt.groupEmail) &&
            !this.equalsValue(r.value, rcpt.subGroupEmail)
          );
        });
      });

      // 추가
      if (rcpt.hasGroup) {
        rcpt.value = rcpt.hasSubGroup ? rcpt.subGroupEmail : rcpt.groupEmail;
      }
      this[this.selectedPanel] = [...this[this.selectedPanel], rcpt];
    },
    removeRcpt(rcpt) {
      const rcpts = this[this.selectedPanel];
      if (!rcpt.hasGroup) {
        this[this.selectedPanel] = rcpts.filter(r => r.value !== rcpt.value);
        return;
      }

      this[this.selectedPanel] = rcpts.filter(
        r => r.value !== rcpt.groupEmail && r.value !== rcpt.subGroupEmail
      );
    }
  }
};
</script>
