<template>
  <div class="cr-envelope-container" :class="breakpoint">
    <div
      class="d-flex align-center jutify-space-between"
      style="position:relative"
    >
      <v-subheader>
        {{ $t("mail.제목") }}
      </v-subheader>

      <v-checkbox
        hide-details
        append-icon="mdi-exclamation"
        class="cr-subject-checkbox mt-0 pt-0"
        :label="$t('mail.106')"
        v-model="_priority"
      />
    </div>

    <div class="cr-input-wrapper">
      <v-select
        v-if="getUseSubjectFirst"
        ref="subjectFirst"
        dense
        outlined
        hide-details
        return-object
        :placeholder="$t('mail.107')"
        :no-data-text="$t('mail.108')"
        class="cr-subject-first"
        item-text="content"
        item-value="key"
        v-model="_subjectFirst"
        :items="getSubjectFirsts"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
        @click:append.stop="$refs.subjectFirst.activateMenu()"
      />
      <v-text-field
        dense
        outlined
        hide-details
        autocomplete="off"
        :placeholder="$t('mail.109')"
        class="cr-subject-input"
        v-model="_subject"
      >
        <template v-slot:prepend-inner v-if="getUseSubjectEmoji">
          <div class="cr-emoji-container">
            <EmojiPicker @appendEmoji="emoji => (_subject += emoji)" />
          </div>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-subject-checkbox.v-input::v-deep {
  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        i {
          font-size: 16px;
        }
      }

      label {
        left: -7px !important;
        font-size: 0.72rem;
      }
    }
  }

  .v-input__append-outer {
    margin-left: -4px;
    .v-input__icon--append {
      min-width: 10px;
      width: 10px;
      .v-icon {
        font-size: 20px;
        color: crimson !important;
      }
    }
  }
}

.cr-input-wrapper {
  display: flex;
  .cr-subject-first::v-deep {
    max-width: 220px;
    fieldset {
      border-right: none !important;
    }

    &.v-input--is-focused,
    &.v-select--is-menu-active {
      fieldset {
        border: 1px solid gray !important;
      }
    }
  }
  .cr-subject-input.v-input::v-deep {
    .v-input__control {
      .v-input__slot {
        .v-input__prepend-inner {
          align-self: center;
          margin-top: 0px !important;
          box-sizing: border-box;
          margin-left: -8px;
          height: 34px;
        }
      }
    }
  }
}

// 반응형
.cr-envelope-container {
  &.cr-breakpoint-sm,
  &.cr-breakpoint-xs {
    .cr-input-wrapper {
      .cr-subject-first {
        max-width: 150px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import EmojiPicker from "@/commons/views/emoji/EmojiPicker";

export default {
  components: {
    EmojiPicker
  },
  computed: {
    ...mapGetters("mailCompose", ["subjectFirst", "subject", "priority"]),
    ...mapGetters("mailConfig", [
      "getSubjectFirsts",
      "getUseSubjectFirst",
      "getUseSubjectEmoji"
    ]),
    breakpoint() {
      const { name } = this.$vuetify.breakpoint;
      return `cr-breakpoint-${name}`;
    },
    _subjectFirst: {
      get() {
        return this.subjectFirst;
      },
      set(subjectFirst) {
        this.SET_SUBJECT_FIRST({ subjectFirst });
      }
    },
    _subject: {
      get() {
        return this.subject;
      },
      set(subject) {
        return this.SET_SUBJECT({ subject });
      }
    },
    _priority: {
      get() {
        return this.priority == 1;
      },
      set(priority) {
        return this.SET_PRIORITY({ priority: priority ? 1 : 3 });
      }
    }
  },
  methods: {
    ...mapMutations("mailCompose", [
      "SET_SUBJECT_FIRST",
      "SET_SUBJECT",
      "SET_PRIORITY"
    ])
  }
};
</script>
