<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <!-- POP3/IMAP/SMTP 사용여부 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
        >{{ title }}
      </v-col>
      <v-col cols="12" sm="8" class="cr-swtich-wrapper">
        <v-switch hide-details inset v-model="config.pop3Flag" />
      </v-col>
    </v-row>
    <v-divider />

    <div v-show="config.pop3Flag" class="config-row">
      <!-- 클라이언트 방식  -->
      <v-row class="ma-0">
        <v-col
          class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
          cols="12"
          sm="4"
          >{{ $t("mail.560") }}
        </v-col>
        <v-col cols="12" sm="8" class="cr-swtich-wrapper d-block">
          <v-radio-group v-model="config.clientProtocolType" row hide-details>
            <v-radio
              v-if="usingPop3"
              :label="$t('mail.555')"
              :value="0"
            ></v-radio>
            <v-radio
              v-if="usingImap"
              :label="$t('mail.556')"
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider />

      <!-- imap -->
      <div v-show="config.clientProtocolType == 1">
        <v-row class="ma-0">
          <v-col
            class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
            cols="12"
            sm="4"
            >{{ $t("mail.561") }}
          </v-col>
          <!-- 동기화 메일 제한 -->
          <v-col cols="12" sm="8">
            <v-select
              style="max-width: 400px;"
              dense
              outlined
              hide-details
              item-text="text"
              item-value="value"
              :menu-props="{ 'offset-y': true, closeOnClick: true }"
              :items="messageMaxLimitList"
              v-model="config.imapMessageMaxLimit"
            ></v-select>
            <div
              class="ml-1 mt-2 caption black--text"
              v-if="config.imapMessageMaxLimit > 0"
            >
              {{ $t("mail.562", { value: config.imapMessageMaxLimit }) }}
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- pop3 -->
      <div v-show="config.clientProtocolType == 0">
        <v-row class="ma-0">
          <v-col
            class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
            cols="12"
            sm="4"
            >{{ $t("mail.454") }}
          </v-col>
          <!-- 적용 범위 -->
          <v-col cols="12" sm="8" class="cr-swtich-wrapper">
            <v-radio-group v-model="pop3FetchRange" row hide-details>
              <v-radio :label="$t('mail.456')" :value="1"></v-radio>
              <v-radio :label="$t('mail.455')" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider />

        <v-row class="ma-0">
          <v-col
            class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
            cols="12"
            sm="4"
            >{{ $t("mail.460") }}
          </v-col>
          <!-- 원본 저장 -->
          <v-col cols="12" sm="8" class="cr-swtich-wrapper">
            <div>
              <div>
                <v-radio-group v-model="pop3Del" row hide-details>
                  <v-radio
                    :label="$t('mail.460')"
                    :value="0"
                    class="mr-1"
                  ></v-radio>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" size="20">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span v-text="$t('mail.474')" />
                  </v-tooltip>

                  <v-radio
                    :label="$t('mail.461')"
                    :value="1"
                    class="ml-4 mr-1"
                  ></v-radio>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" size="20">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span v-html="$t('mail.475')" />
                  </v-tooltip>
                </v-radio-group>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="ma-0">
          <v-col
            class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
            cols="12"
            sm="4"
            >{{ $t("mail.457") }}
          </v-col>
          <v-col cols="12" sm="8" class="cr-swtich-wrapper">
            <v-radio-group v-model="config.pop3ReadMark" row hide-details>
              <v-radio :label="$t('mail.458')" :value="0"></v-radio>
              <v-radio :label="$t('mail.459')" :value="1"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <v-divider />

      <!-- 비밀번호 -->
      <v-row class="ma-0">
        <v-col
          class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
          cols="12"
          sm="4"
          >{{ $t("mail.477") }}
        </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            class="pt-0 mt-0 text-h6"
            readonly
            hide-details
            v-model="externalSetting.pop3Password"
            :prepend-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            append-icon="mdi-restart"
            :type="showPassword ? 'text' : 'password'"
            @click:prepend="showPassword = !showPassword"
            @click:append="resetPassword"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-row no-gutters class="mt-3 pb-2 pr-2">
      <v-col class="d-flex justify-end">
        <v-btn text depressed :disabled="disabledBtn" @click="reset"
          >{{ $t("common.취소") }}
        </v-btn>
        <v-btn
          class="mr-3"
          color="primary"
          depressed
          :disabled="disabledBtn"
          @click="save"
        >
          {{ $t("common.저장") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="d-flex align-center" cols="12">
        <div>
          <div class="cr-config-title mr-1" v-text="$t('mail.462')"></div>
          <div class="text-caption grey--text" v-text="$t('mail.463')" />
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-0 d-flex align-center ">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.464") }}
      </v-col>
      <v-col class="text-body-2" cols="12" sm="8">
        <div class="mb-1 ">
          <span v-text="$t('mail.465')" />
          <span class="font-weight-bold">{{ externalSetting.id }}</span>
        </div>
        <div>
          <span v-text="$t('mail.466')" />
          <span class="font-weight-bold red--text" v-text="pw" />
        </div>
      </v-col>
    </v-row>
    <v-divider class="mx-3" />
    <v-row class="ma-0 d-flex align-center">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.467") }}
      </v-col>
      <v-col class="text-body-2" cols="12" sm="8">
        <div class="mb-1" v-if="usingPop3">
          <span v-text="$t('mail.468')" />
          <span class="font-weight-bold">
            {{ externalSetting.pop3ServerName }}
          </span>
        </div>
        <div class="mb-1" v-if="usingImap">
          <span v-text="$t('mail.563')" />
          <span class="font-weight-bold">
            {{ externalSetting.imapServerName }}
          </span>
        </div>
        <div class="mb-1">
          <span v-text="$t('mail.469')" />
          <span class="font-weight-bold">
            {{ externalSetting.smtpServerName }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mx-3" />
    <v-row class="ma-0 d-flex align-center">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
      >
        {{ $t("mail.470") }}
      </v-col>
      <v-col class="text-body-2" cols="12" sm="8">
        <div class="mb-1" v-if="usingPop3">
          <span v-text="$t('mail.471')" />
          <span class="font-weight-bold">
            {{ externalSetting.pop3Port }}
          </span>
          <span v-text="$t('mail.473')" />
        </div>
        <div class="mb-1" v-if="usingImap">
          <span v-text="$t('mail.564')" />
          <span class="font-weight-bold">
            {{ externalSetting.imapPort }}
          </span>
          <span v-text="$t('mail.473')" />
        </div>
        <div class="mb-1">
          <span v-text="$t('mail.472')" />
          <span class="font-weight-bold">
            {{ externalSetting.smtpPort }}
          </span>
          <span v-text="$t('mail.473')" />
        </div>
      </v-col>
    </v-row>
    <v-divider class="mx-3 mb-10" />
  </div>
</template>

<script>
import i18n from "@/_locales";
import { getExternalSettingInfo, getPop3Password } from "@/mail/api/config.api";
import { mapActions, mapGetters } from "vuex";

export default {
  async created() {
    const { data, status } = await getExternalSettingInfo();
    if (status == 201) {
      this.externalSetting = data;
      this.password = this.externalSetting.pop3Password;
    }

    if (this.usingPop3 && !this.usingImap) {
      // only pop3
      this.config.clientProtocolType = 0;
    } else if (!this.usingPop3 && this.usingImap) {
      // only imap
      this.config.clientProtocolType = 1;
    }

    // 일부고객사를 위한 IMAP 예외 처리 (크리니티, 오픈헬스케어)
    if ([1, 302].includes(this.getUserInfo?.companyId)) {
      this.messageMaxLimitList.push({
        text: i18n.t("common.무제한"),
        value: -1
      });
    }
  },
  props: {
    pop3Config: {
      default: () => {},
      type: Object
    },
    selectedTab: {
      type: Number
    }
  },
  data: ({ pop3Config }) => ({
    config: { ...pop3Config },
    tab: 0,
    disabledBtn: true,
    externalSetting: {},
    showPassword: false,
    password: "",
    messageMaxLimitList: [
      { text: "500", value: 500 },
      {
        text: "1000",
        value: 1000
      }
    ]
  }),
  watch: {
    config: {
      deep: true,
      handler(config) {
        Object.keys(config).forEach(key => {
          if (typeof config[key] == "boolean") {
            config[key] = config[key] ? 1 : 0;
          }
        });

        this.disabledBtn =
          JSON.stringify(config) == JSON.stringify(this.pop3Config) &&
          this.password == this.externalSetting.pop3Password;
      }
    },
    pop3Config() {
      this.reset();
    },
    selectedTab(next) {
      if (next == 6) this.reset();
    }
  },
  computed: {
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),
    ...mapGetters("auth", ["getUserInfo"]),
    pop3FetchRange: {
      get() {
        return this.config?.pop3FetchRange;
      },
      set(v) {
        this.config.pop3FetchRange = v;
      }
    },
    pop3Del: {
      get() {
        return this.config.pop3Del;
      },
      set(v) {
        this.config.pop3Del = v;
      }
    },
    usingPop3() {
      return this.getMailServiceConfig.pop3 == 1;
    },
    usingImap() {
      return this.getMailServiceConfig.imap == 1;
    },
    title() {
      if (this.usingPop3 == 1 && this.usingImap == 1) {
        // pop3, imap
        return i18n.t("mail.453", { value: "POP3/IMAP/SMTP" });
      } else if (this.usingPop3 == 1) {
        // pop3
        return i18n.t("mail.453", { value: "POP3/SMTP" });
      } else if (this.usingImap == 1) {
        // imap
        return i18n.t("mail.453", { value: "IMAP/SMTP" });
      }
      return "";
    },
    pw() {
      if (
        this.getMailServiceConfig.pop3 == 1 &&
        this.getMailServiceConfig.imap == 1
      ) {
        // pop3, imap
        return i18n.t("mail.476", { value: "POP3/IMAP/SMTP" });
      } else if (this.getMailServiceConfig.pop3 == 1) {
        // pop3
        return i18n.t("mail.476", { value: "POP3/SMTP" });
      } else if (this.getMailServiceConfig.imap == 1) {
        // imap
        return i18n.t("mail.476", { value: "IMAP/SMTP" });
      }
      return "";
    }
  },
  methods: {
    ...mapActions("mailConfig", ["updatePop3Config"]),
    ...mapActions("confirm", ["confirm"]),
    reset() {
      this.config = { ...this.pop3Config };
      this.externalSetting.pop3Password = this.password;
    },
    async save() {
      this.config["pop3Password"] = this.externalSetting?.pop3Password;
      const status = await this.updatePop3Config(this.config);
      if (status == 201) {
        this.password = this.config["pop3Password"];
        this.disabledBtn = true;
      }
    },
    resetPassword() {
      this.confirm({
        message: i18n.t("mail.478"),
        callback: async () => {
          const { data } = await getPop3Password();
          this.externalSetting.pop3Password = data;
          this.disabledBtn = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
}

.config-row {
  .v-input {
    .v-input__slot {
      margin: 0px;
    }

    .v-text-field__details {
      display: none;
    }
  }
}
</style>
