var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{class:_vm.getTrCls,attrs:{"colspan":100}},[_c('div',{staticClass:"d-flex align-center cr_receipt_info"},[_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.getReceiptStr)+" ")]),_c('v-tooltip',{attrs:{"right":"","disabled":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isMinimum && !_vm.cancelAllSendDisabled),expression:"isMinimum && !cancelAllSendDisabled"}],attrs:{"icon":"","color":"red darken-3","disabled":_vm.cancelAllSendDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.cancelAllSend(_vm.item.receipt.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-remove-outline")])],1)]}}])},[_c('div',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.$t('mail.190'))}})])],1),_c('v-tabs',{attrs:{"hide-slider":"","vertical":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.title,on:{"click":function($event){return _vm.loadData(tab.value)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.title,attrs:{"transition":false}},[_c('v-data-table',{class:("" + (_vm.pageCount > 1 ? 'height' : '')),attrs:{"fixed-header":"","hide-default-footer":"","hide-default-header":"","item-class":function () { return 'rcpt-row'; },"headers":_vm.rcptHeaders,"loading":_vm.getLoading,"items":_vm.getItems,"items-per-page":_vm.getPageSize,"server-items-length":_vm.getTotalElements},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.recipient",fn:function(ref){
var item = ref.item;
return [_c('AdrEmail',{attrs:{"address":item.address,"personal":item.name,"type":'view'}})]}},{key:"item.receiptStatus",fn:function(ref){
var item = ref.item;
return [(item.receiptStatus == 'UNREAD')?_c('v-btn',{attrs:{"text":"","height":"30","ripple":false,"disabled":item.cancelable == 0,"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.cancelSend({
                    receiptId: _vm.getReceiptId,
                    targetAddress: item.address
                  })}}},[_c('span',{staticClass:"body-2",domProps:{"textContent":_vm._s(_vm.$t('mail.발송취소'))}})]):_vm._e(),(item.receiptStatus != 'UNREAD')?_c('span',{attrs:{"title":item.updateTimeMillis},on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.updateTimeMillis)+" ")]):_vm._e()]}},{key:"item.replyCode",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm.getReplyMsg(item.replyCode)))])])]}},{key:"item.minimum",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('AdrEmail',{attrs:{"address":item.address,"personal":item.name,"type":'view'}})],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.getReplyMsg(item.replyCode)))])]),_c('div',[(item.receiptStatus == 'UNREAD')?_c('v-btn',{attrs:{"text":"","height":"30","ripple":false,"disabled":item.cancelable == 0,"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.cancelSend({
                      receiptId: _vm.getReceiptId,
                      targetAddress: item.address
                    })}}},[_c('span',{staticClass:"body-2",domProps:{"textContent":_vm._s(_vm.$t('mail.발송취소'))}})]):_vm._e(),(item.receiptStatus != 'UNREAD')?_c('span',{attrs:{"title":item.updateTimeMillis},on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.updateTimeMillis)+" ")]):_vm._e()],1)]}},{key:"footer",fn:function(){return [(_vm.pageCount > 1)?_c('Pagination',{attrs:{"type":"list","page":_vm.page,"pageSize":_vm.getPageSize,"blockSize":5,"totalElements":_vm.getTotalElements},on:{"update:page":function($event){_vm.page=$event},"pageClick":function (page) { return _vm.pageClick(page); }}}):_vm._e()]},proxy:true}],null,true)})],1)}),1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }