<template>
  <div class="pt-5">
    <!-- 메일 제목 -->
    <div class="d-flex text-subtitle-1">
      제목
    </div>
    <div class="text-h6 d-flex black--text font-weight-black">
      <span :title="mailSubject" class="text-truncate">
        {{ mailSubject }}
      </span>
    </div>

    <!-- 링크 -->
    <div class="d-flex align-center my-3">
      <v-text-field
        ref="link"
        dense
        outlined
        hide-details
        readonly
        append-icon="mdi-content-copy"
        :value="url"
        :loading="!url"
        :placeholder="url ? '' : '링크 생성중...'"
        @click:append="copyLink"
      />
    </div>

    <!-- 권한 -->
    <div class="d-flex align-center my-3 text-subtitle-1">
      권한
      <v-spacer />
      <v-select
        disabled
        dense
        outlined
        hide-details
        item-text="text"
        item-value="value"
        style="max-width: 150px;"
        v-model="permission"
        :items="permissions"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
      />
    </div>
    <!-- 사용자(이메일) 추가 -->
    <div class="cr-auto-wrapper my-2" v-show="permission == 1">
      <Autocomplete
        ref="autocompleteRef"
        key="messenger_share"
        :selection.sync="selection"
        :useChips="false"
        :listHeight="200"
        :typeList="['MEMBER']"
        placeholder="이메일 추가"
      />
      <v-list v-if="members.length > 0" dense outlined class="pa-0">
        <v-list-item v-for="member in members" :key="member.email" class="pr-3">
          <v-list-item-title>
            {{ member.email }}
          </v-list-item-title>
          <v-list-item-action class="ma-0 pa-0">
            <v-btn icon small @click="deleteMember(member.email)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <!-- 만료일 -->
    <div class="d-flex align-center my-3 text-subtitle-1">
      <div>
        만료일
        <span class="grey--text text-caption" v-if="!!id">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                @click="changeStartDate"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>시작날짜 오늘로 변경</span>
          </v-tooltip>
          <span class="ml-1 red--text">{{ endDate }}</span>
        </span>
      </div>

      <v-spacer />
      <v-select
        dense
        outlined
        hide-details
        item-text="text"
        item-value="value"
        style="max-width: 100px;"
        v-model="expiryDate"
        :items="expiryDates"
        :menu-props="{ 'offset-y': true, closeOnClick: true }"
      />
    </div>

    <!-- 댓글 -->
    <div
      class="d-flex align-center my-2 text-subtitle-1"
      style="margin-right: -12px;"
    >
      댓글 사용
      <v-spacer />
      <v-switch
        disabled
        v-model="useComment"
        hide-details
        inset
        class="pa-0 ma-0"
      />
    </div>
    <div>
      <v-list></v-list>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-auto-wrapper > .v-list {
  max-height: 240px;
  border-top: none;
  overflow: hidden;
  overflow-y: auto;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  async mounted() {
    this.linkShareObj = await this.getLinkShare(this.mailId);
    if (this.linkShareObj?.id > 0) {
      this.SET_LEFT_BTN({
        use: true,
        color: "red",
        title: "삭제",
        func: () =>
          this.deleteLinkShare({
            id: this.linkShareObj.id,
            callback: () => this.CLOSE_DIALOG()
          })
      });
    }
  },
  data() {
    return {
      linkShareObj: null,
      selection: {},
      expiryDates: [
        { text: "1일", value: 1 },
        { text: "3일", value: 3 },
        { text: "7일", value: 7 },
        { text: "15일", value: 15 }
      ],
      permissions: [
        { text: "누구나", value: 0 },
        { text: "승인된 사람", value: 1 }
      ]
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    id() {
      return this.linkShareObj?.id || 0;
    },
    mailId() {
      const { mail } = this.params;
      return mail?.mailId || 0;
    },
    mailSubject() {
      const { mail } = this.params;
      return mail?.subject || "";
    },

    url() {
      return this.linkShareObj?.url ?? "";
    },
    startDate() {
      return getDateWithoutTime(
        this.linkShareObj?.startDateTimeMillis || new Date().getTime()
      );
    },
    endDate() {
      const date = new Date(this.startDate);
      date.setDate(date.getDate() + this.expiryDate);

      return getDateWithoutTime(date.getTime());
    },
    expiryDate: {
      get() {
        return this.linkShareObj?.expiryDate ?? 1;
      },
      set(expiryDate) {
        this.linkShareObj["expiryDate"] = expiryDate;
      }
    },
    permission: {
      get() {
        return this.linkShareObj?.permission ?? 0;
      },
      set(permission) {
        this.linkShareObj["permission"] = permission;
      }
    },
    useComment: {
      get() {
        return this.linkShareObj?.useComment ?? 0;
      },
      set(useComment) {
        this.linkShareObj["useComment"] = useComment ? 1 : 0;
      }
    },
    members: {
      get() {
        return this.linkShareObj?.members ?? [];
      },
      set(members) {
        this.linkShareObj["members"] = members;
      }
    }
  },
  watch: {
    async confirm(next) {
      if (!next) return;

      const { id = 0 } = this.linkShareObj ?? {};
      const func = id > 0 ? this.updateLinkShare : this.addLinkShare;
      if (
        await func({
          id,
          url: this.url,
          expiryDate: this.expiryDate,
          permission: this.permission,
          useComment: this.useComment,
          members: this.members,
          startDateTimeMillis: new Date(`${this.startDate} 0:0:0`).getTime()
        })
      ) {
        const pattern = /[-/\\^$*+~?_.()|[\]{}]/g; // 특수문자 포함시 마크다운 표시 안됨
        const subject = this.mailSubject.replace(pattern, " ");

        this.CLOSE_DIALOG();

        // share link message
        this.confirmFunc({
          message: `대화방에 링크를 공유 하시겠습니까?`,
          callback: async () => {
            const msg = `[📧 ${subject}](${this.url})`;
            this.postMessage(msg);
          }
        });
        return;
      }

      this.openSnackbar({
        message: "공유링크를 저장하지 못했습니다.",
        type: "ERROR"
      });
    },
    // 자동완성에서 이메일 선택시
    selection({ valid, email, value }) {
      if (!valid) {
        this.openSnackbar({
          message: "이메일 형식을 확인해주세요.",
          type: "ERROR"
        });
        return;
      }

      if (
        (!email && !value) ||
        this.members.findIndex(m => m.email == (email ?? value)) !== -1
      ) {
        return;
      }
      this.members = [...this.members, { email: email ?? value }];
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_LEFT_BTN", "CLOSE_DIALOG"]),
    ...mapActions("confirm", { confirmFunc: "confirm" }),
    ...mapActions("messenger", ["postMessage"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mailLinkShare", [
      "getLinkShare",
      "addLinkShare",
      "updateLinkShare",
      "deleteLinkShare"
    ]),
    copyLink() {
      this.$refs.link.$el.querySelector("input").select();
      document.execCommand("copy");
    },
    deleteMember(email) {
      this.members = this.members.filter(m => m.email !== email);
    },
    changeStartDate() {
      const now = new Date();
      now.setDate(now.getDate() + this.expiryDate);
      const date = getDateWithoutTime(now.getTime(), "MMMM Do");
      this.confirmFunc({
        message: `만료일을 <b>${date}</b>로 재설정 하시겠습니까?`,
        callback: async () => {
          this.linkShareObj["startDateTimeMillis"] = new Date().getTime();
        }
      });
    }
  }
};
</script>
