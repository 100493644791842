<template>
  <div class="cr-post-wrapper">
    <!-- 게시글 제목 -->
    <v-row no-gutters class="px-3 mb-3">
      <v-col class="cr-title d-flex align-center" cols="12" sm="3">
        {{ $t("board.65") }}
      </v-col>
      <v-col class="d-flex align-center" cols="12" sm="9">
        <v-text-field
          dense
          outlined
          hide-details
          autocomplete="off"
          ref="newTitle"
          :placeholder="$t('board.66')"
          v-model="title"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- 첨부 파일 -->
    <v-row no-gutters class="px-3 mb-3" v-show="getFileCount > 0">
      <v-col class="cr-title d-flex align-center" cols="12" sm="3">
        <span class="mr-2" v-text="$t('board.첨부파일')"></span>
      </v-col>
      <v-col class="" cols="12" sm="9">
        <div v-for="file in attachList" :key="file.position">
          <v-icon>mdi-file</v-icon>
          {{ file.name }}
        </div>
      </v-col>
    </v-row>
    <!-- 내용 -->
    <v-row no-gutters class="d-flex align-center px-1">
      <v-col cols="12">
        <div
          contenteditable
          ref="contentsRef"
          class="pa-3"
          style="height:300px; overflow:auto; border:1px solid rgba(0, 0, 0, 0.12);"
          v-html="contents"
        ></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import i18n from "@/_locales";
import { insertFeed } from "@/mail/api/linkage.api.js";
import { getMailView } from "@/mail/api/mail.api.js";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    from: [],
    to: [],
    cc: [],
    bcc: [],
    title: "",
    contents: "",
    attachList: []
  }),
  async mounted() {
    setTimeout(() => {
      this.$refs.contentsRef.focus();
    }, 0);

    const { mailId } = this.params;
    const { status, data } = await getMailView(false, mailId, {
      basedMailId: mailId,
      includesReference: false
    });
    if (status != 200) {
      this.CLOSE_DIALOG();
      return;
    }

    this.title = data[0].subject;
    this.contents = data[0].content;
    this.attachList = data[0].attachList?.length > 0 ? data[0].attachList : [];
  },
  watch: {
    async confirm(value) {
      if (value) {
        const { mailId } = this.params;
        const contents = this.$refs.contentsRef.innerHTML;

        const { data } = await insertFeed({
          mailId: mailId,
          title: this.title,
          contents: contents
        });
        const { errorCode, message } = data;

        let type = "SUCCESS";
        let msg = i18n.t("common.77");

        if (errorCode != 0) {
          type = "ERROR";
          msg = i18n.t("common.78") + " " + message;
        }

        this.openSnackbar({
          type: type,
          message: msg
        });

        this.CLOSE_DIALOG();
      }
    }
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    getFileCount() {
      return this.attachList.length;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"])
  }
};
</script>

<style lang="scss" scoped>
.cr-post-wrapper ::v-deep {
  .v-input.v-textarea {
    .v-input__control {
      .v-input__slot {
        padding-right: 13px;
      }
      textarea {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
