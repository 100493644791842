<template>
  <div :class="cls">
    <Envelope ref="envelope" />
    <Attach ref="attach" v-show="!showOnlySubject" />
    <TinymceEditor :height="editorHeight" />
  </div>
</template>

<style lang="scss" scoped>
.cr-container::v-deep {
  min-width: 650px;
  overflow-x: hidden;
  overflow-y: scroll !important;
  padding: 0px 0px 0px 16px;
  &.notice {
    margin-top: 35px;
  }

  // 기본 css
  .cr-envelope-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 8px;

    .v-subheader {
      width: 100px;
      height: 40px;
    }

    .cr-input-wrapper {
      width: calc(100% - 190px);
    }
  }

  // 다국어
  &.locale-en,
  &.locale-id {
    .cr-envelope-container {
      .v-subheader {
        width: 85px;
      }
      .cr-input-wrapper {
        width: calc(100% - 200px);
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Envelope from "./envelope";
import Attach from "./attach";
import TinymceEditor from "./editor/tinymce/TinymceEditor";

export default {
  props: {
    tabSize: {
      type: Number,
      default: 0
    },
    showNotice: {
      type: Boolean,
      default: false
    }
  },
  components: { Envelope, Attach, TinymceEditor },
  mounted() {
    this.$nextTick(() => {
      setTimeout(this.resetEditorHeight, 0);

      const {
        document: editorDocument
      } = window.tinymce?.activeEditor?.iframeElement?.contentWindow;
      if (!editorDocument) return;

      editorDocument.addEventListener("click", this.clickEditor);
      editorDocument.addEventListener("keydown", this.keydown);
    });
  },
  data() {
    return { editorHeight: 619 };
  },
  computed: {
    ...mapGetters("mailCompose", ["showOnlySubject", "content"]),
    cls() {
      let cls = "cr-container";
      cls += ` locale-${this.$i18n.locale}`;
      if (this.showNotice) cls += " notice";

      return cls;
    }
  },
  methods: {
    ...mapMutations("mailCompose", [
      "SET_SHOW_RESERVE_MODAL",
      "SET_SHOW_SECURE_MODAL",
      "TOGGLE_CONTENT_OBSERVER"
    ]),
    ...mapActions("positioningMenu", ["closePositioningMenu"]),
    keydown() {
      this.TOGGLE_CONTENT_OBSERVER();
      this.clickEditor();
    },
    clickEditor() {
      this.closePositioningMenu();
      this.SET_SHOW_RESERVE_MODAL(false);
      this.SET_SHOW_SECURE_MODAL(false);

      if (!this.$refs?.envelope) return;

      const { from, subject } = this.$refs.envelope?.$refs || {};
      if (from) from?.selectBlur();
      if (subject) subject?.$refs?.subjectFirst?.blur();
    },
    resetEditorHeight() {
      setTimeout(() => {
        let { clientHeight: editorHeight } = this.$el;
        const { envelope, attach } = this.$refs;
        if (!envelope || !attach || !editorHeight) return;

        const { clientHeight: envelopeHeight = 0 } = envelope.$el;
        const { clientHeight: attachHeight = 0 } = attach.$el;

        // 탭 사용 여부 변경시 리사이즈 함수에서 크기측정이 정확하지않아 여기서 설정해준다.
        editorHeight += this.tabSize;
        this.$emit("update:tabSize", 0);

        editorHeight -= envelopeHeight + attachHeight;
        this.editorHeight = editorHeight - 18 < 295 ? 295 : editorHeight - 18;
      }, 0);
    }
  }
};
</script>
