<template>
  <v-list-item @click.prevent="$emit('click', item)">
    <v-list-item-action v-if="isFile" class="mr-3">
      <v-checkbox :ripple="false" v-model="checked" />
    </v-list-item-action>

    <v-list-item-avatar tile class="mr-0 my-3">
      <v-icon size="30" class="mr-2 justify-start" :color="iconColor">
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="text-black text-subtitle-1">
      <v-list-item-title class="d-flex">
        <FileName v-if="isFile" :item="fileName" />
        <span v-else>{{ fileName }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ `${item.owner} ${fileSize}` }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import FileName from "@/mail/views/components/main/commons/FileName";

export default {
  components: { FileName },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    selectedFiles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isFile() {
      return this.item.fileType === "FILE";
    },
    icon() {
      const { extension } = this.item;
      return fileIcons[extension] || fileIcons["file"];
    },
    iconColor() {
      const { extension } = this.item;
      return fileIconColors[extension] || fileIconColors["file"];
    },
    fileName() {
      const { fileName, extension } = this.item;
      if (!this.isFile) return fileName;

      const ext = fileName.split(".").at(-1);
      const [name] = fileName.split(`.${ext}`);
      return { name, ext: extension || ext };
    },
    fileSize() {
      const { fileSize } = this.item;
      if (!this.isFile) return "";
      return fileSize > 0 ? `/ ${byteCalculation(fileSize)}` : "—";
    },

    checked: {
      get() {
        return !!this.selectedFiles.find(f => f.id === this.item.id);
      },
      set() {}
    }
  }
};
</script>
