<template>
  <div v-resize="resize" class="pa-2 pb-0" style="max-width: 1029px;">
    <v-row class="ma-0 ml-3 d-flex cr-border">
      <v-col
        class="pb-0 px-2 d-flex align-center grey--text text--darken-2 font-weight-bold"
        cols="2"
      >
        {{ $t("mail.249") }}
      </v-col>
      <v-col
        class="pb-0 px-2 d-flex align-center grey--text text--darken-2 font-weight-bold"
        cols="10"
      >
        <v-btn text tile outlined @click="syncFolder">
          {{ $t("mail.250") }}
        </v-btn>
      </v-col>
      <v-col cols="2" class="pb-2 d-flex" />
      <v-col class="pa-0 pt-1 pb-2 pl-2 grey--text text-caption" cols="10">
        {{ $t("mail.251") }}
      </v-col>
    </v-row>

    <v-row class="ma-0 d-flex justify-space-between">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        md="6"
      >
        <!-- 총 메일함:<span class="mx-1 blue--text">xx개</span>/100개 | 현재 사용량:
        <span class="ml-1 blue--text">XXXMB</span>/30GB (2.57%) -->
      </v-col>
      <v-col cols="12" md="4" class="pr-0 d-flex">
        <v-text-field
          ref="nfnRef"
          outlined
          hide-details
          dense
          :placeholder="$t('mail.252')"
          v-model="newFolderName"
          @keydown.enter="addFolderFunc"
        />
        <v-btn
          tile
          depressed
          height="40"
          color="primary"
          @click="addFolderFunc"
        >
          {{ $t("common.추가") }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- 메일함 리스트 -->
    <v-row class="ma-0">
      <!-- 헤더 -->
      <v-col cols="12" class="py-0 mb-0 pr-0">
        <v-list-item dense class="px-2">
          <v-list-item-content>
            <v-list-item-title class="d-flex text-black text-subtitle-1">
              <div
                class="pa-3 pl-0 pb-0 flex-grow-1 font-weight-bold body-2"
                v-text="$t('mail.47')"
              ></div>
              <div
                v-show="!breakpointXS"
                class="pa-3 pb-0 text-center font-weight-bold body-2"
                style="width: 135px;"
                v-text="$t('mail.253')"
              ></div>
              <div
                class="pa-3 pb-0 text-center font-weight-bold body-2"
                style="width: 120px;"
                v-text="$t('mail.116')"
              ></div>
              <div
                class="pa-3 pb-0 text-center font-weight-bold body-2"
                style="width:171px;"
                v-text="$t('mail.관리')"
              ></div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <!-- 목록 -->
      <v-col cols="12" class="pr-0 pt-0 cr-list">
        <v-list dense class="py-0">
          <v-list-item class="px-2" v-for="folder in folders" :key="folder.id">
            <v-list-item-content>
              <v-list-item-title class="d-flex text-black text-subtitle-1">
                <div
                  ref="nfRef"
                  class="d-flex pa-3 pl-0 flex-grow-1 text-truncate"
                  :class="breakpointXS ? 'flex-column' : 'align-center'"
                >
                  <span
                    class="text-truncate"
                    :style="
                      folder.depth > 0
                        ? `margin-left: ${16 * folder.depth}px;`
                        : ''
                    "
                  >
                    <v-icon
                      v-if="folder.depth > 0"
                      small
                      style="margin-top: -7px;"
                    >
                      mdi-subdirectory-arrow-right
                    </v-icon>
                    {{ folder.nameFolder }}
                  </span>
                  <div
                    v-show="breakpointXS"
                    class="body-2"
                    style="min-width: 135px;"
                  >
                    <span
                      v-if="
                        folder.folderType !== 'DRAFTS' &&
                          folder.folderType !== 'SENT' &&
                          folder.folderType !== 'DELETED'
                      "
                    >
                      <span
                        :class="{
                          'font-weight-bold primary--text': folder.newCount > 0
                        }"
                        v-text="folder.newCount"
                      ></span>
                      <span>/</span>
                    </span>
                    <span v-text="folder.totalCount"></span>
                  </div>
                </div>
                <div
                  v-show="!breakpointXS"
                  class="pa-3 text-center body-2"
                  style="min-width: 135px;"
                >
                  <span
                    v-if="
                      folder.folderType !== 'DRAFTS' &&
                        folder.folderType !== 'SENT' &&
                        folder.folderType !== 'DELETED'
                    "
                  >
                    <span
                      :class="{
                        'font-weight-bold primary--text': folder.newCount > 0
                      }"
                      v-text="folder.newCount"
                    ></span>
                    <span>/</span>
                  </span>
                  <span v-text="folder.totalCount"></span>
                </div>
                <div class="pa-3 text-center body-2" style="min-width: 120px;">
                  {{ byteCalculation(folder.usedSize || 0) }}
                </div>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div v-if="folder.folderType !== 'DRAFTS'" style="width: 155px;">
                <v-btn
                  small
                  text
                  min-width="30"
                  class="px-1 mr-1"
                  @click="
                    SET_DIALOG({
                      headline: $t('mail.254'),
                      show: true,
                      type: 'backup',
                      params: { folder },
                      showConfirmBtn: false
                    })
                  "
                >
                  {{ $t("mail.백업") }}
                </v-btn>
                <v-btn
                  :disabled="
                    !(
                      folder.folderType == 'SENT' ||
                      folder.folderType == 'PRIVATE' ||
                      folder.folderType == 'INBOX'
                    )
                  "
                  small
                  text
                  min-width="30"
                  class="px-1 mr-1"
                  @click="
                    SET_DIALOG({
                      headline: $t('mail.255'),
                      show: true,
                      type: 'restore',
                      params: { folder },
                      btnTitles: { confirm: $t('mail.복원') }
                    })
                  "
                >
                  {{ $t("mail.복원") }}
                </v-btn>
                <v-btn
                  small
                  text
                  min-width="42"
                  class="px-1 mr-1"
                  @click="cleanFolder(folder)"
                >
                  {{ $t("mail.비우기") }}
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on: onMenu }">
                    <v-btn
                      text
                      x-small
                      min-width="20"
                      class="px-0"
                      v-on="{ ...onMenu }"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list outlined dense tile>
                    <v-list-item
                      v-for="(item, index) in menuItems(folder)"
                      :key="index"
                      :disabled="item.disabled"
                      @click="item.click"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div v-else style="width: 155px;" />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.cr-border {
  border-bottom: 1px solid #d2d2d2;
}

.cr-list > .v-list ::v-deep {
  border-top: 1px solid #d2d2d2;
  .v-list-item {
    border-bottom: 1px solid #eee;
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  mounted() {
    // this.resize();
  },
  data() {
    return {
      clientWidth: 0,
      breakpointXS: false,
      newFolderName: ""
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("folder", [
      "getFolders",
      "getTrashId",
      "getPrivateRootId",
      "getSerializationFolders"
    ]),
    folders() {
      return [
        ...this.getFolders.filter(f => f.id > 0 && f.folderType !== "TAG_ROOT"),
        ...this.getSerializationFolders.filter(f =>
          f.folderType.includes("PRIVATE")
        )
      ];
    },
    menuItems() {
      return f => {
        return [
          {
            title: i18n.t("mail.14"),
            type: "private",
            click: () =>
              this.SET_DIALOG({
                headline: i18n.t("mail.256"),
                show: true,
                type: "folder",
                params: { parentFolder: f, targetFolder: f }
              })
          },
          {
            title: i18n.t("mail.16"),
            type: "private",
            click: () =>
              this.SET_DIALOG({
                headline: i18n.t("mail.20"),
                show: true,
                type: "folder",
                params: { parentFolder: f }
              })
          },
          {
            title: i18n.t("mail.18"),
            click: () =>
              this.confirm({
                message: i18n.t("mail.25", {
                  value: f.nameFolder
                }),
                callback: async () => {
                  const result = await this.deleteFolder({
                    folderId: f.id,
                    parentId: f.parentId
                  });

                  if (result) {
                    // 현재 라우팅 되어있는 폴더 id에서 삭제 한다면 메일 첫화면으로 설정한 메일함으로 이동
                    if (f.id == this.getRouteListInfo.folderId) {
                      this.routeMailList({
                        folderId: this.getFirstScreenFolderId,
                        page: 1
                      });
                    }
                  }
                }
              }),
            type: "private"
          },
          {
            title: i18n.t("mail.19"),
            click: () => this.updateAllRead(f.id)
          }
        ].filter(({ type }) => {
          if (f.folderType.includes("PRIVATE")) {
            return true;
          } else {
            return type !== "private";
          }
        });
      };
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mail", ["updateAllRead", "cleanTrash", "updateAllFolderId"]),
    ...mapActions("folder", ["addFolder", "deleteFolder", "syncFolder"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("mailRoute", ["routeMailList"]),
    // 화면 크기 바뀔때 리스트요소 변경
    resize() {
      // const [firstEl] = this.$refs.nfRef || [];
      // if (firstEl) {
      //   if (this.breakpointXS && this.clientWidth < window.innerWidth) {
      //     this.breakpointXS = false;
      //   }
      //   if (!this.breakpointXS && firstEl.clientWidth <= 200) {
      //     this.clientWidth = window.innerWidth;
      //     this.breakpointXS = true;
      //   }
      // }
    },
    byteCalculation(bytes) {
      return byteCalculation(bytes);
    },
    async addFolderFunc() {
      const { newFolderName } = this;
      this.newFolderName = newFolderName.trim();

      if (!this.newFolderName) {
        this.$refs.nfnRef.focus();
      }
      if (this.newFolderName) {
        await this.addFolder({
          nameFolder: this.newFolderName,
          folderType: "PRIVATE",
          parentId: this.getPrivateRootId
        });

        this.newFolderName = "";
      }
    },
    cleanFolder(folder) {
      // 임시 보관함일때
      if (folder.folderType == "DRAFTS") return;

      let message = i18n.t("mail.258", { value: folder.nameFolder });
      let callback = () =>
        this.updateAllFolderId({
          targetFolderId: this.getTrashId,
          prevFolderId: folder.id
        });

      // 휴지통일때
      if (folder.folderType == "DELETED") {
        message = i18n.t("mail.24");
        callback = this.cleanTrash;
      }

      this.confirm({
        message,
        callback
      });
    }
  }
};
</script>
