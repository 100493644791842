<template>
  <div class="cr-envelope-container">
    <div class="d-flex align-center">
      <v-subheader>
        {{ $t("mail.참조") }}
      </v-subheader>

      <v-btn icon x-small @click="SET_SHOW_BCC(!showBcc)">
        <v-icon>
          {{ showBcc ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </div>

    <div class="cr-input-wrapper">
      <Autocomplete
        ref="autocompleteRef"
        key="cc"
        class="cr-dropzone-autocomplete-input"
        :selection.sync="selection_cc"
        :crDraggable="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  computed: {
    ...mapGetters("mailCompose", ["cc", "showBcc"]),
    selection_cc: {
      get() {
        return this.cc.map(c => ({ ...c }));
      },
      set(cc) {
        return this.SET_RECIPIENT({ cc });
      }
    }
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_RECIPIENT", "SET_SHOW_BCC"])
  }
};
</script>
