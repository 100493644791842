<template>
  <div class="cr-selected-box">
    <!-- 첨부파일 정보 -->
    <div class="grey--text caption d-flex justify-end">
      <span class="mr-1">{{ $t("mail.124") }}</span>
      <span class="mx-1 grey--text text--darken-2">{{ tempFileSize }}</span>
      <span class="mx-1">/</span>
      <span>{{ "20MB" }}</span>

      <span class="mr-1 ml-3">
        <span class="mr-1">{{ $t("mail.125") }}</span>
        <span class="grey--text text--darken-2">
          {{ largeTempFileSize }}
        </span>
        <span class="mx-1">/</span>
        <span>{{ largeTempFileMaxSize }}</span>
      </span>

      <span class="ml-1 mr-3">
        {{ $t("mail.126", { value: totalFileCnt }) }}
      </span>
    </div>

    <!-- 선택한 파일 정보 -->
    <v-list>
      <SelectItem
        v-for="item in selectedFiles"
        :key="item.id"
        :item="item"
        @remove="$emit('update:selectedFiles', item)"
        @chagneType="$emit('chagneType', item)"
      />
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
.cr-selected-box::v-deep {
  display: flex;
  flex-direction: column;
  width: calc(100% - 450px);
  height: 600px;
  padding-top: 8px;
  padding-left: 8px;
  border-left: thin solid rgba(0, 0, 0, 0.15);

  .v-list {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import SelectItem from "./SelectItem.vue";

export default {
  components: { SelectItem },
  props: {
    selectedFiles: {
      type: Array,
      default: () => []
    },
    totalFileCnt: {
      type: Number,
      default: 0
    },
    totalTempSize: {
      type: Number,
      default: 0
    },
    totalLargeTempSize: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),

    tempFileSize() {
      return byteCalculation(this.totalTempSize);
    },
    largeTempFileSize() {
      return byteCalculation(this.totalLargeTempSize);
    },
    largeTempFileMaxSize() {
      return byteCalculation(this.getMailServiceConfig.largeFileMaxSize);
    }
  }
};
</script>
