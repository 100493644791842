<template>
  <tr>
    <td :class="getTrCls" :colspan="100">
      <!-- 수신확인 정보 -->
      <div class="d-flex align-center cr_receipt_info">
        <div class="body-2">
          {{ getReceiptStr }}
        </div>
        <v-tooltip right :disabled="$vuetify.breakpoint.xs">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="isMinimum && !cancelAllSendDisabled"
              icon
              color="red darken-3"
              v-bind="attrs"
              v-on="on"
              :disabled="cancelAllSendDisabled"
              @click.stop="cancelAllSend(item.receipt.id)"
            >
              <v-icon>mdi-email-remove-outline</v-icon>
            </v-btn>
          </template>
          <div class="caption" v-text="$t('mail.190')"></div>
        </v-tooltip>
      </div>

      <!-- 수신확인 탭부분 -->
      <v-tabs hide-slider v-model="selectedTab" vertical>
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
          @click="loadData(tab.value)"
        >
          {{ tab.title }}
        </v-tab>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="tab in tabs" :key="tab.title" :transition="false">
            <v-data-table
              fixed-header
              hide-default-footer
              hide-default-header
              :class="`${pageCount > 1 ? 'height' : ''}`"
              :item-class="() => 'rcpt-row'"
              :headers="rcptHeaders"
              :loading="getLoading"
              :items="getItems"
              :items-per-page="getPageSize"
              :server-items-length="getTotalElements"
              @page-count="pageCount = $event"
            >
              <!-- 받는사람 -->
              <template v-slot:[`item.recipient`]="{ item }">
                <AdrEmail
                  :address="item.address"
                  :personal="item.name"
                  :type="'view'"
                />
              </template>
              <!-- 열람상태 / 발송취소 -->
              <template v-slot:[`item.receiptStatus`]="{ item }">
                <v-btn
                  v-if="item.receiptStatus == 'UNREAD'"
                  text
                  height="30"
                  :ripple="false"
                  :disabled="item.cancelable == 0"
                  color="red"
                  @click.stop="
                    cancelSend({
                      receiptId: getReceiptId,
                      targetAddress: item.address
                    })
                  "
                >
                  <span class="body-2" v-text="$t('mail.발송취소')"></span>
                </v-btn>
                <span
                  v-if="item.receiptStatus != 'UNREAD'"
                  v-bind:title="item.updateTimeMillis"
                  @click.stop="() => {}"
                >
                  {{ item.updateTimeMillis }}
                </span>
              </template>
              <!-- 실패사유 -->
              <template v-slot:[`item.replyCode`]="{ item }">
                <div class="text-truncate">
                  <span>{{ getReplyMsg(item.replyCode) }}</span>
                </div>
              </template>
              <!-- 미니멈 -->
              <template v-slot:[`item.minimum`]="{ item }">
                <div>
                  <AdrEmail
                    :address="item.address"
                    :personal="item.name"
                    :type="'view'"
                  />
                </div>
                <div>
                  <span>{{ getReplyMsg(item.replyCode) }}</span>
                </div>
                <div>
                  <v-btn
                    v-if="item.receiptStatus == 'UNREAD'"
                    text
                    height="30"
                    :ripple="false"
                    :disabled="item.cancelable == 0"
                    color="red"
                    @click.stop="
                      cancelSend({
                        receiptId: getReceiptId,
                        targetAddress: item.address
                      })
                    "
                  >
                    <span class="body-2" v-text="$t('mail.발송취소')"></span>
                  </v-btn>
                  <span
                    v-if="item.receiptStatus != 'UNREAD'"
                    v-bind:title="item.updateTimeMillis"
                    @click.stop="() => {}"
                  >
                    {{ item.updateTimeMillis }}
                  </span>
                </div>
              </template>
              <!-- 페이징 -->
              <template v-slot:footer>
                <Pagination
                  v-if="pageCount > 1"
                  type="list"
                  :page.sync="page"
                  :pageSize="getPageSize"
                  :blockSize="5"
                  :totalElements="getTotalElements"
                  @pageClick="page => pageClick(page)"
                />
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.cr-receipt-detail-wrapper {
  padding: 0px !important;
  .cr_receipt_info {
    height: 40px;
    padding-left: 168px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-tab {
    border-left: white 2px solid;
  }
  .v-tab.v-tab--active {
    border-left: var(--v-primary-base) 2px solid;
  }
  .v-tabs-items ::v-deep {
    border-left: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
    > .v-window__container > .v-window-item {
      > .v-data-table {
        margin: 1px 0px;
        .v-data-table__wrapper {
          max-height: 192px;

          > table {
            > tbody > tr.rcpt-row > td,
            > tbody > tr.rcpt-row > th,
            > thead > tr.rcpt-row > td,
            > thead > tr.rcpt-row > th,
            > tfoot > tr.rcpt-row > td,
            > tfoot > tr.rcpt-row > th {
              border: none;
            }
            > tbody
              > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
              background: none;
            }
          }

          .rcpt-row {
            > .text-start.empty-1 {
              overflow: inherit;
              max-width: 62px;
              min-width: 62px;
              width: 62px;
            }
            > .text-start.recipient {
              max-width: 160px;
              min-width: 160px;
              width: 160px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            > .text-start.receiptStatus {
              max-width: 190px;
              min-width: 190px;
              width: 190px;
              overflow: hidden;
              padding: 0 0 0 26px;
              .v-btn {
                width: 80px;
              }
            }
            > .text-start.replyCode {
              max-width: 1px;
              width: 100%;
            }

            .v-data-table__mobile-row__cell {
              text-align: left;
            }
          }
        }
      }

      > .v-data-table.height {
        .v-data-table__wrapper {
          height: 240px;
        }
      }
    }
  }
}

.cr-receipt-detail-wrapper.cr-mobile {
  display: flex;
  flex-direction: column;
  height: auto;
}

.cr-receipt-detail-wrapper.cr-mobile,
.cr-receipt-detail-wrapper.cr-split-1 {
  .cr_receipt_info {
    padding-left: 178px;
  }

  .v-tabs-items ::v-deep {
    border-left: thin solid rgba(0, 0, 0, 0.12);
    border-right: none;

    > .v-window__container
      > .v-window-item
      > .v-data-table
      .v-data-table__wrapper {
      .rcpt-row {
        > .text-start.empty-1 {
          overflow: inherit;
          max-width: 73px;
          min-width: 73px;
          width: 73px;
        }
        > .text-start.receiptStatus {
          padding: 0 0 0 36px;
          max-width: 175px;
          min-width: 175px;
          width: 175px;
        }
        > .text-start.minimum {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }

  &.cr-minimum {
    .cr_receipt_info {
      padding-left: 45px;
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { receiptStatus } from "@/mail/constant/receiptStatus";
import Pagination from "@/commons/views/Pagination";
import AdrEmail from "@/mail/views/components/main/commons/adrEmail";

export default {
  mounted() {
    this.selectedTab = 0;
    this.SET_ITEMS({ items: [], totalElements: 0 });
    const {
      totalCount = 0,
      cancelCount = 0,
      failCount = 0,
      readCount = 0,
      receiptStatus: itemReceiptStatus
    } = this.item?.receipt || {};

    if (itemReceiptStatus == receiptStatus.FAIL) {
      this.selectedTab = 3;
      this.loadData(receiptStatus.FAIL);
    } else if (readCount > 0) {
      this.selectedTab = 0;
      this.loadData(receiptStatus.READ);
    } else if (readCount == 0 && totalCount != 0) {
      if (totalCount == cancelCount + failCount) {
        this.selectedTab = 2;
        this.loadData(receiptStatus.CANCEL);
        return;
      }
      this.selectedTab = 1;
      this.loadData(receiptStatus.UNREAD);
    }
  },
  components: { AdrEmail, Pagination },
  props: {
    item: { default: () => {}, type: Object },
    isMinimum: {
      default: false,
      type: Boolean,
      description: ""
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      tabs: [
        { title: i18n.t("mail.읽음"), value: receiptStatus.READ },
        { title: i18n.t("mail.안읽음"), value: receiptStatus.UNREAD },
        { title: i18n.t("common.취소"), value: receiptStatus.CANCEL },
        { title: i18n.t("common.실패"), value: receiptStatus.FAIL }
      ]
    };
  },
  watch: {
    // expand화면을 완전히 닫고 여는것이 아니면 mounted 타지않음
    item(item, prevItem) {
      const {
        totalCount = 0,
        cancelCount = 0,
        failCount = 0,
        readCount = 0,
        receiptStatus: itemReceiptStatus
      } = item.receipt;
      if (
        item.mailId == prevItem.mailId &&
        readCount == prevItem.receipt.readCount &&
        cancelCount == prevItem.receipt.cancelCount
      ) {
        return;
      }
      this.page = 1;

      if (itemReceiptStatus == receiptStatus.FAIL) {
        this.selectedTab = 3;
        this.loadData(receiptStatus.FAIL);
      } else if (readCount > 0) {
        this.selectedTab = 0;
        this.loadData(receiptStatus.READ);
      } else if (readCount == 0) {
        if (totalCount == cancelCount + failCount) {
          this.selectedTab = 2;
          this.loadData(receiptStatus.CANCEL);
          return;
        }
        this.selectedTab = 1;
        this.loadData(receiptStatus.UNREAD);
      }
    },
    page() {
      this.loadData();
    }
  },
  computed: {
    ...mapGetters("mailConfig", ["getSplitView"]),
    ...mapGetters("mailReceipt", [
      "getLoading",
      "getPageSize",
      "getTotalElements",
      "getSelectedTab",
      "getItems"
    ]),
    selectedTab: {
      get() {
        return this.getSelectedTab;
      },
      set(selectedTab) {
        this.SET_SELECTED_TAB(selectedTab);
      }
    },
    getTrCls() {
      let cls = "cr-receipt-detail-wrapper";
      if (this.getSplitView) cls += " cr-split-1";
      if (this.isMinimum) cls += " cr-minimum";
      if (this.$vuetify.breakpoint.xs) cls += " cr-mobile";

      return cls;
    },
    getReceiptStr() {
      // receiptStatus
      const { readCount, cancelCount, failCount, totalCount } =
        this.item?.receipt || {};
      const unreadCount = totalCount - (readCount + failCount + cancelCount);

      // 전체 / 읽음 / 안읽음 / 실패 / 취소
      return i18n.t("mail.191", {
        totalCount: totalCount,
        readCount: readCount,
        unreadCount: unreadCount,
        failCount: failCount,
        cancelCount: cancelCount
      });
    },
    getReceiptId() {
      return this.item?.receipt?.id || 0;
    },
    cancelAllSendDisabled() {
      const {
        totalCount = 0,
        cancelCount = 0,
        failCount = 0,
        readCount = 0,
        cancelable = 0
      } = this.item?.receipt || {};

      return (
        cancelable == 0 || totalCount == cancelCount + failCount + readCount
      );
    },
    rcptHeaders() {
      if (this.isMinimum) {
        return [
          {
            text: "",
            value: "minimum",
            cellClass: "minimum"
          }
        ];
      }

      return [
        {
          value: "empty-1",
          cellClass: "empty-1"
        },
        {
          text: i18n.t("mail.받는사람"),
          value: "recipient",
          cellClass: "recipient"
        },
        {
          text: i18n.t("mail.192"),
          value: "receiptStatus",
          cellClass: "receiptStatus"
        },
        {
          text: i18n.t("mail.193"),
          value: "replyCode",
          cellClass: "replyCode"
        }
      ];
    }
  },
  methods: {
    ...mapMutations("mailReceipt", ["SET_SELECTED_TAB", "SET_ITEMS"]),
    ...mapActions("mailReceipt", ["cancelAllSend"]),
    ...mapActions("mailReceipt", ["getReceiptUserList", "cancelSend"]),
    async loadData(value) {
      this.getReceiptUserList({
        receiptId: this.getReceiptId,
        receiptStatus: value || this.tabs[this.getSelectedTab].value,
        page: this.page
      });
    },
    getReplyMsg(replyCode) {
      if (replyCode == 550) return i18n.t("mail.194");
      if (replyCode == 501) return i18n.t("mail.195");
      if (replyCode == 404) return i18n.t("mail.196");
      if (replyCode == 552) return i18n.t("mail.197");

      return "";
    },
    pageClick(page) {
      this.page = page;
    }
  }
};
</script>
