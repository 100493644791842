<template>
  <div>
    <div>{{ $t("mail.86") }}</div>
    <div>
      <v-select
        dense
        attach
        outlined
        hide-details
        :items="timeList"
        item-text="title"
        item-value="value"
        class="cr-reserve-time-select"
        :menu-props="{ 'offset-y': true }"
        v-model="timeValue"
      >
        <template v-slot:item="{ item }">
          <div
            class="cr-title d-flex align-center px-3"
            @click="timeChange(item.value)"
          >
            {{ item.title }}
          </div>
        </template>
      </v-select>
      <DatePicker
        v-if="showDatePicker"
        :selectedDate.sync="selectedDate"
        :showDatePicker.sync="showDatePicker"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
/**
    v-select 동일한 아이템 선택시 @change 동작안함
    -> 직접설정 선택시 계속해서 DatePicker열어줘야해서 아래와 같이 스타일 지정
 */
.cr-reserve-time-select::v-deep {
  .v-menu__content {
    .v-list .v-list-item {
      position: relative;
      .cr-title {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DatePicker from "./DatePicker.vue";

export default {
  components: { DatePicker },
  data() {
    return {
      timeValue: moment().hours() >= 18 ? 2 : 1,
      selectedDate: "",
      showDatePicker: false
    };
  },
  computed: {
    ...mapGetters("mailCompose", ["reserveDate"]),
    timeList() {
      const value = this.selectedDate || "";
      return [
        { title: this.$t("mail.96"), value: 1 },
        { title: this.$t("mail.97"), value: 2 },
        { title: this.$t("mail.98"), value: 3 },
        { title: this.$t("mail.99", { value }), value: 4 }
      ];
    }
  },
  methods: {
    timeChange(value) {
      if (value == 4) {
        this.showDatePicker = true;
        return;
      }

      this.selectedDate = "";
      this.showDatePicker = false;
    }
  }
};
</script>
