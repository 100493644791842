<template>
  <v-card
    outlined
    elevation="1"
    class="cr-secure-wrapper"
    :class="{ 'cr-xs': $vuetify.breakpoint.xs }"
    v-click-outside="() => SET_SHOW_SECURE_MODAL(false)"
  >
    <v-card-title>{{ $t("mail.보안메일") }}</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="4">
          <div class="pwHint">
            {{ $t("common.비밀번호_힌트") }}
          </div>
        </v-col>
        <v-col cols="8">
          <v-text-field
            autofocus
            dense
            outlined
            hide-details
            v-model="securePwHint"
            :placeholder="$t('common.86')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <div class="password">
            {{ $t("common.비밀번호") }}
          </div>
        </v-col>
        <v-col cols="8">
          <v-text-field
            ref="pw"
            dense
            outlined
            hide-details
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-model="securePw"
          ></v-text-field>
        </v-col>
        <div class="pwInfo mr-1">
          {{ $t("mail.570") }}
        </div>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text outlined @click="SET_SHOW_SECURE_MODAL(false)">
        {{ $t("common.취소") }}
      </v-btn>
      <v-btn outlined color="accent" @click="confirm">
        {{ $t("common.확인") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.cr-secure-wrapper {
  position: absolute;
  top: -1px;
  left: 350px;
  width: 460px;
  z-index: 3;
  .v-input {
    background-color: #fbfbfb;
  }
}
.password {
  width: 100px;
}
.pwInfo {
  text-align: right;
  color: #f73232;
  width: 500px;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  destroyed() {
    if (this.isSecure) return;

    this.RESET_SECURE_STATE();
  },
  data({ $store: { getters: g } }) {
    const { securePw, securePwHint } = g["mailCompose/secureState"];

    return { securePw, securePwHint, isValidPw: false, showPassword: false };
  },
  computed: {
    ...mapGetters("mailCompose", ["isSecure"])
  },
  methods: {
    ...mapMutations("mailCompose", [
      "SET_SECURE_STATE",
      "RESET_SECURE_STATE",
      "SET_SHOW_SECURE_MODAL"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    confirm() {
      const { securePw, securePwHint } = this;
      const regex = /^(?=.*[a-zA-Z])(?=.*\d)\S{5,}$/;
      this.isValidPw = regex.test(this.securePw);
      if (!this.isValidPw) {
        this.openSnackbar({ message: this.$t("mail.569"), type: "ERROR" });
        return this.$refs.pw.focus();
      }

      this.SET_SECURE_STATE({ isSecure: true, securePw, securePwHint });
      this.SET_SHOW_SECURE_MODAL(false);
    }
  }
};
</script>
