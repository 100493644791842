<template>
  <!-- 
  팝업, 메인 공통 스타일, 이벤트 등
 -->
  <div style="display: contents">
    <slot />
    <Dialog />
  </div>
</template>

<style lang="scss">
.cr-mail-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  &.show-config-drawer {
    &.cr-lg-up {
      width: calc(100% - 320px);
    }

    .v-overlay {
      z-index: 3 !important;
    }
  }

  .cr-p-r {
    position: relative;
  }
  // 검색 하이라이트
  .cr-hlt {
    color: var(--v-primary-base);
    background-color: yellow;
    font-style: normal;
    font-weight: bold;
  }

  .cr-mail-main-content {
    height: 100%;
    .cr-wrapper {
      position: relative;
      height: 100%;

      .cr-container {
        overflow-y: auto;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Dialog from "./Dialog";

export default {
  components: { Dialog },
  created() {
    // 메일쓰기 닫기 방지
    window.addEventListener("beforeunload", this.mailWriteRefresh);
  },
  destroyed() {
    // 메일쓰기 닫기 방지
    window.removeEventListener("beforeunload", this.mailWriteRefresh);
  },
  computed: {
    ...mapGetters("mailConfig", ["getUseTab"]),
    ...mapGetters("mailTab", ["existWriteTab"]),
    ...mapGetters("mailCompose", ["showSendResult"]),
    isPopup() {
      const { name } = this.$route;
      return name.includes("popup_mail");
    },
    isWrite() {
      const { name } = this.$route;
      return name.includes("mail_write");
    }
  },
  methods: {
    // 새로고침 / 창닫기
    mailWriteRefresh(event) {
      // 팝업창이고 메일쓰기가 아닐때
      if (this.isPopup && !this.isWrite) return;
      // 팝업창이고 메일쓰기인데 결과창일때
      if (this.isPopup && this.isWrite && this.showSendResult) return;
      // 팝업창이 아닐때
      if (!this.isPopup) {
        // 탭을 사용하는데 메일쓰기탭이 없을때
        if (this.getUseTab && !this.existWriteTab) return;
        // 탭을 사용안하고 현재 라우팅이 메일쓰기가 아닐때
        if (!this.getUseTab && !this.isWrite) return;
        // 결과창일때
        if (this.showSendResult) return;
      }

      // 표준에 따라 기본 동작 방지
      event.preventDefault();
      // Chrome에서는 returnValue 설정이 필요함
      event.returnValue = "";
    }
  }
};
</script>
