<template>
  <v-list outlined dense tile>
    <v-list-item style="user-select:text;">
      <v-list-item-content @click.stop="() => {}">
        <v-list-item-title class="text-h6 py-1" v-text="this.personal" />
        <v-list-item-subtitle
          class="mt-1"
          v-if="this.detail"
          v-text="this.detail"
        />
        <v-list-item-subtitle class="mt-1" v-text="this.address" />
      </v-list-item-content>

      <v-list-item-action>
        <v-btn fab depressed small>
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider class="my-1" />

    <v-hover v-slot="{ hover }" v-for="(item, index) in items" :key="index">
      <v-list-item
        :class="{ 'cr-list-item--hover': hover }"
        :disabled="item.disabled"
        @click.stop="click(item)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        <v-list-item-action v-if="item.icon">
          <v-icon small>{{ item.icon }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getUserInfo } from "@/commons/api/user.api";

export default {
  props: {
    fullEmail: {
      type: String,
      default: ""
    },
    displayEmail: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    },
    ruleSelection: {
      type: String,
      default: "from"
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      personal: "",
      detail: false
    };
  },
  async mounted() {
    // 내부 사용자 정보 조회
    if (this.equalsDomainName(this.getUserInfo.username, this.address)) {
      this.personal = await this.getUser();
    } else {
      this.personal = this.getPersonal();
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    items() {
      return [
        {
          title: this.$t("mail.메일쓰기"),
          icon: "mdi-chevron-right",
          click: () => this.routeMailWrite({ t: 0, to: [this.fullEmail] })
        },
        {
          // 보낸 사람으로 검색
          title: this.$t("mail.275"),
          icon: "mdi-chevron-right",
          click: () => this.routeSearch("queryFrom")
        },
        {
          // 받는사람으로 검색
          title: this.$t("mail.276"),
          icon: "mdi-chevron-right",
          click: () => this.routeSearch("queryTo")
        },
        {
          // 주소록에 추가
          title: this.$t("mail.277"),
          click: () => this.openDialog("contact")
        },
        {
          // 자동분류 추가
          title: this.$t("mail.278"),
          click: () => this.openDialog("rule")
        }
      ];
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("mailRoute", ["routeMailWrite", "routeMailList"]),
    click(item) {
      this.$emit("update:showMenu", false);
      if (item.click) item.click();
    },
    routeSearch(query) {
      const name = "mail_list_action";
      const search = { type: "detail", useSearchHistory: false };
      const actionObj = { search: { ...search, [query]: this.address } };

      this.routeMailList({ name, page: 1, folderId: -1, actionObj });
    },
    openDialog(type) {
      const { address, personal } = this;
      let headline = this.$t("mail.280");
      let params = { email: address, name: personal };
      if (type === "rule") {
        headline = this.$t("mail.279");
        switch (this.ruleSelection) {
          case "from":
            params = { rule: { adrFrom: [this.address] } };
            break;
          case "to":
            params = { rule: { adrTo: [this.address] } };
            break;
          case "cc":
            params = { rule: { adrCc: [this.address] } };
            break;
          default:
            break;
        }
      }

      this.SET_DIALOG({ show: true, headline, type, params });
    },
    equalsDomainName(left, right) {
      return (
        left.substring(left.indexOf("@")).toLowerCase() ===
        right.substring(right.indexOf("@")).toLowerCase()
      );
    },
    getPersonal() {
      let startOfEmail = this.displayEmail.indexOf("<");
      if (startOfEmail !== -1) {
        return this.displayEmail.substring(0, startOfEmail).trim();
      }

      startOfEmail = this.displayEmail.indexOf("@");
      if (startOfEmail !== -1) {
        return this.displayEmail.substring(0, startOfEmail).trim();
      }

      return this.displayEmail.trim();
    },
    async getUser() {
      const { status, data } = await getUserInfo(this.address);
      const { accountName, organizationName, jobTitle, jobGrade } =
        data?.contents ?? {};

      if (status !== 200 || !data.result || !accountName) {
        return this.getPersonal();
      }

      this.personal = accountName;
      let info = [];
      if (organizationName) info.push(organizationName);
      if (jobTitle) info.push(jobTitle);
      if (jobGrade) info.push(jobGrade);
      this.detail = info.join(" / ");

      return accountName;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-list-item--hover::after {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.12;
  cursor: pointer;
  background-color: var(--v-primary-base);
}
</style>
