<template>
  <div>
    <v-btn
      text
      outlined
      color="primary"
      class="cr-view-print-hide"
      @click="showQuoteContent = !showQuoteContent"
    >
      {{ showQuoteContent ? $t("mail.156") : $t("mail.157") }}
    </v-btn>
    <div
      class="cr-quote-content"
      v-show="showQuoteContent"
      v-html="item.quoteContent"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showQuoteContent: false
    };
  }
};
</script>
