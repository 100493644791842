<template>
  <div class="d-flex align-center pl-4">
    <div class="text-subtitle-2 font-weight-bold">
      {{ $t("mail.101", { value: reserveDate }) }}
    </div>
    <v-btn small icon @click="removeReserve">
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("mailCompose", ["reserveDate"])
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_RESERVE"]),
    removeReserve() {
      this.SET_RESERVE({ isReserve: false, reserveDate: "" });
    }
  }
};
</script>
