<template>
  <div class="cr-expansion-header-wrapper">
    <v-progress-linear
      v-if="item.loading"
      indeterminate
      height="5"
      class="cr-progress"
    />
    <!-- 보낸사람 / 툴바 / 읽음,안읽음 / 중요표시 -->
    <FirstRow v-bind="$props" />
    <!-- 미리보기 or 날짜 -->
    <SecondRow v-bind="$props" :showRecipients.sync="showRecipients" />
    <!-- 받는 사람 -->
    <ThirdRow
      v-if="isPanelOpen && showRecipients"
      v-bind="$props"
      :showRecipients="showRecipients"
    />
    <!-- 메일 열람 시간 -->
    <FourthRow v-bind="$props" />
    <!-- 태그 -->
    <div v-if="showTagInput" @click.stop class="pt-2 cr-view-print-hide">
      <TagInput :item="item" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-expansion-header-wrapper {
  .cr-progress {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import ThirdRow from "./ThirdRow";
import TagInput from "./TagInput";
import FourthRow from "./FourthRow";

export default {
  components: { FirstRow, SecondRow, ThirdRow, FourthRow, TagInput },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isPanelOpen: {
      type: Boolean,
      default: false
    },
    resizeWidth: {
      type: Number,
      default: 0
    }
  },
  data() {
    return { showRecipients: true };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteViewInfo"]),
    showTagInput() {
      if (this.isShare) return false;
      if (this.$route.name !== "popup_mail_view") return true;

      const { actionObj } = this.getRouteViewInfo;
      const { sfi, owi, sp } = actionObj?.list || {};
      if (parseInt(sfi) && parseInt(owi) && parseInt(sp)) {
        return false;
      }

      try {
        return !!window.opener;
      } catch (e) {
        return false;
      }
    }
  }
};
</script>
