<template>
  <div class="cr-search-etc">
    <div class="d-flex">
      <!-- 휴지통 -->
      <v-checkbox
        dense
        hide-details
        :disabled="folderId != -1"
        v-model="checkboxTrash"
      >
        <template v-slot:label>
          <div class="subtitle-2">{{ $t("mail.37") }}</div>
        </template>
      </v-checkbox>
      <!-- 첨부파일 -->
      <v-checkbox dense hide-details class="mx-2" v-model="checkboxAttach">
        <template v-slot:label>
          <div class="subtitle-2">{{ $t("mail.38") }}</div>
        </template>
      </v-checkbox>
    </div>

    <div class="d-flex mt-1">
      <!-- 검색 메일함 추가 -->
      <v-btn
        class="mx-2"
        depressed
        color="grey lighten-1 white--text"
        @click="openAddDialog"
      >
        {{ $t("mail.486") }}
      </v-btn>
      <!-- 검색 -->
      <v-btn depressed color="primary" @click="$parent.searchDetail">
        {{ $t("mail.검색") }}
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-search-etc::v-deep {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 99%;

  .v-input.v-input--checkbox {
    margin-top: 0px;
    > .v-input__control > .v-input__slot {
      .v-input--selection-controls__input {
        margin-right: 0px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    folderId: {
      default: -1,
      type: Number
    }
  },
  data({ $store: { getters } }) {
    const { actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};

    return {
      includesTrash: search?.includesTrash,
      includesAttach: search?.includesAttach
    };
  },
  computed: {
    ...mapGetters("folder", ["getSearchRoot"]),
    checkboxTrash: {
      get() {
        return this.includesTrash;
      },
      set(check) {
        this.includesTrash = check ? 1 : 0;
      }
    },
    checkboxAttach: {
      get() {
        return this.includesAttach;
      },
      set(check) {
        this.includesAttach = check ? 1 : 0;
      }
    }
  },
  watch: {
    folderId(folderId) {
      if (folderId !== -1) {
        this.includesTrash = 0;
      }
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    getParams() {
      return {
        valid: false,
        params: {
          includesTrash: this.includesTrash,
          includesAttach: this.includesAttach
        }
      };
    },
    openAddDialog() {
      let targetFolder = { folderId: this.folderId };
      this.$parent.$children.forEach(c => {
        const { params } = c.getParams();
        targetFolder = { ...targetFolder, ...params };
      });
      if (!isBlank(targetFolder.tags)) {
        targetFolder.tags = targetFolder.tags
          ?.split(",")
          ?.map(tag => ({ tag, color: "" }));
      }

      if (!isBlank(targetFolder.queryToCc)) {
        targetFolder.queryTo = targetFolder.queryToCc;
        targetFolder.queryCc = targetFolder.queryToCc;
      }

      if (!isBlank(targetFolder.querySubjectContentAttach)) {
        targetFolder.querySubject = targetFolder.querySubjectContentAttach;
        targetFolder.queryContent = targetFolder.querySubjectContentAttach;
        targetFolder.queryAttach = targetFolder.querySubjectContentAttach;
      }

      if (!isBlank(targetFolder.querySubjectContent)) {
        targetFolder.querySubject = targetFolder.querySubjectContent;
        targetFolder.queryContent = targetFolder.querySubjectContent;
      }

      this.SET_DIALOG({
        show: true,
        headline: this.$t("mail.22"),
        type: "searchFolder",
        params: {
          targetFolder,
          parentFolder: this.getSearchRoot
        }
      });
    }
  }
};
</script>
