<template>
  <div :class="{ 'cr-container': true, 'cr-split-1': this.getSplitView == 1 }">
    <ViewPageTitle ref="pageTitle" :panel.sync="panel" />
    <ViewThread
      ref="thread"
      :panel.sync="panel"
      :resizeWidth="resizeWidth"
      :clickedPanel.sync="clickedPanel"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewPageTitle from "./viewPageTitle";
import ViewThread from "./viewThread";

export default {
  components: { ViewPageTitle, ViewThread },
  props: {
    resizeWidth: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.openPanelAndMoveScroll();
  },
  data() {
    return { panel: [], clickedPanel: false };
  },
  computed: {
    ...mapGetters("mailConfig", ["getSplitView"]),
    ...mapGetters("mailRoute", ["getRouteViewInfo"]),
    ...mapGetters("mailView", ["getViewMails", "requestParams"])
  },
  watch: {
    async getRouteViewInfo() {
      const { mailId } = this.requestParams;
      if (!mailId) return;

      const index = this.getViewMails.findIndex(v => v.mailId == mailId);
      if (index === -1) return;
      if (!this.getViewMails[index].content) await this.getMailView();
      if (this.clickedPanel) {
        this.clickedPanel = false;
        if (this.panel.findIndex(i => i == index) !== -1) return;
      }

      this.openPanelAndMoveScroll();
    }
  },
  methods: {
    ...mapActions("mailView", ["getMailView"]),
    openPanelAndMoveScroll() {
      const { view = {} } = this.getRouteViewInfo?.actionObj || {};
      if (!view?.basedId || !view.selectedDataId) return;

      // 선택된 메일이 열려있는지 확인
      const index = this.getViewMails.findIndex(
        m => m.dataId == view.selectedDataId
      );
      const panelIndex = this.panel.findIndex(p => p == index);
      // 패널이 안열려있을때 패널 열어준다.
      if (panelIndex == -1) {
        this.panel = [index, ...this.panel];
      }

      // scroll 위치 조정
      const timeout = panelIndex == -1 ? 300 : 0;
      setTimeout(() => {
        const { wrapperRef } = this.$parent.$refs;
        const { thread, pageTitle } = this.$refs;
        const [ref] = thread?.$refs[`${view.selectedDataId}_ref`] || [];
        if (!ref) return;

        const titleHeight = index <= 0 ? 0 : pageTitle.$el.offsetHeight;
        const offsetTop = index <= 0 ? 0 : ref.$el.offsetTop;

        wrapperRef.scrollTop = offsetTop + titleHeight;
      }, timeout);
    }
  }
};
</script>
