<template>
  <div class="cr-envelope-container">
    <v-subheader>
      {{ $t("mail.보낸사람") }}
    </v-subheader>

    <div class="cr-input-wrapper d-flex from">
      <Select
        ref="senderName"
        class="pt-2"
        :items="getSenderNames"
        :selectedItem.sync="selectedName"
      />
      <Select
        ref="senderEmail"
        class="pt-2 mr-4"
        :items="emails"
        :selectedItem.sync="selectedEmail"
      />

      <BtnGroups v-if="!isWriteToMe" class="pt-2" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-envelope-container {
  padding-top: 0px !important;
  .cr-input-wrapper.from {
    flex-wrap: wrap;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import Select from "./Select.vue";
import BtnGroups from "./BtnGroups.vue";

export default {
  components: { Select, BtnGroups },
  mounted() {
    let { senderName: selectedName, senderEmail: selectedEmail } = this;

    selectedEmail = this.emails.find(s => s.content === selectedEmail);
    if (!selectedEmail) selectedEmail = this.emails[0];
    this.selectedEmail = selectedEmail;

    selectedName = this.getSenderNames.find(sn => sn === selectedName);
    if (!selectedName) selectedName = this.getDefaultSenderName;
    this.selectedName = selectedName;
  },
  data() {
    return { selectedEmail: "", selectedName: "" };
  },
  computed: {
    ...mapGetters("auth", ["getDomains", "getUserInfo", "getCompanyInfo"]),
    ...mapGetters("mailCompose", ["isWriteToMe", "senderName", "senderEmail"]),
    ...mapGetters("mailConfig", ["getSenderNames", "getDefaultSenderName"]),
    ...mapGetters("mailConfigExternal", ["externalSenders"]),
    ...mapGetters("mailAlias", ["aliasSenders"]),
    emails() {
      const { domainId: senderId } = this.getCompanyInfo;
      const { username } = this.getUserInfo;
      const [userAddress, domain] = username?.split("@") || [];
      if (!username || !domain) return [];

      const domains = this.getDomains.filter(d => d.domainName !== domain);
      return [
        {
          content: username,
          senderType: "DOMAIN",
          senderId
        },
        ...domains.map(d => ({
          content: `${userAddress}@${d.domainName}`,
          senderType: "DOMAIN",
          senderId: d.id
        })),
        ...this.externalSenders.map(ex => ({
          content: ex.email,
          senderType: "EXTERNAL",
          senderId: ex.id
        })),
        ...this.aliasSenders.map(ex => ({
          content: ex.email,
          senderType: "ALIAS",
          senderId: ex.id
        }))
      ];
    }
  },
  watch: {
    senderEmail(email) {
      let selectedEmail = this.emails.find(s => s.content === email);
      if (!selectedEmail) selectedEmail = this.emails[0];

      this.selectedEmail = selectedEmail;
    },
    selectedEmail({ content, senderType, senderId }) {
      this.SET_SENDER({ senderEmail: content, senderType, senderId });
    },
    senderName(senderName) {
      let selectedName = this.getSenderNames.find(sn => sn === senderName);
      if (!selectedName) selectedName = this.getDefaultSenderName;
      this.selectedName = selectedName;
    },
    selectedName(senderName) {
      this.SET_SENDER({ senderName });
    },
    getDefaultSenderName(senderName) {
      this.SET_SENDER({ senderName });
    }
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_SENDER"]),
    selectBlur() {
      const { senderName, senderEmail } = this.$refs;
      if (senderName) senderName?.$refs?.ref?.blur();
      if (senderEmail) senderEmail?.$refs?.ref?.blur();
    }
  }
};
</script>
