var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-file-list-wrapper",on:{"drop":function (e) { return _vm.$emit('drop', e); },"dragenter":function (e) { return _vm.$emit('dragenter', e); },"dragleave":function (e) { return _vm.$emit('dragleave', e); },"dragover":function($event){$event.preventDefault();}}},[_c('v-data-table',{attrs:{"dense":"","show-select":"","fixed-header":"","hide-default-footer":"","item-key":"key","mobile-breakpoint":0,"items-per-page":100,"headers":_vm.headers,"items":_vm.attachFiles},on:{"click:row":function (i, r) { return r.select(!r.isSelected); },"dragenter":function (e) { return _vm.$emit('dragenter', e); },"dragleave":function (e) { return _vm.$emit('dragleave', e); },"dragover":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('Name',{attrs:{"item":item}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('Status',{attrs:{"item":item}})]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('Size',{attrs:{"item":item}})]}},{key:"item.uploadType",fn:function(ref){
var item = ref.item;
return [_c('Type',{attrs:{"item":item}})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }