<template>
  <v-row class="ma-0 align-center">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="4" sm="4">
      {{ $t("mail.335") }}
    </v-col>
    <v-col
      sm="2"
      cols="8"
      class="cr-swtich-wrapper pl-0"
      :class="$vuetify.breakpoint.xs ? 'd-flex justify-end pr-0' : ''"
    >
      <v-switch hide-details inset class="my-1 pb-1" v-model="useTag" />
    </v-col>
    <!-- 태그 입력창 -->
    <v-col v-if="useTag" cols="12" sm="6" class="pl-0 cr-tag-wrap">
      <TagAutocomplete
        ref="tagAutoComp"
        outlined
        :listHeight="170"
        :myTags="getTags"
        :viewTags="viewTags"
        @add:tag="addTag"
        @delete:tag="deleteTag"
      />
      <div v-if="warning" class="cr-warning text-caption">
        <v-icon color="red" size="12">mdi-asterisk</v-icon>
        {{ warning }}
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-tag-wrap {
  position: relative;
  .cr-warning {
    position: absolute;
    padding-top: 3px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import TagAutocomplete from "@/commons/views/tag/autocomplete";

export default {
  components: { TagAutocomplete },
  props: { item: { type: Object, default: () => ({}) } },
  data({ item: { useTag, tagName } }) {
    return {
      tagName,
      useTag: !!useTag,
      viewTags: tagName ? tagName.split(",") : []
    };
  },
  computed: {
    ...mapGetters("folder", ["getTags"]),
    warning() {
      if (!this.item.id) return "";
      return this.$t("mail.356");
    }
  },
  watch: {
    useTag(n) {
      if (!n) return;
      this.tagInputFocus();
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    tagInputFocus() {
      setTimeout(() => {
        const { $refs } = this.$refs.tagAutoComp || {};
        if ($refs?.input) $refs?.input.focus();
      }, 0);
    },
    validation() {
      if (!this.useTag) return true;
      if (this.viewTags.length === 0) {
        this.tagInputFocus();
        this.openSnackbar({ message: this.$t("mail.352"), type: "VALIDATION" });
        return false;
      }

      this.tagName = this.viewTags.join(",");
      return true;
    },
    addTag(tag) {
      this.viewTags.push(tag);
    },
    deleteTag(tag) {
      this.viewTags = this.viewTags.filter(t => t !== tag);
    }
  }
};
</script>
