const extractText = value => {
  // 텍스트 추출
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, "text/html");

  function recursivelyExtractText(node, texts) {
    for (let child of node.childNodes) {
      if (child.nodeType === Node.TEXT_NODE) {
        // 공백과 줄바꿈을 그대로 유지합니다.
        texts.push(child.textContent);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        recursivelyExtractText(child, texts); // 재귀적으로 자식 노드들을 확인합니다.
        // BLOCK 레벨 요소 뒤에는 줄바꿈을 추가합니다.
        if (
          ["DIV", "P", "BR", "HR", "BLOCKQUOTE", "LI"].includes(child.tagName)
        ) {
          texts.push("\n");
        }
      }
    }
  }

  // 모든 텍스트를 저장할 배열입니다.
  let texts = [];
  recursivelyExtractText(doc.body, texts);
  let combinedText = texts.join("").trim();
  combinedText = combinedText
    .split("\n")
    .map(text => {
      return text.trim() ? text.trim() : "";
    })
    .join("\n");
  // 줄바꿈이 2회 이상 연속되면 한 줄바꿈으로 변경하고 앞뒤 공백을 제거합니다.
  combinedText = combinedText.replace(/\n\s*\n/g, "\n").trim();

  return combinedText;
};

export { extractText };
