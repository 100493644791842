var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"min-width":"0px"}},[_c('div',{staticClass:"d-flex align-center",style:(("min-width: 0px; " + (_vm.item.size > 0 ? '' : 'opacity: 0.6')))},[_c('v-icon',{staticClass:"cr-view-print-hide",attrs:{"color":_vm.getIcon.color}},[_vm._v(" "+_vm._s(_vm.getIcon.icon)+" ")]),(_vm.item.size > 0)?_c('div',{staticStyle:{"min-width":"0px"}},[_c('a',{staticClass:"d-flex align-center ml-1",attrs:{"download":_vm.item.name},on:{"click":function($event){$event.preventDefault();return _vm.attachDownload.apply(null, arguments)}}},[_c('FileName',{attrs:{"item":_vm.item}})],1),_c('div',{staticClass:"size grey--text mt-1 ml-1"},[_vm._v(" "+_vm._s(_vm.displaySize)+" ")])]):_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"0px"}},[_c('span',{staticClass:"text-truncate",staticStyle:{"cursor":"default","text-decoration":"line-through","min-width":"0px"}},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('span',{staticClass:"mr-2",staticStyle:{"flex-shrink":"0"}},[_vm._v(" ."+_vm._s(_vm.item.ext)+" ")])])],1),(_vm.item.size > 0)?_c('div',{staticClass:"cr-view-print-hide d-flex align-center ml-1"},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showEmlViewBtn)?_c('v-btn',_vm._g(_vm._b({staticClass:"cr-attach-list-btn",attrs:{"icon":"","x-small":""},on:{"click":_vm.routeView}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-content-copy")])],1):_vm._e()]}}],null,false,3936548745)},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("common.메일로_보기")))])]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cr-attach-list-btn download",attrs:{"icon":"","x-small":""},on:{"click":_vm.attachDownload}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,710927918)},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("common.저장")))])]),(!_vm.isSentType)?_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isEmlView)?_c('v-btn',_vm._g(_vm._b({staticClass:"cr-attach-list-btn close",attrs:{"icon":"","x-small":""},on:{"click":_vm.removeAttach}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}],null,false,1528294001)},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("common.삭제")))])]):_vm._e()],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }