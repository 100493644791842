<template>
  <div>
    <CountryIcon v-if="showCountryIcon" :countryCode="countryCode" />
    <v-hover v-slot="{ hover }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-on="on"
            v-bind="attrs"
            class="text-truncate"
            @click.stop="$emit('update:showMenu', true)"
          >
            <span
              :class="{
                'font-weight-bold': !readStatus,
                'text-decoration-underline': hover
              }"
            >
              {{ displayEmail }}
            </span>
            <slot />
          </div>
        </template>

        <span>{{ fullEmail }}</span>
      </v-tooltip>
    </v-hover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import { containsCountryCode } from "@/commons/utils/localeUtil";
import CountryIcon from "./CountryIcon.vue";

export default {
  components: { CountryIcon },
  props: {
    type: {
      type: String,
      default: "list"
    },
    fullEmail: {
      type: String,
      default: ""
    },
    displayEmail: {
      type: String,
      default: ""
    },
    countryCode: {
      type: String,
      default: ""
    },
    readStatus: {
      type: Number,
      default: 0
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.$emit("update:activatorWidth", this.$el.offsetWidth);
    }, 0);
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("serviceConfig", ["getMailServiceConfig"]),
    showCountryIcon() {
      if (isBlank(this.countryCode) || this.countryCode == '--"') return false;

      const { countryCode } = this.getMailServiceConfig;
      if (countryCode == 0) return false;

      // 현재 설정된 언어에 국가 코드가 포함된 경우 예외 항목으로 처리
      const { languageType } = this.getUserInfo;
      return !containsCountryCode(languageType, this.countryCode);
    }
  }
};
</script>
