<template>
  <v-row class="ma-0" :class="{ 'cr-border': config.useAway }">
    <v-col
      class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2  font-weight-bold"
      cols="12"
      sm="4"
    >
      {{ $t("mail.538") }}
    </v-col>
    <v-col cols="12" sm="8" class="cr-switch-wrapper">
      <v-switch hide-details inset v-model="config.useAway" />
    </v-col>

    <v-col cols="4" class="pb-2 d-flex" />
    <v-col class="pa-0 pt-1 pb-2 pl-2 grey--text text-caption" cols="8">
      {{ $t("mail.537") }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    useAway: {
      get() {
        return this.config?.useAway || 0;
      },
      set(useAway) {
        this.$emit("update:config", { ...this.config, useAway });
      }
    }
  }
};
</script>
