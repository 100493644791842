<template>
  <div class="d-flex align-center">
    <div class="cr-detail-label text-subtitle-2">
      {{ $t("mail.보낸사람") }}
    </div>

    <v-text-field
      dense
      outlined
      hide-details
      autocomplete="off"
      v-model="queryFrom"
      @keydown.enter="$parent.searchDetail"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  height: 40px;
}
</style>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  data({ $store: { getters } }) {
    const { actionObj } = getters["mailRoute/getRouteListInfo"];
    const { search } = actionObj || {};

    let queryFrom = "";
    if (search?.type === "all.from") queryFrom = search?.query || "";
    if (search?.type === "detail") queryFrom = search?.queryFrom || "";
    return { queryFrom };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"])
  },
  watch: {
    getRouteListInfo({ actionObj }) {
      const { search } = actionObj || {};
      let queryFrom = "";
      if (search?.type === "all.from") queryFrom = search?.query || "";
      if (search?.type === "detail") queryFrom = search?.queryFrom || "";
      this.queryFrom = queryFrom;
    }
  },
  methods: {
    getParams() {
      return {
        valid: !isBlank(this.queryFrom),
        params: { queryFrom: this.queryFrom }
      };
    }
  }
};
</script>
