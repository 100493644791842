<template>
  <v-row class="ma-0">
    <v-col class="pb-0 pb-sm-3 cr-config-title" cols="12" sm="4">
      {{ $t("common.비밀번호") }}
    </v-col>
    <v-col cols="12" sm="8" class="pl-0">
      <v-text-field
        ref="fetchPasswordRef"
        outlined
        dense
        hide-details
        type="password"
        v-model="fetchPassword"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  data({ item: { fetchPassword } }) {
    return { fetchPassword };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    validation() {
      const { id } = this.item;
      if (!id && isBlank(this.fetchPassword)) {
        this.$refs.fetchPasswordRef.focus();
        this.openSnackbar({ message: this.$t("mail.351"), type: "VALIDATION" });
        return false;
      }

      return true;
    }
  }
};
</script>
