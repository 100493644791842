<template>
  <Toolbar
    ref="spamListToolRef"
    :indeterminate="indeterminate"
    :items="items"
    :checkbox.sync="checkbox"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  components: { Toolbar },
  data() {
    return { checkbox: false };
  },
  watch: {
    checkbox(checkbox) {
      if (checkbox || (!checkbox && this.indeterminate)) {
        const userFilterList = this.getMails.filter(
          ({ isUserFilter }) => isUserFilter
        );
        this.selectedMails = userFilterList;
      } else {
        this.selectedMails = [];
      }
    },
    selectedMails(selectedMails) {
      const { length: selectedLength } = selectedMails;
      if (selectedLength == 0 && this.checkbox) {
        this.checkbox = false;
      } else if (
        selectedLength != 0 &&
        this.userFilterList.length == selectedLength
      ) {
        setTimeout(() => {
          this.checkbox = true;
        }, 0);
      }
    }
  },

  computed: {
    ...mapGetters("mail", ["getSelectedMails", "getMails"]),
    selectedMails: {
      get() {
        return this.getSelectedMails;
      },
      set(selectedMails) {
        return this.SET_SELECTED_MAILS(selectedMails);
      }
    },
    userFilterList() {
      return this.getMails.filter(({ isUserFilter }) => isUserFilter);
    },
    // 체크박스 미확정 여부 (userfilter만 선택가능 )
    indeterminate() {
      const { length: selectedLength } = this.selectedMails;

      return selectedLength > 0 && selectedLength < this.userFilterList.length;
    },

    items() {
      const { length: selectedLength } = this.selectedMails;

      return [
        {
          ref: "recovery",
          title: this.$t("mail.메일복구"),
          disabled: selectedLength != 1,
          click: async () => {
            this.showBlockDetailDialog(this.selectedMails[0].dataId);
          }
        },
        {
          ref: "allow",
          title: this.$t("mail.차단해제"),
          disabled: selectedLength == 0,
          click: () => {
            this.confirm({
              message: this.$t("mail.433"),
              callback: () => {
                const ids = this.selectedMails
                  .map(({ dataId }) => dataId)
                  .join(",");

                this.unblockSender(ids);
              }
            });
          }
        }
      ];
    }
  },
  methods: {
    ...mapMutations("mail", ["SET_SELECTED_MAILS"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("mail", ["unblockSender", "showBlockDetailDialog"])
  }
};
</script>
