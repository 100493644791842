<template>
  <div class="d-flex" style="float:right;">
    <div class="flex-grow-1">
      <v-btn small depressed height="34" @click="openAIDialog('SUMMARY')">
        <v-icon small color="primary" class="mr-1">mdi-creation-outline</v-icon>
        {{ $t("mail.591") }}
      </v-btn>
    </div>
    <div class="d-flex" style="width:36px">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small tile depressed height="34" v-bind="attrs" v-on="on">
            <v-icon small color="grey">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="openAIDialog('TRANSLATE')">
            <v-list-item-title>{{ $t("mail.585") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { extractText } from "@/commons/utils/htmlUtils";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("ai", ["showAIDialog"]),
    openAIDialog(type) {
      const { content } = this.item;

      this.showAIDialog({
        type: type,
        contents: extractText(content),
        run: true
      });
    },
    translate() {
      const { content } = this.item;

      this.showAIDialog({
        type: "TRANSLATE",
        contents: extractText(content),
        run: false
      });
    }
  }
};
</script>
