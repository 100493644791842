<template>
  <div
    class="cr-attach-draggable-area"
    :class="{ hover, 'cr-empty': isEmptyFiles }"
    @drop="e => $emit('drop', e)"
    @dragenter="e => $emit('dragenter', e)"
    @dragleave="e => $emit('dragleave', e)"
    @dragover.prevent
  >
    <DefaultArea
      v-if="isEmptyFiles"
      @dragenter="e => $emit('dragenter', e)"
      @dragleave="e => $emit('dragleave', e)"
      @openFileInput="openFileInput"
      @openDriveDialog="openDriveDialog"
    />
    <AttachToolbar
      v-else
      @dragenter="e => $emit('dragenter', e)"
      @dragleave="e => $emit('dragleave', e)"
      @openFileInput="openFileInput"
      @openDriveDialog="openDriveDialog"
    />
    <input
      v-show="false"
      multiple
      type="file"
      ref="fileInput"
      @change="changeFileInput"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-attach-draggable-area {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 1px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  &.cr-empty {
    justify-content: center;
  }

  &.hover {
    border: 2px solid #1867c0;
    padding: 0px;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import DefaultArea from "./DefaultArea";
import AttachToolbar from "./AttachToolbar";

export default {
  components: { DefaultArea, AttachToolbar },
  props: {
    hover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("mailFile", ["isEmptyFiles"])
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    // file input을 통한 파일등록
    openFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    changeFileInput({ target }) {
      if (!target.files) return;
      this.$emit("addFiles", target.files);
    },
    openDriveDialog() {
      const headline = this.$t("mail.553");
      this.SET_DIALOG({ headline, show: true, type: "driveAttach" });
    }
  }
};
</script>
