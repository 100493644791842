<template>
  <v-row class="py-2" @keyup.stop>
    <v-col cols="12" class="pa-1">
      <TagAutocomplete
        outlined
        :creatable="false"
        :placeholder="$t('mail.483')"
        :listHeight="240"
        :myTags="getTags"
        :viewTags="viewTags"
        @add:tag="addTag"
        @delete:tag="deleteTag"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import TagAutocomplete from "@/commons/views/tag/autocomplete";

export default {
  components: { TagAutocomplete },
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("folder", ["getTags"]),
    viewTags: {
      get() {
        return this.tags.map(t => t.tag);
      },
      set(tags) {
        this.$emit("update:tags", tags);
      }
    }
  },
  methods: {
    addTag(tag) {
      this.viewTags = [...this.tags, { tag, color: "" }];
    },
    deleteTag(tag) {
      this.viewTags = this.tags.filter(t => t.tag !== tag);
    }
  }
};
</script>
