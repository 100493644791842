<template>
  <v-row class="py-2">
    <v-col cols="12" sm="6" md="4" class="pa-1">
      <v-text-field
        dense
        outlined
        hide-details
        required
        autofocus
        ref="newNameFolder"
        :label="`${$t('mail.252')}*`"
        autocomplete="off"
        v-model="nameFolderModel"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="pa-1">
      <MailboxSelect
        :label="$t('mail.메일함')"
        :folderId="folderId"
        @change="changeFolder"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
      class="pa-0 pl-2"
      :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'd-flex' : ''"
    >
      <v-checkbox
        dense
        hide-details
        :label="$t('mail.37')"
        class="pa-0 ma-0"
        v-model="includesTrashModel"
        :disabled="folderId != -1"
      ></v-checkbox>
      <v-checkbox
        dense
        hide-details
        :label="$t('mail.38')"
        class="pa-0 ma-0"
        :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'ml-1' : ''"
        v-model="includesAttachModel"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";

export default {
  components: { MailboxSelect },
  props: {
    folderId: {
      type: Number,
      default: -1
    },
    nameFolder: {
      type: String,
      default: ""
    },
    includesTrash: {
      type: Number,
      default: 0
    },
    includesAttach: {
      type: Number,
      default: 0
    }
  },
  computed: {
    nameFolderModel: {
      get() {
        return this.nameFolder;
      },
      set(nameFolder) {
        this.$emit("update:nameFolder", nameFolder);
      }
    },
    includesTrashModel: {
      get() {
        return this.includesTrash;
      },
      set(includesTrash) {
        this.$emit("update:includesTrash", includesTrash ? 1 : 0);
      }
    },
    includesAttachModel: {
      get() {
        return this.includesAttach;
      },
      set(includesAttach) {
        this.$emit("update:includesAttach", includesAttach ? 1 : 0);
      }
    }
  },
  methods: {
    changeFolder(folder) {
      this.$emit("update:folderId", folder.id);
    }
  }
};
</script>
