<template>
  <div @click.stop>
    <Toolbar ref="viewToolRef" :showCheckbox="false" :items="items" />
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";
// import {
//   getDateWithoutTime,
//   getHours,
//   getMinutes,
//   timezone
// } from "@/commons/utils/moment";

export default {
  components: { Toolbar },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    toolLimitWidth: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    setTimeout(() => {
      // toolbar 공백 초기값 11, dots 44
      let sumWidth = 55;
      const widthArr = {};
      if (this.$refs?.viewToolRef?.$refs) {
        Object.keys(this.$refs.viewToolRef.$refs).forEach(key => {
          const [itemRef] = this.$refs.viewToolRef.$refs[key] || [];
          if (!itemRef) return;
          if (key != "dots") sumWidth += itemRef.$el.offsetWidth;
          widthArr[key] = sumWidth;
        });
      }

      this.widthArr = widthArr;
    }, 0);
  },
  data() {
    return {
      // 초기값이 배열인건 초기 mount확인을 위함
      widthArr: []
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsedModule", "getCustomizeUIInfo"]),
    ...mapGetters("mail", ["getMails"]),
    ...mapGetters("folder", [
      "getFolders",
      "getTrashId",
      "isShare",
      "isSent",
      "isTome",
      "currentShareFolder"
    ]),
    ...mapGetters("mailRoute", ["getRouteViewInfo", "getRouteShareInfo"]),
    isSharedMail() {
      if (this.$route.name == "popup_mail_view") {
        const { actionObj } = this.getRouteViewInfo;
        const { sfi, owi, sp } = actionObj?.list || {};
        return !!(parseInt(sfi) && parseInt(owi) && parseInt(sp));
      }

      return this.isShare;
    },
    items() {
      const {
        readStatus = 0,
        folderType,
        mailType,
        attachPosition
      } = this.item;
      const isPopup = this.$route.name == "popup_mail_view";

      /**
       * permission
       * - 2 : 읽기
       * - 3 : 편집
       */
      let { permission = 3 } =
        (this.isSharedMail && this.currentShareFolder) || {};
      if (isPopup && this.isSharedMail) {
        const { actionObj } = this.getRouteViewInfo;
        permission = actionObj?.list?.sp;
      }

      let hasOpener = false;
      try {
        if (window.opener) {
          hasOpener = true;
        }
      } catch (e) {
        console.error(e);
      }

      return [
        {
          ref: "resend",
          title:
            mailType == "RESERVE"
              ? i18n.t("mail.예약수정")
              : i18n.t("mail.다시보내기"),
          showItem: folderType == "SENT",
          click: () => this.routeWrite(5)
        },
        {
          ref: "reserve_cancel",
          title: i18n.t("mail.예약취소"),
          showItem: folderType == "SENT" && mailType == "RESERVE",
          click: this.cancelReserve
        },
        {
          ref: "read",
          title: readStatus == 0 ? i18n.t("mail.읽음") : i18n.t("mail.안읽음"),
          showItem: permission == 3 && attachPosition == -1,
          click: () => this.updateReadStatus({ mails: [this.item] })
        },
        {
          ref: "delete",
          title: i18n.t("common.삭제"),
          showItem:
            !this.isSharedMail &&
            attachPosition == -1 &&
            ((!isPopup && folderType !== "DELETED") ||
              (isPopup && folderType !== "DELETED" && hasOpener)),
          click: () => this.deleteMailConfirm(isPopup && !hasOpener)
        },
        {
          ref: "deleteCompletely",
          title: i18n.t("mail.완전삭제"),
          showItem:
            (!isPopup && folderType == "DELETED") ||
            (isPopup && folderType == "DELETED" && hasOpener),
          click: () => {
            if (isPopup && !hasOpener) return;
            this.deleteMails([this.item]);
          }
        },
        {
          ref: "reply",
          title: i18n.t("mail.답장"),
          showItem:
            permission == 3 &&
            attachPosition == -1 &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: i18n.t("mail.164")
              });
            this.routeWrite(1);
          }
        },
        {
          ref: "replyAll",
          title: i18n.t("mail.전체답장"),
          showItem:
            permission == 3 &&
            attachPosition == -1 &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: i18n.t("mail.164")
              });
            this.routeWrite(3);
          }
        },
        {
          ref: "forward",
          title: i18n.t("mail.전달"),
          showItem:
            permission == 3 &&
            attachPosition == -1 &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: i18n.t("mail.164")
              });
            this.routeWrite(2);
          }
        },
        {
          ref: "move",
          title: i18n.t("mail.이동"),
          showItem:
            !this.isSharedMail &&
            attachPosition == -1 &&
            ((!isPopup && folderType !== "SENT") || (isPopup && hasOpener)),
          click: () => {
            if (isPopup && !hasOpener) return;

            this.SET_DIALOG({
              headline: i18n.t("mail.165"),
              show: true,
              type: "moveMail",
              params: {
                mails: [this.item]
              }
            });
          }
        },
        {
          ref: "share",
          showItem:
            permission == 3 &&
            attachPosition == -1 &&
            (this.getUsedModule.board ||
              this.getCustomizeUIInfo.tigrisEnabled == 1),
          title: i18n.t("common.공유"),
          type: "menu",
          vModel: "share",
          children: [
            {
              ref: "postShare",
              title: i18n.t("mail.511"),
              showItem: this.getUsedModule.board,
              click: () => {
                if (isPopup && !hasOpener) return;
                this.SET_DIALOG({
                  headline: i18n.t("mail.511"), // 게시판으로 공유
                  show: true,
                  type: "postShare",
                  params: {
                    mailId: this.item.mailId,
                    includeAttach: true
                  }
                });
              }
            },
            {
              title: i18n.t("mail.516"),
              showItem: this.getCustomizeUIInfo.tigrisEnabled == 1,
              click: () => {
                this.SET_DIALOG({
                  headline: i18n.t("mail.516"), // 티그리스 피드 등록
                  show: true,
                  type: "postShareForTigris",
                  params: {
                    mailId: this.item.mailId,
                    includeAttach: true
                  }
                });
              }
            }
          ]
        },
        {
          ref: "print",
          title: i18n.t("mail.인쇄"),
          showItem:
            folderType !== "DRAFTS" &&
            folderType !== "RESERVE" &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: i18n.t("mail.164")
              });
            this.printMail();
          }
        },
        {
          ref: "block",
          title: i18n.t("mail.수신거부"),
          showItem:
            folderType !== "SENT" && attachPosition == -1 && !this.isSharedMail,
          click: () => {
            const { address, personal } = this.item.from;
            const email = personal ? `${personal} &lt${address}&gt` : address;

            this.confirm({
              headline: i18n.t("mail.428"),
              message: `<div>${i18n.t("mail.429", { email: email })}</div>`,
              callback: () => {
                this.unsubscribe(address);
              }
            });
          }
        },
        {
          ref: "report",
          title: i18n.t("mail.악성메일신고"),
          showItem: false,
          /* TODO 메일 회수 사용시 활성화
          showItem:
            !this.isShare &&
            permission == 3 &&
            !this.isSent &&
            !this.isTome &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          */
          click: () => this.openReportMailDialog()
        },
        {
          ref: "save",
          title: i18n.t("mail.166"),
          showItem:
            permission == 3 &&
            folderType !== "DRAFTS" &&
            folderType !== "RESERVE" &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: i18n.t("mail.164")
              });
            this.downloadMail();
          }
        },
        {
          ref: "origin",
          title: i18n.t("mail.519"),
          showItem:
            permission == 3 &&
            folderType !== "DRAFTS" &&
            folderType !== "RESERVE" &&
            !(
              this.item.mailType == "REPORT_MAIL" ||
              this.item.mailType == "SYSTEM_BLOCK"
            ),
          click: () => {
            if (this.isBlockedMail())
              return this.openSnackbar({
                message: i18n.t("mail.164")
              });
            this.viewOriginal();
          }
        },
        {
          ref: "dots",
          title: "dots",
          type: "menu",
          vModel: "dots",
          menuIcon: "mdi-dots-horizontal",
          children: [
            {
              ref: "resend",
              title:
                mailType == "RESERVE"
                  ? i18n.t("mail.예약수정")
                  : i18n.t("mail.다시보내기"),
              showItem: folderType == "SENT",
              click: () => this.routeWrite(5)
            },
            {
              ref: "reserve_cancel",
              title: i18n.t("mail.예약취소"),
              showItem: folderType == "SENT" && mailType == "RESERVE",
              click: () => this.deleteMailReserve(this.item.mailId)
            },
            {
              ref: "read",
              title:
                readStatus == 0 ? i18n.t("mail.읽음") : i18n.t("mail.안읽음"),
              showItem: permission == 3,
              click: () => this.updateReadStatus({ mails: [this.item] })
            },
            {
              ref: "delete",
              title: i18n.t("common.삭제"),
              showItem:
                !this.isSharedMail &&
                ((!isPopup && folderType !== "DELETED") ||
                  (isPopup && hasOpener)),
              click: () => this.deleteMailConfirm(isPopup && !hasOpener)
            },
            {
              ref: "deleteCompletely",
              title: i18n.t("mail.완전삭제"),
              showItem:
                (!isPopup && folderType == "DELETED") || (isPopup && hasOpener),
              click: () => {
                if (isPopup && !hasOpener) return;
                this.deleteMails([this.item]);
              }
            },
            {
              ref: "reply",
              title: i18n.t("mail.답장"),
              showItem: permission == 3 && folderType !== "SENT",
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: i18n.t("mail.164")
                  });
                this.routeWrite(1);
              }
            },
            {
              ref: "replyAll",
              title: i18n.t("mail.전체답장"),
              showItem: permission == 3 && folderType !== "SENT",
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: i18n.t("mail.164")
                  });
                this.routeWrite(3);
              }
            },
            {
              ref: "forward",
              title: i18n.t("mail.전달"),
              showItem: permission == 3,
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: i18n.t("mail.164")
                  });
                this.routeWrite(2);
              }
            },
            {
              ref: "move",
              title: i18n.t("mail.이동"),
              showItem:
                !this.isSharedMail &&
                ((!isPopup && folderType !== "SENT") || (isPopup && hasOpener)),
              click: () => {
                if (isPopup && !hasOpener) return;

                this.SET_DIALOG({
                  headline: i18n.t("mail.165"),
                  show: true,
                  type: "moveMail",
                  params: {
                    mails: [this.item]
                  }
                });
              }
            },
            {
              ref: "share",
              showItem:
                permission == 3 &&
                (this.getUsedModule.board ||
                  this.getCustomizeUIInfo.tigrisEnabled == 1),
              title: i18n.t("common.공유"),
              type: "menu",
              vModel: "share",
              children: [
                {
                  ref: "postShare",
                  title: i18n.t("mail.511"),
                  showItem: this.getUsedModule.board,
                  click: () => {
                    if (isPopup && !hasOpener) return;
                    this.SET_DIALOG({
                      headline: i18n.t("mail.511"), // 게시판으로 공유
                      show: true,
                      type: "postShare",
                      params: {
                        mailId: this.item.mailId,
                        includeAttach: true
                      }
                    });
                  }
                },
                {
                  title: i18n.t("mail.516"),
                  showItem: this.getCustomizeUIInfo.tigrisEnabled == 1,
                  click: () => {
                    this.SET_DIALOG({
                      headline: i18n.t("mail.516"), // 티그리스 피드 등록
                      show: true,
                      type: "postShareForTigris",
                      params: {
                        mailId: this.item.mailId,
                        includeAttach: true
                      }
                    });
                  }
                }
              ]
            },
            {
              ref: "print",
              title: i18n.t("mail.인쇄"),
              showItem:
                folderType !== "DRAFTS" &&
                folderType !== "RESERVE" &&
                !(
                  this.item.mailType == "REPORT_MAIL" ||
                  this.item.mailType == "SYSTEM_BLOCK"
                ),
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: i18n.t("mail.164")
                  });
                this.printMail();
              }
            },
            {
              ref: "block",
              title: i18n.t("mail.수신거부"),
              showItem: folderType !== "SENT" && !this.isSharedMail,
              click: () => {
                const { address, personal } = this.item.from;
                const email = personal
                  ? `${personal} &lt${address}&gt`
                  : address;

                this.confirm({
                  headline: i18n.t("mail.428"),
                  message: `<div>${i18n.t("mail.429", { email: email })}</div>`,
                  callback: () => {
                    this.unsubscribe(address);
                  }
                });
              }
            },
            {
              ref: "report",
              title: i18n.t("mail.악성메일신고"),
              showItem: false,
              /* TODO 메일 회수 사용시 활성화
              showItem:
                !this.isShare &&
                permission == 3 &&
                !this.isSent &&
                !this.isTome &&
                !(
                  this.item.mailType == "REPORT_MAIL" ||
                  this.item.mailType == "SYSTEM_BLOCK"
                ),
              */
              click: () => this.openReportMailDialog()
            },
            {
              ref: "save",
              title: i18n.t("mail.166"),
              showItem:
                permission == 3 &&
                folderType !== "DRAFTS" &&
                folderType !== "RESERVE" &&
                !(
                  this.item.mailType == "REPORT_MAIL" ||
                  this.item.mailType == "SYSTEM_BLOCK"
                ),
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: i18n.t("mail.164")
                  });
                this.downloadMail();
              }
            },
            {
              ref: "origin",
              title: i18n.t("mail.519"),
              showItem:
                permission == 3 &&
                folderType !== "DRAFTS" &&
                folderType !== "RESERVE" &&
                !(
                  this.item.mailType == "REPORT_MAIL" ||
                  this.item.mailType == "SYSTEM_BLOCK"
                ),
              click: () => {
                if (this.isBlockedMail())
                  return this.openSnackbar({
                    message: i18n.t("mail.164")
                  });
                this.viewOriginal();
              }
            }
          ].filter(item => this.widthArr[item.ref] >= this.toolLimitWidth)
        }
      ].filter(item => {
        if (this.toolLimitWidth == 0 || this.widthArr.length == 0) return true;
        if (item.title == "dots") {
          return this.widthArr[item.ref] >= this.toolLimitWidth;
        }
        return this.widthArr[item.ref] < this.toolLimitWidth;
      });
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mailRoute", ["routeMailWrite", "routeMailView"]),
    ...mapActions("mail", [
      "updateReadStatus",
      "deleteMails",
      "deleteMailReserve",
      "unsubscribe",
      "checkMailsBeforeMove"
    ]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    routeWrite(type) {
      const { mailId, mailType } = this.item;

      if (type == 5 && mailType == "RESERVE") {
        this.confirm({
          message: i18n.t("mail.167"),
          callback: async () => {
            const reserveData = await this.deleteMailReserve(mailId);
            if (reserveData) {
              await this.routeMailWrite({ t: type, i: mailId, is: [mailId] });
              // 기존에 예약정보 다시 세팅해줬었음
              // TODO:: 메일목록, 보기화면에 예약정보 갱신필요

              // const [gmt] =
              //   timezone.filter(t => t.value == reserveData.info.gmt) ||
              //   timezone;
              // this.SET_RESERVE_OPTION({
              //   isReserve: true,
              //   gmt,
              //   timeValue: 4,
              //   date: getDateWithoutTime(
              //     reserveData.reserveTimeMillis,
              //     "YYYY-MM-DD"
              //   ),
              //   hours: parseInt(getHours(reserveData.reserveTimeMillis)),
              //   min: getMinutes(reserveData.reserveTimeMillis)
              // });
            }
          }
        });
      } else {
        this.routeMailWrite({ t: type, i: mailId, is: [mailId] });
      }
    },
    cancelReserve() {
      this.confirm({
        message: i18n.t("mail.168"),
        callback: () => {
          this.deleteMailReserve(this.item.mailId);
        }
      });
    },
    downloadMail() {
      const { mailId, attachPosition } = this.item;
      const { folderId, ownerId } = this.getRouteShareInfo;

      const url = this.isSharedMail
        ? `${
            process.env.VUE_APP_API_SERVER_URL
          }/api/share/mails/download/eml/${mailId}/${attachPosition}?folderId=${folderId}&ownerId=${ownerId}&access_token=${localStorage.getItem(
            "access_token"
          )}`
        : `${
            process.env.VUE_APP_API_SERVER_URL
          }/api/mail/download/eml/${mailId}/${attachPosition}?access_token=${localStorage.getItem(
            "access_token"
          )}`;

      window.location.href = url;
    },
    viewOriginal() {
      const { mailId, attachPosition } = this.item;
      const { folderId, ownerId } = this.getRouteShareInfo;
      const actionObj = {
        mailId,
        folderId,
        ownerId,
        attachPosition,
        isSharedMail: this.isSharedMail
      };

      const url = `/#/mail/view/original/${JSON.stringify(actionObj)}`;
      const popupName = `mailview-original-${mailId}-${attachPosition}`;
      const options =
        "width=1050,height=870,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes";

      if (attachPosition === -1) return window.open(url);
      window.open(url, popupName, options);
    },
    printMail() {
      if (
        this.item.attachPosition !== -1 ||
        this.getRouteViewInfo?.actionObj?.view?.printable
      ) {
        setTimeout(() => {
          window.print();
        }, 100);
      } else {
        this.routeMailView({
          basedId: this.item.mailId,
          selectedDataId: this.item.mailId,
          attachPosition: this.item.attachPosition,
          printable: true
        });
      }
    },
    // 메일 삭제
    deleteMailConfirm(isPrevent) {
      if (isPrevent) return;
      this.checkMailsBeforeMove({
        targetFolderId: this.getTrashId,
        selectedMails: [this.item]
      });
    },
    openReportMailDialog() {
      if (this.isBlockedMail()) {
        return this.openSnackbar({
          message: i18n.t("mail.170")
        });
      }
      this.SET_DIALOG({
        headline: i18n.t("mail.171"),
        show: true,
        type: "spamReport",
        params: {
          mails: [this.item]
        },
        btnTitles: { confirm: i18n.t("mail.보내기") }
      });
    },
    // 차단 메일 확인
    isBlockedMail() {
      return (
        this.item.mailType == "REPORT_MAIL" ||
        this.item.mailType == "SYSTEM_BLOCK"
      );
    }
  }
};
</script>
