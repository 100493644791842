<template>
  <v-container @keyup.enter="() => $emit('update:confirm', true)">
    <FolderInfo
      ref="folderInfo"
      :folderId.sync="folderId"
      :nameFolder.sync="nameFolder"
      :includesTrash.sync="includesTrash"
      :includesAttach.sync="includesAttach"
    />
    <Recipients
      :queryFrom.sync="queryFrom"
      :queryTo.sync="queryTo"
      :queryCc.sync="queryCc"
    />
    <Content
      :querySubject.sync="querySubject"
      :queryContent.sync="queryContent"
      :queryAttach.sync="queryAttach"
    />
    <MailSize ref="mailSize" :sizeRange="sizeRange" />
    <Period ref="period" :timeRange="timeRange" />
    <Tag :tags.sync="tags" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import FolderInfo from "./searchFolder/FolderInfo";
import Recipients from "./searchFolder/Recipients";
import Content from "./searchFolder/Content";
import MailSize from "./searchFolder/MailSize";
import Period from "./searchFolder/Period";
import Tag from "./searchFolder/Tag";

export default {
  components: { FolderInfo, Recipients, Content, MailSize, Period, Tag },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data(vue) {
    const getters = vue.$store.getters;
    const { targetFolder: folder } = getters["mailDialog/params"];

    return {
      nameFolder: folder?.nameFolder || "",
      folderId: folder?.folderId == 0 ? -1 : folder?.folderId,
      queryFrom: folder?.queryFrom || "",
      queryTo: folder?.queryTo || "",
      queryCc: folder?.queryCc || "",
      querySubject: folder?.querySubject || "",
      queryContent: folder?.queryContent || "",
      queryAttach: folder?.queryAttach || "",
      sizeRange: folder?.sizeRange || [],
      timeRange: folder?.timeRange || [],
      includesAttach: folder?.includesAttach ? 1 : 0,
      includesTrash: folder?.includesTrash ? 1 : 0,
      tags: folder?.tags || []
    };
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    ...mapGetters("folder", ["getOpenFolders"])
  },
  watch: {
    confirm(next) {
      if (!next) return;
      this.addFolderFunc();
    },
    folderId(folderId) {
      this.includesTrash = folderId == -1 ? this.includesTrash : 0;
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("folder", ["SET_OPEN_FOLDERS"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("folder", ["addFolder", "updateSearchFolder"]),
    async addFolderFunc() {
      const { nameFolder, params, folderId } = this;
      const { parentFolder, targetFolder } = params;
      this.nameFolder = nameFolder.trim();
      if (!this.nameFolder) {
        this.openSnackbar({ message: this.$t("mail.315"), type: "VALIDATION" });
        this.$emit("update:confirm", false);
        return this.$refs.folderInfo.$refs.newNameFolder.focus();
      }

      let sizeRange = [];
      if (this.$refs.mailSize.sizeSelectItem !== "all") {
        let {
          startSize,
          startSizeUnit,
          endSize,
          endSizeUnit
        } = this.$refs.mailSize;
        startSize = startSize * startSizeUnit || 0;
        endSize = endSize * endSizeUnit || 0;
        sizeRange = [startSize, endSize];
      }

      let { startDate, endDate } = this.$refs.period;
      startDate = new Date(startDate).getTime() || 0;
      endDate = new Date(endDate).getTime() || 0;
      const options = {
        ...this.$data,
        sizeRange,
        timeRange: [startDate, endDate],
        folderId: folderId > 0 ? folderId : 0
      };

      if (targetFolder?.id > 0) {
        await this.updateSearchFolder({
          folderId: targetFolder.id,
          nameFolder: this.nameFolder,
          options
        });
      } else {
        await this.addFolder({
          nameFolder: this.nameFolder,
          folderType: "SEARCH",
          parentId: parentFolder.id,
          options
        });
      }

      this.SET_OPEN_FOLDERS([...this.getOpenFolders, parentFolder]);
      this.CLOSE_DIALOG();
    }
  }
};
</script>
