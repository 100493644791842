<template>
  <div class="cr-btn-group">
    <v-tooltip top nudge-top="-5">
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined tile text class="pl-0" @click="openDialog()">
          <v-btn
            v-on="on"
            v-bind="attrs"
            x-small
            tile
            depressed
            color="transparent"
            class="px-0 mr-1"
            @click.stop="changeType()"
            v-if="typesSize > 1"
          >
            <v-icon color="grey">
              mdi-swap-horizontal
            </v-icon>
          </v-btn>

          {{ text }}
        </v-btn>
      </template>
      {{ tooltip }}
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.cr-btn-group {
  .v-btn {
    height: 40px;

    &.organ {
      border-left: 0;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      types: this.initializeTypes(),
      selectorType: this.initializeSelectorType()
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsedModule", "restrictedFunctions"]),
    typesSize() {
      return Object.keys(this.types).length;
    },
    tooltip() {
      return this.types[this.selectorType].tooltip;
    },
    text() {
      const keys = Object.keys(this.types);
      const currentIndex = keys.indexOf(this.selectorType);
      return this.types[keys[currentIndex]].title;
    }
  },
  methods: {
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    initializeSelectorType() {
      const type = localStorage.getItem("st#mail#write#type");
      if (!type || type === "undefined") return "contact";

      const { isOrgChartRestricted } = this.$store.getters[
        "auth/restrictedFunctions"
      ];
      if (isOrgChartRestricted && type === "organ") {
        return "contact";
      }

      const { customer } = this.$store.getters["auth/getUsedModule"];
      if (!customer && type === "customer") {
        return "contact";
      }

      return type;
    },
    initializeTypes() {
      const types = {};

      const { isOrgChartRestricted } = this.$store.getters[
        "auth/restrictedFunctions"
      ];
      const { customer } = this.$store.getters["auth/getUsedModule"];

      if (!isOrgChartRestricted) {
        types.organ = {
          text: "organ",
          title: this.$t("common.조직도"),
          tooltip: this.$t("mail.551")
        };
      }

      types.contact = {
        text: "contact",
        title: this.$t("common.주소록"),
        tooltip: customer ? this.$t("mail.587") : this.$t("mail.552")
      };

      if (customer) {
        types.customer = {
          text: "customer",
          title: this.$t("common.공용주소록"),
          tooltip: this.$t("mail.552")
        };
      }

      const itemSize = Object.keys(types).length;
      if (itemSize === 1) {
        for (const key in types) {
          types[key].tooltip = types[key].title;
        }
      }

      return types;
    },
    changeType() {
      if (this.isOrgChartRestricted) return;

      const keys = Object.keys(this.types);
      const currentIndex = keys.indexOf(this.selectorType);
      const nextIndex = (currentIndex + 1) % keys.length;
      this.selectorType = this.types[keys[nextIndex]].text;

      localStorage.setItem("st#mail#write#type", this.selectorType);
    },
    openDialog(type) {
      if (!type) {
        const keys = Object.keys(this.types);
        const currentIndex = keys.indexOf(this.selectorType);
        type = this.types[keys[currentIndex]].text;
      }

      this.SET_DIALOG({
        show: true,
        headline: "",
        type: "recipient",
        params: { type }
      });
    }
  }
};
</script>
