<template>
  <div>{{ serverName }}</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: { item: { type: Object, default: () => ({}) } },
  computed: {
    ...mapGetters("mailConfigExternal", ["getServerName"]),
    serverName() {
      return this.getServerName(this.item.serverName).text;
    }
  }
};
</script>
