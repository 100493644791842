<template>
  <span class="grey--text">
    {{ status }}
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    status() {
      switch (this.item?.status) {
        case 0:
          return this.$t("mail.118");
        case 1:
          return this.$t("mail.119");
        default:
          return this.$t("mail.120");
      }
    }
  }
};
</script>
